import { call, take, put }               from 'redux-saga/effects';
import axios                             from 'axios';
import { SUBMIT_COMPLETION_OF_SCENARIO } from '../actions';


const env = process.env.NODE_ENV;
const endpoint = `${ process.env[`REACT_APP_URL_${env}`]}/${env}/scenarioLog`


function* scenarioCompletion() {
  while(true) {
    try {

         const userInput = yield take('SUBMIT_COMPLETION_OF_SCENARIO_SAGA');
    
         const response = yield call(
          axios.post,
          endpoint,
          { 
            headers: {
              'Access-Control-Allow-Origin': '*'
            },
            payload: userInput.payload
          }
         );

         console.log('response.data   :   ',response.data)
  
  
         yield put(SUBMIT_COMPLETION_OF_SCENARIO(userInput.payload))

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default scenarioCompletion;

