import React              from 'react';
import colors             from '../../utils/colors'
import { makeStyles }     from '@material-ui/core/styles';
import { words }          from '../../utils/dictionary';


const useStyles = makeStyles(theme => ({
    
   
    text_sign_container: {
      position:        'fixed',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: colors.kehe,
      border:          `1px solid ${ '#FFF'/* colors.halavi */ }`,
      left:            '50%',
      top:             '50%',
      padding:         10,
      borderRadius:    '1em',
      zIndex:          10,
    },
    text_sign: {
      padding:         30,
      width:           '90%',
      height:          '90%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           '#FFF',//colors.darkGrey,
      textShadow:      '0 0 7px rgba(0, 0, 0, 1)',
      fontWeight:      'bold',
      borderRadius:    '0.5em',
    },
    icon: {
      position:        'fixed',
      left:            '50%',
      transform:       'translate(-50%, 0)',
      width:           185,
      height:          85,
      color:           '#FFF',
      fontSize:        35,
      backgroundColor: colors.blue_,
      borderRadius:    '1em',
      cursor:          'pointer',
      border:          `1px solid #FFF`,
      boxShadow:       '0 5px 5px rgba(0,0,0,0.4)',
      zIndex:          11,
      transition:      'bottom 1.7s, top 1.7s, width 0.4s',
      '&:hover': {
        width: 235
      }
    }
}));


type TextSignProps = {
    size:                number[];
    emotionMenuText:     string[];
    lang:                string;
    dir:                 string;
    showTextSign:        boolean;
}

const TextSign: React.FC<TextSignProps> = ({ 
    size,
    emotionMenuText,
    showTextSign,
    lang,
    dir
  }) => {

  const classes = useStyles();


  return (
       <>
              <div 
                    className = { classes.text_sign_container }
                    style     = {{
                      transform:  `translate(-50%, ${ showTextSign ? -40 : 150 }%)`,
                      opacity:    showTextSign ? 1 : 0,
                      width:      size[0] > 600 ? 550 : '85vw',
                      transition: showTextSign ? 'all 2s' : 'all 1s',
                    }}
              >
                      <div 
                        dir       = { dir }
                        className = { classes.text_sign }
/*                         onClick   = { handleTextSignClick }
 */                        style     = {{
                          fontSize:   size[0] > 600 && size[1] > 600 ? 35 : 25,
                        }}
                      >
                        { `${ words[emotionMenuText[0]][lang] }` }
                      </div>
                
                   
              </div>
              {/* <ForwardRoundedIcon 
                        className = { classes.icon }
                        onClick   = { handleTextSignClick }
                        style     = {{
                        bottom:  showTextSign ? '15vh' : '-100vh',
                      //   opacity: showTextSign ? 1 : 0,
                      }}
              /> */}
        </>
  )
}

export default TextSign



