import React              from 'react';
import { makeStyles }     from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    
    text_sign_container: {
      position:        'fixed',
      top:             '10vh',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      border:          `1px solid ${ '#FFF'/* colors.halavi */ }`,
      left:            '50%',
      transform:       `translate(-50%, -50%)`,
      padding:         10,
      borderRadius:    '1em',
      cursor:          'pointer',
      zIndex:          99,
      transition:      'all 0.4s'
    },
    text_sign: {
      padding:         30,
      width:           '90%',
      height:          '90%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      flexFlow:        'column',
      color:           '#FFF',
      textShadow:      '0 0 7px rgba(0, 0, 0, 1)',
      fontWeight:      'bold',
      borderRadius:    '0.5em',
    }
}));


type ToolTipProps = {
    size:           number[];
    dir:            string;
    toolTipText:    string;
    showToolTip:    boolean;
    setShowToolTip: (val: boolean) => void;
}

const ToolTip: React.FC<ToolTipProps> = ({ 
    size,
    dir,
    showToolTip,
    toolTipText,
    setShowToolTip
  }) => {

  const classes = useStyles();
/* 
  const MagicWandText = React.useMemo(() => {
    return !toolTipText.length
  },[toolTipText])
 */
  return (
      <div 
            className    = { classes.text_sign_container }
            onMouseEnter = { () => { setShowToolTip(true) }}
            style        = {{
              transform:  `translate(-50%, ${ showToolTip ? 0 : -500 }%)`,
              opacity:    showToolTip ? 1 : 0,
              width:      size[0] > 600 ? 550 : '85vw',
            }}
      >
              <div 
                dir       = { dir }
                className = { classes.text_sign }
                style     = {{
                  fontSize:   size[0] > 600 && size[1] > 600 ? 35 : 25,
                }}
              >
                 { toolTipText }
              </div>
      </div>
  )
}

export default ToolTip



