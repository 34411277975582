import React          from 'react';
import key            from '../../graphic_components/key_.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    icon_container: {
       zIndex:             4,
       position:           'fixed',
       top:                '50vh',
       right:              '10vw',
       transform:          'translate(0%, -50%)',
       borderRadius:       '50%',
       cursor:             'grab',
       transition:         'box-shadow 3s, background 2s',
       backgroundPosition: 'center',
       backgroundSize:     'contain',
       backgroundRepeat:   'no-repeat',
       animation:          'puls2 1s infinite'
    }
}));

type KeyProps = {
  lockSize:         number;
  initial:          boolean;
  keyReceived:      boolean;
  setInitial:       (val: boolean) => void;
  handleMouseDown:  (e: React.MouseEvent) => void;
  handleTouchStart: (e: React.TouchEvent) => void;
}

const Key: React.FC<KeyProps> = ({
     lockSize,
     initial,
     keyReceived,
     setInitial,
     handleMouseDown,
     handleTouchStart 
  }) => {

  const classes = useStyles();

  const [go, setGo] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => { setGo(1); if (!initial) { setInitial(true) }}, 10);
  },[setGo, setInitial, initial]);

  return (
    <div 
      id           = {`keyIcon`}
      className    = { classes.icon_container }
      onMouseDown  = { e => { handleMouseDown(e) }}
      onTouchStart = { e => { handleTouchStart(e) }}
      style        = {{
        backgroundImage: `url(${key})`,
        height:          lockSize,
        width:           lockSize,
        opacity:         keyReceived ? 0 : 1,
        backgroundColor: !go && !initial ? '#FFF' : 'transparent',
        boxShadow:       !go && !initial ? `0 0 20px 20px rgba(250, 250, 250, 0.8)` : 'none'
     }}
    />
   )
}

export default Key
//