import React          from 'react';
//import LockIcon       from '@material-ui/icons/Lock';
import lock           from '../../graphic_components/lock.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    lock: {
      position:           'fixed',
      left:               '50%',
      display:            'flex',
      justifyContent:     'center',
      alignItems:         'center',
      transform:          'translate(-52%, 0%)',
      zIndex:             5,
      transition:         'all 0.5s'
    },
    image: {
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat',
      transition:         'all 0.5s'
    }
}));

type LockProps = {
  size:           number[];
  lockSize:       number
  heart_size:     number;
}

const Lock: React.FC<LockProps> = ({ size, lockSize, heart_size }) => {

  const classes = useStyles();

  const [go, setGo] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => { setGo(1) }, 10);
},[setGo])

  return (
      <div 
        className = { classes.lock }
        style     = {{
          top:       size[0] > size[1] ? '40vh' : '45vh',
          width:     heart_size/1.5,
          height:    heart_size/1.5,
          // fontSize:  go ? lockSize : 1,
          opacity:   go ? 1 : 0,
        }}
      >
        <div
         className = { classes.image }
         style = {{
               width:           lockSize,
               height:          lockSize,
               backgroundImage: `url(${lock})`,
          }}
         />
        {/* <LockIcon 
           className = { classes.icon }
           style     = {{
             fontSize:  go ? imoj : 1,
             opacity:   go ? 1 : 0,
           }}
        /> */}
      </div>
  )
}

export default Lock




