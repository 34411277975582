import { ActionTypes } from '../types/actions';
import { stateType }   from '../types/reducer';
import { DateTime }    from 'luxon';

const initialState: stateType = {
  userId:                     '',
  userName:                   '',
  situation:                  '',
  videoBlob:                  '',
  videoBlobName:              '',
  lastScenarioCompletionTime: '',
  profileId:                  -1,
  selectedProfile:            -1,
  situationsArr:              [],
  profiles:                   [],
  showRootLoader:             true,
  showSituationMenuLoader:    true,
  videoPreloaded:             false,
  showPreloadError:           false,
  showGeneralError:           false
};

const reducer = (state = initialState, action: ActionTypes) => {

  switch (action.type) {

 
    case 'SUBMIT_QUESTIONNAIRE':
      {

        const { newProfile, selectedProfile } = action.payload;

        const newProfiles = [...state.profiles, newProfile];

        localStorage.setItem('profiles', JSON.stringify(newProfiles))

        return {
          ...state,
          profiles: newProfiles, 
          selectedProfile: selectedProfile,
          showSituationMenuLoader: false
        };
      } 



    //      -      -      -      -      -      -      -      -      -      -      -      -



    case 'SUBMIT_COMPLETION_OF_SCENARIO':
      {

        const { time, situation_id, scenario } = action.payload;
        const newSituationsArr = state.situationsArr;

        for (let i = 0; i < newSituationsArr.length; i++) {

            if (newSituationsArr[i].id === situation_id) {

              newSituationsArr[i].created_at = time;

              if (newSituationsArr[i].scenarios) {
                 newSituationsArr[i].scenarios = newSituationsArr[i].scenarios.concat(`,${ scenario }`);
              }
              else {
                newSituationsArr[i].scenarios = `${ scenario }`
              }

            }
        }
       

        return {
          ...state,
          situationsArr: newSituationsArr,
          lastScenarioCompletionTime: time
        }

      }
  


    //      -      -      -      -      -      -      -      -      -      -      -      -




    case 'SET_SCENARIOS_ARR':
      {

        const { situationsArr } = action.payload;

        let latest;
  
        for (let i = 0; i < situationsArr.length; i++) {
          if (situationsArr[i].created_at) {
            if (!latest) {
              latest = situationsArr[i].created_at;
            }
            else {
              const current = DateTime.fromISO(situationsArr[i].created_at)
              latest = latest > current ? latest : current;
            }
          }
        } 

        return {
          ...state,
          situationsArr: situationsArr,
          showSituationMenuLoader: false,
          lastScenarioCompletionTime: latest && latest.toFormat ? latest.toFormat('yyyy-LL-dd') : ''
        }

      }


    //      -      -      -      -      -      -      -      -      -      -      -      -

     

    case 'GET_USER_INFO':
      {

        const { userName, userId } = action.payload;
        return {
          ...state,
          userName: userName,
          userId: userId,
        }

      }


    //      -      -      -      -      -      -      -      -      -      -      -      -

     

    case 'SET_PROFILES':
      {

        const { profiles } = action.payload;

        return {
          ...state,
          profiles: profiles,
          showRootLoader: false
        }

      }




//      -      -      -      -      -      -      -      -      -      -      -      -

     

      case 'SELECT_PROFILE':
        {

          const { profileId } = action.payload;

          console.log('profileId : ', profileId)

          
          return {
            ...state,
            profileId: profileId
          }

        }


  //      -      -      -      -      -      -      -      -      -      -      -      -


       
      case 'PRELOAD_VIDEO_SUCCESS':
        {

          console.log('action.payload  :  ',action.payload)
          const { videoBlob, videoName } = action.payload
          
          return {
            ...state,
            videoBlob: videoBlob,
            videoBlobName: videoName,
            videoPreloaded: true,
            showPreloadError: false
          }

        }



//      -      -      -      -      -      -      -      -      -      -      -      -




    case 'PRELOAD_VIDEO_FAILUR':
      {
        return {
          ...state,
          videoBlob: '',
          videoBlobName: '',
          videoPreloaded: false,
          showPreloadError: true
        }

      }



    //      -      -      -      -      -      -      -      -      -      -      -      -


      

      case 'SHOW_ERROR_MODAL':

        {
          console.log('testtest')

          return {
            ...state,
            showGeneralError: true
          }
  
        }




     //      -      -      -      -      -      -      -      -      -      -      -      -


      case 'HIDE_ERROR_MODAL':
      {
        return {
          ...state,
          showPreloadError: false,
          showGeneralError: false
        }

      }



    //      -      -      -      -      -      -      -      -      -      -      -      -



    case 'SET_USER_VIDEO':
      {

        const { userVideoSrc } = action.payload;

        console.log('REDUCER - userVideoSrc  :  ',userVideoSrc)
        return {
          ...state,
       //   userVideoSrc: userVideoSrc
        }

      }



    //      -      -      -      -      -      -      -      -      -      -      -      -


    default: return state;
  }
};

export type reducerType = ReturnType<typeof reducer>;

export { reducer };
