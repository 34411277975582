import React              from 'react';
import ScenarioUnit       from './ScenarioUnit';
import { stationArr }     from '../../utils';
import { actionType }     from '../../types';
import { makeStyles }     from '@material-ui/core/styles';
import { words }          from '../../utils/dictionary'

const useStyles = makeStyles({
  root: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',  
    marginLeft:     10,
    marginRight:    10,
  },
  container: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column',
  },
  scenarios: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'row',
  },
  action_name: {
    marginTop: 25,
    width:     '90%',
    textAlign: 'center',
    color:     '#FFF'
  }
});

type ScenarioProps = {
    action:    number;
    iconSize:  number;
    speed:     number;
    lang:      string;
    dir:       string;
    station:   string;
    withTitle: boolean;
    current:   boolean;
    actionArr: actionType[];
}
  

const Scenario: React.FC<ScenarioProps> = ({ 
    action,
    iconSize,
    speed,
    lang,
    dir,
    station,
    withTitle,
    actionArr,
    current
}) => {

  const classes = useStyles();

  const [initialPos, setInitialPos] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setInitialPos(true)
    }, 10)
  },[setInitialPos])

  const arr = React.useMemo(() => {
     return [
        { image: actionArr[action].actionImg,      text: `${ words['action'][lang] }`, opacity: !actionArr[action].viewed,       mark: !actionArr[action].viewed && stationArr.indexOf(station) === 3 },
        { image: actionArr[action].resultImgSmall, text: `${ words['result'][lang] }`, opacity: !actionArr[action].resultViewed, mark: current && stationArr.indexOf(station) === 4 },
      ];
  },[current, actionArr, action, station, lang])

  return (
    <div className = { classes.root }>
        <div className = { classes.container }>
            {
               !withTitle
               &&
               <div 
                   dir       = { dir }
                   className = { classes.action_name }
               >
                   { lang === 'eng' ? actionArr[action].actionEng : actionArr[action].action }
               </div>
            }
            <div 
              className = { classes.scenarios }
              style     = {{
                height:     initialPos ? iconSize : 1,
                width:      iconSize * 2 + 30,
                transition: `${ speed / 1000 }s`
              }}
            >
                {
                    arr.map((val, ind) => {
                      return(
                        
                                <ScenarioUnit
                                    key       = { `ScenarioUnit_${ ind }` }
                                    ind       = { ind }
                                    dir       = { dir }
                                    image     = { val.image }
                                    text      = { val.text }
                                    opacity   = { val.opacity }
                                    mark      = { val.mark }
                                    iconSize  = { iconSize }
                                    withTitle = { withTitle }
                                />
                      )
                    })
                }
            </div>
        </div>
    </div>
  );
}


export default Scenario