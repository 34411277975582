import React           from 'react';
import colors          from '../../utils/colors';
import { makeStyles }  from '@material-ui/core/styles';
 
const useStyles = makeStyles({
  text_container: {
    width:           '100%',
    height:          '100%',
    display:         'flex',
    flexFlow:        'column',
    overflowY:       'scroll',
    overflowX:       'hidden',
    backgroundColor: `rgba(250, 250, 250, 0.85)`,
    border:          `1px solid ${ colors.darkGrey }`,
    borderRadius:    '1em',
    transition:      'all 1s',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.grey,
      outline:         '1px solid #FFF',
      borderRadius:    10
    }
  },
  text: {
    padding:    10,
    lineHeight: 2,
    fontWeight: 'bold',
    color:      colors.darkGrey
  }
});

type TextProps = {
  text:     string[];
  dir:      string;
  showMenu: boolean;
}

const Text: React.FC<TextProps> = ({ text, dir, showMenu }) => {

const classes = useStyles();

return (
       <div 
          className = { classes.text_container }
          dir       = { dir }
          style     = {{
            transform: `translate(0px, ${ showMenu ? 150 : 0 }vh)`,
            textAlign: dir === 'rtl' ? 'right' : 'left'
          }}
       >
           { 
                text.map((val, ind) => {
                    return(
                        <div 
                            key       = {`text_${ind}`}
                            className = { classes.text }
                            dir       = { dir }
                        >
                            { val }
                        </div>
                    )
                })
           }
       </div>
  );
}


export default Text