import React           from 'react';

import Logo            from '../../graphic_components/logo.png';
import Wand            from '../../graphic_components/wand_.png';
import VidIcon         from '../../graphic_components/vidIconPng.png';
import Wheel           from '../../graphic_components/wheel.png';
import Ruler           from '../../graphic_components/ruler.png';
import Gingel          from '../../graphic_components/gingel.png';
import Crown           from '../../graphic_components/crown_golden.png';

import colors          from '../../utils/colors';
import { words }       from '../../utils/dictionary';
import { makeStyles }  from '@material-ui/core/styles';


 
const useStyles = makeStyles({
  root: {
    width:           '100%',
    height:          '100%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    backgroundColor: colors.halavi,
    border:          `1px solid #FFF`,
    borderRadius:    '1em',
    transition:      'all 1s'
  },
  container: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'space-around',
    flexFlow:       'column',
    border:         `1px solid #FFF`,
    borderRadius:   '1em',
    backgroundColor:`rgba(0, 0, 190, 0.1)`,
    transition:     'all 0.4s',
    cursor:         'pointer',
    '&:hover': {
      width:  '35%',
    }
  },
  title: {
    width:          '85%',
    display:        'flex',
    flexFlow:       'column',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    color:          '#FFF',
    border:         '1px solid #FFF',
    borderRadius:   '0.5em',
    backgroundColor: colors.pink,
    fontWeight:     'bold',
  },
  image: {
     width:              '50%',
     backgroundPosition: 'center',
     backgroundRepeat:   'no-repeat',
     borderRadius:       '1em',
     overflow:           'hidden',
  }
});

type MenuProps = {
  size:           number[];
  showMenu:       boolean;
  page:           string;
  lang:           string;
  dir:            string;
  setShowMenu:    (val: boolean) => void;
  setInfoVariant: (val: string) => void;
}


const Menu: React.FC<MenuProps> = ({ size, lang, dir, showMenu, page, setShowMenu, setInfoVariant }) => {

const classes = useStyles();

const general = React.useMemo(() => {
  return { name: `${ words['help'][lang] }`, image: Logo, variant: 'general', size: 'contain' }
},[lang])

const obj: { [key: string]: { name: string; image: string; variant: string; size: string }[] } = React.useMemo(() => {
   return {
    emotion_menu: [
      general,
      { name: `${ words['emotion_scale'][lang] }`, image: Ruler, variant: 'emotion_menu', size: 'contain' },
    ],
    gingel: [
      general,
      { name: `${ words['my_jingle'][lang] }`, image: Gingel, variant: 'gingel', size: 'contain' },
    ],
    action_menu: [
      general,
      { name: `${ words['my_clip'][lang] }`,     image: VidIcon, variant: 'custom_video', size: 'cover' },
      { name: `${ words['action_menu'][lang] }`, image: Wheel,   variant: 'action_wheel', size: 'contain' },
      { name: `${ words['magicAction'][lang] }`, image: Wand,    variant: 'magic',        size: 'contain' },
    ],
    result_test: [
      general,
      { name: `${ words['conclusion_'][lang] }`, image: Crown, variant: 'result_test', size: 'contain' },
    ]
  }
},[lang, general])




const [hover, setHover] = React.useState(-1)

const handleClick = (variant: string) => {
  setShowMenu(false)
  setInfoVariant(variant)
}

const menuArr = React.useMemo(() => {
   return obj[page] || [general]
},[page, general, obj])

return (
       <div 
         className = { classes.root }
         style     = {{
          transform: `translate(0px, ${ showMenu ? -100 : 500 }%)`,
          flexFlow:  size[0] > 900 ? 'row' : 'column'
        }}
       >
           { 
                menuArr.map((val, ind) => {
                    return(
                        <div 
                            key          = {`image_${ind}`}
                            className    = { classes.container }
                            onClick      = { () => { handleClick(val.variant) }}
                            onMouseEnter = { () => { setHover(ind) }}
                            onMouseLeave = { () => { setHover(-1) }}
                            style        = {{
                                width:  size[0] > 900 ? (hover === ind ? '35%' : '25%') : (hover === ind ? '85%' : '75%'),
                                height: size[0] > 900 ? '75%' : '25%',
                                margin: size[0] <= 900 && page === 'action_menu' ? 0 : 20
                            }}
                        >
                            <div 
                               className = { classes.image }
                               style     = {{
                                   backgroundImage: `url(${ val.image })`,
                                   backgroundSize:  val.size,
                                   height:          page === 'action_menu' && size[1] < 700 ? 0 : '50%'
                               }}
                            />
                            <div 
                                className = { classes.title }
                                style     = {{
                                  height: page === 'action_menu' && size[1] < 700 ? '100%' : '20%'
                                }}
                            >
                                { val.name }
                            </div>
                        </div>
                    )
                })
           }
       </div>
  );
}


export default Menu