import React             from 'react';

import Target            from './Target';
import Crown             from './Crown';
import Ghost             from './Ghost';
import OptionMenu        from './OptionMenu';
import Pyrotechnics      from './Pyrotechnics';
import TextSign          from './TextSign';
import ResultTestAudio   from './ResultTestAudio';
import Hidden            from './Hidden';
//import CompLoader        from '../../components/comp_loader';
//import Audio             from '../../components/audio_component';

import { actionType }    from '../../types';

import { makeStyles }    from '@material-ui/core/styles';
import { words }         from '../../utils/dictionary';

const doneForNowHeb        = 'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/done_for_now.wav'; 
const additionalOptionsHeb = 'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/additional_options.wav'; 
const doneForNowEng        = 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/done_for_now.mp3'; 
const additionalOptionsEng = 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/additional_options.mp3'; 


const useStyles = makeStyles(theme => ({
  black: {
        backgroundColor: '#000',
        position:        'fixed',
        width:           '100vw',
        minHeight:       '100vh',
  },
    root: {
        position:           'fixed',
        width:              '100vw',
        height:             '100vh',
        backgroundPosition: 'center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:     'cover',
        transition:         'opacity 3s, background 1s',
        backgroundColor:    '#000'
    },
    filter: {
        width:           '100%',
        height:          '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    target: {
      position:  'absolute',
      height:    '100vh',
      top:       0,
      left:      '50%',
      transform: 'translate(-50%, 0%)',
      display:   'flex',
      flexFlow:  'column',
      alignItems:'center',
    },
    lion: {
      position: 'absolute',
      top:      '50%',
      left:     '50%',
      transform:'translate(-50%, -50%)'
    },
    crown_container: {
      position:       'absolute',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      borderRadius:   '50%',
    },
    crown: {
      backgroundPosition: 'center',
      backgroundRepeat:   'no-repeat',
      backgroundSize:     'contain',
      cursor:             'pointer',
    },
    text: {
      position:      'absolute',
      right:         '5vw',
      top:           '5vh',
      display:       'flex',
      flexFlow:      'column',
      textAlign:     'right',
      justifyContent:'center',
      fontSize:      35,
      transition:    'all 1.5s',
      zIndex:        1
    },
    pyrotechnics: {
      position:        'fixed',
      width:           '100vw',
      height:          '100vh',
      backgroundColor: 'rgba(0,0,0,0.5)',
      transition:      'all 1s',
      zIndex:          2
    },
    text_sign_right: {
      position:   'fixed',
      right:      '10vw',
      zIndex:     1,
    },
    text_sign_center: {
      position:   'fixed',
      left:       '50%',
      transform:  'translate(-50%, 0)',
      zIndex:     1,
    },
}));

type ResultTestProps = {
  size:                number[];
  actionArr:           actionType[];
  resultText:          string[];
  lang:                string;
  dir:                 string;
  page:                string;
  resultImg:           string;
  situationBackground: string;
  smallFigure:         string;
  testAudio:           string;
  successAudio:        string;
  actionAudio:         string;
  action:              number;
  correct:             boolean;
  audioEnd:            boolean;
  playAudio:           boolean;
  intructionsEnabled:  boolean;
  setAction:           (val: number) => void;
  setBackground:       (val: string) => void;
  setPage:             (val: string) => void;
  setStation:          (val: string) => void;
  setAudioSrc:         (val: string) => void;
  setShowResult:       (val: boolean) => void;
  setShowMagicWand:    (val: boolean) => void;
  setShowUserVideo:    (val: boolean) => void;
  setPlayAudio:        (val: boolean) => void;
  setPauseAudio:       (val: boolean) => void;
  setAudioEnd:         (val: boolean) => void;
  setShowAudioButtons: (val: boolean) => void;
  setActionArr:        (val: actionType[]) => void;
  resetEverything:     () => void;
}

const ResultTest: React.FC<ResultTestProps> = ({
  page,
  size, 
  actionArr,
  action,
  lang,
  dir,
  resultText,
  resultImg,
  smallFigure,
  correct,
  situationBackground,
  testAudio,
  successAudio,
  audioEnd,
  playAudio,
  actionAudio,
  intructionsEnabled,
  setAudioEnd,
  setAudioSrc,
  setPlayAudio,
  setPauseAudio,
  setPage, 
  setBackground,
  setShowResult,
  setStation,
  resetEverything,
  setShowMagicWand,
  setShowUserVideo,
  setActionArr,
  setShowAudioButtons,
  setAction
}) => {

  const classes = useStyles();

  const [doneForNow, additionalOptions] = React.useMemo(() => {
       return[
        lang === 'eng' ? doneForNowEng :  doneForNowHeb,
        lang === 'eng' ? additionalOptionsEng :  additionalOptionsHeb
      ]
  },[lang])

  const pyrotechics = React.useMemo(() => {
     return (size[0] > 800 ? new Array(30).fill(0) : new Array(20).fill(0));
  },[size])

  const [crown_h, crown_w, target_size, target_top, target_bottom, target_left, target_right] = React.useMemo(() => {
     const crownW  = size[1]/4;
     const crownH  = crownW
     const targetS = size[1]/2.5;
     const targetT = -crownH/2;
     const targetB = targetS - crownH/2;
     const targetL = size[0]/2 - targetS/2 - crownW/2;
     const targetR = size[0]/2 + targetS/2 - crownW/2;
     return[crownH, crownW, targetS, targetT, targetB, targetL, targetR];
  },[size])

  const [ghost, setGhost]                             = React.useState(false);
  const [ghostPosition, setGhostPosition]             = React.useState([0,0]);
  const [ghostBright, setGhostBright]                 = React.useState(false);
  const [reception, setReception]                     = React.useState(false);
  const [success, setSuccess]                         = React.useState(false);
  const [announceSeccess, setAnnounceSeccess]         = React.useState(false);
  const [showPyrotechnics, setShowPyrotechnics]       = React.useState(false);
  const [showOptionMenu, setShowOptionMenu]           = React.useState(false);
  const [opacity, setOpacity]                         = React.useState(false);
  const [showTextSign, setShowTextSign]               = React.useState(false);
  const [attempt, setAttempt]                         = React.useState(false);
  const [shake, setShake]                             = React.useState(false);
  const [resultTestAudioSrc, setResultTestAudioSrc]   = React.useState(testAudio);
  const [playResultTestAudio, setPlayResultTestAudio] = React.useState(false);
  const [showCrown, setShowCrown]                     = React.useState(false);
  const [narrating, setNarrating]                     = React.useState(false);
  const [doOnce, setDoOnce]                           = React.useState(false);
  const [doOnce2, setDoOnce2]                         = React.useState(false);
  const [hiddenLoaded, setHiddenLoaded]               = React.useState(false);

  React.useEffect(() => {
    if (audioEnd || !intructionsEnabled) {
      setPlayAudio(false);
      setPauseAudio(true);
      if (narrating) {
        setNarrating(false)
        return
      }
      if (!showCrown) {
        setShowCrown(true)
      }
      if (!announceSeccess) {
     //   setResultTestAudioSrc(successAudio);
        setShowTextSign(false);
      }
      if (success && resultTestAudioSrc !== successAudio && !doOnce2)  {
        setDoOnce2(true)
        setResultTestAudioSrc(successAudio);
      }
      if (announceSeccess) {
        setSuccess(true)
        setTimeout(() => { setShowPyrotechnics(true) }, 10);
        setTimeout(() => { 
          setShowTextSign(false)
          setShowOptionMenu(true)
        }, 10000);
      }
    }
  },[audioEnd, announceSeccess, narrating, showCrown, successAudio, success, resultTestAudioSrc, doOnce2, intructionsEnabled, setDoOnce2, setResultTestAudioSrc, setPlayAudio, setPauseAudio]) 


  React.useEffect(() => {
    if (doOnce) {
      return
    }
    setDoOnce(true)
    setTimeout(() => { 
      setOpacity(true); 
    }, 500); 
    setTimeout(() => { 
      setPauseAudio(false)
    }, 1000); 
    setTimeout(() => { 
      setShowTextSign(true); 
    }, 900); 
  },[doOnce, setDoOnce, setPauseAudio, setOpacity, setShowTextSign]);
  

  const do_reception = (x: number, y: number) => {
    setGhostPosition([x, y]);
    if ( x > target_left && x < target_right && y > target_top && y < target_bottom) {
      if (!attempt) { setAttempt(true) }
      if (correct) {
        setReception(true);
        setGhostBright(true)
      }
      else {
        setShake(true)
      }
    }
    else {
      setReception(false);
      setGhostBright(false);
      setShake(false)
    }
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    if (showTextSign) { 
      setShowTextSign(false)
    }
    setGhost(true)
    setGhostPosition([e.pageX - crown_w / 2, e.pageY - crown_h / 2]);
  }

  const handleTouchStart = (e: React.TouchEvent) => {
    if (showTextSign) { 
      setShowTextSign(false)
    }
    setGhost(true);
    setGhostPosition([e.touches[0].pageX - crown_w / 2, e.touches[0].pageY - crown_h / 2]);
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!ghost) { return }
    const x_ = e.pageX - crown_w/2;
    const y_ = e.pageY - crown_h/2;
    do_reception(x_, y_);
  }

  const handleTouchMove = (e: React.TouchEvent ) => {
    if (!ghost) { return }
    const x_ = e.touches[0].pageX - crown_w/2;
    const y_ = e.touches[0].pageY - crown_h/2;
    do_reception(x_, y_);
  }
  
  
  
  const handleMouseUp = () => {
        if (showOptionMenu) { return }
        if (ghostBright) {
          setGhostBright(false)
          setAnnounceSeccess(true);
          setShowTextSign(true);
          setGhostPosition([0,0]);
          if (!playResultTestAudio) { setTimeout(() => { setPlayResultTestAudio(true); },10) }
        }
        else {
          if (shake) {
            setShowOptionMenu(true)
          }
        }
       if (ghost) { setGhost(false) }
  }
    
  const handleEndAudio = () => {
    setPlayAudio(false)
    setPlayResultTestAudio(false)
    console.log('PlayAudio             :    ',playAudio)
    console.log('PlayResultTestAudio   :    ',playResultTestAudio)
    if (narrating) {
      setNarrating(false)
      return
    }
    if (!showCrown) {
      setShowCrown(true)
    }
    if (!announceSeccess) {
      setResultTestAudioSrc(successAudio);
      setShowTextSign(false);
    }
    if (announceSeccess) {
      setSuccess(true)
      setTimeout(() => { setShowPyrotechnics(true) }, 10);
      setTimeout(() => { 
        setShowTextSign(false)
        setShowOptionMenu(true)
      }, 10000);
    }
    
  }

  const handleMouseEnter = (num: number) => {

    if (playAudio || playResultTestAudio) { return }
    setNarrating(true)
    if (!num) { setResultTestAudioSrc(doneForNow) }
    if (num)  { setResultTestAudioSrc(additionalOptions) }
    setTimeout(() => { setPlayResultTestAudio(true) },10)

  }


  const handleGoToActionMenu = () => {
      setBackground(situationBackground);
      setShowResult(false);
      setAudioSrc(actionAudio)
      setAudioEnd(false)
      setPauseAudio(false)
      setShowAudioButtons(false)
      setTimeout(() => {
        setPlayAudio(true)
      },10)
      setTimeout(() => {
        setPauseAudio(true)
      },20)
      setTimeout(() => { 
        setShowAudioButtons(true)
        setStation('action_menu')
        setPage('action_menu');
      }, 1000); 
  }
  

  return (
    <div className = { classes.black }>
        <div 
            className = { classes.root } 
            style = {{
              backgroundImage: `url(${ resultImg })`,
              opacity:         opacity ? 1 : 0
            }}
        >
          <div 
            className     = { classes.filter } 
            onMouseMove   = { handleMouseMove }
            onTouchMove   = { handleTouchMove }
            onMouseUp     = { handleMouseUp }
            onTouchEnd    = { handleMouseUp }
            onTouchCancel = { handleMouseUp }
          >
            {/* { !hiddenLoaded && <CompLoader variant = { 'page' } lang = { lang }/> } */}
            { /* hiddenLoaded && */
              <>
                  <div className = { classes.target }>
                    <Target
                      announceSeccess = { announceSeccess }
                      target_size     = { target_size }
                      crown_w         = { crown_w }
                      crown_h         = { crown_h }
                    />
                    <div className = { classes.lion } style = {{ height: '80vh' }}>
                      <img src = { smallFigure } alt = 'lion' height = '100%'/>
                    </div>
                  </div>
                  <Crown
                      size             = { size }
                      ghostPosition    = { ghostPosition }
                      crown_w          = { crown_w }
                      crown_h          = { crown_h }
                      ghost            = { ghost }
                      reception        = { reception }
                      showCrown        = { showCrown }
                      attempt          = { attempt }
                      handleMouseDown  = { handleMouseDown }
                      handleTouchStart = { handleTouchStart }
                  />
                  {
                    ghost === true 
                    && 
                    <Ghost
                      position    = { ghostPosition } 
                      ghostBright = { ghostBright } 
                      width       = { crown_w } 
                      height      = { crown_h }
                      shake       = { shake }
                    />
                  }
                  { 
                    (showOptionMenu) 
                    && 
                    (
                      <OptionMenu
                        size                 = { size }
                        actionArr            = { actionArr }
                        action               = { action }
                        lang                 = { lang }
                        dir                  = { dir }
                        handleGoToActionMenu = { handleGoToActionMenu }
                        setOpacity           = { setOpacity }
                        resetEverything      = { resetEverything }
                        handleMouseEnter     = { handleMouseEnter }
                        setActionArr         = { setActionArr }
                      />
                    ) 
                  }
                  <TextSign
                    size                = { size }
                    dir                 = { dir }
                    showTextSign        = { showTextSign }
                    text                = { announceSeccess ? [`${ words[resultText[2]][lang] }`, `${ words[resultText[3]][lang] }`] : [`${ words[resultText[0]][lang] }`, `${ words[resultText[1]][lang] }`] }
                    announceSeccess     = { announceSeccess }
                  />
                  <ResultTestAudio
                    src            = { resultTestAudioSrc }
                    playAudio      = { playResultTestAudio }
                    handleEndAudio = { handleEndAudio }
                  /> 
                  {
                    success &&
                      (
                      <div 
                        className = { classes.pyrotechnics }
                        style     = {{
                          opacity: showPyrotechnics ? 1 : 0
                        }}
                      >
                        {
                          pyrotechics.map((val, ind) => {
                            return(
                              <div key = { `pyrotechics_${ind}` }>
                                  <Pyrotechnics 
                                    size           = { size } 
                                    num            = { Math.round(Math.random() * 6) }
                                    showOptionMenu = { showOptionMenu }
                                  />
                              </div>
                            )
                          })
                        }
                      </div>
                      )
                  } 
              </>
            }
           </div>
         </div>
          <Hidden
            images          = { [smallFigure] }
            audio           = { [testAudio, doneForNow, additionalOptions, successAudio] }
            setHiddenLoaded = { setHiddenLoaded }
         /> 
       </div>

      )
    }

export default ResultTest;







