import React                                from 'react';

var playPromise: Promise<any>;

type srcArrType = {
    situationName: string; 
    variant:       string; 
    index:         number; 
    url:           string;
}

type VideoLoaderProps = {
    uploadingFiles:     boolean;
    videoLoaderSrcArr:  srcArrType[];
    loadedVideos:       string[];
    setLoadedVideos:    (val: string[]) => void;
}

const VideoLoader: React.FC<VideoLoaderProps> = ({
    uploadingFiles,
    videoLoaderSrcArr,
    loadedVideos,
    setLoadedVideos
}) => {

    const [start, setStart]       = React.useState(true)
    const [srcArr, setSrcArr]     = React.useState([] as srcArrType[])
    const [videoSrc, setVideoSrc] = React.useState(videoLoaderSrcArr[0].url)
    const [state, setState]       = React.useState({ pauseVideo: false, playVideo: true, downloadComplete: false })

    const Ref = React.useRef<HTMLVideoElement>(null);
 

    React.useEffect(() => {

        setStart(false)
        
        if (srcArr !== videoLoaderSrcArr) {
            setSrcArr(videoLoaderSrcArr)
            setStart(true)
        }
    },[srcArr, videoLoaderSrcArr, setStart, setSrcArr])


     React.useEffect(() => {

        if (start || state.downloadComplete) {

            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    setState({ playVideo: false, pauseVideo: true, downloadComplete: false})
                    setStart(false)
                    for (let i = 0; i < videoLoaderSrcArr.length; i++) {
                        if (!loadedVideos.includes(videoLoaderSrcArr[i].url)) {    
                            setVideoSrc(videoLoaderSrcArr[i].url)
                            setState({ playVideo: true, pauseVideo: false, downloadComplete: false})
                            break;
                        }
                    }
                }).catch(error => {
                    console.log('Video Loader Component Error   :   ',error)
                }); 
            }
        }
    },[
       start,
       videoSrc,
       loadedVideos,
       videoLoaderSrcArr,
       state,
       setState,
       setStart,
    ])



     const handleDownLoad = () => {
     
        if (Ref && Ref.current && Ref.current.buffered && Ref.current.buffered.length > 0 && Ref.current.buffered.end && Ref.current.buffered.end(0) && Ref.current.duration) {

            if (Math.round(Ref.current.buffered.end(0)) / Math.round(Ref.current.seekable.end(0)) >= 0.3 ) {             

                    const arr = loadedVideos.slice();
                    arr.push(videoSrc)
                    setLoadedVideos(arr)
                    setState({ playVideo: false, pauseVideo: true, downloadComplete: true})
                     
            }        
        }
    } 
 

    if (Ref && Ref.current && state.playVideo && !state.pauseVideo && !uploadingFiles) {
       playPromise = Ref.current.play();       
    }


    return(
           <video 
               muted  
               webkit-playsinline = "true" 
               playsInline
               autoPlay
               onProgress = { handleDownLoad } 
               ref        = { Ref }
               src        = { videoSrc }
               preload    = "auto"
               height     = '1px'
               width      = '1px' 
            /*     height     = '150'
               width      = '100%'  */
               style      = {{ position: 'fixed', transform: `translate(-300vh, -300vh)` }}  
          /*           style = {{
                   position: 'fixed',
                   top: 0,
                   left: 0
                   
               }}     */
               
           />
    )
}

export default VideoLoader;

