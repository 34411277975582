import React          from 'react';
import DropDownMenu   from './DropDown';
import RadioForm      from './RadioForm';

import colors         from '../../utils/colors';
import { makeStyles } from '@material-ui/core/styles';

import { words }      from '../../utils/dictionary';

var clsx = require('classnames')


const useStyles = makeStyles(theme => ({
    container: {
        width:          '100%',
        display:        'flex',
        flexFlow:       'column',
        justifyContent: 'center',
        alignItems:     'center',
        transition:     'all 1s'
    },
    text_field: {
      border:          `1px solid ${ colors.darkGrey }`,
      backgroundColor: `transparent`,
      height:          50,
      paddingLeft:     10,
      paddingRight:    10,
      borderRadius:    '0.5em',
      fontSize:        17,
      color:           colors.darkGrey,
      marginTop:       10,
      marginBottom:    10,
      '&:focus': {
        outline: 'none',
        border:  `2px solid ${ colors.darkGrey }`,
        height:   48,
       },
      '&::placeholder': {
         color: colors.darkGrey,
      }
    },
    form: {
        display:        'flex',
        flexFlow:       'column',
        justifyContent: 'center',
        alignItems:     'center',
      },
    age: {
        display:    'flex',
        flexFlow:   'row',
        alignItems: 'center',
    },
    center: {
      textAlign: 'center',
      color:     colors.darkGrey
    },
    siblings_container: {
        display:       'flex',
        flexFlow:      'column',
        borderTop:     `1px solid ${ colors.darkGrey }`,
        paddingTop:    20,
    },
    siblings: {
        display:        'flex',
        flexFlow:       'row',
        justifyContent: 'space-around',
        alignItems:     'center',
    },
    siblings_text: {
    //    marginTop:      10,
        marginLeft:     15,
        marginRight:    15,
        height:         54,
        display:        'flex',
        alignItems:     'center',
        textAlign:      'center',
        justifyContent: 'center',
        color:           colors.darkGrey,
    },
    siblings_text_field: {
      maxWidth: 100,
      color:    colors.darkGrey,
    }
}));

type Form1Props = {
  size:               number[];
  lang:               string;
  dir:                string;
  childName:          string;
  childAge:           number;
  childGender:        string;
  siblingNum:         number;
  ordinalPosition:    number;
  city:               string;
  parentOcupation:    string;
  initialPos:         boolean;
  setChildName:       (val: string) => void;
  setChildAge:        (val: number) => void;
  setChildGender:     (val: string) => void;
  setSiblingNum:      (val: number) => void;
  setOrdinalPosition: (val: number) => void;
  setCity:            (val: string) => void;
  setParentOcupation: (val: string) => void;
}

const Form1: React.FC<Form1Props> = ({ 
    size,
    lang,
    dir,
    childName,
    childAge,
    childGender,
    siblingNum,
    ordinalPosition,
    city,
    parentOcupation,
    initialPos,
    setChildAge,
    setChildName,
    setChildGender,
    setSiblingNum,
    setOrdinalPosition,
    setCity,
    setParentOcupation
}) => {

  const classes = useStyles();

  const textField = React.useMemo(() => {
     return size[0] > 900 ? { width: '50%' } : { width: '75%' }
  },[size])

  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    if (target.id === 'name')        { setChildName(target.value) }
    if (target.id === 'sib_num')     { setSiblingNum(target.value.length ? +target.value : -1) }
    if (target.id === 'ordinal_pos') { setOrdinalPosition(target.value.length ? +target.value : -1) }
    if (target.id === 'city')        { setCity(target.value) }
    if (target.id === 'ocupation')   { setParentOcupation(target.value) }

  }

  return (
      <div 
          className = { classes.container } 
          dir       = { dir }
          style     = {{
            transform: `translate(0px, ${ initialPos ? 0 : 150 }vh`
          }}
       >
          <form className = { classes.form } style = {{ width: size[0] > 750 ? '80%' : '100%' }}>
            <input
              className    = { classes.text_field }
              style        = { textField }      
              onChange     = { changeInputValue }
              id           = "name"
              placeholder  = { childName.length ? childName : `${ words['child_name'][lang] }` }
            />
            <div className = { classes.age }>
                <p className = { classes.siblings_text }>{`${ words['age'][lang] }:`}</p>
                <DropDownMenu 
                  lang        = { lang }
                  dir         = { dir }
                  childAge    = { childAge }
                  setChildAge = { setChildAge }
                />
            </div>
            <div style = { textField }>
              <RadioForm 
                lang           = { lang }
                dir            = { dir }
                childGender    = { childGender }
                setChildGender = { setChildGender }
              />
            </div>
            <div 
                className = { classes.siblings_container } 
                style     = { textField }
            >
                <div className = { classes.center }>
                     {`${ words['ordinal_pos_f'][lang] }:`}
                </div>
                <div className = { classes.siblings }>
                    <div className = { classes.siblings_text }>
                         {`${ words['sibling_num'][lang] }:`}
                    </div>
                    <input
                        className    = { clsx(classes.text_field, classes.siblings_text_field) }
                        onChange     = { changeInputValue }
                        id           = "sib_num"
                        placeholder  = { siblingNum > -1 ? `${ siblingNum }` : `${ words['siblings'][lang] }` }
                    />
                    <div 
                        className = { classes.siblings_text }>
                        {`${ words['ordinal_pos'][lang] }:`}
                    </div>
                    <input
                        className    = { clsx(classes.text_field, classes.siblings_text_field) }
                        onChange     = { changeInputValue }
                        id           = "ordinal_pos"
                        placeholder  = { ordinalPosition > -1 ? `${ ordinalPosition }` : `${ words['ordinal_pos'][lang] }` }
                    />
                </div>
            </div>
            <input
               className    = { classes.text_field }
               style        = { textField }      
               onChange     = { changeInputValue }
               id           = "city"
               placeholder  = { city.length ? city : `${ words['city_of_residence'][lang] }` }
            />
            <input
               className    = { classes.text_field }
               style        = { textField }      
               onChange     = { changeInputValue }
               id           = "ocupation"
               placeholder  = { parentOcupation.length ? parentOcupation : `${ words['parent_ocupation'][lang] }` }
            />
         </form>
      </div>
    )
  
}

export default Form1







