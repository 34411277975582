import React from 'react';

type HiddenAudioProps = {
    emojiArr: { name: string; audio: string; image: string }[];
}

const HiddenAudio: React.FC<HiddenAudioProps> = ({
    emojiArr
  }) => {

    return (
    <>
          {
              emojiArr.map((val, ind) => {
                return(
                    <audio  
                        muted  
                        webkit-playsinline = "true" 
                        playsInline
                        autoPlay
                        key              = { `emotionMenuHiddenAudio${ val }_${ ind }` }
                        src              = { val.audio } 
                        preload          = "auto"
                   //     onCanPlayThrough = { handleOnAudioLoad }
                   /> 
                )
              })
          }
    </>
  )
}

export default HiddenAudio

