import React              from 'react';

import colors             from '../../utils/colors'
import { makeStyles }     from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    
   
    text_sign_container: {
      position:        'fixed',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      border:          `1px solid #FFF`,
      left:            '50%',
      top:             '50%',
      padding:         10,
      borderRadius:    '1em',
      zIndex:          10,
    },
    text_sign: {
      padding:         30,
      width:           '90%',
      height:          '90%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      //backgroundColor: colors.halavi,
      color:           '#FFF',
      textShadow:      '0 0 7px rgba(0, 0, 0, 1)',
      fontWeight:      'bold',
      borderRadius:    '0.5em',
    },
    icon: {
      position:        'fixed',
      left:            '50%',
      transform:       'translate(-50%, 0)',
      width:           185,
      height:          85,
      color:           '#FFF',
      fontSize:        35,
      backgroundColor: colors.blue_,
      borderRadius:    '1em',
      cursor:          'pointer',
      border:          `1px solid #FFF`,
      boxShadow:       '0 5px 5px rgba(0,0,0,0.4)',
      zIndex:          11,
      transition:      'bottom 1.7s, top 1.7s, width 0.4s',
      '&:hover': {
        width: 235
      }
    }
}));


type TextSignProps = {
    size:                number[];
    text:                string[];
    dir:                 string;
    showTextSign:        boolean;
    announceSeccess:     boolean;
}

const TextSign: React.FC<TextSignProps> = ({ 
    size,
    text,
    dir,
    announceSeccess,
    showTextSign
  }) => {

  const classes = useStyles();

  return (
       <>
              <div 
                    className = { classes.text_sign_container }
                    style     = {{
                      transform:  `translate(-50%, ${ showTextSign ? '-50%' : '200vh' })`,
                      opacity:    showTextSign ? 1 : 0,
                      width:      size[0] > 600 ? 550 : '85vw',
                      transition: showTextSign ? 'all 2s' : 'all 1s',
                    }}
              >
                      <div 
                        dir       = { dir }
                        className = { classes.text_sign }
                        style     = {{
                          fontSize:   size[0] > 600 && size[1] > 600 ? 35 : 25,
                        }}
                      >
                        { text[0] }
                        <br/>
                        { text[1] }
                      </div>
                
                   
              </div>
        </>
  )
}

export default TextSign



