import React          from 'react';
import { actionType } from '../../types';
import { makeStyles } from '@material-ui/core/styles';
import Fractions      from './Fractions';

var clsx = require('classnames');

const useStyles = makeStyles(theme => ({
  root: {
      position:        'absolute',
      borderRadius:    '50%',
   /*    top:             '50%',
      left:            '50%', */
      transform:       `translate(-50%, -50%)`,
      transition:      'all 0.4s',
  },
  pie: {
   // position:      'relative',
    position:      'absolute',
    borderRadius:  '50%',
    overflow:      'hidden',
    boxShadow:     `9px 9px 9px rgba(0, 0, 0, 0.4)`,
    transition:    'all 0.5s',
   // border: '10px solid #00F',

    zIndex:         1,
  },
  spin: {
   animation: 'spin 25s linear infinite'
  }
}));

type PieProps = {
  size:           number[];
  spin:           number;
  circleSize:     number;
  hover:          number;
  lang:           string;
  userVideoSrc:   string;
  name:           string;
  redoSituation:  boolean;
  actionArr:      actionType[];
  setHover:       (val: number) => void;
  setToolTipText: (val: string) => void;
  setShowToolTip: (val: boolean) => void;
  ActionClick:    (ind: number) => void;
}

const Pie: React.FC<PieProps> = ({
  size,
  name,
  userVideoSrc,
  spin,
  lang, 
  circleSize,
  actionArr,
  hover,
  redoSituation,
  ActionClick,
  setHover,
  setToolTipText,
  setShowToolTip,
}) => {

  const classes = useStyles();

  return (
    <div className = { classes.root }>
        <div
          className    = { !spin ? classes.pie : clsx(classes.pie, classes.spin) }
          style        = {{ width:  circleSize, height: circleSize }} 
        >
          <Fractions
            size            = { size }
            lang            = { lang }
            name            = { name }
            userVideoSrc    = { userVideoSrc }
            spin            = { spin }
            circleSize      = { circleSize }
            actionArr       = { actionArr }
            hover           = { hover }
            redoSituation   = { redoSituation }
            setHover        = { setHover }
            ActionClick     = { ActionClick }
            setShowToolTip  = { setShowToolTip }
            setToolTipText  = { setToolTipText }
          />
      </div>
    </div>
  )
}

export default Pie


