import React          from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    none: {
    },
    animation: {
       position:  'absolute',
       width:     400,
       height:    400,
       animation: 'pyrotechnics 4.5s',
    },
    partical: {
        position:                 'absolute',
        width:                    5,
        height:                   5,
        borderRadius:             '50%',
        transition:               'left 2s ease-out, top 2s ease-out, opacity 4.5s ease-out',
        transitionTimingFunction: `cubic-bezier(0.1, 0.1, 0.1, 0.9)`
    }
}));

const colors = ['#FFF', '#CFF', '#FCF', '#CCF', '#FCC', '#CFC', '#FFC'];

const before = new Array(20).fill({
    color:   'transparent',
    left:    200,
    top:     200,
    opacity: 1,
});

type PyrotechnicsProps = { 
    size:           number[];
    num:            number;
    showOptionMenu: boolean;
}

const Pyrotechnics: React.FC<PyrotechnicsProps> = ({ size, num, showOptionMenu }) => {

  const classes = useStyles();

  const [particals, setParticals] = React.useState(before);
  const [rootClass, setRootclass] = React.useState(classes.none);

  React.useEffect(() => {

    if ( showOptionMenu ) { return; }

    let after: {}[] = [];

    for (let i = 0; i < before.length; i++) {
        after.push({
            color:   colors[num],
            left:    Math.round(Math.random() * 400),
            top:     Math.round(Math.random() * 400),
            opacity: 0
        })
    }

    setTimeout(() => {
        setParticals(after)
        setRootclass(classes.animation);
    }, Math.round(Math.random() * 5000 + 500));

  },[setParticals, setRootclass, classes.animation, num, showOptionMenu]);

  return (
     <div 
       className = { rootClass }
       style     = {{
           left: Math.random() * (size[0] - 400),
           top:  Math.random() * (size[1] / 3)
       }}
     >
        {
            particals.map((val, ind) => {
                return(
                    <div 
                        key       = { `partical_${ind}` }
                        className = { classes.partical }
                        style     = {{
                            left:            val.left,
                            top:             val.top,
                            backgroundColor: val.color,
                            opacity:         val.opacity
                        }}
                    />
                )
            })
        }
     </div>
   )
}

export default Pyrotechnics;





















