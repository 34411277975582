import React              from 'react';

import TextSign           from './TextSign';
import LionImage          from './LionImage';
import HeartSprite        from './HeartSprite';
import ReceivedEmojis     from './ReceivedEmojis';
import Emojis             from './Emojis';
import Ghost              from './Ghost';
import Key                from './Key';
import KeyGhost           from './KeyGhost';
import Lock               from './Lock';
import ReactionMenu       from './ReactionMenu';
import Hidden             from './Hidden';
import EmojiAudio         from './EmojiAudio';
import CompLoader         from '../../components/comp_loader';

import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';
import colors             from '../../utils/colors'
import { makeStyles }     from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    
    black: {
      backgroundColor: '#000',
      position:        'fixed',
      width:           '100vw',
      minHeight:       '100vh',
    },
    root: {
      position:           'fixed',
      top:                0,
      left:               0,
      width:              '100vw',
      height:             '100vh',
      backgroundPosition: 'center',
      backgroundRepeat:   'no-repeat',
      backgroundSize:     'cover',
      transition:         'opacity 1s'
    },
    filter: {
      position:        'fixed',
      top:             0,
      left:            0,
      width:           '100vw',
      height:          '100vh',
      backgroundColor: 'rgba(0,0,0,0.5)',
      transition:      'all 0.7s',
    },
    button: {
      position:       'fixed',
      width:          140,
      height:         140,
     // backgroundColor:colors.blue,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      zIndex:         7,
      transition:     'all 0.7s',
    },
    reaction_menu: {
      position: 'fixed',
      height:   '100vh',
      width:    '100vw',
      zIndex:   99999
    },
    icon: {
      position:        'fixed',
      left:            '50%',
      transform:       'translate(-50%, 0)',
      width:           185,
      height:          85,
      color:           '#FFF',
      fontSize:        35,
      backgroundColor: colors.blue_,
      borderRadius:    '1em',
      cursor:          'pointer',
      border:          `1px solid #FFF`,
      boxShadow:       '0 5px 5px rgba(0,0,0,0.4)',
      zIndex:          20,
      transition:      'bottom 1.7s, top 1.7s, width 0.4s',
      '&:hover': {
        width: 235
      }
    },
    shake: {
      animation:               'shake 0.5s',
      animationIterationCount: '0.5s',
    },
    dont_shake: {
    }
}));

const receivedCompsArr: { name: string, src: string, pos: number[] }[] = [];
type  emojisArrType = { name: string, src: string, audio: string; received: boolean; accept: boolean; }[];

type EmotionMenuProps = {
  size:                   number[];
  acceptEmojisArr:        number[];
  emotionMenuText:        string[];
  lang:                   string;
  dir:                    string;
  name:                   string;
  resultAudio:            string;
  background:             string;
  situationBackground:    string;
  largeFigure:            string;
  audioSrc:               string;
  actionAudio:            string;
  storyAudio:             string;
  showResult:             boolean;
  magicAction:            boolean;
  customVideo:            boolean;
  timeToSwitchVideoLoader:boolean;
  audioEnd:               boolean;
  playAudio:              boolean;
  intructionsEnabled:     boolean;
  emojiArr:               { name: string; audio: string; image: string }[];
  switchVideoLoader:      (val: string) => void;
  setPage:                (val: string) => void;
  setBackground:          (val: string) => void;
  setStation:             (val: string) => void;
  setAudioSrc:            (val: string) => void;
  setMuteAudio:           (val: boolean) => void;
  setPlayAudio:           (val: boolean) => void;
  setPauseAudio:          (val: boolean) => void;
  setShowAudioButtons:    (val: boolean) => void;
  setShowNavBar:          (val: boolean) => void;
  setShowResult:          (val: boolean) => void;
  setSatisfiedWithResult: (val: boolean) => void;
  setUploadingFiles:      (val: boolean) => void;
  setAudioEnd:            (val: boolean) => void;

}

const EmotionMenu: React.FC<EmotionMenuProps> = ({ 
    size,
    name,
    lang,
    dir,
    background,
    largeFigure,
    emotionMenuText,
    resultAudio,
    magicAction,
    customVideo,
    showResult,
    situationBackground,
    emojiArr,
    acceptEmojisArr,
    timeToSwitchVideoLoader,
    audioEnd,
    playAudio,
    audioSrc,
    storyAudio,
    actionAudio,
    intructionsEnabled,
    setAudioSrc,
    setPlayAudio,
    setMuteAudio,
    setPauseAudio,
    setShowAudioButtons,
    switchVideoLoader,
    setUploadingFiles,
    setPage,
    setShowNavBar,
    setShowResult,
    setBackground,
    setStation,
    setSatisfiedWithResult,
    setAudioEnd
  }) => {

  const classes = useStyles();

  const [imoj, lockSize, heart_size] = React.useMemo(() => {
     
    const imojSize = (size[0] > 700 && size[1] > 500 ? (size[0] - size[0]/10) / emojiArr.length : 40)

    return [
        imojSize,
        imojSize * 1.5,
        (size[0] > size[1] ? (size[1] / 100 * 45) : (size[0] / 3))
    ];
  },[size, emojiArr])

  

  const [opacity, setOpacity]                   = React.useState(false);
  const [mouseDown, setMaousDown]               = React.useState(false);
  const [ghostSrc, setGhostSrc]                 = React.useState('');
  const [ghostName, setGhostName]               = React.useState('');
  const [ghostAcceptable, setGhostAcceptable]   = React.useState(false);
  const [ghostPosition, setGhostPosition]       = React.useState([0,0]);
  
  const [receivedComps, setReceivedComps]       = React.useState(receivedCompsArr.slice());
  const [emojis, setEmojis]                     = React.useState([] as emojisArrType );
  const [reception, setReception]               = React.useState(false);
  const [sprite, setSprite]                     = React.useState('static');
  const [shake, setShake]                       = React.useState(false);

  const [initial, setInitial]                   = React.useState(false);
  const [showKey, setShowKey]                   = React.useState(false);
  const [keyGhostPosition, setKeyGhostPosition] = React.useState([0,0]);
  const [keyGhost, setKeyGhost]                 = React.useState(false);
  const [keyReceived, setKeyReceived]           = React.useState(false);
  const [showLock, setShowLock]                 = React.useState(false);

  const [showButtons, setShowButtons]           = React.useState(false);
  const [showTextSign, setShowTextSign]         = React.useState(false);
  const [showEmojis, setShowEmojis]             = React.useState(false);

  const [hiddenLoaded, setHiddenLoaded]         = React.useState(false);
  const [doOnce, setDoOnce]                     = React.useState(false);

  const [emojiAudioSrc, setEmojiAudioSrc]       = React.useState('');
  const [playEmojiAudio, setPlayEmojiAudio]     = React.useState(false);  
  const [pauseEmojiAudio, setPauseEmojiAudio]   = React.useState(false)



  React.useEffect(() => {
    if (playAudio) {
      if (showTextSign) {
        setShowAudioButtons(true)
      }
      else {
        setShowAudioButtons(false)
      }
    }
  },[playAudio, showTextSign, showButtons, setShowAudioButtons])


  React.useEffect(() => {
    if (audioEnd || !intructionsEnabled) {
      setPlayAudio(false);
      setPauseAudio(true);
      if (showTextSign) { setShowTextSign(false); }
      if (!showEmojis) { setShowEmojis(true) }
    }
  },[audioEnd, showEmojis, showTextSign, setPlayAudio, setShowTextSign, setShowEmojis, setPauseAudio, intructionsEnabled])

  
  React.useEffect(() => {
    if (hiddenLoaded) {
      setUploadingFiles(false)
      if (timeToSwitchVideoLoader) {
        switchVideoLoader('scenarios')
      }
    }
  },[hiddenLoaded, timeToSwitchVideoLoader, switchVideoLoader, setUploadingFiles])

  
  React.useEffect(() => {
    if (doOnce) {
      return
    }
    setDoOnce(true)
    setTimeout(() => { 
      setPauseAudio(false)
    }, 20); 
  },[doOnce, setDoOnce, setPauseAudio]);


  React.useEffect(() => {
    const newEmojiArr = []
    for (let i = 0; i < emojiArr.length; i++) {
        newEmojiArr.push({
          src:      emojiArr[i].image,
          name:     emojiArr[i].name,
          audio:    emojiArr[i].audio,
          received: false,
          accept:   acceptEmojisArr[i] ? true : false
        })
    }
    setEmojis(newEmojiArr)
 },[emojiArr, acceptEmojisArr, setEmojis]);


  React.useEffect(() => {
      setTimeout(() => {
        setOpacity(true);
      }, 10);
      if (hiddenLoaded) {
           setTimeout(() => {
            setShowTextSign(true);
          }, 10); 
      }
  },[hiddenLoaded, setOpacity, setShowTextSign]);
 
  
  const do_reception = (x: number, y: number) => {

    const left = size[0]/2 - heart_size/2 - imoj / 2;
    const top  = size[1]/100*40 - imoj / 2;

    if (keyGhost) { setKeyGhostPosition([x, y]) }
    else { setGhostPosition([x, y]) }

    if ((x > left ) && (x < left + heart_size) && (y > top) && (y < top + heart_size*0.85)) { 

      if (!ghostAcceptable && !keyGhost) {
         setShake(true)
         return;
      }

      setReception(true);

      if (!keyGhost && (sprite !== 'open')) {
        setSprite('open');
      }
    }
    else {
      if (reception) {
        setReception(false)
        if (!keyGhost && (sprite !== 'close')) {
          setSprite('close');
        }
      }
      if (shake) {
       setShake(false)
      }
    }
  }

  const set_ghost = (src: string, name: string, accept: string) => {
    setMaousDown(true);
    setGhostSrc(src)
    setGhostName(name)
    setGhostAcceptable(accept === 'true' ? true : false)
  }

  const setEmojiAudio = (aud: string) => {
      setEmojiAudioSrc(aud);
      if (playEmojiAudio) {
        setPlayEmojiAudio(false)
      }
      else {
        setPauseEmojiAudio(false)
        setPlayEmojiAudio(true)
      }
  }

  const startPlayingResultAudio = () => {
    if (showResult) {  
      setAudioSrc(resultAudio) 
      setPlayAudio(true) 
      setTimeout(() => {
        setPauseAudio(true) 
      },10)
    }
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    if (showButtons) { return }
    if (showTextSign) { setShowTextSign(false) }

    const target = e.target as HTMLDivElement;

    if (target.id === 'keyIcon') {
      if (playEmojiAudio) {
        setPlayEmojiAudio(false)
      }
      startPlayingResultAudio()
      setShowKey(false)
      setMaousDown(true);
      setKeyGhost(true);
      setKeyGhostPosition([e.pageX - lockSize/2, e.pageY - lockSize/2]);
    }
    else {
      let [src, name] = target.id.split(":::");
      let accept = target.translate ? 'true' : 'false'
      set_ghost(src, name, accept);
      setGhostPosition([e.pageX - imoj/2, e.pageY - imoj/2]);
      if (target.lang.length) {
        setEmojiAudio(target.lang)
      }
  //  setGhostPosition([e.pageX - imoj/2, e.pageY - imoj/2]);

    }
  }

  const handleTouchStart = (e: React.TouchEvent) => {
    if (showButtons) { return }
    if (showTextSign) { setShowTextSign(false) }

    const target = e.target as HTMLDivElement;


    if (target.id === 'keyIcon') {
      if (playEmojiAudio) {
        setPlayEmojiAudio(false)
      }
      startPlayingResultAudio()
      setShowKey(false)
      setMaousDown(true);
      setKeyGhost(true);
      setKeyGhostPosition([e.touches[0].pageX - imoj/2, e.touches[0].pageY - imoj/2]);
    }
    else {
      let [src, name] = target.id.split(":::");
      let accept = target.translate ? 'true' : 'false'
      set_ghost(src, name, accept);
      setGhostPosition([e.touches[0].pageX - imoj/2, e.touches[0].pageY - imoj/2]);
      if (target.lang.length) {
          setEmojiAudio(target.lang)
      }
    }
  }

  const handleMouseMove = (e: React.MouseEvent ) => {
    e.preventDefault();
    if (!mouseDown || showButtons || showTextSign) { return }

    const x_ = e.pageX - imoj/2;
    const y_ = e.pageY - imoj/2;
    do_reception(x_, y_);
  }

  const handleTouchMove = (e: React.TouchEvent ) => {
    if (!mouseDown || showButtons || showTextSign) { return }
    const x_ = e.touches[0].pageX - imoj/2;
    const y_ = e.touches[0].pageY - imoj/2;
    do_reception(x_, y_);
  }

  const handleMouseUp = () => {

    if (keyGhost) {
      setKeyGhostPosition([0,0]);
      setKeyGhost(false);
      setMaousDown(false);
      if ( !keyReceived ) { setShowKey(true) }
      if ( reception ) {
            setShowLock(true);
            setKeyReceived(true);
            setTimeout(() => { 
                  setShowButtons(true) 
            }, 1300);
            if (showResult) {  
              setTimeout(() => { 
                  setShowAudioButtons(false)
                  setPauseAudio(false) 
              }, 2000);
            }
      }
      return;
    }

    if (reception && ghostSrc?.length) {

        const new_comp_arr = receivedComps;
        new_comp_arr.push({ name: ghostName, src: ghostSrc, pos: ghostPosition });
        setReceivedComps(new_comp_arr);
        //if (new_comp_arr.length > 2) { setShowButtons(true) }
        if ((!showKey) && (new_comp_arr.length > 0)) { setShowKey(true) }
        
        let do_once = true;

        setEmojis(emojis.map(emo => {
            if (emo.name === ghostName && do_once) {
              do_once = false;
              return { ...emo, received: true }
            }
            return emo;
          })
        )

    }
    setGhostSrc('')
    setGhostName('')
    setReception(false) 
    setMaousDown(false)
    if (sprite === 'open') { setSprite('close') }
  }


  /* const startPlayingNextAudio = () => {
    setOpacity(false);
    setAudioSrc(actionAudio)
    setAudioEnd(false)
    setPauseAudio(false)
    setTimeout(() => {
      setPlayAudio(true)
    },10)
    setTimeout(() => {
      setPauseAudio(true)
    },20)
    setTimeout(() => { 
      setStation('action_menu')
      setPage('action_menu');
    }, 1000); 
  }
 */

  const handle_button_click = (page: string) => {
    setGhostSrc('')
    setGhostName('')
    setReception(false)
    setShowButtons(false)
    setReceivedComps(receivedCompsArr.slice())
   // setEmojis(emojiArr.slice());
    setOpacity(false);
    if (page === 'story') {
        setShowNavBar(false);
        setAudioSrc(storyAudio)
    }
    if (page === 'action_menu' || page === 'gingel'){
        setShowResult(false);
        setStation('action_menu');
        setBackground(situationBackground);
        if (page === 'action_menu') {
          setAudioSrc(actionAudio)
        }
    }
    setAudioEnd(false)
    setPauseAudio(false)
    setTimeout(() => {
      setPlayAudio(true)
    },10)
    setTimeout(() => {
      setPauseAudio(true)
    },20)
    setTimeout(() => {
      setPage(page);
    },1000)
  }



  const handleEndEmojiAudio = () => {
     setPlayEmojiAudio(false)
     setPauseEmojiAudio(true)
  }

  

  return (
    <div className = { classes.black }>
        <div 
            className = { classes.root } 
            style     = {{
              backgroundImage: `url(${background})`,
              opacity:         opacity ? 1 : 0 
            }}
        >
          <div 
            className     = { classes.filter } 
            onMouseMove   = { e => handleMouseMove(e) }
            onTouchMove   = { e => handleTouchMove(e) }
            onMouseUp     = { () => handleMouseUp() }
            onTouchEnd    = { () => handleMouseUp() }
            onTouchCancel = { () => handleMouseUp() }
          >
            { !hiddenLoaded && <CompLoader variant = { 'page' } lang = { lang }/> } 
            {
              hiddenLoaded && 
              <>
                    <LionImage name = { name } largeFigure = { largeFigure }/>
                      <div className = { shake ? classes.shake : classes.dont_shake }>
                          <HeartSprite 
                              size         = { size }
                              variant      = { 'back' }
                              heart_size   = { heart_size }
                              sprite       = { sprite }
                          />
                          <HeartSprite 
                              size         = { size }
                              variant      = { 'cover' }
                              heart_size   = { heart_size }
                              sprite       = { sprite }
                          />
                          <ReceivedEmojis
                              size          = { size }
                              sprite        = { sprite }
                              heart_size    = { heart_size }
                              showButtons   = { showButtons }
                              showResult    = { showResult }
                              receivedComps = { receivedComps }
                              gridSize      = { receivedComps.length }
                          />
                      </div>
                      <Emojis
                          size             = { size }
                          emojis           = { emojis }
                          showButtons      = { showButtons }
                          showResult       = { showResult }
                          showEmojis       = { showEmojis }
                          imoj             = { imoj }
                          mouseDown        = { mouseDown }
                          ghostName        = { ghostName }
                          showTextSign     = { showTextSign }
                          handleMouseDown  = { handleMouseDown }
                          handleTouchStart = { handleTouchStart }
                    />
                  
                  {
                      showLock
                      &&
                      (
                        <Lock
                          size           = { size }
                          heart_size     = { heart_size }
                          lockSize       = { lockSize }
                        />
                      )
                    }
                    {
                      showKey
                      &&
                      (
                        <Key
                          initial          = { initial }
                          setInitial       = { setInitial }
                          lockSize         = { lockSize }
                          keyReceived      = { keyReceived }
                          handleMouseDown  = { handleMouseDown }
                          handleTouchStart = { handleTouchStart }
                        />
                      )
                    }
                    {
                      ((mouseDown === true) && (keyGhost === true))
                      &&
                      (
                        <KeyGhost
                            position  = { keyGhostPosition } 
                            lockSize  = { lockSize }
                            reception = { reception }
                        />
                      )
                    }
                    {
                      ((mouseDown === true) && (keyGhost === false))
                      &&
                      (
                      <Ghost
                          src      = { ghostSrc }
                          position = { ghostPosition } 
                          imoj     = { imoj }
                          received = { false }
                          ghostName= { ghostName }
                      />
                      )
                    }
                    { 
                      <div 
                        className = { classes.filter }
                        style     = {{
                          opacity: showButtons ? 1 : 0,
                          zIndex:  showButtons ? 13 : 0,
                        }}
                      /> 
                    }
                    {              
                      (!showResult && !magicAction && !customVideo) 
                      && 
                      ( 
                        <ForwardRoundedIcon 
                            className = { classes.icon }
                            onClick   = { () => { handle_button_click('gingel') }}
                            style     = {{
                              top:       showButtons ? '50%' : '200vh',
                      //       transform: `translate(${ size[0] > 600 ? 0 : 50 }%, -50%)`
                            }}
                        />
                      )
                    }
                    {
                      ((showResult || magicAction || customVideo) && showButtons)
                      &&
                      ( 
                        <div className = { classes.reaction_menu }>
                          <ReactionMenu
                            size                   = { size }
                            lang                   = { lang }
                            dir                    = { dir }
                            emotionMenuText        = { emotionMenuText }
                            handle_button_click    = { handle_button_click }
                            setSatisfiedWithResult = { setSatisfiedWithResult }
                          />
                        </div>
                      )
                    }
                    <TextSign
                      size                = { size }
                      lang                = { lang }
                      dir                 = { dir }
                      emotionMenuText     = { emotionMenuText }
                      showTextSign        = { showTextSign }
                    />
                   <EmojiAudio
                      audioSrc         = { emojiAudioSrc }
                      playAudio        = { playEmojiAudio }
                      pauseAudio       = { pauseEmojiAudio }
                      handleEndAudio   = { handleEndEmojiAudio }
                    /> 
              </>
            } 
            </div>
        </div>
        {
          !hiddenLoaded
          &&
          <Hidden 
              emojiArr        = { emojiArr }
              audio           = { [] }
              setHiddenLoaded = { setHiddenLoaded }
          />
        }
   </div>
  )
}

export default EmotionMenu




