import React                 from 'react';

type HiddenProps = {
    audio:           string[];
    emojiArr:        { name: string; audio: string; image: string }[];
    setHiddenLoaded: (val: boolean) => void;
}

const Hidden: React.FC<HiddenProps> = ({
    audio,
    emojiArr,
    setHiddenLoaded
  }) => {
  
    const [audioCounter, setAudioCounter] = React.useState(0)
    const [audioLoaded, setAudioLoaded]   = React.useState(false)


    // this is a temporary ugly workaround specifically for ios - - - - 

    React.useEffect(() => {

      setTimeout(() => {
        setHiddenLoaded(true)
      }, 1500)

    },[setHiddenLoaded])

    //  - - - - - - - - - - - - - - - - - - - - - - - - - - - - 


    const [audioArr] = React.useMemo(() => {

      let AA = audio.slice();

      for (let i = 0; i < emojiArr.length; i++) {
        if(emojiArr[i].audio.length) {
          AA.push(emojiArr[i].audio)
        }
      }

      return[AA]

    },[audio, emojiArr])


    React.useEffect(() => {
  
      if (audioCounter > 0) {
        setAudioLoaded(true)
      }
    },[audioCounter, audioArr, setAudioLoaded])


    const handleOnAudioLoad = () => {
      const newNum = audioCounter + 1;
      setAudioCounter(newNum);
    }


    React.useEffect(() => {
       if (audioLoaded) {
          setHiddenLoaded(true)
       }
    },[setHiddenLoaded, audioLoaded])


    return (
    <>
          {
              audioArr.map((val, ind) => {
                return(
                    <audio  
                        muted  
                        webkit-playsinline = "true" 
                        playsInline
                        autoPlay
                        key              = { `emotionMenuHiddenAudio${ val }_${ ind }` }
                        src              = { val } 
                        preload          = "auto"
                        onCanPlayThrough = { handleOnAudioLoad }
                   /> 
                )
              })
          }
    </>
  )
}

export default Hidden

