import React           from 'react';
import DiagramUnit     from './DiagramUnit';
import { actionType }  from '../../types';
import { makeStyles }  from '@material-ui/core/styles';

const diagramUnits = [
    { variant: 'situation' },
    { variant: 'heart1',   },
    { variant: 'scenarios' },
    { variant: 'heart2'    },
    { variant: 'lock'      },
];

const useStyles = makeStyles({
  root: {
    position:        'fixed',
    left:            0,
    top:             0,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    height:          '100vh',
    transition:      'width 0.8s',
    overflow:        'hidden',
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  diagram: {
    height:         '100vh',
    width:          '100vw',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
  },
  column: {
    flexFlow: 'column'
  }
});

type MapProps = {
  size:        number[];
  action:      number;
  openMap:     boolean;
  minimizeMap: boolean;
  actionArr:   actionType[];
  sitImg:      string;
  station:     string;
  lang:        string;
  dir:         string;
}

const Map: React.FC<MapProps> = ({ 
  size,
  actionArr,
  openMap,
  sitImg,
  action,
  station,
  minimizeMap,
  lang,
  dir
}) => {

  const classes = useStyles();

  const iconSize = React.useMemo(() => {
    return size[0]/10
  },[size])

  return (
    <div 
         className = { classes.root } 
         style     = {{
           width: openMap ? '100vw' : '0vw'
         }}
    >
        <div className = { classes.diagram }>
            {
                diagramUnits.map((val, ind) => {
                    return(
                        <DiagramUnit
                           key         = { `DiagramUnit_${ ind }` }
                           size        = { size }
                           lang        = { lang }
                           dir         = { dir }
                           iconSize    = { iconSize }
                           minimizeMap = { minimizeMap }
                           variant     = { val.variant }
                           actionArr   = { actionArr }
                           sitImg      = { sitImg }
                           action      = { action }
                           station     = { station }
                        />
                    )
                })
            }
        </div>
    </div>
  );
}


export default Map