import React                  from 'react';
import Root                   from './pages/Root';

const App: React.FC = () => {
   return (
        <Root/> 
    )  
}

export default App
  
 








/*

//aws s3 sync build/ s3://choices --delete && aws cloudfront create-invalidation --distribution-id E19TNVGHZGM0Q4 --paths '/*'

npm run build && aws s3 sync build/ s3://7pm-demo --delete --profile moti && aws cloudfront create-invalidation --distribution-id E3KIVPWPQDH7VD --paths '/*'  --profile moti 

*/




