import React              from 'react';
import colors             from '../../utils/colors';
import CheckRoundedIcon   from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon   from '@material-ui/icons/ClearRounded';
import { makeStyles }     from '@material-ui/core/styles';
import { words }          from '../../utils/dictionary';

var clsx = require('classnames')

const useStyles = makeStyles(theme => ({
    container: {
      width:          '100%',
      height:         '100%',
      display:        'flex',
      flexFlow:       'column',
      justifyContent: 'center',
      alignItems:     'center',
      transition:     'all 2s',
      zIndex:         999,
    },
    options: {
      width:          '100%',
      display:        'flex',
      justifyContent: 'space-around',
      alignItems:     'center',
    },
    text: {
      color:     '#FFF',
      width:     '100%',
      padding:   40,
      fontSize:  34,
      textAlign: 'center'
    },
    button_container: {
      flex:           1,
      height:         250,
      display:        'flex',
      flexFlow:       'column',
      justifyContent: 'center',
      alignItems:     'center',
    },
    button: {
      width:           190,
      height:          90,
      display:         'flex',
      justifyContent:  'center',
      alignItems:      'center',
      transition:      'transform 1s, width 0.4s',
      border:          '1px solid #FFF',
      cursor:          'pointer',
      borderRadius:    '1em',
      '&:hover': {
        width: 250
      }
    },
    pink: {
      backgroundColor: colors.pink,
    },
    blue: {
      backgroundColor: colors.blue_,
    },
    icon: {
      color:    '#FFF',
      fontSize: 50
    },
    p: {
      color:      '#FFF',
      fontSize:   34,
      fontWeight: 1000,
      cursor:     'pointer'
    },
    column: {
      flexFlow: 'column'
    },
}));

type ReactionMenuProps = {
  size:                   number[];
  emotionMenuText:        string[];
  lang:                   string;
  dir:                    string;
  handle_button_click:    (val: string) => void;
  setSatisfiedWithResult: (val: boolean) => void;
}

const ReactionMenu: React.FC<ReactionMenuProps> = ({
  size,
  lang,
  dir,
  emotionMenuText,
  handle_button_click,
  setSatisfiedWithResult,
}) => {

  const classes = useStyles();

  const [initialPos, setInitialPos] = React.useState(false)

  React.useEffect(() => {
    setTimeout(function(){ setInitialPos(true) }, 500); 
   },[setInitialPos]);


   const handleContinue = () => {
      setInitialPos(false)
      setTimeout(() => {
        setSatisfiedWithResult(true);
        handle_button_click('story')
      },800)
   }

   const handleDisatisfaction = () => {
       setInitialPos(false)
       setTimeout(() => {
        setSatisfiedWithResult(false);
        handle_button_click('action_menu');
       },800)
   }

  return (  
    <div 
        dir       = { dir }
        className = { classes.container } 
        style     = {{ 
          marginTop: !initialPos ? '150vh' : 0 
          }}
    >
        <p className = { classes.text }>
          { `${ words[emotionMenuText[1]][lang] }` }
       </p>
        <div className = { size[0] > 600 ? classes.options : clsx(classes.options, classes.column) }>
           <div className = { classes.button_container }>
                <div 
                   className = { clsx(classes.button, classes.blue) }
                   onClick   = { handleContinue }
                >
                  <CheckRoundedIcon className = { classes.icon }/>
                </div>
                <p 
                  className = { classes.p }
                  onClick   = { handleContinue }
                >
                  { `${ words['yes'][lang] }` }
                </p>
            </div>
            <div className = { classes.button_container }>
                <div 
                   className = { clsx(classes.button, classes.pink) }
                   onClick   = { handleDisatisfaction }
                >
                  <ClearRoundedIcon className = { classes.icon }/>
                </div>
                <p 
                  className = { classes.p }
                  onClick   = { handleDisatisfaction }
                >
                   { `${ words['no'][lang] }` }
                </p>
            </div>
        </div>
    </div>
         
  )
}

export default ReactionMenu;







