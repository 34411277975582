import React          from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button         from '@material-ui/core/Button';
import colors         from '../../utils/colors';
import MenuItem       from '@material-ui/core/MenuItem';
import { words }      from '../../utils/dictionary';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop:    10,
        paddingBottom: 15
    },
    button: {
        width:        75,
        height:       45,
        borderRadius: '0.3em',
        color:        colors.darkGrey,
    },
    typography: {
        width:           75,
        position:        'absolute',
        backgroundColor: '#FFF',
        transition:      'all 0.4s',
        boxShadow:       `0 3px 7px rgba(0, 0, 0, 0.4)`,
        zIndex:          999
    },
    item: {
      color: colors.darkGrey,
    }
}));

const menuItemArr = new Array(9).fill(0);

type DropDownProps = {

    lang:        string;
    dir:         string;
    childAge:    number;
    setChildAge: (age: number) => void;
}

const DropDownMenu: React.FC<DropDownProps> = ({ lang, dir, childAge, setChildAge }) => {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const menuItemClick = (num: number) => {
    setChildAge(num);
    setOpen(false)
  };

  return (
    <div className = { classes.root }>
      <Button 
        className = { classes.button } 
        onClick   = { () => setOpen(!open) }
        style     = {{ border: open ? `2px solid ${ colors.darkGrey }` : `1px solid ${ colors.darkGrey }` }}
      >
       { childAge === -1 ? `${ words['select'][lang] }` : childAge }
      </Button>
        <div className = { classes.typography } style = {{ display: open ? 'block' : 'none' }}>
            {
              menuItemArr.map((val, ind) => {

                  const my_num = ind + 2;

                  return(
                    <div 
                       dir = { dir }
                       key = { `MenuItem_${my_num}` }
                    >
                        <MenuItem 
                          className = { classes.item }
                          value     = { my_num }
                          onClick   = { () => menuItemClick(my_num) }
                        >
                          { `${ my_num }` }
                        </MenuItem>
                    </div>
                  )
              })
            }
        </div>
    </div>
  );
}


export default DropDownMenu;
