import React                           from 'react';
import { DateTime, Interval }          from 'luxon';
import { connect }                     from 'react-redux';
import { Dispatch }                    from "redux";
import { ActionTypes,
         userInfo,
         getProfilesSaga,
         setProfiles,
       }                               from '../types/actions';

import { GET_USER_INFO,
         GET_PROFILES_SAGA,
         SET_PROFILES,
       }                               from '../actions';

import { reducerType }                 from '../reducer';
import { situationAndScenariosType }   from '../types';

import Questionnaire                   from './questionnaire';
import Intro                           from './intro';
import Home                            from './home';
import ProfileMenu                     from './profile_menu';
import SitutationMenu                  from './situation_menu';
import Gingel                          from './gingel';
import EmotionMenu                     from './emotion_menu';
import ActionMenu                      from './action_menu';
import VideoUpload                     from './video_upload';
import Story                           from './story';
import ResultTest                      from './result_test';
import Map                             from '../components/map';
import Navbar                          from '../components/navbar';
import Info                            from '../components/info';
import IntroVideo                      from '../components/videoComponent';
import VideoLoader                     from '../components/videoLoader';
import ImageLoader                     from '../components/imageLoader';
import Audio                           from '../components/audio_component';

import { situations, scenarios }       from '../utils/situations';
import { 
  situationUrls, 
  scenarioUrls,
  situationUrlsEng, 
  scenarioUrlsEng,
  situationImages,
  scenarioImages,
  //hebAudio,
  engAudio
}                                      from '../utils/allUrls';

const now = DateTime.local();


type RootProps = {
  profiles:                    [];
  situation:                   string;
  lastScenarioCompletionTime:  string;
  showRootLoader:              boolean;
  situationsArr:               situationAndScenariosType[];
  //getUserInfo:                 (payload: userInfo) => void;
  //getProfiles:                 (payload: getProfilesSaga) => void;
  //updateProfiles:              (payload: setProfiles) => void;
}

const Root: React.FC<RootProps> = ({
  profiles,
  situation,
  showRootLoader,
  lastScenarioCompletionTime,
 // userVideoSrc,
  situationsArr,
}) => {

  const [size, setSize] = React.useState([0,0]);

  React.useLayoutEffect(() => {

    const updateSize = () => { setSize([window.innerWidth, window.innerHeight]) }
  
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const [page, setPage]                                               = React.useState('home')//home  
  const [station, setStation]                                         = React.useState('home')//home

  const [allSituations, setAllSituations]                             = React.useState(situations)
  const [scenariosState, setScenariosState]                           = React.useState(scenarios)

  const [selectedSituation, setSelectedSituation]                     = React.useState('')//''
  const [situationObj, setSituationObj]                               = React.useState(!situation.length ? allSituations['shaming'] : allSituations[situation])
  const [actionArr, setActionArr]                                     = React.useState(!situation.length ? scenariosState['shaming'] : scenariosState[situation])
  const [action, setAction]                                           = React.useState(0)
  const [emojiArr, setEmojiArr]                                       = React.useState(situationObj.emojis_eng)
  const [acceptableEmojiArr, setAcceptableEmojiArr]                   = React.useState(situationObj.acceptEmojis)
  const [background, setBackground]                                   = React.useState(situationObj.background)
  const [gingle, setGingle]                                           = React.useState(situationObj.gingle)
  const [largeFigure, setLargeFigure]                                 = React.useState(situationObj.large_figure)
  const [smallFigure, setSmallFigure]                                 = React.useState(situationObj.small_figure)
  const [audio, setAudio]                                             = React.useState(situationObj.audio)
  const [situationId, setSituationId]                                 = React.useState(situationObj.id)
  const [introVideoSrc, setIntroVideoSrc]                             = React.useState(situationObj.introVideo)
  const [emotionMenuAudio, setEmotionMenuAudio]                       = React.useState('')
  //const [storyAudio, setStoryAudio]                                   = React.useState(engAudio.story)
  const storyAudio = engAudio.story;
  const [objectURLCreated, setObjectURLCreated]                       = React.useState(false)
  const [reEnterSituationMenu, setReEnterSituationMenu]               = React.useState(false)
  
  const [showNavBar, setShowNavBar]                                   = React.useState(false)//false   
  const [openMap, setOpenMap]                                         = React.useState(false)//false
  const [minimizeMap, setMinimizeMap]                                 = React.useState(false)
  const [disableMinimizeMap, setDisableMinimizeMap]                   = React.useState(true)
  const [magicAction, setMagicAction]                                 = React.useState(false);
  const [showResult, setShowResult]                                   = React.useState(false)//false
  const [showMagicWand, setShowMagicWand]                             = React.useState(false)
  const [showUserVideo, setShowUserVideo]                             = React.useState(false)//false
  const [userStoryCreated, setUserStoryCreated]                       = React.useState(false)
  const [satisfiedWithResult, setSatisfiedWithResult]                 = React.useState(true);
  const [situationViedoWached, setSituationViedoWached]               = React.useState(false);
  const [redoSituation, setRedoSituation]                             = React.useState(false);
  const [customVideo, setCustomVideo]                                 = React.useState(false);
  const [showInfo, setShowInfo]                                       = React.useState(false);//false
  const [signup, setSignup]                                           = React.useState(false);//false
  const [infoVariant, setInfoVariant]                                 = React.useState('general');
  const [intructionsEnabled, setIntructionsEnabled]                   = React.useState(true);
  
  const showLoader = false;
  const [showBlockedSituationModal, setShowBlockedSituationModal]     = React.useState(false);
  const [showCleanRoomModal, setShowCleanRoomModal]                   = React.useState(false);
  const [playIntroVideo, setPlayIntroVideo]                           = React.useState(false);
  const [pauseIntroVideo, setPauseIntroVideo]                         = React.useState(false); 

  const [uploadingFiles, setUploadingFiles]                           = React.useState(false);  
  const [videoLoaderSrcArr, setVideoLoaderSrcArr]                     = React.useState(situationUrlsEng);  
  const [imageLoaderSrcArr, setImageLoaderSrcArr]                     = React.useState(situationImages);  
  const [loadedVideos, setLoadedVideos]                               = React.useState([] as string[]);
  const [loadedImages, setLoadedImages]                               = React.useState([] as string[]);
  const [loadVideo, setLoadVideo]                                     = React.useState(false) 
  const [fullStory, setFullStory]                                     = React.useState([] as string[]) 

  const [audioSrc, setAudioSrc]                                       = React.useState(audio.emotion_menu);
  const [playAudio, setPlayAudio]                                     = React.useState(false);
  const [pauseAudio, setPauseAudio]                                   = React.useState(false);
  const [showAudioButtons, setShowAudioButtons]                       = React.useState(false);
  const [audioEnd, setAudioEnd]                                       = React.useState(false);
  const [muteAudio, setMuteAudio]                                     = React.useState(false);

  const lang = 'eng'//const [lang, setLang] = React.useState('eng')
  const dir  = 'ltr'//const [dir, setDir]   = React.useState('ltr') 

  React.useEffect(() => {


      //setActionArr(actionArr.map((val, i) => i === ind ? {...val, viewed: true} : val))


    if (situationsArr.length) { 


      let updatedSituations = allSituations;
      let updatedScenarios = scenariosState;

        for (let i = 0; i < situationsArr.length; i++) {

          if (situationsArr[i].name === 'clean_room') {
             if (!situationsArr[i].scenarios) {
              setShowCleanRoomModal(true)
             }
             else {
              if (showCleanRoomModal) { setShowCleanRoomModal(false) }
             }
          }
           
          updatedSituations[situationsArr[i].name].id = situationsArr[i].id;
       //   updatedSituations[situationsArr[i].name].completed = situationsArr[i].completed;

          if (situationsArr[i].scenarios) {

              const completedScenariosArr = situationsArr[i].scenarios.split(",");

               for (let j = 0; j < completedScenariosArr.length; j++) {

                updatedScenarios[situationsArr[i].name][completedScenariosArr[j]].viewed = true;
                updatedScenarios[situationsArr[i].name][completedScenariosArr[j]].resultViewed = true;
              }

              if (completedScenariosArr.length >= scenariosState[situationsArr[i].name].length - 1) {
                updatedSituations[situationsArr[i].name].completed = 1;
             }
             
              setScenariosState(updatedScenarios)
              
          }

        }

        setAllSituations(updatedSituations)
    }
    
  },[situationsArr, 
     allSituations, 
     scenariosState,
     showCleanRoomModal, 
     setAllSituations, 
     setScenariosState, 
     setShowCleanRoomModal])
  

   React.useEffect(() => {

      if (actionArr[actionArr.length-1].actionVideo.length && actionArr[actionArr.length-1].resultVideo.length) {
        setUserStoryCreated(true)
      }
      else {
        setUserStoryCreated(false)
      }

   },[actionArr, setUserStoryCreated])


   React.useEffect(() => {
    if (localStorage.getItem('signup') && localStorage.getItem('signup') === 'true') {
      localStorage.setItem('signup', 'false');
      setSignup(true)
    }
  },[setSignup]) 


  



  React.useEffect(() => {
    if (station === 'result' || station === 'second_emotion_menu' || station === 'result_test') {
      if (disableMinimizeMap) { setDisableMinimizeMap(false) }
    }
    else {
      if (!disableMinimizeMap) { setDisableMinimizeMap(true) }
      setMinimizeMap(false)
    }
  },[station, disableMinimizeMap, setDisableMinimizeMap, setMinimizeMap])


  const handleGoHome = () => {
    setPage('situation_menu')
    setReEnterSituationMenu(true)
    setStation('situation')
    setSelectedSituation('')
    setEmotionMenuAudio('')
    setSituationViedoWached(false)
    setShowResult(false)
    setShowNavBar(false)
    setRedoSituation(false)
    setAudioEnd(false)
  }

  const resetEverything = () => {
    const defaultSituation = !selectedSituation.length ? 'shaming' : selectedSituation;
    setActionArr(scenariosState[defaultSituation]);
    setAction(0);
    setMagicAction(false);
    setBackground(situationObj.background);
    setEmojiArr(lang === 'eng' ? situationObj.emojis_eng : situationObj.emojis)
    setShowResult(false);
    setShowMagicWand(false);
    setShowUserVideo(false);
    handleGoHome();
    setObjectURLCreated(false)
    setRedoSituation(false)
    setEmotionMenuAudio('')
    setAudioEnd(false)
  }


  const handleSituationSelection = ( name: string ) => {


      //block user from watching mor than one new situation per day

      if (lastScenarioCompletionTime.length) {

         for (let i = 0; i < situationsArr.length; i++) {

            if (situationsArr[i].name === name && !situationsArr[i].created_at) {
               const latest = DateTime.fromISO(lastScenarioCompletionTime)
               const int = Interval.fromDateTimes(latest, now).count('days');
               if (int <= 1) {
                  setShowBlockedSituationModal(true)
                  return;
               }
            }
         } 

      }

      // determine if magic wand option should be blocked
     
      let counter = 0;

      for (let i = 0; i < scenariosState[name].length; i++) {
          if (scenariosState[name][i].viewed && scenariosState[name][i].resultViewed) {
            counter++;
          }
          if (counter >= 2) {
            setShowMagicWand(true);
            break;
          }
          else {
            if (showMagicWand) {
              setShowMagicWand(false);
            }
          }
      }

      const eng = lang === 'eng';

      setSelectedSituation(name)
      setSituationObj(allSituations[name])
      setActionArr(scenariosState[name])
      setEmojiArr(eng ? allSituations[name].emojis_eng : allSituations[name].emojis)
      setAcceptableEmojiArr(allSituations[name].acceptEmojis)
      setGingle(allSituations[name].gingle)
      setLargeFigure(allSituations[name].large_figure)
      setSmallFigure(allSituations[name].small_figure)
      setAudio(eng ? allSituations[name].audioEng : allSituations[name].audio)
      setBackground(allSituations[name].background)
      setObjectURLCreated(false)
      setShowUserVideo(false);
      setSituationId(allSituations[name].id)
      setIntroVideoSrc(allSituations[name].introVideo)
      setVideoLoaderSrcArr(lang === 'eng' ? scenarioUrlsEng[name] : scenarioUrls[name])
      setImageLoaderSrcArr(scenarioImages[name])
      setEmotionMenuAudio(eng ? allSituations[name].audioEng.emotion_menu : allSituations[name].audio.emotion_menu)
  }


  const handleActionSelection = (action: number) => {
    setAcceptableEmojiArr(actionArr[action].acceptEmojis)
  }


  const handlePlayIntroVideo = (src: string) => {
    setIntroVideoSrc(src)
    setPlayIntroVideo(true)
    setPauseIntroVideo(false)
  }

  const handleEndIntroVideo = () => {
    setPlayIntroVideo(false)
    setPauseIntroVideo(true)
  }

/* 
  const selectLang = (lang: string) => {
    setLang(lang)
    setDir(lang === 'heb' ? 'rtl' : 'ltr')
  } */
  
  const switchVideoLoader = (val: string) => {

      if (val === 'scenarios') {
        setVideoLoaderSrcArr(lang === 'eng' ? scenarioUrlsEng[selectedSituation] : scenarioUrls[selectedSituation])
        setImageLoaderSrcArr(scenarioImages[selectedSituation])
      }
      if (val === 'situations') {
        setVideoLoaderSrcArr(lang === 'eng' ? situationUrlsEng : situationUrls)
        setImageLoaderSrcArr(situationImages)
      }
      if (val === 'ternOff') {
        setVideoLoaderSrcArr([])
        setImageLoaderSrcArr([])
      }
  }

  const handleEndAudio = () => {
    setAudioEnd(true)
  }


  return (
    <div style = {{ maxWidth: size[0] }}>
        {
          ( page === 'intro' ) 
          && 
          ( <Intro
              size        = { size }
              signup      = { signup }
              lang        = { lang }
              dir         = { dir }
              setShowInfo = { setShowInfo }
              setPage     = { setPage }
            /> 
          )
        }
        {
          ( page === 'home' ) 
          && 
          <Home 
             size         = { size } 
             lang         = { lang }
             dir          = { dir }
             showLoader   = { showLoader } 
             signup       = { signup }
             setPage      = { setPage } 
             setLoadVideo = { setLoadVideo }
          />
        }
        {
          ( page === 'profile_menu' ) 
          && 
          ( <ProfileMenu size = { size } lang = { lang } setPage = { setPage }/> )
        }
        {
          ( page === 'questionnaire' ) 
          && 
          ( <Questionnaire
              size    = { size }
              lang    = { lang }
              dir     = { dir }
              signup  = { signup }
              setPage = { setPage }
            /> 
          )
        }
        {
          ( page === 'situation_menu' ) 
          && 
          ( <SitutationMenu
              size                           = { size }
              lang                           = { lang }
              dir                            = { dir }
              allSituations                  = { allSituations }
              situationViedoWached           = { situationViedoWached }
              selectedSituation              = { selectedSituation }
              signup                         = { signup }
              showInfo                       = { showInfo }
              redoSituation                  = { redoSituation }
              showBlockedSituationModal      = { showBlockedSituationModal }
              reEnterSituationMenu           = { reEnterSituationMenu }
              showCleanRoomModal             = { showCleanRoomModal }
           //   timeToSwitchVideoLoader        = { ((lang === 'heb' && videoLoaderSrcArr !== situationUrls) || (lang === 'eng' && videoLoaderSrcArr !== situationUrlsEng)) }
              timeToSwitchVideoLoader        = { videoLoaderSrcArr !== situationUrlsEng }
              uploadingFiles                 = { uploadingFiles }
              loadedVideos                   = { loadedVideos }
              emotionMenuAudio               = { emotionMenuAudio }
              emojiArr                       = { emojiArr }
              setMuteAudio                   = { setMuteAudio }
              setPlayAudio                   = { setPlayAudio }
              setPauseAudio                  = { setPauseAudio }
              setAudioSrc                    = { setAudioSrc }
              switchVideoLoader              = { switchVideoLoader }
              setAllSituations               = { setAllSituations }
              setShowCleanRoomModal          = { setShowCleanRoomModal }
              setReEnterSituationMenu        = { setReEnterSituationMenu }
              setShowBlockedSituationModal   = { setShowBlockedSituationModal }
              setRedoSituation               = { setRedoSituation }
              setSignup                      = { setSignup }
              setSituationViedoWached        = { setSituationViedoWached }
              setPage                        = { setPage }
              setStation                     = { setStation }
              setShowNavBar                  = { setShowNavBar }
              handleSituationSelection       = { handleSituationSelection }
              handlePlayIntroVideo           = { handlePlayIntroVideo }
              setUploadingFiles              = { setUploadingFiles }
              setLoadedVideos                = { setLoadedVideos }
            /> 
          )
        }
        {
           ( page === 'gingel' ) 
           && 
           ( <Gingel
              size              = { size }
              loadedVideos      = { loadedVideos }
              lang              = { lang }
              dir               = { dir }
              gingle            = { gingle }
              situations        = { allSituations }
              selectedSituation = { selectedSituation }
              background        = { situationObj.background }
              gingelDownloaded  = { loadedVideos.includes(gingle) }
              actionAudio       = { audio.action }
              setAudioSrc       = { setAudioSrc }
              setPlayAudio      = { setPlayAudio }
              setPauseAudio     = { setPauseAudio }
              setPage           = { setPage }
              setStation        = { setStation }
              setAudioEnd       = { setAudioEnd }
            /> 
           )
        }
        {
          ( page === 'emotion_menu' ) 
          && 
          ( 
            <EmotionMenu 
              size                    = { size } 
              lang                    = { lang }
              dir                     = { dir }
              customVideo             = { customVideo }
              resultAudio             = { audio.result }
              name                    = { situationObj.name }
              emotionMenuText         = { situationObj.emotionMenuText }
              emojiArr                = { emojiArr }
              acceptEmojisArr         = { acceptableEmojiArr }
              magicAction             = { magicAction }
              showResult              = { showResult }
              largeFigure             = { largeFigure }
              background              = { background }
              situationBackground     = { situationObj.background }
              timeToSwitchVideoLoader = { videoLoaderSrcArr !== scenarioUrlsEng[selectedSituation] }
              playAudio               = { playAudio }
              audioEnd                = { audioEnd }
              audioSrc                = { audioSrc }
              actionAudio             = { audio.action }
              storyAudio              = { storyAudio }
              intructionsEnabled      = { intructionsEnabled }
              setAudioEnd             = { setAudioEnd }
              setMuteAudio            = { setMuteAudio }
              setAudioSrc             = { setAudioSrc }
              setPlayAudio            = { setPlayAudio }
              setPauseAudio           = { setPauseAudio }
              setShowAudioButtons     = { setShowAudioButtons }
              switchVideoLoader       = { switchVideoLoader }
              setStation              = { setStation }
              setShowResult           = { setShowResult }
              setBackground           = { setBackground }
              setSatisfiedWithResult  = { setSatisfiedWithResult }
              setPage                 = { setPage } 
              setShowNavBar           = { setShowNavBar }
              setUploadingFiles       = { setUploadingFiles }
            />   
          )
        }
        {
          ( page === 'action_menu' ) 
          && 
          ( 
              <ActionMenu 
                 now                    = { now.toFormat('yyyy-LL-dd') }
                 size                   = { size } 
                 lang                   = { lang }
                 dir                    = { dir }
                 name                   = { situationObj.name }
                 background             = { background }
                 centerImage            = { situationObj.image }
                 magicVideoSrc          = { situationObj.magicVideoSrc }
                 userVideoSrc           = { actionArr[actionArr.length-1].actionVideo }
                 actionArr              = { actionArr }
                 action                 = { action }
                 allSituations          = { allSituations }
                 selectedSituation      = { selectedSituation }
                 actionAudio            = { audio.action }
                 magicAction            = { magicAction }
                 showResult             = { showResult }
                 showMagicWand          = { showMagicWand }
                 showUserVideo          = { showUserVideo }
                 satisfiedWithResult    = { satisfiedWithResult }
                 customVideo            = { customVideo }
                 actionMenuText         = { situationObj.actionMenuText }
                 situationId            = { situationId }
                 userStoryCreated       = { userStoryCreated }
                 objectURLCreated       = { objectURLCreated }
                 redoSituation          = { redoSituation }
                 loadedVideos           = { loadedVideos }
                 audioEnd               = { audioEnd }
                 playAudio              = { playAudio }
                 emotionMenuAudio       = { audio.emotion_menu }
                 intructionsEnabled     = { intructionsEnabled }
                 setFullStory           = { setFullStory }
                 setAudioEnd            = { setAudioEnd }
                 setLoadedVideos        = { setLoadedVideos }
                 setShowMagicWand       = { setShowMagicWand }
                 setShowUserVideo       = { setShowUserVideo }
                 setActionArr           = { setActionArr }
                 setAction              = { setAction }
                 setMagicAction         = { setMagicAction }
                 setShowResult          = { setShowResult }
                 setStation             = { setStation }
                 setShowNavBar          = { setShowNavBar }
                 setSatisfiedWithResult = { setSatisfiedWithResult }
                 setCustomVideo         = { setCustomVideo }
                 setBackground          = { setBackground }
                 setPage                = { setPage }
                 handleActionSelection  = { handleActionSelection }
                 setAllSituations       = { setAllSituations }
                 setAudioSrc            = { setAudioSrc }
                 setPlayAudio           = { setPlayAudio }
                 setPauseAudio          = { setPauseAudio }
                 setShowAudioButtons    = { setShowAudioButtons }
                 setMuteAudio           = { setMuteAudio }
              />
          )
        }
        {
          ( page === 'video_upload' ) 
          && 
          ( 
              <VideoUpload 
                 lang                 = { lang } 
                 dir                  = { dir } 
                 name                 = { situationObj.name }
                 background           = { background }
                 actionArr            = { actionArr }
                 actionAudio          = { audio.action }
                 setAudioSrc          = { setAudioSrc }
                 setAudioEnd          = { setAudioEnd }
                 setPlayAudio         = { setPlayAudio }
                 setPauseAudio        = { setPauseAudio }
                 setObjectURLCreated  = { setObjectURLCreated }
                 setPage              = { setPage }
                 setActionArr         = { setActionArr }
              />
          )
        }
       {
          ( page === 'story' ) 
          && 
          ( 
            <Story 
              size                = { size }
              lang                = { lang }
              dir                 = { dir }
              background          = { situationObj.background }
              fullLengthVideo     = { fullStory } 
              audioEnd            = { audioEnd } 
              testAudio           = { audio.conclusion_test }
              setPage             = { setPage } 
              setShowNavBar       = { setShowNavBar } 
              setStation          = { setStation } 
              setPauseAudio       = { setPauseAudio }
              setPlayAudio        = { setPlayAudio }
              setAudioSrc         = { setAudioSrc }
              setAudioEnd         = { setAudioEnd }
              setShowAudioButtons = { setShowAudioButtons }
            />
          )
        }
        {
          ( page === 'result_test' ) 
          && 
          ( 
            <ResultTest 
              size                = { size } 
              lang                = { lang }
              dir                 = { dir }
              resultImg           = { magicAction ? situationObj.magicBackground : actionArr[action].resultImg }
              resultText          = { situationObj.resultText }
              page                = { page }
              smallFigure         = { smallFigure }
              situationBackground = { situationObj.background }
              actionArr           = { actionArr }
              action              = { action }
              correct             = { actionArr[action].correct }
              testAudio           = { audio.conclusion_test }
              successAudio        = { audio.conclusion_result }
              audioEnd            = { audioEnd }
              playAudio           = { playAudio }
              actionAudio         = { audio.action }
              intructionsEnabled  = { intructionsEnabled }
              setAudioEnd         = { setAudioEnd }
              setAudioSrc         = { setAudioSrc }
              setPlayAudio        = { setPlayAudio }
              setPauseAudio       = { setPauseAudio }
              setPage             = { setPage }
              setBackground       = { setBackground }
              setShowResult       = { setShowResult }
              resetEverything     = { resetEverything }
              setStation          = { setStation }
              setShowMagicWand    = { setShowMagicWand }
              setShowUserVideo    = { setShowUserVideo }
              setActionArr        = { setActionArr }
              setAction           = { setAction }
              setShowAudioButtons = { setShowAudioButtons }
            /> 
          )
        }
        <Map 
           size        = { size }
           lang        = { lang }
           dir         = { dir }
           actionArr   = { actionArr }
           openMap     = { openMap }
           minimizeMap = { minimizeMap }
           sitImg      = { situationObj.image }
           action      = { action }
           station     = { station }
        />
        <Audio
            audioSrc         = { audioSrc }
            playAudio        = { playAudio }
            pauseAudio       = { pauseAudio }
            muteAudio        = { muteAudio }
            showButtons      = { showAudioButtons }
            setPauseAudio    = { setPauseAudio }
            setPlayAudio     = { setPlayAudio }
            handleEndAudio   = { handleEndAudio }
        />
        {
           showNavBar
           &&
           <Navbar 
              size                  = { size }
              lang                  = { lang }
              dir                   = { dir }
              page                  = { page }
              openMap               = { openMap }
              minimizeMap           = { minimizeMap }
              disableMinimizeMap    = { disableMinimizeMap }
              disableHomeButton     = { page === 'situation_menu' && selectedSituation === '' }
              disableInfoButton     = { false }
              intructionsEnabled    = { intructionsEnabled }
              setIntructionsEnabled = { setIntructionsEnabled }
              handleGoHome          = { handleGoHome }
              setOpenMap            = { setOpenMap }
              setMinimizeMap        = { setMinimizeMap }
              setShowInfo           = { setShowInfo }
           />
        }
        {
          showInfo 
          &&
          <Info 
             size           = { size }
             lang           = { lang }
             dir            = { dir }
             setShowInfo    = { setShowInfo } 
             setInfoVariant = { setInfoVariant }
             variant        = { infoVariant }
             page           = { page }
          />
        }
        <IntroVideo
            videoSrc       = { introVideoSrc }
            playVideo      = { playIntroVideo }
            pauseVideo     = { pauseIntroVideo }
            setPauseVideo  = { setPauseIntroVideo }
            handleEndVideo = { handleEndIntroVideo }
        />
        {
          loadVideo
          &&
          <VideoLoader
             videoLoaderSrcArr  = { videoLoaderSrcArr }
             loadedVideos       = { loadedVideos }
             uploadingFiles     = { uploadingFiles }
             setLoadedVideos    = { setLoadedVideos }
          />
        }
        {
          !uploadingFiles
          &&
          <ImageLoader
             imageLoaderSrcArr  = { imageLoaderSrcArr }
             loadedImages       = { loadedImages }
             setLoadedImages    = { setLoadedImages }
          />
        }
    </div>
  )
}

const mapStateToProps = (reducer: reducerType) => {

  return {
    profiles:                   reducer.profiles,
    situation:                  reducer.situation,
    situationsArr:              reducer.situationsArr,
    showRootLoader:             reducer.showRootLoader,
    lastScenarioCompletionTime: reducer.lastScenarioCompletionTime
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  getUserInfo:    (payload: userInfo) => dispatch(GET_USER_INFO(payload)),
  getProfiles:    (payload: getProfilesSaga) => dispatch(GET_PROFILES_SAGA(payload)),
  updateProfiles: (payload: setProfiles) => dispatch(SET_PROFILES(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
//