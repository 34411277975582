

export const words: { [key: string]: { [key: string]: string; }} = {

    //authenticators   -    -    -    -    -    -   

    user_name:             { eng: 'username',          heb: 'שם משתמש' },
    email:                 { eng: 'Email',             heb: `דוא״ל` },
    password:              { eng: 'password',          heb: `סיסמה` },
    new_password:          { eng: 'new password',      heb: `סיסמה חדשה` },
    code:                  { eng: 'verification code', heb: `קוד אימות` },
    signup:                { eng: 'sign up',           heb: `הירשם` },
    signup_:               { eng: 'sign up',           heb: `הרשמה` },
    signin:                { eng: 'log in',            heb: `כניסה` },
    submit_:               { eng: 'submit',            heb: 'אישור' },

    no_username_err:       { eng: 'please enter your name',                                          heb: `אנא הזן שם משתמש` },
    no_email_err:          { eng: 'please enter a valid Email address',                              heb: `אנא הזן כתובת דוא״ל תקינה` },
    email_not_found_err:   { eng: 'the Email address you have provided does not exist',              heb: `כתובת הדוא״ל שהזנת לא נמצאה.` },
    empty_password_err:    { eng: 'please enter your password',                                      heb: `אנא הזן סיסמה` },
    no_password_err:       { eng: 'password must be at least 8 characters long',                     heb: `אנא הזן סיסמה באורך 8 תוים לפחות` },
    no_code_err:           { eng: 'confirmation code cannot be empty',                               heb: `אנא הזן קוד אימות` },
    no_new_password_err:   { eng: 'password cannot be empty',                                        heb: `אנא הזן סיסמה חדשה` },
    user_already_exists:   { eng: 'a user with the Email you provided already exists',               heb: `משתמש עם דוא״ל זה כבר רשום במערכת` },
    go_to_login:           { eng: 'if you already have an account log in here:',                     heb: `אם הנך רשום היכנס כאן:` },
    go_to_signup:          { eng: `if you don't have an account sign up here:`,                      heb: `אם אינך רשום, אנא הירשם כאן:` },
    enter_credentials1:    { eng: 'Please enter a username, Email and an 8 character long password', heb: 'אנא הזן שם משתמש, כתובת דוא״ל וסיסמה בת 8 תוים לפחות' },
    AttemptLimitExceeded:  { eng: 'Attempt limit exceeded, please try after some time.',             heb: 'ביצעת מספר רב מידי של ניסיונות כניסה כושלים. אנא נסה שוב מאוחר יותר' },
    signin_form:           { eng: `enter your Email and password`,                                   heb: `אנא הזן כתובת דוא״ל וסיסמה` },
    enter_email:           { eng: `enter your Email address`,                                        heb: `הזן כתובת דוא״ל` },
    forgot_password:       { eng: `forgot password?`,                                                heb: `שכחת סיסמה?` },
    enter_new_password:    { eng: `enter your Email address and your new password`,                  heb: `אנא הזן כתובת דוא״ל ואת הסיסמה החדשה שיצרת` },

    code_sent_1:           { eng: 'A verification code has been sent to',                                            heb: `קוד אימות נשלח אליך בכתובת:` },
    code_sent_2:           { eng: 'please enter your verification code below.',                                      heb: `אנא הזן את קוד האימות שנשלח אליך בתיבת הטקסט.` },
    code_sent_3:           { eng: `note - if you can't find the code in your inbox, it may be in your spam folder`,  heb: `שים לב - אם אינך מוצא את קוד האימות בתיבת הדואר הנכנס, ייתכן שהוא הועבר אל תיבת הספאם.` },
    invalid_code_err:      { eng: `Invalid verification code provided, please try again.`,                           heb: `הקוד שהוזן שגוי` },
    general_auth_err:      { eng: 'Please enter a username, a valid Email address and an 8 character long password', heb: `אנא ודא שהזנת כתובת דוא״ל תקינה וסיסמה באורך 8 תוים לפחות` },
    general_textfield_err: { eng: 'Please fill all text fields correctly',                                           heb: `אנא ודא שהזנת נתונים תקפים בכל תיבות הטקסט` },

    
    //components    -    -    -    -    -    -   

    continue:       { eng: 'Continue',             heb: 'המשך' },
    return:         { eng: 'Return',               heb: 'חזור' },
    submit:         { eng: 'Submit',               heb: 'סיים' },
    loading_video:  { eng: 'Loading Video',        heb: 'טוען סרטון' },
    loading_page:   { eng: 'Loading Page',         heb: 'הדף בטעינה' },
    play:           { eng: 'Play',                 heb: 'נגן סירטון' },
    replay:         { eng: 'Replay',               heb: 'נגן סירטון שוב' },
    yes:            { eng: 'Yes',                  heb: 'כן' },
    no:             { eng: 'No',                   heb: 'לא' },
    situation:      { eng: 'Situation',            heb: 'סיטואציה ראשית' },
    emotion_scale:  { eng: 'Emotion Scale',        heb: 'סרגל רגשות' },
    conclusion:     { eng: 'Conclusion',           heb: 'סיכום' },
    action:         { eng: 'Action',               heb: 'תגובה' },
    result:         { eng: 'Result',               heb: 'תוצאה' },
    signout:        { eng: 'Sign Out',             heb: 'התנתק' },
    help:           { eng: 'Help',                 heb: 'עזרה' },
    my_jingle:      { eng: 'MyJingle',             heb: 'מנטרה לי'},
    action_menu:    { eng: 'Action Menu',          heb: 'גלגל תגובות'},
    magicAction:    { eng: 'Magic Action',         heb: 'תגובת קסם'},
    conclusion_:    { eng: 'Conclusion',           heb: 'כתר'},
    disable_inst:   { eng: 'Disable Instructions', heb: 'בטל הוראות קוליות'},
    enable_inst:    { eng: 'Enable Instructions',  heb: 'הפעל הוראות קוליות'},

    
    //profile menu   -    -    -    -    -    -   

    select_profile: { eng: 'Select Profile',     heb: 'בחר פרופיל' },
    create_profile: { eng: 'Create New Profile', heb: 'צור פרופיל חדש' },

    //questionnaire   -    -    -    -    -    -   

    child_name:             { eng: 'Child name',                      heb: 'שם הילד' },
    age:                    { eng: 'Age',                             heb: 'גיל' },
    gender:                 { eng: 'Gender',                          heb: 'מגדר' },
    boy:                    { eng: 'Boy',                             heb: 'בן' },
    girl:                   { eng: 'Girl',                            heb: 'בת' },
    select:                 { eng: 'Select',                          heb: 'בחר' },
    ordinal_pos_f:          { eng: 'Ordinal position in the family',  heb: 'מיקום הילד במשפחה' },
    ordinal_pos:            { eng: 'Position',                        heb: 'מיקום הילד' },
    sibling_num:            { eng: 'Number of siblings',              heb: 'מספר אחים ואחיות' },
    siblings:               { eng: 'Siblings',                        heb: 'מספר אחים/ות' },
    city_of_residence:      { eng: 'City of residence',               heb: 'עיר מגורים' },
    parent_ocupation:       { eng: 'Parent ocupation (not required)', heb: `עיסוק ההורים (לא חובה)` },  
    introversion:           { eng: 'Introversion',                    heb: 'מופנם' },
    extroversion:           { eng: 'Extroversion',                    heb: 'מוחצן' },
    gratification_delay:    { eng: 'Ability to delay gratification',  heb: 'יכולת לדחות סיפוקים' },
    no_gratification_delay: { eng: 'Inability to delay gratification',heb: 'מתקשה לדחות סיפוקים' },
    violent:                { eng: 'Verbal/physical violent behavior',heb: 'מגיב באלימות פיזית/מילולית' },
    non_violent:            { eng: 'Non violent behavior',            heb: 'לא מגיב באלימות פיזית/מילולית' },

    name_taken_err1:        { eng: 'A profile by the name of ',                                                  heb: 'פרופיל בשם ' },
    name_taken_err2:        { eng: ` already exists in your account. Profile names must be unique.`,             heb: ` כבר קיים בחשבונך. חובה לבחור שם יחודי לכל פרופיל.` },
    no_name_err:            { eng: 'Child name is a required field',                                             heb: 'חובה לציין את שם הילד' },
    no_age_err:             { eng: 'Child age is a required field',                                              heb: 'חובה לציין את גיל הילד' },
    no_sib_num_err:         { eng: 'Number of siblings is a required field',                                     heb: `חובה לציין מספר אחים ואחיות`},
    no_ordinal_pos_err:     { eng: 'Ordinal position in the family is a required field',                         heb: 'חובה לציין את מיקום הילד ביחס לאחים ואחיות'},
    sib_num_NaN:            { eng: 'Number of siblings must be entered using numerals',                          heb: `חובה לציין מספר אחים ואחיות באמצעות ספרות בלבד`},
    ordinal_pos_NaN:        { eng: 'Ordinal position must be entered using numerals',                            heb: 'חובה לציין את מיקום הילד במשפחה באמצעות ספרות בלבד'},
    no_city_err:            { eng: 'City of residence is a required field',                                      heb: 'חובה לציין עיר מגורים'},
    questionnaire_f_2:      { eng: 'Which emotion names is the child familiar with, and uses on a daily basis?', heb: 'אלו שמות של רגשות הילד מכיר ומשתמש ביומיום?' },
    questionnaire_f_2_text: { eng: 'Please list any number of emotion names known to the child',                 heb: 'טקסט חופשי' },
    questionnaire_f_2_err:  { eng: 'Please list at least one name',                                              heb: 'חובה לציין שם אחד לפחות' },
    questionnaire_f_3:      { eng: 'Place the child on a scale of:',                                             heb: `מקם את הילד על הרצף של:` },

    //situation menu   -    -    -    -    -    -  
    
    shaming:    { eng: 'Friends Insulted Me', heb: 'חברים ביישו אותי' },
    clean_room: { eng: '',                    heb: 'לא רוצה לאסוף צעצועים' },
    ball_thief: { eng: '',                    heb: 'לקחו לי משחק' },
    cookie_jar: { eng: '',                    heb: 'קשה לי לומר את האמת' },

    // emotion menu   -   -    -    -    -    -    -  

    girlEmotionMenuText1: { eng: `What does Nell feel now? Let's put the right feelings in the heart and lock it with the key.`, heb: `מה נל מרגישה עכשיו? בואו נכניס ללב את הרגשות המתאימים, ובסוף ננעל עם המפתח.` },
    boyEmotionMenuText1:  { eng: ``,                                                                                             heb: `מה נובי מרגיש עכשיו? בואו נכניס ללב את הרגשות המתאימים, ובסוף ננעל עם המפתח.` },
    girlEmotionMenuText2: { eng: `Is Nell satisfied with the result?`, heb: `האם נל מרוצה מהתוצאה?` },
    boyEmotionMenuText2:  { eng: ``,                                   heb: `האם נובי מרוצה מהתוצאה?` },


    // action menu   -   -    -    -    -    -    -  

    my_clip:             { eng: 'MyClip',                              heb: 'קליפלי'},
    boyActionMenuText1:  { eng: '',                                    heb: `איך כדאי לנובי להגיב?` },
    boyActionMenuText2:  { eng: '',                                    heb: `אז איך כדאי לנובי להגיב?` },
    girlActionMenuText1: { eng: 'How do you think Nell should react?', heb: `איך כדאי לנל להגיב?` },
    girlActionMenuText2: { eng: 'How do you think Nell should react?', heb: `אז איך כדאי לנל להגיב?` },
           
    magicModal1:         { eng: `If you could cast a spell, which spell would you cast?`,              heb: `אם היה אפשר לעשות עכשיו קסם, איזה קסם הייתם רוצים לעשות?` },
    magicModal2:         { eng: `To view this action, you must first complete at least two scenarios`, heb: `לצפייה בתגובה זו עליך להשלים שתי תגובות לפחות` },
    user_video_err:      { eng: `To create your own action, you must first complete all scenarios`,    heb: `ליצירת תגובה משלך עליך להשלים תחילה את כל שאר התגובות` },
    view_user_videos:    { eng: `View your videos`,                                                    heb: `צפה בסירטונים שיצרת` },
    create_user_videos:  { eng: `Create new videos`,                                                   heb: `צור סירטונים חדשים` },
    result_video_title:  { eng: `Let's think, what will happen now?`,                                  heb: `בואו נחשוב מה יקרה עכשיו?` },


    // video upload   -   -   -   -   -   -   -   -   - 

    bad_video_upload:      { eng: 'Something whent wrong. Please try again later', heb: 'משהו לא בסדר. אנא נסה שוב מאוחר יותר' },
    upload_action_success: { eng: 'Action video successfuly uploaded!',            heb: `סרטון התגובה הועלה בהצלחה!` },
    upload_result_success: { eng: 'Result video successfuly uploaded!',            heb: `סרטון התוצאה הועלה בהצלחה!` },
    create_action_video:   { eng: 'Create an action video',                        heb: 'צור סרטון תגובה' },
    create_result_video:   { eng: 'Create a result video',                         heb: 'צור סרטון תוצאה' },
    upload_video:          { eng: 'Upload video',                                  heb: 'העלה סרטון' },
    upload_video_type_err: { eng: 'Please select a file of type mp4 or mov',       heb: `אנא בחר קובץ מסוג mp4 או מסוג mov` },
    upload_video_size_err: { eng: 'Please select a smaller file',                  heb: 'הקובץ שבחרת כבד מידי. אנא בחר קובץ יותר קטן' },

    // story    -   -   -   -   -    -    -    -   -    -    -  

    story_title: { eng: `Let's watch from the beginig and see what happend`, heb: 'בואו נראה מההתחלה את מה שקרה' },


    // result test -   -   -   -   -    -    -    -   -    -    -  


    girlresultText0: { eng: `Did Nell act like a Queen?`,        heb: `האם נל התנהגה כמו מלכה?` },
    girlresultText1: { eng: `Try the crown on her head and see`, heb: `נשים לה את הכתר ונראה` },
    girlresultText2: { eng: `Great!`,                            heb: `איזה יופי!` },
    girlresultText3: { eng: `You are a Queen!`,                  heb: `את מלכה!` },

    boyresultText0:  { eng: '', heb: `האם נובי הנהג כמו מלך?` },
    boyresultText1:  { eng: '', heb: `נשים לו את הכתר ונראה` },
    boyresultText2:  { eng: '', heb: `איזה יופי!` },
    boyresultText3:  { eng: '', heb: `אתה מלך!` },

    tryAgain:        { eng: 'Try Different Options', heb: `עוד אפשרות תגובה` },
    end:             { eng: 'End',                   heb: `סיימתי לעכשיו` },



    // modals -   -   -   -   -    -    -    -   -    -    -  


    redo_situation:  { 
        eng: `You have completed all the scenarions in this situation. Click on "continue" to practice them again.`,
        heb: `השלמת את כל התרחישים בסיטואציה זו. לחץ על ״המשך״ כדי לתרגל אותם שוב.`
    },


}
