import { call, take, put }                from 'redux-saga/effects';
import axios                              from 'axios';
import { SET_PROFILES, SHOW_ERROR_MODAL } from '../actions'


/* const endpoint = `${ process.env.REACT_APP_URL }/${ process.env.NODE_ENV }/getAllUserProfiles`
 */
const env = process.env.NODE_ENV;
const endpoint = `${ process.env[`REACT_APP_URL_${env}`]}/${env}/getAllUserProfiles`


function* selectProfile() {
  while(true) {
    try {

        const userInput = yield take('GET_PROFILES_SAGA');
        
        const response = yield call(
            axios.post,
            endpoint,
            { 
               headers: {
                 'Access-Control-Allow-Origin': '*'
               },
               payload: { 
                  userId: userInput.payload.userId 
               }
            }
        );

        if (response.data.success) {
            localStorage.setItem('profiles', JSON.stringify(response.data.result))
            const payload = { profiles: response.data.result }
            yield put(SET_PROFILES(payload));
        }
   
    }
    catch(err) {
      console.log('err  :  '+err);
      yield put(SHOW_ERROR_MODAL(true));
    }
    finally {}
  }
}


export default selectProfile;