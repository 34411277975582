import React              from 'react';
import { makeStyles }     from '@material-ui/core/styles';
import colors             from '../../utils/colors';

const t1 = `לפני שאנחנו מתחילים...`;
const t2 = `רק שתי דקות לקרוא איך האפליקציה פועלת`;

const t1eng = `Before we begin...`;
const t2eng = `The following presentation of 7:00 PM will help you to get started.`;

const useStyles = makeStyles(theme => ({
 
    text_container: {
       position:        'fixed',
       top:             0,
       height:          '75vh',
       minHeight:       300,
       width:           '100vw',
       display:         'flex',
       justifyContent:  'center',
       alignItems:      'center',
       flexFlow:        'column',
       backgroundColor: colors.halavi,
       transition:      'all 1.5s',
    },
    text: {
      width:          '100%',
      height:         '80%',
      textAlign:      'center',  
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      flexFlow:       'column',
      color:          colors.darkGrey,
      fontWeight:     900,
      padding:        10,
    },
    list: {
       marginLeft:    '50%',
      transform:      'translate(-50%, 0%)', 
      border:         `1.5px solid ${ colors.darkGrey }`,//'#FFF'}`,
      borderRadius:   '1em',
      textAlign:      'right',
      color:          colors.darkGrey,// '#FFF',
      textShadow:     '0 0 7px rgba(250, 250, 250, 1)',
      fontSize:       25,
      fontWeight:     900,
      padding:        15
    },
    p: {
       margin:   10,
       fontSize: 29,
    },
}));


type TextCompProps = {
  size:       number[];
  lang:       string;
  dir:        string;
  initialPos: boolean;
}

const TextComp: React.FC<TextCompProps> = ({ size, lang, dir, initialPos }) => {

  const classes = useStyles();

  return (
      <>
        <div 
           className = { classes.text_container }
           style     = {{
             transform: `translate(0px, ${ initialPos ? 0 : 150 }vh)`,
           }}
        >
            <div 
               dir       = { dir }
               className = { classes.text }
               style     = {{
                fontSize: size[0] > 700 && size[1] > 650  ? 25 : 17
               }}
            >
               <p className = { classes.p }>
                    { lang === 'eng' ? t1eng : t1 }
                </p>  
                <p className = { classes.p }>
                    { lang === 'eng' ? t2eng : t2 }
                </p>             
            </div>
        </div>
    </>
  )
}

export default TextComp
