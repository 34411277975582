import React             from 'react';
import heartsprite       from '../../graphic_components/heart_sprite_final.png';
import heartsprite_cover from '../../graphic_components/heart_sprite_cover_final.png';
import { makeStyles }    from '@material-ui/core/styles';

var clsx = require('classnames');

const useStyles = makeStyles(theme => ({
    root: {
      position:   'fixed',
      width:      '100vw',
      transition: 'opacity 6s',
      overflowY:  'hidden',

    },
    heart_sprite: {
      height:             '100%',
      position:           'absolute',
      backgroundRepeat:   'no-repeat',
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      transition:         'opacity 0.5s'
    },
    static: {
    },
    open: {
      animation: 'open 1.3s steps(16)',
    },
    close: {
      animation: 'close 1.3s steps(16)',
    }
}));

type HeartProps = {
  variant:       string;
  sprite:        string;
  size:          number[];
  heart_size:    number;
}

const Heart: React.FC<HeartProps> = ({ 
    variant,
    sprite,
    size,
    heart_size
}) => {

  const classes = useStyles();

  const [animating, setAnimating]   = React.useState(false);
  const [opacity, setOpacity]       = React.useState(1);
  const [initialPos, setInitialPos] = React.useState(false);

  React.useEffect(() => {
     setTimeout(() => {
      setInitialPos(true)
     },10)
  },[setInitialPos])

  const Ref = React.useRef<HTMLDivElement>(null);

  const currentRef = Ref.current;

 
  React.useEffect(() => {

    const myEndFunction = () => {
      setAnimating(false);
      if (variant === 'cover' && sprite === 'close') {
        setOpacity(0.5);
      }
    };
  
    const myStartFunction = () => {
      setAnimating(true);
      if (variant === 'cover' && sprite === 'open') {
        setOpacity(1);
      }
    }
  
    if (Ref && currentRef) {
        currentRef.addEventListener("webkitAnimationEnd", myEndFunction);
        currentRef.addEventListener("animationend", myEndFunction);
        currentRef.addEventListener("webkitAnimationStart", myStartFunction);
        currentRef.addEventListener("animationstart", myStartFunction);
        
        return function() {
          currentRef?.removeEventListener("webkitAnimationEnd", myEndFunction);
          currentRef?.removeEventListener("animationend", myEndFunction);
          currentRef?.removeEventListener("webkitAnimationStart", myStartFunction);
          currentRef?.removeEventListener("animationstart", myStartFunction);
        }
    }
  },[Ref, currentRef, sprite, variant]);


  const int_x = size[0] / 2 - heart_size * 1.2;
  const sprite_height =  heart_size * 18.75;

  let top = -sprite_height;
  if (animating && sprite === 'open')  { top = sprite_height/15 }
  if (animating && sprite === 'close') { top = 0 }
  if (!animating && sprite === 'open') { top = 0 }
  
  const animation = sprite === 'open'
                    ?
                    classes.open
                    :
                    (sprite === 'close' ? classes.close : classes.static);

  return (
    <div 
      className = { classes.root }
      style     = {{
          height:   heart_size * 1.1,
          top:      size[0] > size[1] ? '30vh' : '40vh',
          zIndex:   variant === 'cover' ? 3 : 0,
          opacity:  initialPos ? 1 : 0
        }}
    >
       <div 
            ref       = { Ref }
            className = { clsx(classes.heart_sprite, animation) } 
            style     = {{
                backgroundImage: `url(${variant === 'cover' ? heartsprite_cover : heartsprite})`,
                width:           heart_size * 2.3,
                height:          heart_size * 20,
                top:             top,
                left:            int_x,
                opacity:         opacity
            }}
        />
    </div>

  )
}

export default Heart
























/**
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * import React          from 'react';
import heart          from '../../graphic_components/heart.png';
import heartsprite    from '../../graphic_components/heartsprite.png';
import { makeStyles } from '@material-ui/core/styles';

var clsx = require('classnames');

const useStyles = makeStyles(theme => ({
    root: {
      position:   'fixed',
      border:     '3px solid #0F0',
      transform:  'translate(-10%, 0%)',
      transition: 'opacity 0.5s',
      overflow :  'hidden'
    },
    heart_image: { 
      border:             '1px solid #00F',
      position:           'absolute',
      backgroundRepeat:   'no-repeat',
      backgroundPosition: 'center',
    },
    open: {
      animation: 'heart 1.3s steps(16)'
    },
    close: {
      animation:          'heart 1.3s steps(16)',
      animationDirection: 'reverse'
    }
}));

type HeartProps = {
  reception:     boolean;
  int_x:         number;
  int_y:         number;
  heart_size:    number;
  showButtons:   boolean;
  showResult:    boolean;

}

const Heart: React.FC<HeartProps> = ({ 
    heart_size,
    reception,
    int_x,
    int_y,
    showButtons,
    showResult,
}) => {

  const classes = useStyles();
  
  const [go, setGo] = React.useState(true);

  // React.useEffect(() => {
  //   setTimeout(function(){ setGo(true); }, 10); 
  // },[]);

  const heart_status = reception ? '140%' : '120%';

  return (
    <div 
      className = { classes.root }
      style = {{
          top:     int_y,
          left:    int_x,
          width:   400 * 2,
          height:  400,
          opacity: showResult && showButtons ? 0.5 : 1
      }}
    >
       <div 
            className = { classes.heart_image } 
            style     = {{
                backgroundImage: `url(${heart})`,
                width:           800,
                height:          400,
                top:             -20,
                left:            '25%',
                backgroundSize:  'contain',

            }}
        />
        { <div 
            className = { reception ? clsx(classes.heart_image, classes.open) : clsx(classes.heart_image, classes.close) } 
            style     = {{
                backgroundImage: `url(${heartsprite})`,
                width:           400 * 2,
                height:          400 * 16,
                top:             0,
                left:            0,
                backgroundSize:  'cover',
            }}
        /> }
        </div>

        )
      }
      
      export default Heart
      
      
      

      



 */