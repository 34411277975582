import { /*call,*/ take, put }      from 'redux-saga/effects';
//import axios                    from 'axios';
import { SET_USER_VIDEO }       from '../actions'

function* uploadUserVideo() {
  while(true) {
    try {

         const userInput = yield take('UPLOAD_VIDEO_SAGA');

         const { videoUrl } = userInput.payload;
 
         const payload = { userVideoSrc: videoUrl } 
  
         yield put(SET_USER_VIDEO(payload));

    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default uploadUserVideo;
