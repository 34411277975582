import React from 'react';

var playPromise: Promise<any>;

type ResultTestProps = { 
  src:            string;
  playAudio:      boolean;
  handleEndAudio: () => void;
}

const ResultTest: React.FC<ResultTestProps> = ({ 
    src, 
    playAudio,
    handleEndAudio
}) => {

const [mySrc, setMySrc] = React.useState(src)

React.useEffect(() => {

  if (src !== mySrc) {
    console.log('mySrc   :   ',mySrc)
    console.log('src     :   ',src)
    setMySrc(src)
  }

},[src, mySrc, setMySrc])
 
const AudioRef = React.useRef<HTMLAudioElement>(null);



if (AudioRef && AudioRef.current && src.length && playAudio) {
  playPromise = AudioRef.current.play();       
}

if (!playAudio) {
  if (playPromise !== undefined) {
      playPromise.then(_ => {
        if (AudioRef && AudioRef.current) {
          AudioRef.current.pause();
        }
      }).catch(error => {
          console.log('Audio Component Error   :   ',error)
      });  
  }
}


return (
     <audio  
           playsInline
           webkit-playsinline = "true" 
           preload            = "auto"
           ref                = { AudioRef }
           src                = { src } 
           onEnded            = { handleEndAudio }
     />
  )
}

export default ResultTest









