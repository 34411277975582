import { createMuiTheme } from '@material-ui/core/styles';
import colors             from './utils/colors';

export default createMuiTheme({
  palette: {
    primary: {
      main: colors.grey
    },
    secondary: {
      main: '#FFF'
    }
  }
});
/*
*/
