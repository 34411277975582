import { call, take, put } from 'redux-saga/effects';
import axios               from 'axios';

function* test() {
  while(true) {
    try {
        const userInput = yield take('TEST_SAGA');

        const { testing, yes_no } = userInput.values;

        console.log('testing   :',  testing)

        const response = yield call(
            axios.get,
            `https://gujjmrfbg0.execute-api.us-east-2.amazonaws.com/dev/hello`, {
          }
        );
  
        console.log('response.data  :  ',response.data);

        yield put({ type: 'TEST', payload: {
           testing: testing,
           yes_no: yes_no
         }
       })

   
    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default test;