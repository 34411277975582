import React                     from 'react';
import colors                    from '../../utils/colors';

import FullscreenRoundedIcon     from '@material-ui/icons/FullscreenRounded';
import FullscreenExitRoundedIcon from '@material-ui/icons/FullscreenExitRounded';
import HomeRoundedIcon           from '@material-ui/icons/HomeRounded';
import MapRoundedIcon            from '@material-ui/icons/MapRounded';
import InfoRoundedIcon           from '@material-ui/icons/InfoRounded';

import MenuButton                from './MenuButton';
import { makeStyles }            from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  root: {
    position:       'fixed',
    top:            0,
    right:          0,
    height:         80,
    width:          '100vw',
    display:        'flex',
    alignItems:     'center'
  },
  button_container: {
    height:          60,
    width:           60,
    marginRight:     20,
    marginLeft:      20,
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    transition:      'all 0.3s',
  },
  button: {
    height:          '90%',
    width:           '90%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    borderRadius:    '50%',
    cursor:          'pointer',
    transition:      'all 0.3s',
    backgroundColor: colors.blue_,
  //  border:          `1px solid #FFF`,
    '&:hover': {
      backgroundColor: '#89D',
    //  width:  '100%',
    }
  },
  icon: {
    color:           '#FFF',
    height:          '75%',
    width:           '75%',
    borderRadius:    '50%',
   // backgroundColor: colors.blue_,

  }
}));

type NavbarProps = {
    size:                  number[];
    page:                  string;
    lang:                  string;
    dir:                   string;
    openMap:               boolean;
    minimizeMap:           boolean;
    disableHomeButton:     boolean;
    disableInfoButton:     boolean;
    disableMinimizeMap:    boolean;
    intructionsEnabled:    boolean;
    setIntructionsEnabled: (val: boolean) => void;
    setOpenMap:            (val: boolean) => void;
    setMinimizeMap:        (val: boolean) => void;
    setShowInfo:           (val: boolean) => void;
   // setInfoVariant:        (val: string) => void;
    handleGoHome:          () => void;
}

const Navbar: React.FC<NavbarProps> = ({
     size,
     page,
     lang,
     dir,
     openMap,
     disableHomeButton,
     disableInfoButton,
     minimizeMap,
     disableMinimizeMap,
     setMinimizeMap,
     handleGoHome,
     setOpenMap,
     intructionsEnabled,
     setIntructionsEnabled,
     setShowInfo
}) => {

  const classes = useStyles()

  const [showMapSizeButton, setShowMapSizeButton] = React.useState(openMap)
  const [marginTop, setMarginTop]                 = React.useState(0)

  const handleMapClick = () => {
    setOpenMap(!openMap)
    setMarginTop(-250)
    setTimeout(() => {
      setMarginTop(0)
      setShowMapSizeButton(!openMap)
    },150)
  }


  const handleShowInfo = () => {
    if (disableInfoButton) { return }
    setShowInfo(true)
  //  setInfoVariant(page)
  }

  return (
    <div 
       className = { classes.root }
       style     = {{
        width:          size[0] > 800 ? 400 : '100vw',
        justifyContent: size[0] > 800 ? 'flex-end' : 'space-around'
       }}
    >
        <div className = { classes.button_container }>
              <div 
                className = { classes.button }
                onClick   = { handleShowInfo }
                style     = {{
                  opacity:   disableInfoButton ? 0.3 : 1,
                  marginTop: openMap ? -250 : 0,
                  cursor:    disableInfoButton ? 'default' : 'pointer'
                }}
              >
                <InfoRoundedIcon className = { classes.icon } />
              </div>
       </div>
       <div className = { classes.button_container }>
              <div 
                className = { classes.button }
                onClick   = { () => { if (!disableHomeButton) { handleGoHome() }}}
                style     = {{
                  opacity:   disableHomeButton ? 0.3 : 1,
                  marginTop: openMap ? -250 : 0
                }}
              >
                <HomeRoundedIcon className = { classes.icon } />
              </div>
       </div>
       <div className = { classes.button_container }>
              <div 
                 className = { classes.button }
                 onClick   = { handleMapClick }
              >
                <MapRoundedIcon className = { classes.icon }/>
              </div>
       </div>
       <div 
           className = { classes.button_container }
           style     = {{
            marginTop: marginTop
          }}
        >
           {
            !showMapSizeButton
            &&
            <MenuButton
              size                  = { size }
              lang                  = { lang }
              dir                   = { dir }
              intructionsEnabled    = { intructionsEnabled }
              setIntructionsEnabled = { setIntructionsEnabled }
            />
           }
           {
            showMapSizeButton
            &&
              <div 
                 className = { classes.button }
                 onClick   = { () => {}}
              >
                {
                  !minimizeMap
                  &&
                  <FullscreenExitRoundedIcon 
                     className = { classes.icon } 
                     onClick   = { () => { if (disableMinimizeMap) { return }; setMinimizeMap(!minimizeMap)} }
                     style     = {{
                       opacity: disableMinimizeMap ? 0.3 : 1
                     }}
                  />
                }
                {
                  minimizeMap
                  &&
                  <FullscreenRoundedIcon
                      className = { classes.icon }
                      onClick   = { () => { if (disableMinimizeMap) { return }; setMinimizeMap(!minimizeMap)} }
                      style     = {{
                        opacity: disableMinimizeMap ? 0.3 : 1
                      }}
                  />
                }      
              </div>
           }
        </div>
    </div>
  )
}

export default Navbar;








