import React                   from 'react';
import VolumeSlider            from '../../components/volume';

import PauseRoundedIcon        from '@material-ui/icons/PauseRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextRoundedIcon     from '@material-ui/icons/SkipNextRounded';
import PlayArrowRoundedIcon    from '@material-ui/icons/PlayArrowRounded';

import colors                  from '../../utils/colors';
import { makeStyles }          from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
     gingel_container: {
        position:        'fixed',
        top:             '50%',
        left:            '50%',
      //  transform:       'translate(-50%, -50%)',
        height:          '75vh',
        width:           '75vw',
        borderRadius:    '3em',
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
        backgroundColor: colors.kehe,
        border:          `1px solid ${ colors.halavi }`,
        transition:      'opacity 1s',
        overflow:        'hidden',
        paddingLeft:     30,
        paddingRight:    30,
        zIndex:          2
      },
     controlls: {
        position:        'fixed',
        display:         'flex',
        justifyContent:  'space-between',
        alignItems:      'center',
        bottom:          '5vh',
        left:            '5vh',
        width:           200,
        padding:         5,
        borderRadius:    '1em',
        backgroundColor: colors.kehe,
        color:           '#FFF',
        zIndex:          3
     },
     icon: {
         cursor: 'pointer'
     },
     sub: {
         position:        'absolute',
         width:           '100%',
         height:          '15%',
         minHeight:       100,
         bottom:          0,
         left:            0,
         display:         'flex',
         alignItems:      'center',
         justifyContent:  'center',
         textAlign:       'center',
         backgroundColor: 'rgba(0,0,0,0.4)',
         color:           '#FFF',
         fontWeight:      'bold'
     }
}));

type VideoComponentProps = {
    size:                number[];
    lang:                string;
    videoSrc:            string;
    playVideo:           boolean;
    pauseVideo:          boolean;
    downloadComplete:    boolean;
    setDownloadComplete: (val: boolean) => void;
    setPauseVideo:       (val: boolean) => void;
    handleEndVideo:      () => void;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
    size,
    lang,
    videoSrc,
    playVideo,
    pauseVideo,
    downloadComplete,
    setDownloadComplete,
    setPauseVideo,
    handleEndVideo,
}) => {

    const classes = useStyles();

    const [volume, setVolume]       = React.useState(30);
    const [muteVideo, setMuteVideo] = React.useState(true);


    React.useEffect(() => {
        if (downloadComplete) {
            setMuteVideo(false)
            handleReplay()
        }
        else {
            setMuteVideo(true)
        }
    },[downloadComplete, setMuteVideo])
    

    const gingelStyle = React.useMemo(() => {
        return {
           transform: `translate(-50%, ${ playVideo && downloadComplete ? -50 : -50000 }%)`,
           opacity: playVideo && downloadComplete ? 1 : 0
       }
    },[playVideo, downloadComplete])



    const Ref = React.useRef<HTMLVideoElement>(null);


    const handleDownLoad = () => {
     
        if (Ref && Ref.current && Ref.current.buffered && Ref.current.buffered.length > 0 && Ref.current.buffered.end && Ref.current.duration) {

            if (Math.round(Ref.current.buffered.end(0)) / Math.round(Ref.current.seekable.end(0)) === 1) {

                setDownloadComplete(true)
             }        
        }
    }

    if (Ref && Ref.current) {
        if (muteVideo) {
            Ref.current.muted = true
        }
        else {
            Ref.current.muted = false
        }
    }


    if (Ref && Ref.current && playVideo && !pauseVideo) {
        Ref.current.play();
    }

    const handlePause = () => {
        if (Ref && Ref.current) {
            Ref.current.pause();
            setPauseVideo(true);
        }
    };

    const handlePlay = () => { 
        setPauseVideo(false) 
    };

    const handleReplay = () => {
        if (Ref && Ref.current) {
            Ref.current.currentTime = 0;
        }
    }

    const handleVolume = (val: number) => { 
        setVolume(val);
        if (Ref && Ref.current) {
            Ref.current.volume = val/100;
        }
    };

    const handleSkip = () => {
        if (Ref && Ref.current) {
            Ref.current.currentTime = 0;
            Ref.current.pause();
            setPauseVideo(true);
            handleEndVideo()
        }
    }


    return(
        <>
            <div 
                className = { classes.gingel_container }
                style     = { gingelStyle }
            >
            <video 
                webkit-playsinline = "true"
                playsInline
                onProgress         = { handleDownLoad }
                onEnded            = { handleEndVideo }
                ref                = { Ref }
                src                = { videoSrc }
                height             = '80%'
                width              = '100%'
                preload            = "auto"
            />
            {
                lang === 'eng'
                &&
                <div
                   dir       = 'ltr'
                   className = { classes.sub }
                   style     = {{
                      fontSize: size[0] > 600 ? 29 : 20,
                   }}
                >
                   Breath, Feel, Think and now Act
                </div>
            }
            </div>
            {
                playVideo && downloadComplete
                && 
                (
                <div className = { classes.controlls } >
                    <SkipPreviousRoundedIcon               className = {classes.icon } onClick = { handleReplay }/>
                    { !pauseVideo && <PauseRoundedIcon     className = {classes.icon } onClick = { handlePause }/> }
                    { pauseVideo  && <PlayArrowRoundedIcon className = {classes.icon } onClick = { handlePlay }/> }
                    <VolumeSlider                          volume    = { volume } handleVolume = { handleVolume } />
                    <SkipNextRoundedIcon                   className = {classes.icon } onClick = { handleSkip }/>
                </div>
                )
            }
        </>
    )
}

export default VideoComponent;
//