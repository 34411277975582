import React                   from 'react';
import VolumeSlider            from '../../components/volume';

import PauseRoundedIcon        from '@material-ui/icons/PauseRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextRoundedIcon     from '@material-ui/icons/SkipNextRounded';
import PlayArrowRoundedIcon    from '@material-ui/icons/PlayArrowRounded';

import colors                  from '../../utils/colors';
import { makeStyles }          from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    video_container: {
      position:       'fixed',
      height:         '100vh',
      top:             0,
      margin:          0,
      display:         'flex',
      justifyContent:  'center',
      backgroundColor: "#000",
      zIndex:          2,
      transition:      'opacity 0.7s'
    },
    video: {
        position:   'fixed',
        transition: 'opacity 0.7s'
    },
    controlls: {
       position:        'fixed',
       display:         'flex',
       justifyContent:  'space-between',
       alignItems:      'center',
       bottom:          '5vh',
       left:            '5vh',
       width:           200,
       padding:         5,
       borderRadius:    '1em',
       backgroundColor: colors.kehe,
       color:           '#FFF',
    },
    icon: {
        cursor: 'pointer'
    }
}));


type VideoLoaderProps = {
    videoCompSrcArr:    string[];
    playVid:            string;
    start:              boolean;
    setPlayVid:         (val: string) => void;
    setStart:           (val: boolean) => void;
    handleGoToNextPage: () => void;

}

const VideoLoader: React.FC<VideoLoaderProps> = ({
    start,
    videoCompSrcArr,
    playVid,
    setStart,
    setPlayVid,
    handleGoToNextPage
}) => {

    const classes = useStyles();

    const [opacity, setOpacity]       = React.useState(0);
    const [muted, setMuted]           = React.useState(true);
    const [volume, setVolume]         = React.useState(30);
    const [videoNum, setVideoNum]     = React.useState(0)
    const [playVideo, setPlayVideo]   = React.useState(false)
    const [pauseVideo, setPauseVideo] = React.useState(true)

    const Ref = React.useRef<HTMLVideoElement>(null);

    React.useEffect(() => {
       if (playVid === 'play') {
        setPlayVid('playing')
        setOpacity(1)
        if (Ref && Ref.current) {
            Ref.current.play();
        }
       }
    },[playVid, setPlayVid])


    React.useEffect(() => {
        if (start) {
            setPlayVideo(true)
            setPauseVideo(false)
            setStart(false)
            setTimeout(() => {
                setMuted(false)
            },10)
        }
    },[start, setPlayVideo, setPauseVideo, setStart, setMuted])


     const handleEnd = () => { 

        if (videoNum < 2) {
            const newVideoNum = videoNum + 1
            setPlayVideo(false)
            setVideoNum(newVideoNum)
            setTimeout(() => {
                setPlayVideo(true)
            },10)
        }
        else {
            setOpacity(0)
            setPlayVideo(false)
            setPauseVideo(true)
            handleGoToNextPage()
        }
      
    } 
 

    if (Ref && Ref.current && playVideo && !pauseVideo) {
       Ref.current.play();       
    }


    const handlePause = () => {
        if (Ref && Ref.current) {
            Ref.current.pause();
            setPauseVideo(true);
        }
    };

    const handlePlay = () => { 
        setPauseVideo(false) 
    };

    const handleReplay = () => {
        if (Ref && Ref.current) {
            Ref.current.currentTime = 0;
        }
    }

    const handleVolume = (val: number) => { 
        setVolume(val);
        if (Ref && Ref.current) {
            Ref.current.volume = val/100;
        }
    };

    return(
        <div 
            className = { classes.video_container }
            style     = {{
                left:    playVideo ? 0 : -1000,
                width:   playVideo ? '100vw' : 0,
                opacity: opacity
            }}
        >
           <video 
               webkit-playsinline = "true"
               playsInline
               className  = { classes.video }
               muted      = { muted }
               onEnded    = { handleEnd } 
               ref        = { Ref }
               src        = { videoCompSrcArr[videoNum] }
               preload    = "auto"
               height     = '100%'
               width      = '100%'
               style      = {{ 
                   opacity: playVideo && opacity === 1 ? 1 : 0
               }}  
           />
           {
            playVideo
            && 
            (
              <div className = { classes.controlls } >
                  <SkipPreviousRoundedIcon               className = { classes.icon } onClick = { handleReplay }/>
                  { !pauseVideo && <PauseRoundedIcon     className = { classes.icon } onClick = { handlePause }/> }
                  { pauseVideo  && <PlayArrowRoundedIcon className = { classes.icon } onClick = { handlePlay }/> }
                  <VolumeSlider                          volume    = { volume } handleVolume = { handleVolume } />
                  <SkipNextRoundedIcon                   className = { classes.icon } onClick = { handleEnd }/>
              </div>
            )
           }
        </div>
    )
}

export default VideoLoader;

