import { call, take, put }      from 'redux-saga/effects';
import axios                    from 'axios';
import { SUBMIT_QUESTIONNAIRE } from '../actions'

//const endpoint = 'https://hbcfg6hyf1.execute-api.us-east-2.amazonaws.com/dev/createProfile';
//const endpoint = `${ process.env.REACT_APP_URL }/${ process.env.NODE_ENV }/createProfile`
const env = process.env.NODE_ENV;
const endpoint = `${ process.env[`REACT_APP_URL_${env}`]}/${env}/createProfile`

function* submit_questionnaire() {
  while(true) {
    try {
        const userInput = yield take('SUBMIT_QUESTIONNAIRE_SAGA');

        const response = yield call(
            axios.post,
            endpoint,
            { 
              headers: {
                'Access-Control-Allow-Origin': '*'
              },
              payload: userInput.payload
            }
        );
     
        console.log('userInput.payload  :  ',userInput.payload);
        console.log('response.data      :  ',response.data);

        if (response.data.profile_created) {

          const selectedProfile = response.data.insertId;

          const newProfile = {
            id: selectedProfile,
            ...userInput.payload
          }

          const payload = { newProfile: newProfile, selectedProfile: selectedProfile }

          yield put(SUBMIT_QUESTIONNAIRE(payload));
          
        }

        else {
          console.error('error: profile was not created');
        }
   
    }
    catch(err) {
      console.log('err  :  '+err);
    }
    finally {}
  }
}


export default submit_questionnaire;