import { call, take, put }                    from 'redux-saga/effects';
import axios                                  from 'axios';
import { 
     SET_SCENARIOS_ARR,
     SELECT_PROFILE,
     SHOW_ERROR_MODAL
    }                                         from '../actions'

//const endpoint = `${ process.env.REACT_APP_URL }/${ process.env.NODE_ENV }/scenariosState`


const env = process.env.NODE_ENV;
const endpoint = `${ process.env[`REACT_APP_URL_${env}`]}/${env}/scenariosState`

function* selectProfile() {
  while(true) {
    try {
        const userInput = yield take('SELECT_PROFILE_SAGA');
       
        const response = yield call(
            axios.post,
            endpoint,
            { 
              headers: {
                'Access-Control-Allow-Origin': '*'
              },
              payload: { profile_id: userInput.payload.selectedProfile }
            }
        );
        
        /* console.log('endpoint        :  ',endpoint)
        console.log('response.data   :  ',response.data) */
          
        yield put(SET_SCENARIOS_ARR({ situationsArr : response.data }));
        yield put(SELECT_PROFILE({ profileId : userInput.payload.selectedProfile }));
   
    }
    catch(err) {
      console.log('err  :  '+err);
      yield put(SHOW_ERROR_MODAL(true));

    }
    finally {}
  }
}


export default selectProfile;