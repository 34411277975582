import React                   from 'react';
import VolumeSlider            from '../volume';

import PauseRoundedIcon        from '@material-ui/icons/PauseRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextRoundedIcon     from '@material-ui/icons/SkipNextRounded';
import PlayArrowRoundedIcon    from '@material-ui/icons/PlayArrowRounded';

import { makeStyles }          from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
     container: {
        position:        'fixed',
        top:             0,
        left:            0,
        width:           '100vw',
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
        backgroundColor: '#000',
        transition:      'opacity 1s',
        overflow:        'hidden',
        zIndex:          999
      },
     controlls: {
        position:        'fixed',
        display:         'flex',
        justifyContent:  'space-between',
        alignItems:      'center',
        bottom:          '5vh',
        left:            '5vh',
        width:           200,
        padding:         5,
        borderRadius:    '1em',
       // backgroundColor: colors.kehe,
        backgroundColor: 'rgba(0,0,0,0.3)',
        color:           '#FFF',
        zIndex:          9999
     },
     icon: {
         cursor: 'pointer'
     }
}));

type VideoComponentProps = {
    videoSrc:       string;
    playVideo:      boolean;
    pauseVideo:     boolean;
    setPauseVideo:  (val: boolean) => void;
    handleEndVideo: () => void;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
    videoSrc,
    playVideo,
    pauseVideo,
    setPauseVideo,
    handleEndVideo,
}) => {

    const classes = useStyles();

    const [volume, setVolume]   = React.useState(30);
    const [opacity, setOpacity] = React.useState(0);

    React.useEffect(() => {
       if (playVideo) {
          setOpacity(1)
       }
    },[playVideo, setOpacity])

    const Ref = React.useRef<HTMLVideoElement>(null);

    if (Ref && Ref.current && playVideo && !pauseVideo) {
        Ref.current.play();
    }

    const handlePause = () => {
        if (Ref && Ref.current) {
            Ref.current.pause();
            setPauseVideo(true);
        }
    };

    const handlePlay = () => { 
        setPauseVideo(false) 
    };

    const handleReplay = () => {
        if (Ref && Ref.current) {
            Ref.current.currentTime = 0;
        }
    }

    const handleVolume = (val: number) => { 
        setVolume(val);
        if (Ref && Ref.current) {
            Ref.current.volume = val/100;
        }
    };

    const handleSkip = () => {
        if (Ref && Ref.current) {
            Ref.current.currentTime = 0;
            Ref.current.pause();
            setPauseVideo(true);
            setOpacity(0);
            setTimeout(() => { handleEndVideo() },400)
        }
    }

    return(
        <>
            <div 
                className = { classes.container }
                style     = {{
                    height: playVideo ? '100vh' : 0,
                    opacity: opacity
                }}
            >
                <video 
                    onEnded = { () => { setOpacity(0); handleEndVideo(); } }
                    ref     = { Ref }
                    src     = { videoSrc }
                    width   = '100%'
                    height  = '100%'
                    preload = "auto"
                />
                </div>
                <div 
                className = { classes.controlls }
                style     = {{
                    transform: `translate(0px, ${ playVideo ? 0 : 200 }vh)`
                }}
                >
                    <SkipPreviousRoundedIcon               className = {classes.icon } onClick = { handleReplay }/>
                    { !pauseVideo && <PauseRoundedIcon     className = {classes.icon } onClick = { handlePause }/> }
                    { pauseVideo  && <PlayArrowRoundedIcon className = {classes.icon } onClick = { handlePlay }/> }
                    <VolumeSlider                          volume    = { volume } handleVolume = { handleVolume } />
                    <SkipNextRoundedIcon                   className = {classes.icon } onClick = { handleSkip }/>
                </div>
        </>
    )
}

export default VideoComponent;
