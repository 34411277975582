import React              from 'react';
import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';
import { makeStyles }     from '@material-ui/core/styles';
import colors             from '../../utils/colors';

const arr = [
    `משתמשים יקרים`,
    `הלומדה הינה בשלבי פיתוח.`,
    `כרגע ישנם שלושה סגנונות אנימציה.`,
    `לאחר השימוש בלומדה יופיע שאלון חווית משתמש.`,
    `בידכם האפשרות להשפיע על נראות וחווית השימוש.`,
    `לשם כך, נשמח אם תוכלו לענות על השאלות ולדרג את העדפתכם לסגנון האנימציה והדמויות.`,
    `כמו כן נשמח לקבל הערות בכל נושאי האפליקציה.`
]

const arr2 = [
  `סיטואציה זו עם דמויות שונות ואנימציה שונה.`,
  `ישנן כרגע רק שתי תגובות ותוצאות,`,
  `למרות זאת היא צורפה על מנת שתוכלו להתייחס בהמשך לשאלון חווית המשתמש.`,
];

const useStyles = makeStyles(theme => ({
 
    text_container: {
       position:        'fixed',
       top:             0,
       height:          '75vh',
       minHeight:       300,
       width:           '100vw',
       display:         'flex',
       justifyContent:  'center',
       alignItems:      'center',
       flexFlow:        'column',
       backgroundColor: colors.halavi,
       transition:      'all 1.5s',
    },
    text: {
      width:          '100%',
      height:         '80%',
      textAlign:      'center',  
      display:        'flex',
      alignItems:     'space-around',
      justifyContent: 'center',
      flexFlow:       'column',
      color:          colors.darkGrey,
      fontWeight:     900,
      padding:        10,
    },
    list: {
       marginLeft:    '50%',
      transform:      'translate(-50%, 0%)', 
      border:         `1.5px solid ${ colors.darkGrey }`,//'#FFF'}`,
      borderRadius:   '1em',
      textAlign:      'right',
      color:          colors.darkGrey,// '#FFF',
      textShadow:     '0 0 7px rgba(250, 250, 250, 1)',
      fontSize:       25,
      fontWeight:     900,
      padding:        15
    },
    p: {
       margin: 10
    },
    button: {
        width:          200,
        position:       'fixed',
        left:           '50%',
        transform:      `translate(-50%, 0%)`,
        display:        'flex',
        flexFlow:       'column',
        justifyContent: 'center',
        alignItems:     'center',
        border:         `1px solid #FFF`,
        borderRadius:   '0.5em',
        cursor:         'pointer',
        transition:     'width 0.4s, transform 2.2s',
        backgroundColor:colors.kehe,
        '&:hover': {
         width: 260
        }
       },
      icon_container: {
        width:          60,
        height:         60,
        display:        'flex',
        flexFlow:       'column',
        justifyContent: 'center',
        alignItems:     'center',
        borderRadius:   '50%',
        border:         `1px solid #FFF`,
        backgroundColor: '#FFF'
      },
     icon: {
       fontSize: 40,
       color:    colors.grey,
     },
}));


type TextCompProps = {
  size:                 number[];
  modalText:            number;
  showModal:            boolean;
  showInfo:             boolean;
  modalPosition:        boolean;
  setModalPosition:     (val: boolean) => void;
  handleModalClick:     (val: string) => void;

}

const TextComp: React.FC<TextCompProps> = ({ 
  size, 
  showModal,
  showInfo,
  modalText, 
  modalPosition,
  handleModalClick,
  setModalPosition
}) => {

  const classes = useStyles();

  const MyText = React.useMemo(() => {
     return modalText ? arr2 : arr
  },[modalText])

  React.useEffect(() => {
      if (showInfo || !showModal) {
        return
      }
      setTimeout(() => {
        setModalPosition(true)
      },10)
  },[setModalPosition, showInfo, showModal])


  const onClick = () => {
    handleModalClick('clean_room');
  }
  
  return (
      <>
        <div 
           className = { classes.text_container }
           style     = {{
             transform: `translate(0px, ${ modalPosition ? 0 : 150 }vh)`,
           }}
        >
            <div 
               className = { classes.text }
               style     = {{
               }}
            >
                {
                  MyText.map((val, ind) => {
                      return(
                        <p 
                           key       = {`p__${ind}`}
                           dir       = 'rtl' 
                           className = { classes.p } 
                           style     = {{
                            fontSize: size[0] > 700 && size[1] > 650  ? 25 : 17,
                           }}
                        >
                            { val }
                        </p>
                      )
                  })
                }
            </div>
        </div>
        <div
                    className = { classes.button }
                    onClick   = { onClick }
                    style     = {{
                        height:    size[0] > 600 && size[1] > 700 ? 100 : 75,
                        transform: `translate(-50%, ${ modalPosition ? 0 : 100 }vh)`,
                        bottom:    size[1] > 800 ? 90 : '5vh',
                    }}
                >
                    <div className = { classes.icon_container }>
                        <ForwardRoundedIcon className = { classes.icon }/>
                    </div>
        </div>
    </>
  )
}

export default TextComp

