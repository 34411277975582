import React          from 'react';

import Page1          from './Page1';
import Hidden         from './Hidden';
import CompLoader     from '../../components/comp_loader';
import background     from '../../graphic_components/main_bgrnd.png';
import colors         from '../../utils/colors';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position:           'absolute',
    top:                0,
    left:               0,
    minWidth:           '100vw',
    minHeight:          '100vh',
    maxWidth:           '100vw',
    maxHeight:          '100vh',
    display:            'flex',
    alignItems:         'center',
    justifyContent:     'center',
    backgroundPosition: 'center',
    backgroundRepeat:   'no-repeat',
    backgroundSize:     'cover',
    overflow:           'hidden'
  },
  filter: {
    width:           '100vw',
    height:          '100vh',
    backgroundColor: colors.halavi,//'rgba(249, 139, 209, 0.4)',//colors.halavi,
//    opacity: 0.3,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
   // boxShadow:       `5px 5px 5px 10px ${ colors.moka }`
  },
}));


type HomeProps = {
  size:         number[];
  lang:         string;
  dir:          string;
  signup:       boolean;
  showLoader:   boolean;
  setPage:      (val: string) => void;
  setLoadVideo: (val: boolean) => void;
}

const Home: React.FC<HomeProps> = ({ 
  size, 
  lang,
  dir,
  signup, 
  showLoader,
  setPage, 
  setLoadVideo 
}) => {

  const classes = useStyles();

  const [hiddenLoaded, setHiddenLoaded] = React.useState(false)

  return (
   <>
      <div 
          className = { classes.root } 
          style     = {{
            backgroundImage: `url(${ background })`
          }}
      >
        <div className = { classes.filter }>
              {
                (showLoader || !hiddenLoaded)
                &&
                <CompLoader variant = { '' } lang = { lang }/> 
              }
              {
                (!showLoader && hiddenLoaded)
                &&
                <Page1 
                  size         = { size } 
                  lang         = { lang } 
                  dir          = { dir }
                  signup       = { signup } 
                  setPage      = { setPage } 
                  setLoadVideo = { setLoadVideo }
                />
              }
        </div>
      </div>
      <Hidden setHiddenLoaded = { setHiddenLoaded }/>
   </>
  )
}


export default Home

