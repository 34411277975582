import React              from 'react';
import Image              from './Image';
import ScenariosBig       from './ScenariosBig';
import ScenariosSmall     from './ScenariosSmall';
import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';
import { stationArr }     from '../../utils';
import { actionType }     from '../../types';
import { makeStyles }     from '@material-ui/core/styles';

const speed = 1000;

const useStyles = makeStyles({
  root: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',  
  },
  arrow: {
   color: '#FFF'
} 
});

type DiagramUnitProps = {
  size:        number[];
  action:      number;
  iconSize:    number;
  lang:        string;
  dir:         string;
  variant:     string;
  station:     string;
  sitImg:      string;
  minimizeMap: boolean;
  actionArr:   actionType[];
}

const DiagramUnit: React.FC<DiagramUnitProps> = ({
  size, 
  action,
  iconSize,
  lang,
  dir,
  variant,
  station,
  sitImg,
  minimizeMap,
  actionArr
}) => {

  const classes = useStyles();

  const [showMinimizedScenario, setShowMinimizedScenario] = React.useState(minimizeMap)
  const [closeBigScenario, setCloseBigScenario]           = React.useState(false)

  React.useEffect(() => {

    if (minimizeMap && !showMinimizedScenario) {
      setCloseBigScenario(true)
      setTimeout(() => {
        setShowMinimizedScenario(true)
      },speed)
    }
    else {
      if (!minimizeMap) {
        setShowMinimizedScenario(false)
      }
      setCloseBigScenario(false)
    }

  },[minimizeMap, showMinimizedScenario, setShowMinimizedScenario, setCloseBigScenario])



  return (
    <div className = { classes.root }>
        {
            variant === 'situation'
            &&
            <Image
              sitImg   = { sitImg }
              lang     = { lang }
              dir      = { dir }
              variant  = { variant }
              station  = { station }
              iconSize = { iconSize }
            />
        }
        {
            variant === 'heart1'
            &&
            <Image
              sitImg   = { sitImg }
              lang     = { lang }
              dir      = { dir }
              variant  = { variant }
              station  = { station }
              iconSize = { iconSize }
            />
        }
        {
            variant === 'scenarios' && showMinimizedScenario
            &&
            <>
              <ScenariosSmall
                  action    = { action }
                  lang      = { lang }
                  dir       = { dir }
                  station   = { station }
                  actionArr = { actionArr }
                  iconSize  = { iconSize }
                  speed     = { speed }
                  current
                  withTitle
              />
              <ForwardRoundedIcon 
                 className = { classes.arrow }
                 style     = {{
                   opacity: stationArr.indexOf(station) >= 5 ? 0.3 : 1
                 }}
              />
            </>
        }
        {
            variant === 'scenarios' && !showMinimizedScenario
            &&
            <>
              <ScenariosBig
                  size                     = { size }
                  action                   = { action }
                  speed                    = { speed }
                  lang                     = { lang }
                  dir                      = { dir }
                  station                  = { station }
                  actionArr                = { actionArr }
                  iconSize                 = { iconSize }
                  closeBigScenario         = { closeBigScenario }
                  setCloseBigScenario      = { setCloseBigScenario }
                  setShowMinimizedScenario = { setShowMinimizedScenario }
              />
              <ForwardRoundedIcon 
                 className = { classes.arrow }
                 style     = {{
                   opacity: stationArr.indexOf(station) >= 5 ? 0.3 : 1
                 }}
              />
            </>
        }
        {
            variant === 'heart2'
            &&
            <Image
              sitImg   = { sitImg }
              lang     = { lang }
              dir      = { dir }
              variant  = { variant }
              station  = { station }
              iconSize = { iconSize }
            />
        }
        {
            variant === 'lock'
            &&
            <Image
              sitImg   = { sitImg }
              lang     = { lang }
              dir      = { dir }
              variant  = { variant }
              station  = { station }
              iconSize = { iconSize }
            />
        }
    </div>
  );
}


export default DiagramUnit