import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    image_container: {
       display:        'flex',
       alignItems:     'center',
       justifyContent: 'center',
       flexFlow:       'column',
       zIndex:         4,
       cursor:         'grab'
    },
    image: {
      pointerEvents: "none"
    },
    text: {
      color:     '#FFF',
      fontWeight:'bold'
    }
}));

type GhostProps = {
  src:      string;
  position: number[];
  imoj:     number;
  received: boolean;
  ghostName:string;
}

const Ghost: React.FC<GhostProps> = ({ src, position, imoj, received, ghostName }) => {

  const classes = useStyles();

  return (
    <div 
      className = { classes.image_container }
      style     = {{
        position: received ? 'static' : 'fixed',
        width:    imoj,
        height:   imoj,
        left:     position[0],
        top:      position[1],
      }}
    >
      <img className = { classes.image } src = { src } alt = 'alt' height = '100%'/>
      <div 
         dir       = 'rtl'
         className = { classes.text }
      >
        { ghostName }
      </div>
    </div>
   )
}

export default Ghost


/*

onContextMenu = {e => { e.preventDefault(); e.stopPropagation(); } }
      onMouseDown  = { e => { e.preventDefault(); e.stopPropagation();} }
      onTouchStart = { e => { e.preventDefault(); e.stopPropagation(); } }
*/

