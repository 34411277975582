import React          from 'react';
import crown          from '../../graphic_components/crown_golden.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    target: {
        zIndex:         1,
        display:        'flex',
        flexFlow:       'column',
        alignItems:     'center',
    },
    inner: {
        backgroundPosition: 'center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:     'contain',
    }
}));

type TargetProps = {
    announceSeccess: boolean;
    target_size:     number;
    crown_h:         number;
    crown_w:         number;
}

const Target: React.FC<TargetProps> = ({ announceSeccess, target_size, crown_h, crown_w }) => {

  const classes = useStyles();

  return (
    <div 
      className = { classes.target }
      style = {{ 
        width:  target_size,
        height: target_size
      }}
    >
      { 
        announceSeccess
        && 
        <div 
          className = { classes.inner } 
          style     = {{
            backgroundImage: `url(${ crown })`,
            height:          crown_h,
            width:           crown_w
          }}
        />
      }
    </div>
  )
}
export default Target
