import React          from 'react';
import { makeStyles } from '@material-ui/core/styles';

var clsx = require('classnames')

const useStyles = makeStyles(theme => ({
    target: {
      position:  'fixed',
      width:     '50vw',
      top:       '50%',
      left:      '50%',
      transition:'opacity 0.5s',
    },
    normal: {
      height:    '90vh',
      transform: `translate(-50%, -50%)`,
    },
    quasimodo: {
      height:    '80vh',
      transform: `translate(-50%, -55%)`,
    },
    lion_image: {
       position:           'absolute',
       backgroundRepeat:   'no-repeat',
       backgroundPosition: 'center',
       backgroundSize:     'contain',
       height:             '100%',
       width:              '100%',
    }
}));

type LionImageProps = {
  largeFigure: string;
  name:        string;
}

const LionImage: React.FC<LionImageProps> = ({ largeFigure, name }) => {

  const classes = useStyles();

  return (
    <div className = { name !== 'clean_room' ? clsx(classes.target, classes.normal) : clsx(classes.target, classes.quasimodo) }>
      <div className = { classes.lion_image } style = {{ backgroundImage: `url(${ largeFigure })` }}/>
    </div>
  )
}

export default LionImage



