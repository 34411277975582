import React                    from 'react';
import colors                   from '../../utils/colors';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import { makeStyles }           from '@material-ui/core/styles';
import { situationsObjType }    from '../../types';

import { words }                from '../../utils/dictionary';

var clsx = require('classnames');

const useStyles = makeStyles(theme => ({
  big_grid: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexWrap:       'wrap',
    width:          '90%',
    transition:     'transform 1s'
  },
  small_grid: {
    marginTop:      25,
    overflowY:      "scroll",
    overflowX:      "hidden",
    border:         `1px solid #FFF`,
    width:          '80%',
    padding:        30,
    transition:     'transform 1s',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(250, 250, 250, 0.7)',
      outline:         '1px solid #FFF',
      borderRadius:    10
    }
  },
  situation_grid_unit: {
    display:        'flex',
    flexFlow:       'column',
    alignItems:     'center',
    justifyContent: 'center',
    padding:         30,
    borderRadius:   '1em',
    border:         '1px solid #FFF',
    backgroundColor:colors.kehe,
    margin:         10
  },
  situation_image: {
    backgroundPosition: 'center',
    backgroundSize:     'cover',
    backgroundRepeat:   'no-repeat',
    borderRadius:       '1em',
    cursor:             'pointer',
    boxShadow:          '0 5px 5px rgba(0,0,0,0.4)',
    transition:         'transform 1s, padding 0.4s',
    '&:hover': {
      padding:  20,
    }
  },
  big: {
    height: 200,
    width:  200
  },
  small: {
    height: 200,
    width:  200
  },
  title_container: {
    marginTop:      15,
    height:         55,
    width:          '90%',
    maxWidth:        300,
    display:        'flex',
    flexFlow:       'column',
    alignItems:     'center',
    justifyContent: 'center',
    color:          '#FFF',
    border:         '1px solid #FFF',
    borderRadius:   '0.5em',
    backgroundColor: colors.pink,
    fontWeight:     'bold',
    cursor:         'pointer',
    transition:     `transform 1.5s, padding 0.4s`,
  },
  intro_video_container: {
    marginTop:      6,
    marginBottom:   15,
    height:         40,
    maxHeight:      40,
    width:          '100%',
    display:        'flex',
    alignItems:     'flex-end',
    justifyContent: 'flex-end',
    borderRadius:   '0.5em',
    fontWeight:     'bold',
    transition:     `transform 1.5s, padding 0.4s`,
  },
  intro_video: {
    height:         40,
    maxHeight:      40,
    width:          120,
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'space-around',
    border:         '1px solid #FFF',
    borderRadius:   '0.5em',
    backgroundColor: colors.blue_,
    fontWeight:     'bold',
    cursor:         'pointer',
    transition:     'all 0.4s',
    '&:hover': {
      width: 170
    }
  },
  Icon: {
    height: '100%',
    color:  '#FFF'
  },
  tip: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    height:         '100%',
    color:          '#FFF'
  }
}));


type GridProps = {
  size:                 number[];
  lang:                 string;
  dir:                  string;
  gridPos:              boolean;
  dontShowGrid:         boolean;
  situations:           situationsObjType;
  setGridPos:           (val: boolean) => void;
  selectSituation:      (val: string) => void;
  handlePlayIntroVideo: (val: string) => void;
}

const Grid: React.FC<GridProps> = ({
      size,
      lang,
      dir,
      situations,
      gridPos,
      dontShowGrid,
      setGridPos,
      handlePlayIntroVideo,
      selectSituation
  }) => {


  const classes = useStyles();

  const situationsArr = React.useMemo(() => {
      return lang === 'heb' ? Object.keys(situations) : Object.keys(situations).slice(0,1);
  },[situations, lang])


  React.useEffect(() => {
    if (dontShowGrid) {
      return
    }
    setTimeout(() => { setGridPos(true) },10)
  },[setGridPos, dontShowGrid])



  return (
    <div 
      className = { size[0] > 700 && size[1] > 800 ? classes.big_grid : classes.small_grid }
      style     = {{
        transform: `translate(0px, ${ gridPos ? 0 : 150 }vh)`
      }}
    >
          {
              situationsArr.map((val, ind) => {

                return(
                  <div
                      key       = { val }
                      className = { classes.situation_grid_unit }
                  >
                     {
                         lang === 'heb'
                         &&
                         <div className = { classes.intro_video_container }>
                           {
                               situations[val].introVideo.length > 0
                               &&
                               <div 
                                   dir       = 'rtl'
                                   className = { classes.intro_video }
                                   onClick   = { () => { handlePlayIntroVideo(situations[val].introVideo) }}
                                   style     = {{
                                     transform:  `translate(0px, ${ gridPos ? 0 : 100 }vh)`,
                                     opacity:    situations[val].completed ? 0.5 : 1
                                   }}
                               >
                                   <OndemandVideoRoundedIcon className = { classes.Icon }/>
                                   <div dir = 'rtl' className = { classes.tip } >
                                     { `טיפ להורה` }
                                   </div>
                               </div>
                            }
                         </div>
                     }
                     
                      <div
                         className = { size[0] > 700 ? clsx(classes.situation_image, classes.big) : clsx(classes.situation_image, classes.small) }
                         onClick   = { () => { selectSituation(situations[val].name) }}
                         style     = {{
                            backgroundImage: `url(${ situations[val].image })`,
                            transform:       `translate(0px, ${ gridPos ? 0 : 100 }vh)`,
                            opacity:         situations[val].completed ? 0.5 : 1
                         }}
                      />
                       <div 
                          dir       = { dir }
                          className = { classes.title_container }
                          onClick   = { () => { selectSituation(situations[val].name) }}
                          style     = {{
                            transform:  `translate(0px, ${ gridPos ? 0 : 100 }vh)`,
                            opacity:    situations[val].completed ? 0.5 : 1
                          }}
                       >
                          { `${ words[situations[val].name][lang] }` }
                       </div>
                  </div>
                )
              })
          }
    </div>
  )
}

export default Grid





/**
 * 
 * 
 * React.useEffect(() => {

    if (phaseOutAnimationDone) {

        if (showPreloadError) {
          console.log('PreloadError')
          setWaitingForPreload(false)
          setPhaseOutAnimationDone(false)
          return
        } 
    
        if (readyToPlayVideo) {
          setWaitingForPreload(false)
          setPhaseOutAnimationDone(false)
          handlePlay()
        }
    }
  
  },[
    name,
    phaseOutAnimationDone, 
    readyToPlayVideo, 
    waitingForPreload,
    showPreloadError,
    setWaitingForPreload,
    handlePlay, 
    setPhaseOutAnimationDone
  ])


 */