import { createStore, applyMiddleware } from "redux";
import { reducer }                      from "../reducer";
import createSagaMiddleware             from 'redux-saga';
import indexSaga                        from '../sagas';

export type AppState = ReturnType<typeof reducer>;

const sagaMiddleware = createSagaMiddleware();

export const configureStore = () => {
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(indexSaga);
  return store;
};
