import React                      from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider                     from '@material-ui/core/Slider';
import VolumeDownRoundedIcon      from '@material-ui/icons/VolumeDownRounded';
import VolumeUpRoundedIcon        from '@material-ui/icons/VolumeUpRounded';
import VolumeMuteRoundedIcon      from '@material-ui/icons/VolumeMuteRounded';

const useStyles = makeStyles({
  root: {
    width:          120,
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    color:          '#FFF',
  },
  icon: {
    width:          40,
    display:        'flex',
    justifyContent: 'flex-start'
  }
});

const MySlider = withStyles({
    root: {
      color:  '#FFF',
      cursor: 'pointer'
    },
    thumb: {
        height:     10,
        width:      10,
        transition: 'height 0.2s, width 0.2s, margin 0.2s',
        '&:hover': {
            marginTop:  -8,
            marginLeft: -8,
            height:     16,
            width:      16,
        },
      },
  })(Slider);

type VolumeSliderProps = {
    volume:       number;
    handleVolume: (val: number) => void;
}

const VolumeSlider: React.FC<VolumeSliderProps> = ({ volume, handleVolume }) => {

  const classes = useStyles();

  const handleChange = (event: any, newValue: number | number[]) => {
    handleVolume(newValue as number);
  };
  
  const Icon = volume === 0 ? <VolumeMuteRoundedIcon/> : ( volume >= 50 ? <VolumeUpRoundedIcon/> : <VolumeDownRoundedIcon/> );

  return (
    <div className = { classes.root }>
          <div className = { classes.icon }>{Icon}</div>
          <MySlider value = { volume } onChange = { handleChange } aria-labelledby = "continuous-slider" />
    </div>
  );
}

export default VolumeSlider