

const bigVideoHeb   = `https://7pm-videos.s3.us-east-2.amazonaws.com`;
const smallVideoHeb = `https://7pm-videos-compressed.s3.us-east-2.amazonaws.com`;

const bigVideoEng   = `https://7pm-videos-eng.s3.us-east-2.amazonaws.com`;
const smallVideoEng = `https://7pm-videos-compressed-eng.s3.us-east-2.amazonaws.com`;

const bigImage      = `https://7pm-images.s3.us-east-2.amazonaws.com`;
const smallImage    = `https://7pm-images-compressed.s3.us-east-2.amazonaws.com`;

const videoBucket    = (window.screen.width < 750 || window.screen.height < 550) ? smallVideoHeb : bigVideoHeb;
const videoBucketEng = (window.screen.width < 750 || window.screen.height < 550) ? smallVideoEng : bigVideoEng;
const imageBucket    = (window.screen.width < 750 || window.screen.height < 550) ? smallImage : bigImage;



//videos    -      -     -      -     -      -     -      -     -      -     -      -  


export const situationUrls = [
    { situationName: 'shaming',    variant: 'situation', index: -1, url: `${ videoBucket }/shaming/shaming_situation.mp4`},
    { situationName: 'clean_room', variant: 'situation', index: -1, url: `${ videoBucket }/clean_room/situation_cleanup_room.mp4`},
    { situationName: 'ball_thief', variant: 'situation', index: -1, url: `${ videoBucket }/ball_thief/ball_thief_situation.mp4`},
    { situationName: 'cookie_jar', variant: 'situation', index: -1, url: `${ videoBucket }/cookie_jar/cookie_jar_situation.mp4`},
]

export const situationUrlsEng = [
    { situationName: 'shaming', variant: 'situation', index: -1, url: `${ videoBucketEng }/shaming/shaming_situation.mp4`},
    { situationName: 'shaming', variant: 'gingel',    index: -1, url: `${ videoBucketEng }/shaming/gingle.mp4`},
]


export const scenarioUrls: { [key: string]: { situationName: string; variant: string; index: number; url: string }[] } = {
    shaming: [
       { situationName: 'shaming', variant: 'gingel',  index: -1, url: `${ videoBucket }/shaming/gingle.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 0,  url: `${ videoBucket }/shaming/accept_criticism.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 1,  url: `${ videoBucket }/shaming/be_a_snob.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 2,  url: `${ videoBucket }/shaming/cry.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 3,  url: `${ videoBucket }/shaming/fight.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 4,  url: `${ videoBucket }/shaming/shame_friends.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 0,  url: `${ videoBucket }/shaming/accept_criticism_result.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 1,  url: `${ videoBucket }/shaming/be_a_snob_result.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 2,  url: `${ videoBucket }/shaming/cry_result.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 3,  url: `${ videoBucket }/shaming/fight_result.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 4,  url: `${ videoBucket }/shaming/shame_friends_result.mp4`},
       { situationName: 'shaming', variant: 'magic',   index: -1, url: `${ videoBucket }/shaming/shaming_magic.mp4`},
    ],
    clean_room: [
        { situationName: 'clean_room', variant: 'gingel', index: -1, url: `${ videoBucket }/shaming/gingle.mp4`},
        { situationName: 'clean_room', variant: 'action', index: 0,  url: `${ videoBucket }/clean_room/clean_up_room_with_mother.mp4`},
        { situationName: 'clean_room', variant: 'action', index: 1,  url: `${ videoBucket }/clean_room/read_story_with_mother.mp4`},
        { situationName: 'clean_room', variant: 'result', index: 0,  url: `${ videoBucket }/clean_room/destroy_room.mp4`},
        { situationName: 'clean_room', variant: 'result', index: 1,  url: `${ videoBucket }/clean_room/wake_up_in_fear.mp4`},
        { situationName: 'clean_room', variant: 'magic',  index: -1, url: `${ videoBucket }/clean_room/clean_up_room_with_magic.mp4`}
    ],
    ball_thief: [
        { situationName: 'ball_thief', variant: 'gingel', index: -1, url: `${ videoBucket }/ball_thief/gingle.mp4`},
        { situationName: 'ball_thief', variant: 'action', index: 0,  url: `${ videoBucket }/ball_thief/aggresivly_demand_ball.mp4`},
        { situationName: 'ball_thief', variant: 'action', index: 1,  url: `${ videoBucket }/ball_thief/aggresivly_demand_ball_result.mp4`},
        { situationName: 'ball_thief', variant: 'action', index: 2,  url: `${ videoBucket }/ball_thief/politely_demand_ball.mp4`},
        { situationName: 'ball_thief', variant: 'action', index: 3,  url: `${ videoBucket }/ball_thief/politely_demand_ball_result.mp4`},
        { situationName: 'ball_thief', variant: 'action', index: 4,  url: `${ videoBucket }/ball_thief/attack_ball_thief.mp4`},
        { situationName: 'ball_thief', variant: 'result', index: 0,  url: `${ videoBucket }/ball_thief/attack_ball_thief_result.mp4`},
        { situationName: 'ball_thief', variant: 'result', index: 1,  url: `${ videoBucket }/ball_thief/play_together.mp4`},
        { situationName: 'ball_thief', variant: 'result', index: 2,  url: `${ videoBucket }/ball_thief/play_together_result.mp4`},
        { situationName: 'ball_thief', variant: 'result', index: 3,  url: `${ videoBucket }/ball_thief/surrender.mp4`},
        { situationName: 'ball_thief', variant: 'result', index: 4,  url: `${ videoBucket }/ball_thief/surrender_result.mp4`},
        { situationName: 'ball_thief', variant: 'magic',  index: -1, url: `${ videoBucket }/ball_thief/ball_thief_magic.mp4`}
    ],
    cookie_jar: [
        { situationName: 'cookie_jar', variant: 'gingel', index: -1, url: `${ videoBucket }/cookie_jar/gingle.mp4`},
        { situationName: 'cookie_jar', variant: 'action', index: 0,  url: `${ videoBucket }/cookie_jar/tell_the_truth.mp4`},
        { situationName: 'cookie_jar', variant: 'action', index: 1,  url: `${ videoBucket }/cookie_jar/tell_the_truth_result.mp4`},
        { situationName: 'cookie_jar', variant: 'action', index: 2,  url: `${ videoBucket }/cookie_jar/admit_the_truth.mp4`},
        { situationName: 'cookie_jar', variant: 'action', index: 3,  url: `${ videoBucket }/cookie_jar/admit_the_truth_result.mp4`},
        { situationName: 'cookie_jar', variant: 'action', index: 4,  url: `${ videoBucket }/cookie_jar/dont_admit_the_truth.mp4`},
        { situationName: 'cookie_jar', variant: 'result', index: 0,  url: `${ videoBucket }/cookie_jar/dont_admit_the_truth_result.mp4`},
        { situationName: 'cookie_jar', variant: 'result', index: 1,  url: `${ videoBucket }/cookie_jar/lie.mp4`},
        { situationName: 'cookie_jar', variant: 'result', index: 2,  url: `${ videoBucket }/cookie_jar/lie_result.mp4`},
        { situationName: 'cookie_jar', variant: 'result', index: 3,  url: `${ videoBucket }/cookie_jar/hide_evidence.mp4`},
        { situationName: 'cookie_jar', variant: 'result', index: 4,  url: `${ videoBucket }/cookie_jar/hide_evidence_result.mp4`},
        { situationName: 'cookie_jar', variant: 'magic',  index: -1, url: `${ videoBucket }/cookie_jar/cookie_jar_magic.mp4`},
    ]
}


export const scenarioUrlsEng: { [key: string]: { situationName: string; variant: string; index: number; url: string }[] } = {
    shaming: [
       { situationName: 'shaming', variant: 'actions', index: 0,  url: `${ videoBucketEng }/shaming/accept_criticism.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 1,  url: `${ videoBucketEng }/shaming/be_a_snob.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 2,  url: `${ videoBucketEng }/shaming/cry.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 3,  url: `${ videoBucketEng }/shaming/fight.mp4`},
       { situationName: 'shaming', variant: 'actions', index: 4,  url: `${ videoBucketEng }/shaming/shame_friends.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 0,  url: `${ videoBucketEng }/shaming/accept_criticism_result.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 1,  url: `${ videoBucketEng }/shaming/be_a_snob_result.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 2,  url: `${ videoBucketEng }/shaming/cry_result.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 3,  url: `${ videoBucketEng }/shaming/fight_result.mp4`},
       { situationName: 'shaming', variant: 'result',  index: 4,  url: `${ videoBucketEng }/shaming/shame_friends_result.mp4`},
       { situationName: 'shaming', variant: 'magic',   index: -1, url: `${ videoBucketEng }/shaming/shaming_magic.mp4`},
    ]
}




//images     -      -     -      -     -      -     -      -     -      -     -      -     





export const situationImages = [
    { situationName: 'shaming',    url:`${ imageBucket }/shaming/shaming_situation_image.png` },
    { situationName: 'shaming',    url:`${ imageBucket }/shaming/shaming_situation_background.png` },
    { situationName: 'clean_room', url:`${ imageBucket }/clean_room/situationCleanRoom.png` },
    { situationName: 'clean_room', url:`${ imageBucket }/clean_room/aaa.png` },
    { situationName: 'ball_thief', url:`${ imageBucket }/ball_thief/ball_thief_situation.png` },
    { situationName: 'cookie_jar', url:`${ imageBucket }/cookie_jar/cookie_jar_image.png` },
    { situationName: 'cookie_jar', url:`${ imageBucket }/cookie_jar/cookie_jar_situation.png` },
]


export const scenarioImages: { [key: string]: { situationName: string; url: string }[] } = {
    shaming: [
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/lioness_image.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/contentment.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/insult.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/pleasure.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/fear.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/happiness.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/anxiety.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/disapointment.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/sadness.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/nutral.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/anger.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/accept_criticism_action.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/be_a_snob_action.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/cry_action.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/fight_action.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/shame_friends_action.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/accept_criticism_background.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/be_a_snob_background.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/cry_background.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/fight_background.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/shame_friends_background.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/accept_criticism_res_bgrnd.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/be_a_snob_res_bgrnd.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/cry_res_bgrnd.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/fight_res_bgrnd.png' },
        { situationName: 'shaming', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/shame_friends_res_bgrnd.png' },
    ],
    clean_room: [
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/lion_image.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/anger.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/disapointment.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/hapiness.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/edginess.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/fatigue.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/pleasure.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/sadness.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/fear.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/normal.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/actions/clean_room_with_mother_action.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/actions/destroy_room_action.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/clean_room_with_mother_background.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/destroy_room_background.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/clean_room_with_mother_result.png' },
        { situationName: 'clean_room', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/wake_up_in_fear_background.png' },
    ],
    ball_thief: [
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/boy_image.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/anger.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/confusion.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/disapointment.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/fear.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/happiness.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/joy.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/regret.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/sadness.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/shame.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/friendship.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/aggresivly_demand_action.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/politely_demand_action.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/attack_action.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/play_together_action.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/surrender_action.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/aggresivly_demand_background.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/politely_demand_background.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/attack_background.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/play_together_background.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/surrender_background.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/aggresivly_demand_res_bgrnd.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/politely_demand_res_bgrnd.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/attack_res_bgrnd.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/play_together_res_bgrnd.png' },
        { situationName: 'ball_thief', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/surrender_res_bgrnd.png' },
    ],
    cookie_jar: [
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/girl_image.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/contentment.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/shame.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/relief.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/pride.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/fear.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/happiness.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/regret.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/sadness.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/pain.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/tell_the_truth_action.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/admit_the_truth_action.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/dont_admit_the_truth_action.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/lie_action.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/hide_evidence_action.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/tell_the_truth_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/admit_the_truth_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/dont_admit_the_truth_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/lie_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/hide_evidence_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/tell_the_truth_result_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/admit_the_truth_result_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/dont_admit_the_truth_result_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/lie_result_background.png' },
        { situationName: 'cookie_jar', url: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/hide_evidence_result_background.png' },
    ]
}


//  audio  -    -     -      -     -      -      - 


export const hebAudio = {
    story:             'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/story.wav',
    doneForNow:        'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/done_for_now.wav',
    additionalOptions: 'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/additional_options.wav'
}

export const engAudio = {
    story:             'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/story.mp3',
    doneForNow:        'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/done_for_now.mp3',
    additionalOptions: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/additional_options.mp3'
}

