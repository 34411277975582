import React          from 'react';

import { actionType } from '../../types';

import colors         from '../../utils/colors';
import red            from '../../graphic_components/red.png';
import green          from '../../graphic_components/green.png';
import VideoIconRed   from '../../graphic_components/vidIconPng.png'
import VideoIconGreen from '../../graphic_components/vidIconGreen.png'

import { makeStyles } from '@material-ui/core/styles';

var clsx = require('classnames');

type refType = React.RefObject<HTMLDivElement> | null | undefined

const useStyles = makeStyles(theme => ({
  fractions: {
    position:        'absolute',
    transformOrigin: '100% 100%',
    overflow:        'hidden',
    transition:      'all 0.5s',
  },
  image_container: {
    cursor:        'pointer',
    transition:    'all 0.5s',
  },
  image: {
    backgroundRepeat:   'no-repeat',
    backgroundPosition: 'center',
    backgroundSize:     'cover',
    height:             '100%',
    width:              '100%',
    display:            'flex',
    alignItems:         'center',
    justifyContent:     'center',
    textAlign:          'center',
    pointerEvents:      'none'
  },
  action_name: {
    width:           '100%',
    height:          '100%',
    backgroundColor: colors.blue_,
    color:           '#FFF',
    fontWeight:      'bold',
    textAlign:       'center',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    pointerEvents:   'none'
  },
  red: {
    position: 'absolute',
    height:   '1000%',
    width:    '1000%',
    transform: 'translate(-50%, -50%)'
  },
  spin: {
   animation: 'spin 25s linear infinite'
  },
  counter_spin: {
   animation: 'counterspin 25s linear infinite',
  }
}));

type FractionsProps = {
  name:           string;
  userVideoSrc:   string;
  lang:           string;
  spin:           number;
  size:           number[];
  circleSize:     number;
  hover:          number;
  redoSituation:  boolean;
  actionArr:      actionType[];
  setToolTipText: (val: string) => void;
  setShowToolTip: (val: boolean) => void;
  setHover:       (val: number) => void;
  ActionClick:    (ind: number) => void;
}

const Fractions: React.FC<FractionsProps> = ({
  name,
  lang,
  userVideoSrc,
  spin,
  size,
  circleSize,
  actionArr,
  hover,
  redoSituation,
  ActionClick,
  setToolTipText,
  setShowToolTip,
  setHover,

}) => {

  
  const classes = useStyles();

  const [mySkew, containerSize, margin] = React.useMemo(() => {
    return [
      name === 'clean_room' ? -30 : 30,
      name === 'clean_room' ? '60%' : '50%',
      name === 'clean_room' ? '-10%' : 0,
    ]
  },[name])
  

  const [vidColor, vidIcon] = React.useMemo(() => {
     return [
               !userVideoSrc.length ? red : green,
               !userVideoSrc.length ? VideoIconRed : VideoIconGreen
            ]
  },[userVideoSrc])

  const [touchEls, setRefs] = React.useState([] as refType[])
/* 
  const touchEnd = (e: any) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    console.log('touchEnd')

    setToolTipText('false')
   } */

   const handleMouseEnter = (e: any, i: number) => {
    e.preventDefault();
     if (!spin) { return }
     setHover(i)
  //  e.stopImmediatePropagation();
     setToolTipText(lang === 'eng' ? actionArr[i].actionEng : actionArr[i].action)
     setShowToolTip(true)
  }
 
  const handleMouseLeave = () => {
    setHover(-1)
    setShowToolTip(false)
  }


  React.useEffect(() => {
    setRefs(refs => actionArr.map((_, i) => refs[i] || React.createRef<HTMLDivElement>()))
  },[actionArr])

  /* React.useEffect(() =>{   
    if (touchEls.length !== 6) { return }

    touchEls.forEach((el, i) => {
      el && el.current && el.current.addEventListener('touchstart', (e) => { touchStart(e, i)}, { passive: false });
     })
    return () => {
      touchEls.forEach((el, i) =>{
        el && el.current && el.current.removeEventListener('touchstart', (e) => { touchStart(e, i)});
        })
    }
  },[touchEls]) */

  

  return (
    <>
        {
          actionArr.map((val, my_ind) => { 

            const enlargeImage    = hover === my_ind && !val.viewed;
            const bigImage        = name === 'clean_room' ? (val.custom ? '100%' : '170%') : '135%';
            const smallImage      = name === 'clean_room' ? (val.custom ? '90%' : '150%')  : '125%';   

            return(
            <div key = { `fraction_${ my_ind }` }>
                <div 
                  ref           = { touchEls[my_ind] }
                  className     = { classes.fractions } 
                  onClick       = { e => { ActionClick(my_ind) }}
                  onMouseEnter  = { e => { handleMouseEnter(e, my_ind) }}
                  onMouseLeave  = { handleMouseLeave }
                //  onTouchStart  = { e => { handleTouchEnter(e) }}
                //  onTouchEnd    = { handleLeave }
               //   onTouchCancel = { handleLeave } 
                  style         = {{
                    width:           containerSize,
                    height:          containerSize,
                    marginLeft:      margin,
                    marginTop:       margin,
                    transform:       `rotate(${ val.deg + 30 }deg) skew(${ mySkew }deg)`,  //18
                    borderRight:     size[0] > 800 && size[1] > 500 ? '10px solid transparent' : '1px solid transparent',
                    opacity:         val.viewed && !redoSituation ? 0.3 : 1,
                  }}
                >
                  <div  
                    className = { classes.image_container }
                    style     = {{
                      transform:       `skew(${ -mySkew }deg) rotate(${-val.deg - 30}deg)`,
                      width:           enlargeImage ? bigImage : smallImage,
                      height:          enlargeImage ? bigImage : smallImage,
                    }} 
                  >
                          { 
                             val.custom && 
                                <div 
                                  className = { classes.red }
                                  style     = {{ backgroundImage: `url(${ vidColor })`}}
                                />
                          }
                          <div 
                              className = { !spin ? classes.image : clsx(classes.image, classes.counter_spin) }
                              style     = {{
                                backgroundImage: `url(${ val.custom ? vidIcon : val.actionImg })`
                              }}
                          />
                   </div>
                </div>
            </div>
          )})
        }
    </>
  )
}

export default Fractions

