import React              from 'react';
import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';
import { makeStyles }     from '@material-ui/core/styles';

var clsx = require('classnames')

const useStyles = makeStyles({
  root: {
    height:             '100%',
    backgroundRepeat:   'no-repeat',
    backgroundPosition: 'center',
    display:            'flex',
    alignItems:         'center',
    justifyContent:     'center',   
  },
  container: {
    width:          '100%',
    height:         '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column'
  },
  title: {
    width:          '100%',
    height:         '20%',
    color:          '#FFF',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column',
    textAlign:      'center'
  },
  image: {
    backgroundSize:     'cover',
    backgroundRepeat:   'no-repeat',
    backgroundPosition: 'center',
    width:              '80%',
    height:             '80%',
    borderRadius:       '1em',
  },
  arrow: {
    color: '#FFF'
  },
  mark: {
     animation: 'puls 1s infinite'
  }, 
});

type ScenarioUnitProps = {
   iconSize:  number;
   ind:       number;
   withTitle: boolean;
   opacity:   boolean;
   mark:      boolean;
   image:     string;
   text:      string;
   dir:       string;
}

const ScenarioUnit: React.FC<ScenarioUnitProps> = ({ 
    iconSize,
    ind,
    withTitle,
    opacity,
    mark,
    image,
    text,
    dir,
}) => {

  const classes = useStyles();

  return (
    
    <div 
       className = { classes.root }
       style     = {{
         width:   !ind ? iconSize + 30 : iconSize,
         opacity: opacity ? 1 : 0.4
       }}
    >
          <div className = { classes.container }>
              <div 
                 dir       = { dir }
                 className = { classes.title }
              >
                  { withTitle && text }
              </div>
              <div className = { mark ? clsx(classes.image, classes.mark) : classes.image }
                style     = {{
                    backgroundImage: `url(${ image })`,
                }}
              />
        </div>
        {
          ind === 0 && <ForwardRoundedIcon className = { classes.arrow }/>
        }
     </div>
  );
}


export default ScenarioUnit