import React                  from 'react';
import Buttons                from './Buttons';
import VideoComponent         from './VideoComponent';
import CompLoader             from '../../components/comp_loader';
import { situationsObjType }  from '../../types';

import { makeStyles }         from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  black: {
    backgroundColor: '#000',
    position:        'fixed',
    width:           '100vw',
    minHeight:       '100vh',
  },
  container: {
    position:           'fixed',
    width:              '100vw',
    minHeight:          '100vh',
    backgroundPosition: 'center',
    backgroundSize:     'cover',
    backgroundRepeat:   'no-repeat',
    transition:         'all 1s'
  },
  filter: {
    position:        'fixed',
    width:           '100vw',
    minHeight:       '100vh',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    transition:      'all 1s'
  }
}));

type GingelVideoProps = {
  size:              number[];
  loadedVideos:      string[];
  lang:              string;
  dir:               string;
  background:        string;
  gingle:            string;
  selectedSituation: string;
  actionAudio:       string;
  gingelDownloaded:  boolean;
  situations:        situationsObjType;
  setPage:           (val: string) => void;
  setStation:        (val: string) => void;
  setAudioSrc:       (val: string) => void;
  setPlayAudio:      (val: boolean) => void;
  setPauseAudio:     (val: boolean) => void;
  setAudioEnd:       (val: boolean) => void;
}

const GingelVideo: React.FC<GingelVideoProps> = ({
  size,
  loadedVideos,
  lang,
  dir,
  background, 
  gingle,
  selectedSituation,
  gingelDownloaded,
  situations,
  actionAudio,
  setPage,
  setStation,
  setAudioSrc,
  setPlayAudio,
  setPauseAudio,
  setAudioEnd
}) => {

  const classes = useStyles();

  const [showLoader, setShowLoader]             = React.useState(true);
  const [pauseGingel, setPauseGingel]           = React.useState(false);
  const [playGingel, setPlayGingel]             = React.useState(false);
  const [opacity, setOpacity]                   = React.useState(false);
  const [initialPos, setInitialPos]             = React.useState(false);
  const [showButtons, setShowButtons]           = React.useState(false);
  const [downloadComplete, setDownloadComplete] = React.useState(gingelDownloaded);


  React.useEffect(() => {

    setTimeout(() => { 
    //  setMuteAudio(true)
      setInitialPos(true);
      setPlayGingel(true)
      setOpacity(true);
    }, 10); 
    
  },[setPlayGingel, setOpacity, setInitialPos/* , setMuteAudio */]);



  React.useEffect(() => {
    if (playGingel && !downloadComplete) {
      setShowLoader(true)
    }
    else {
      setShowLoader(false)
    }
  },[playGingel, downloadComplete, setShowLoader])



  const AudioRef = React.useRef<HTMLAudioElement>(null);

  if (AudioRef && AudioRef.current && playGingel) {
    AudioRef.current.play() 
  }

  const handleEnd = () => {
    situations[selectedSituation].gingleDownLoaded = true
    setPlayGingel(false);
    setShowButtons(true);
  }


  const handlePlay = () => {
     if (loadedVideos.includes(gingle)) {
      setDownloadComplete(true)
     } 
     setTimeout(() => {
      if (pauseGingel) { setPauseGingel(false) }
      setPlayGingel(true);
    },500) 
  }
  
  const handleContinue = () => {
    setOpacity(false);
    setAudioSrc(actionAudio)
    setAudioEnd(false)
    setPauseAudio(false)
    setTimeout(() => {
      setPlayAudio(true)
    },10)
    setTimeout(() => {
      setPauseAudio(true)
    },20)
    setTimeout(() => { 
      setStation('action_menu')
      setPage('action_menu');
    }, 1000); 
  }


  return (
    <div className = { classes.black }>
        <div 
            className = { classes.container }
            style     = {{
              opacity:         opacity ? 1 : 0,
              backgroundImage: `url(${ background })`
            }}
        >
         <div 
           className = { classes.filter }
           style     = {{
             opacity: initialPos ? 1 : 0
           }}
         >
           {
             showLoader && <CompLoader variant = 'video' lang = { lang }/>
           }
           <VideoComponent
                size                = { size }
                lang                = { lang }
                playVideo           = { playGingel }
                pauseVideo          = { pauseGingel }
                videoSrc            = { gingle } 
                downloadComplete    = { downloadComplete }
                setDownloadComplete = { setDownloadComplete }
                setPauseVideo       = { setPauseGingel }
                handleEndVideo      = { handleEnd }
            />
            <Buttons
               size           = { size }
               lang           = { lang }
               dir            = { dir }
               showButtons    = { showButtons }
               handlePlay     = { handlePlay }
               handleContinue = { handleContinue }
               setShowButtons = { setShowButtons }
           />
         </div>
       </div>
    </div>
  )
}


export default GingelVideo;


//