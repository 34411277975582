import React          from 'react';
import { makeStyles } from '@material-ui/core/styles';

var clsx = require('classnames');

const useStyles = makeStyles(theme => ({
    target: {
      position:       'fixed',
      left:           '50%',
      transition:     'opacity 0.5s',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center'
    },
    grid: {
      display:             'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    static: {
    },
    open: {
      animation: 'move_right 1.1s steps(16)',
    },
    close: {
      animation: 'move_left 1.1s steps(16)',
    },
    received_container: {
     
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      flexFlow:       'column',
    },
    received: {
      width:              '100%',
      height:             '100%',
      backgroundPosition: 'center',
      backgroundRepeat:   'no-repeat',
      backgroundSize:     'contain',
    },
    text: {
      color: '#FFF'
    }
}));

type BasketProps = {
  size:          number[];
  sprite:        string;
  heart_size:    number;
  showButtons:   boolean;
  showResult:    boolean;
  gridSize:      number;
  receivedComps: { name: string, src: string, pos: number[] }[];
}

const Basket: React.FC<BasketProps> = ({
    size,
    sprite,
    heart_size,
    showButtons,
    showResult,
    receivedComps,
    gridSize
  }) => {

  const classes = useStyles();

  const [animating, setAnimating] = React.useState(false);

  const Ref = React.useRef<HTMLDivElement>(null);

  const currentRef = Ref.current;

  React.useEffect(() => {
    if (Ref && currentRef) {
        currentRef.addEventListener("webkitAnimationEnd", myEndFunction);
        currentRef.addEventListener("animationend", myEndFunction);
        currentRef.addEventListener("webkitAnimationStart", myStartFunction);
        currentRef.addEventListener("animationstart", myStartFunction);
        
        return function() {
          currentRef?.removeEventListener("webkitAnimationEnd", myEndFunction);
          currentRef?.removeEventListener("animationend", myEndFunction);
          currentRef?.removeEventListener("webkitAnimationStart", myStartFunction);
          currentRef?.removeEventListener("animationstart", myStartFunction);
        }
    }
  },[Ref, currentRef]);

  const myEndFunction = () => {
    setAnimating(false)
  };

  const myStartFunction = () => {
    setAnimating(true)
  }

  const animation = sprite === 'open'
                    ?
                    classes.open
                    :
                    (sprite === 'close' ? classes.close : classes.static);

  return (
      <div 
        ref       = { Ref }
        className = { clsx(classes.target, animation) }
        style     = {{
          top:       size[0] > size[1] ? '40vh' : '45vh',
          width:     heart_size/1.5,
          height:    heart_size/1.5,
          opacity:   showResult && showButtons ? 0.5 : 1,
          transform: animating || sprite === 'open' ? 'translate(15%, -15%) rotate(-25deg)' : 'translate(-53%, 0%)'
        }}
      >
        <div
          className = { classes.grid }
          style     = {{
            width:  gridSize <= 3 ? '100%' : (gridSize <= 6 ? '80%' : '70%'),
            height: gridSize <= 3 ? '100%' : (gridSize <= 6 ? '80%' : '70%')
          }}
        >
            {
              receivedComps.map((val) => {
                return(
                  <div 
                      key       = { val.name } 
                      className = { classes.received_container }
                    /*   style     = {{
                        width:  gridSize <= 3 ? '33.3%' : (gridSize <= 6 ? '26.6%' : '23.3%'),
                        height: gridSize <= 3 ? '33.3%' : (gridSize <= 6 ? '26.6%' : '23.3%')
                      }} */
                  >
                      <div 
                          className = { classes.received }
                          style     = {{
                            backgroundImage: `url(${val.src})`,
                          }}
                      />
             {/*          <div 
                         dir       = 'rtl'
                         className = { classes.text }
                      >
                         { val.name }
                      </div> */}
                  </div>
                )
              })
            }
        </div>
      </div>
  )
}

export default Basket







                      // onContextMenu = {e => { e.preventDefault(); e.stopPropagation(); } }




  // const handleTouchStart = React.useCallback(() => {
  //   if (showButtons) { return }
  //   const e = window.event as TouchEvent;
  //   if(!e) { return }
  //   e.preventDefault();
  //   const target = e.target as HTMLImageElement;
  //   if(target.className !== 'image') { return }
  //   setMaousDown(true);
  //   setGhostSrc(target.src)
  //   setGhostName(target.alt)
  //   setGhostPosition([e.touches[0].screenX - imoj/2, e.touches[0].screenY - imoj/2]);
  // },[window.event])


  // React.useEffect(() => {

  //   window.addEventListener('touchstart', handleTouchStart, { passive: false });

  // }, [handleTouchStart]);



















