import React          from 'react';
import key            from '../../graphic_components/key_.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    icon_container: {
       zIndex:             4,
       cursor:             'grab',
       backgroundPosition: 'center',
       backgroundSize:     'contain',
       backgroundRepeat:   'no-repeat',
       position:           'fixed',
       borderRadius:       '50%',
       transition:         'box-shadow 0.5s',
    }
}));

type KeyGhostProps = {
  position:  number[];
  lockSize:  number;
  reception: boolean;
}

const KeyGhost: React.FC<KeyGhostProps> = ({ position, lockSize, reception }) => {

  const classes = useStyles();

  return (
    <div 
      className = { classes.icon_container }
      style     = {{
            backgroundImage: `url(${key})`,
            width:           lockSize,
            height:          lockSize,
            left:            position[0],
            top:             position[1],
            boxShadow:       reception ? `0px 0px 20px 20px rgba(250, 250, 250, 0.6)` : 'none',
      }}
    />
   )
}

export default KeyGhost
//const bright = '0px 0px 20px 20px rgba(250, 250, 250, 0.6)';
