import React            from 'react';
import Radio            from '@material-ui/core/Radio';
import RadioGroup       from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import colors           from '../../utils/colors';
import { makeStyles }   from '@material-ui/core/styles';
import { words }        from '../../utils/dictionary';


const useStyles = makeStyles({
    root: {
      borderTop:      `1px solid ${ colors.darkGrey }`,
      paddingTop:     10,
      paddingBottom:  10,
      height:         56,
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
    },
    radiogroup: {
       display:  'flex',
       flexFlow: 'row',
   },
   text: {
     color:        colors.darkGrey,
     paddingRight: 10,
     paddingLeft:  10
   },
    radio: {
      color: colors.darkGrey
    }
});

type RadioFormProps = {
  lang:           string;
  dir:            string;
  childGender:    string;
  setChildGender: (gender: string) => void;
}

const RadioForm: React.FC<RadioFormProps> = ({ lang, dir, childGender, setChildGender }) => {

  const classes = useStyles();

  //const [value, setValue] = React.useState('female');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChildGender((event.target as HTMLInputElement).value);
  };

  return (
    <div className = { classes.root }>
        <div
          dir       = { dir }
          className = { classes.text }
        >
          { `${ words['gender'][lang] }:` }
        </div>
        <RadioGroup
            className  = { classes.radiogroup }
            aria-label = "gender" 
            name       = "gender1" 
            value      = { childGender }
            onChange   = { handleChange }
        >
            <FormControlLabel className = { classes.radio } value = "female" control = { <Radio color="primary"/>  }  label = { `${ words['girl'][lang] }` } />
            <FormControlLabel className = { classes.radio } value = "male"   control = { <Radio color="primary"/>  }  label = { `${ words['boy'][lang] }` } />
        </RadioGroup>
    </div>
  );
}

export default RadioForm

