import React                  from 'react';
import { connect }            from 'react-redux';

import VideoComponent         from './VideoComponent';
import CompLoader             from '../../components/comp_loader';
import Grid                   from './Grid';
import Buttons                from './Buttons';
import Modal                  from './Modal';
import RedoSituationModal     from './RedoSituationModal';
import BlockSituationModal    from './BlockSituationModal';
import HiddenAudio            from './HiddenAudio';

import { reducerType }        from '../../reducer';
import { situationsObjType }  from '../../types';

import mainBackground         from '../../graphic_components/main_bgrnd.png';
import colors                 from '../../utils/colors';
import { makeStyles }         from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  root: {
    width:              '100vw',
    height:             '100vh',
    backgroundPosition: 'center',
    backgroundRepeat:   'no-repeat',
    backgroundSize:     'cover',
    transition:         'all 0.8s'
  },
  container: {
    width:              '100vw',
    height:             '100vh',
    display:            'flex',
    flexFlow:           'column',
    justifyContent:     'center',
    alignItems:         'center',
    transition:         'all 1s'
  },
  options: {
    width:          '100%',
    display:        'flex',
    justifyContent: 'space-around',
    alignItems:     'center',
  },
  button_container: {
    flex:           1,
    height:         300,
    width:          '100vw',
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
  },
  column: {
    flexFlow: 'column'
  },
  button_and_text: {
    width:          300,
    height:         300,
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    flexFlow:       'column',
  },
  button_text: {
    color:     '#FFF',
    fontSize:  35,
    width:     '100%',
    textAlign: 'center'
  },
  p: {
    color:      '#FFF',
    fontSize:   34,
    fontWeight: 1000
  }
}));

type SitutationMenuProps = {
  size:                         number[];
  loadedVideos:                 string[];
  lang:                         string;
  dir:                          string;
  selectedSituation:            string;
  emotionMenuAudio:             string;
  situationViedoWached:         boolean;
  signup:                       boolean;
  showInfo:                     boolean;
  showSituationMenuLoader:      boolean;
  redoSituation:                boolean;
  showBlockedSituationModal:    boolean;
  reEnterSituationMenu:         boolean;
  showCleanRoomModal:           boolean;
  timeToSwitchVideoLoader:      boolean;
  uploadingFiles:               boolean;
  allSituations:                situationsObjType;
  emojiArr:                     { name: string; audio: string; image: string }[];
  setAllSituations:             (val: situationsObjType) => void;
  setMuteAudio:                 (val: boolean) => void;
  setPlayAudio:                 (val: boolean) => void;
  setPauseAudio:                (val: boolean) => void;
  setShowCleanRoomModal:        (val: boolean) => void;
  setSignup:                    (val: boolean) => void;
  setSituationViedoWached:      (val: boolean) => void;
  setShowNavBar:                (val: boolean) => void;
  setRedoSituation:             (val: boolean) => void;
  setShowBlockedSituationModal: (val: boolean) => void;
  setReEnterSituationMenu:      (val: boolean) => void;
  setUploadingFiles:            (val: boolean) => void;
  setAudioSrc:                  (val: string) => void;
  switchVideoLoader:            (val: string) => void;
  setPage:                      (val: string) => void;
  setStation:                   (val: string) => void;
  handleSituationSelection:     (val: string) => void;
  handlePlayIntroVideo:         (val: string) => void;
  setLoadedVideos:              (val: string[]) => void;
}

const SitutationMenu: React.FC<SitutationMenuProps> = ({
      size,
      lang,
      dir,
      allSituations,
      selectedSituation,
      situationViedoWached,
      signup,
      showInfo,
      showSituationMenuLoader,
      redoSituation,
      showBlockedSituationModal,
      reEnterSituationMenu,
      showCleanRoomModal,
      loadedVideos,
      timeToSwitchVideoLoader,
      uploadingFiles,
      emotionMenuAudio,
      emojiArr,
      setMuteAudio,
      setPlayAudio,
      setPauseAudio,
      setAudioSrc,
      switchVideoLoader,
      setAllSituations,
      setShowCleanRoomModal,
      setReEnterSituationMenu,
      setShowBlockedSituationModal,
      setRedoSituation,
      setSignup,
      setSituationViedoWached,
      setPage,
      setUploadingFiles,
      setStation,
      setShowNavBar,
      handleSituationSelection,
      handlePlayIntroVideo,
      setLoadedVideos
  }) => {

  const classes = useStyles();

 //const [hiddenLoaded, setHiddenLoaded]                     = React.useState(false);
  const [videoSrc, setVideoSrc]                             = React.useState('');
  const [playVideo, setPlayVideo]                           = React.useState(false);
  const [downloadComplete, setDownloadComplete]             = React.useState(false);
  const [pauseVideo, setPauseVideo]                         = React.useState(false);
  const [faseOut, setFaseOut]                               = React.useState(false);
  const [modalPosition, setModalPosition]                   = React.useState(false);
  const [modalText, setModalText]                           = React.useState(0);
  const [buttonPos, setButtonPos]                           = React.useState(false)
  const [background, setBackground]                         = React.useState(mainBackground)
  const [showRedoSituationModal, setShowRedoSituationModal] = React.useState(false)
  const [gridPos, setGridPos]                               = React.useState(false)  
  const [name, setName]                                     = React.useState('') 
  const [showModal, setShowModal]                           = React.useState(false);
  const [showLoaderComp, setShowLoaderComp]                 = React.useState(false) 
  const [dontShowGrid, setDontShowGrid]                     = React.useState(false) 
  const [blockVideo, setBlockVideo]                         = React.useState(false) 
  //const [doOnce, setDoOnce]                                 = React.useState(false) 
  const [aud, setAud]                                       = React.useState('')


  React.useEffect(() => {
    if (emotionMenuAudio.length) {
      setAud(emotionMenuAudio)
    }
 },[emotionMenuAudio, setAud])



  React.useEffect(() => {
      if (blockVideo) {
        setVideoSrc('')
        setGridPos(true)
        setPlayVideo(false)
        setBlockVideo(false)
      }
  },[blockVideo, setPlayVideo, setGridPos, setBlockVideo, setVideoSrc])



  React.useEffect(() => {

    if (uploadingFiles) {
       if (timeToSwitchVideoLoader) {
        switchVideoLoader('situations')
      } 
      setUploadingFiles(false)
    }
  },[uploadingFiles,  timeToSwitchVideoLoader, switchVideoLoader,  setUploadingFiles])


  React.useEffect(() => {
    if (playVideo && !downloadComplete && !showBlockedSituationModal) {   ///////
      setShowLoaderComp(true)
      console.log('setShowLoaderComp(true)')
    }
    else {
      setShowLoaderComp(false)
      console.log('setShowLoaderComp(false)')
      if (videoSrc.length && !loadedVideos.includes(videoSrc)) {
        const arr = loadedVideos.slice();
        arr.push(videoSrc);
        setLoadedVideos(arr)
      } 
    }
  },[
    playVideo,
     downloadComplete,
     showBlockedSituationModal,
     videoSrc,
     loadedVideos,
     setLoadedVideos,
     setShowLoaderComp,
    ])



  React.useEffect(() => {
    if (showModal || buttonPos || playVideo) {
      setDontShowGrid(true)
    }
  },[showModal, buttonPos, playVideo, setDontShowGrid])




  React.useEffect(() => {
    if (reEnterSituationMenu) {
      setTimeout(() => { setGridPos(true) },10)
      setReEnterSituationMenu(false)
    }
  },[reEnterSituationMenu, setGridPos, setReEnterSituationMenu])


  


  React.useEffect(() => {
      setTimeout(() => {
         setBackground(!selectedSituation.length ? mainBackground : allSituations[selectedSituation].background)
      },1000)
  },[allSituations, selectedSituation])




  const handlePlay = () => {
    if (buttonPos)  { setButtonPos(false) }
    if (pauseVideo) { setPauseVideo(false) }
    if (showModal)  { setShowModal(false) }
    if (gridPos)    { setGridPos(false) }
    setShowNavBar(false);
    setPlayVideo(true)
  }
 
  const handleReplay = () => {
    goToVideo(name);
  } 

  
  const handleContinue = () => {
    setFaseOut(true)
    setUploadingFiles(true)
    setAudioSrc(aud)
    setPauseAudio(false)
    setTimeout(() => {
      setPlayAudio(true)
    },10) 
    setTimeout(() => {
      setPauseAudio(true)
    },20) 
    setTimeout(() => {
      setStation('first_emotion_menu')
      setPage('emotion_menu')
    },1010)
  }


  const handleEndVideo = () => {
    const situations = allSituations;
    if (!situations[name].videoDownLoaded) {
      situations[name].videoDownLoaded = true;
    }
    setAllSituations(situations);
    setPlayVideo(false);
    setSituationViedoWached(true);
    setShowNavBar(true);
    setDownloadComplete(false)
  }


  const goToVideo = (name: string) => {

    const vid = lang === 'eng' ? allSituations[name].videoEng : allSituations[name].video;

    if (loadedVideos.includes(vid)) {
      
      setDownloadComplete(true)
    }
   /*  if (allSituations[name].videoDownLoaded === true) {
      setDownloadComplete(true)
    } */
    handleSituationSelection(name)
    setVideoSrc(vid)
    setGridPos(false)
    setTimeout(() => {
        handlePlay();
    },400)   
  }

  
  const selectSituation = (name: string) => {

    setName(name)

    if (allSituations[name].completed && !redoSituation) {
      setShowRedoSituationModal(true);
      setGridPos(false);
      return;
    }

    if (showCleanRoomModal && name === 'clean_room') {
      setModalText(1)
      setShowModal(true)
      setGridPos(false)
      setShowCleanRoomModal(false)
    }
    else {
      
      goToVideo(name)
    } 
  }


  const handleModalClick = (variant: string) => {

    if (variant === 'clean_room') {
      setModalPosition(false)
      if (signup) { setSignup(false) }
      if (modalText) {
        handleSituationSelection('clean_room')
        goToVideo('clean_room')
      }

    }
    if (variant === 'redo') {
       setShowRedoSituationModal(false)
       setRedoSituation(true)
       handleSituationSelection(name)
       goToVideo(name)
    }

    setGridPos(false)
 }


  return (
    <div
      className = { classes.root }
      style     = {{ backgroundImage: `url(${ background })` }}
    >
       <div 
         className = { classes.container }
         style     = {{
          backgroundColor: faseOut ? "#000" : (selectedSituation.length > 0 ? 'rgba(250, 250, 250, 0.25)' : colors.halavi)
         }}
       >
          {
            showBlockedSituationModal 
            && 
            <BlockSituationModal
                size                         = { size }
                showBlockedSituationModal    = { showBlockedSituationModal }
                setBlockVideo                = { setBlockVideo }
                setShowBlockedSituationModal = { setShowBlockedSituationModal }
            />
          }
          {
             showLoaderComp && <CompLoader variant = 'video' lang = { lang }/>
          }
        {/*   {
             ((showSituationMenuLoader) && !showBlockedSituationModal) && <CompLoader variant = 'page' lang = { lang }/>
          } */}
          {
            (!showBlockedSituationModal)
            &&
            <>
                <Grid
                      situations                  = { allSituations }
                      lang                        = { lang }
                      dir                         = { dir }
                      size                        = { size }
                      gridPos                     = { gridPos }
                      dontShowGrid                = { dontShowGrid }
                      selectSituation             = { selectSituation }
                      setGridPos                  = { setGridPos }
                      handlePlayIntroVideo        = { handlePlayIntroVideo }
                />
                <Modal 
                  size                 = { size }
                  showModal            = { showModal }
                  showInfo             = { showInfo }
                  modalText            = { modalText }
                  modalPosition        = { modalPosition }
                  handleModalClick     = { handleModalClick }
                  setModalPosition     = { setModalPosition }
                /> 
                <RedoSituationModal 
                    size                      = { size }
                    lang                      = { lang }
                    dir                       = { dir }
                    showRedoSituationModal    = { showRedoSituationModal }
                    setName                   = { setName }
                    setRedoSituation          = { setRedoSituation }
                    setGridPos                = { setGridPos }
                    setShowRedoSituationModal = { setShowRedoSituationModal }
                    handleModalClick          = { handleModalClick }
                /> 
                {
                  !playVideo && situationViedoWached
                  &&
                  <Buttons
                      size           = { size }
                      lang           = { lang }
                      dir            = { dir }
                      selected       = { selectedSituation.length > 0 }
                      buttonPos      = { buttonPos }
                      setButtonPos   = { setButtonPos }
                      handleContinue = { handleContinue }
                      handlePlay     = { handleReplay }
                  />
                }
     
          <VideoComponent
            size                = { size }
            playVideo           = { playVideo }
            pauseVideo          = { pauseVideo }
            videoSrc            = { videoSrc }
            downloadComplete    = { downloadComplete }
            vol                 = { selectedSituation === 'cookie_jar' ? 100 : 30 }
            setPauseVideo       = { setPauseVideo }
            setDownloadComplete = { setDownloadComplete }
            handleEndVideo      = { handleEndVideo }
          />
            </>
          }
       </div>
       <HiddenAudio emojiArr = { emojiArr }/>
     {/*   {
         !hiddenLoaded
         &&
         <Hidden
             situations      = { allSituations }
             setHiddenLoaded = { setHiddenLoaded }
         />
       } */}
    </div>
  )
}


const mapStateToProps = (reducer: reducerType) => {
  return {
    showSituationMenuLoader: reducer.showSituationMenuLoader
  }
};


const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SitutationMenu);




