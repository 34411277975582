import React             from 'react';

import crown             from '../../graphic_components/crown_golden.png';

import { makeStyles }    from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    crown_container: {
      position:       'absolute',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      borderRadius:   '50%',
    },
    crown: {
      backgroundPosition: 'center',
      backgroundRepeat:   'no-repeat',
      backgroundSize:     'contain',
      cursor:             'pointer',
    }
}));

type CrownProps = {
    size:             number[];
    ghostPosition:    number[];
    crown_w:          number;
    crown_h:          number;
    ghost:            boolean;
    reception:        boolean;
    showCrown:        boolean;
    attempt:          boolean;
    handleMouseDown:  (e: React.MouseEvent) => void;
    handleTouchStart: (e: React.TouchEvent) => void;
}

const Crown: React.FC<CrownProps> = ({
    size,
    crown_w,
    crown_h,
    ghost,
    ghostPosition,
    reception,
    showCrown,
    attempt,
    handleMouseDown,
    handleTouchStart
}) => {

  const classes = useStyles();

  return (
            <div 
                className = { classes.crown_container }
                style     = {{
                  width:      crown_w + 40,
                  height:     crown_h + 40,
                  left:       ghost ? ghostPosition[0] : 50,
                  top:        ghost ? ghostPosition[1] : size[1] - crown_h * 2,
                  opacity:    ghost || reception ? 0 : 1,
                  transition: ghost ? 'all 0s' : 'top 0.4s ease-in-out, left 0.4s ease-in-out, transform 1.5s',
                  transform:  `translate(0vw, ${ showCrown ? 0 : -100 }vh)`,
                  animation:  !attempt ? 'puls2 1s infinite' : 'none'
                }}
            >
                <div 
                  className    = { classes.crown }
                  onMouseDown  = { handleMouseDown }
                  onTouchStart = { handleTouchStart }
                  style        = {{
                      backgroundImage: `url(${crown})`,
                      width:  crown_w,
                      height: crown_h,
                  }}
                />
         </div>
       
      )
    }

export default Crown;







