import React                 from 'react';
import colors                from '../../utils/colors';   
import ForwardRoundedIcon    from '@material-ui/icons/ForwardRounded';
import { makeStyles }        from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
 
  text_sign: {
    position:        'fixed',
    height:          100,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    textAlign:       'center',
    flexFlow:        'column',
   // backgroundColor: colors.halavi,
    color:           colors.darkGrey,
    textShadow:      '0 0 7px rgba(250, 250, 250, 1)',
    fontSize:        35,
    fontWeight:      'bold',
    left:            '50%',
    transform:       'translate(-50%, 0)',
    borderRadius:    '0.5em',
    cursor:          'pointer',
    zIndex:          1,
  },
  icon: {
    position:        'fixed',
    left:            '50%',
    transform:       'translate(-50%, 0)',
    width:           185,
    height:          85,
    color:           '#FFF',
    fontSize:        35,
    backgroundColor: colors.blue_,
    borderRadius:    '1em',
    cursor:          'pointer',
    border:          `1px solid #FFF`,
    boxShadow:       '0 5px 5px rgba(0,0,0,0.4)',
    zIndex:          1,
    transition:      'bottom 1.7s, width 0.4s',
    '&:hover': {
      width: 235
    }
  }
}));

type TextSign = {
  size:                number[];
  text:                string;
  initialPos:          boolean;
  showTextSign:        boolean;
  handleTextSignClick: () => void;
}

const TextSign: React.FC<TextSign> = ({
    handleTextSignClick,
    initialPos,
    showTextSign,
    size,
    text,
}) => {

  const classes = useStyles();


  return (

              <>
                  <div 
                    dir       = 'rtl'
                    className = { classes.text_sign }
                    onClick   = { handleTextSignClick }
                    style     = {{
                      top:        initialPos && showTextSign ? '15vh' : '150vh',
                      opacity:    initialPos && showTextSign ? 1 : 0,
                      width:      size[0] > 600 ? 550 : '85vw',
                      transition: showTextSign ? 'all 2s' : 'all 1s',
                    }}
                  >
                    { text }
                  </div>
                    <ForwardRoundedIcon 
                      className = { classes.icon }
                      onClick   = { handleTextSignClick }
                      style     = {{
                       bottom:  initialPos && showTextSign ? '15vh' : '-100vh',
                       opacity: initialPos && showTextSign ? 1 : 0,
                    }}/>
              </>
   )
}



export default TextSign;
