import React                            from 'react';
import colors                           from '../../utils/colors'
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import { makeStyles }                   from '@material-ui/core/styles';
import { words }                        from '../../utils/dictionary';

const useStyles = makeStyles(theme => ({
    root: {
        position:           'fixed',
        width:              '100vw',
        height:             '100vh',
        backgroundPosition: 'center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:     'cover',
        transition:         'opacity 1s'
    },
    filter: {
        position:           'fixed',
        width:              '100vw',
        height:             '100vh',
        display:            'flex',
        justifyContent:     'center',
        alignItems:         'center',
        backgroundColor:    colors.kehe
    },
    container: {
        height:          '50%',
        display:         'flex',
        flexFlow:        'column',
        justifyContent:  'center',
        alignItems:      'center',
        borderRadius:    '1em',
        border:          `1px solid #FFF`,
        overflow:        'hiiden',
        backgroundColor: colors.kehe,
        transition:      'all 2.5s'
    },
    text: {
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
        textAlign:       'center',
        transition:      'all 3s',
        fontWeight:      'bold',
        textShadow:      '0px 2px 2px #000',      
        color:           '#FFF'
    },
    button: {
      width:           190,
      height:          90,
      display:         'flex',
      justifyContent:  'center',
      alignItems:      'center',
      transition:      'transform 3s, opacity 0.4s, width 0.4s',
      border:          '1px solid #FFF',
      cursor:          'pointer',
      backgroundColor: colors.pink,
      borderRadius:    '1em',
      '&:hover': {
        width: 250
      }
    },
    icon: {
      color:    '#FFF',
      fontSize: 50
    },
}));

type ResultProps = {
  size:                number[];
  myInd:               number;
  background:          string;
  lang:                string;
  dir:                 string;
  emotionMenuAudio:    string;
  playAudio:           boolean;
  intructionsEnabled:  boolean;
  ActionClick:         (val: number) => void;
  setAudioSrc:         (val: string) => void;
  setPlayAudio:        (val: boolean) => void;
  setPauseAudio:       (val: boolean) => void;
  setShowAudioButtons: (val: boolean) => void;
}

const Result: React.FC<ResultProps> = ({ 
  size,
  myInd,
  lang,
  dir,
  emotionMenuAudio,
  background,
  playAudio,
  intructionsEnabled,
  setAudioSrc,
  setPlayAudio,
  setPauseAudio,
  ActionClick,
  setShowAudioButtons
}) => {

  const classes = useStyles();

  const [showButton, setShowButton] = React.useState(false);
  const [opacity, setOpacity]       = React.useState(false);

  React.useEffect(() => {
     if (!intructionsEnabled) {
      setTimeout(() => { setOpacity(true); }, 10); 
      setTimeout(() => { setShowButton(true); }, 10); 
     }
     else {
      setPauseAudio(false)
      setTimeout(() => { setOpacity(true); }, 10); 
      setTimeout(() => { setShowButton(true); }, 500); 
      setTimeout(() => { setPlayAudio(true); }, 1000); 
     }  
  },[intructionsEnabled, setOpacity, setShowButton, setPlayAudio, setPauseAudio]);

  const handleContinue = (myInd: number) => {
    if (playAudio) { return }
    setAudioSrc(emotionMenuAudio)
    setPlayAudio(true)
    setShowButton(false)
    setShowAudioButtons(false)
    setOpacity(false)
    setTimeout(() => {
      setPauseAudio(true)
    },10) 
    setTimeout(() => {
      ActionClick(myInd)
    },900)
  }

  return (
    <div 
       className = { classes.root } 
       style     = {{
          backgroundImage: `url(${ background })`,
          opacity:         opacity ? 1 : 0
      }}
    >
      <div className = { classes.filter }>
          <div 
            className = { classes.container }
            style     = {{
              width:     size[0] > 800 ? '50%' : '97%',
              transform: `translate(0px, ${ opacity ? 0 : 150 }vh)`
            }}
          >
            <div 
              dir       = { dir } 
              className = { classes.text }
              style     = {{
                transform:    `translate(0px, ${ opacity ? 0 : 150 }vh)`,
                marginTop:    size[1] > 600 ? 100 : 50,
                marginBottom: size[1] > 600 ? 100 : 50,
                fontSize:     size[0] > 800 ? 30 : 25,
              }}
            >
              { `${ words['result_video_title'][lang] }` }
            </div>
            <div 
                className = { classes.button }
                onClick   = { () => { handleContinue(myInd) }}
                style     = {{
                  transform: `translate(0px, ${ showButton ? 0 : 150 }vh)`,
                  opacity:   playAudio ? 0.3 : 1,
                  cursor:    playAudio ? 'default' : 'pointer'
                }}
            > 
                <PlayCircleFilledWhiteRoundedIcon className = { classes.icon }/>
            </div>
          </div>
      </div>
    </div>
   )
}

export default Result





/***
 * 
 * 
 * /*  button: {
        backgroundPosition: 'center',
        backgroundSize:     'cover',
        backgroundRepeat:   'no-repeat',
        marginBottom:       100,
        height:             150,
        minHeight:          100,
        width:              220,
        borderRadius:       '0.5em',
        boxShadow:          `0 3px 7px rgba(0, 0, 0, 0.7)`,
        transition:         'transform 6s, opacity 0.5s',
        '&:hover': {
           opacity: 0.7
        }
    } */
 