import React                 from 'react';

type ImageLoaderProps = {
    imageLoaderSrcArr:  { situationName: string; url: string }[];
    loadedImages:       string[];
    setLoadedImages:    (val: string[]) => void;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({
    imageLoaderSrcArr,
    loadedImages,
    setLoadedImages
  }) => {


  const handleOnLoad = (url: string) => {
    if (!loadedImages.includes(url)) {
        const arr = loadedImages.slice();
        arr.push(url)
        setLoadedImages(arr)
    }
  }

  
  return (
    <>
          {
              imageLoaderSrcArr.map((val, ind) => {
                return(
                  <div key = { `${ val.url }${ ind }` }>
                      <img 
                        src    = { val.url }
                        alt    = { val.situationName }
                        width  = '1'
                        height = '1'
                        onLoad = { () => { handleOnLoad(val.url) } }
                        style = {{
                            position:   'fixed',
                            top:        -100,
                            left:       0,
                            opacity:    0,
                        }}
                      />
                  </div>
                       
                )
              })
          }
    </>
  )
}

export default ImageLoader

