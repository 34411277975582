import { ActionTypes } from "../types/actions";
import {
   QuestionnaireValues,
   userInfo,
   profileInfo,
   getProfilesSaga,
   setProfiles,
   selectProfileSaga,
   selectProfile,
   setScenariosArr,
   videoUploadType,
   setUserVideoType,
   situationAndScenarioType,
   preLoadVideoSuccess,
   preloadVideoSaga,
} from "../types/actions";





export const SHOW_ERROR_MODAL = (payload: boolean): ActionTypes => ({
  type: 'SHOW_ERROR_MODAL',
  payload: payload
});



export const HIDE_ERROR_MODAL = (payload: boolean): ActionTypes => ({
  type: 'HIDE_ERROR_MODAL',
  payload: payload
});





export const PRELOAD_VIDEO_SAGA = (payload: preloadVideoSaga): ActionTypes => ({
  type: 'PRELOAD_VIDEO_SAGA',
  payload: payload
});


export const PRELOAD_VIDEO_SUCCESS = (payload: preLoadVideoSuccess): ActionTypes => ({
  type: 'PRELOAD_VIDEO_SUCCESS',
  payload: payload
});



export const PRELOAD_VIDEO_FAILUR = (payload: boolean): ActionTypes => ({
  type: 'PRELOAD_VIDEO_FAILUR',
  payload: payload
});




export const GET_USER_INFO = (payload: userInfo): ActionTypes => ({
  type: 'GET_USER_INFO',
  payload: payload
});



export const GET_PROFILES_SAGA = (payload: getProfilesSaga): ActionTypes => ({
  type: 'GET_PROFILES_SAGA',
  payload: payload
});


export const SET_PROFILES = (payload: setProfiles): ActionTypes => ({
  type: 'SET_PROFILES',
  payload: payload
});

export const SET_SCENARIOS_ARR = (payload: setScenariosArr): ActionTypes => ({
  type: 'SET_SCENARIOS_ARR',
  payload: payload
});


export const SET_SCENARIO_STATE = (payload: getProfilesSaga): ActionTypes => ({
  type: 'SET_SCENARIO_STATE',
  payload: payload
});



export const GET_SCENARIO_STATE_SAGA = (payload: getProfilesSaga): ActionTypes => ({
  type: 'GET_SCENARIO_STATE_SAGA',
  payload: payload
});



export const SELECT_PROFILE_SAGA = (payload: selectProfileSaga): ActionTypes => ({
  type: 'SELECT_PROFILE_SAGA',
  payload: payload
});

 
export const SELECT_PROFILE = (payload: selectProfile): ActionTypes => ({
  type: 'SELECT_PROFILE',
  payload: payload
}); 




export const SUBMIT_QUESTIONNAIRE_SAGA = (payload: QuestionnaireValues): ActionTypes => ({
    type: 'SUBMIT_QUESTIONNAIRE_SAGA',
    payload: payload
});

export const SUBMIT_QUESTIONNAIRE = (payload: profileInfo): ActionTypes => ({
  type: 'SUBMIT_QUESTIONNAIRE',
  payload: payload
});




export const UPLOAD_VIDEO_SAGA = (payload: videoUploadType): ActionTypes => ({
  type: 'UPLOAD_VIDEO_SAGA',
  payload: payload
});


export const SET_USER_VIDEO = (payload: setUserVideoType): ActionTypes => ({
  type: 'SET_USER_VIDEO',
  payload: payload
});



export const SUBMIT_COMPLETION_OF_SCENARIO_SAGA = (payload: situationAndScenarioType): ActionTypes => ({
  type: 'SUBMIT_COMPLETION_OF_SCENARIO_SAGA',
  payload: payload
});


export const SUBMIT_COMPLETION_OF_SCENARIO = (payload: situationAndScenarioType): ActionTypes => ({
  type: 'SUBMIT_COMPLETION_OF_SCENARIO',
  payload: payload
});



