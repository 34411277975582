import { call, all }         from 'redux-saga/effects';
import test                  from './test'
import submitQuestionnaire   from './questionnaireSubmition'
import videoUpload           from './videoUpload'
import scenarioCompletion    from './scenarioCompletion'
import getProfiles           from './getProfiles'
import selectProfileSaga     from './selectProfileSaga'
import preloadVideo          from './preloadVideo'

export default function* indexSaga(){
  yield all([
    call(test),
    call(videoUpload),
    call(submitQuestionnaire),
    call(scenarioCompletion),
    call(getProfiles),
    call(preloadVideo),
    call(selectProfileSaga)
  ])
}