import React            from 'react';
import { makeStyles }   from '@material-ui/core/styles';
import colors           from '../../utils/colors'
import { words }        from '../../utils/dictionary'

var clsx = require('classnames')

const useStyles = makeStyles(theme => ({
  form: {
    width:           '100%',
    height:          '100%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    flexFlow:        'column',
    textAlign:       'center',
    transition:      'all 1s'
  },
  title: {
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      textAlign:      'center',
      color:          '#FFF',
      fontSize:       30,
      fontWeight:     'bold',
      margin:         20
  },
  error: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    color:          '#FFF',
    fontSize:       30,
    fontWeight:     'bold',
 },
  buttons: {
    height:         '100%',
    width:          '100%',
    display:        'flex',
    flexFlow:       'column',
    alignItems:     'center',
    justifyContent: 'center',
  },
  button: {
    width:               270,
    height:              95,
    margin:              20,
    display:            'flex',
    justifyContent:     'center',
    alignItems:         'center',
    color:              '#FFF',
    fontWeight:         'bold',
    borderRadius:       '0.5em',
    cursor:             'pointer',
    border:             '1px solid #FFF',
    fontSize:            30,
    transition:         'all 0.4s',
    backgroundColor:    colors.kehe,
    fontFamily:         'Varela Round',
    '&:hover': {
      width: 330,
    }
  },
   input: {
    width:   0,
    height:  0,
    opacity: 0
  },
  progress_title: {
    fontSize:     30,
    marginBottom: 30,
    fontWeight:   'bold',
    color:        '#FFF',
    textShadow:   '0 0 5px rgba(0, 0, 0, 1)',
  },
  loader: {
    width:               330,
    height:              45,
    display:            'flex',
    borderRadius:       '1em',
    border:             '1px solid #FFF',
    overflow:           'hidden'
  },
  completed: {
    height:          '100%',
    backgroundColor: 'rgba(250, 250, 250, 0.6)',
  },
  not_completed: {
    height:          '100%',
    backgroundColor: 'transparent',
  },
  pink: {
      backgroundColor: colors.pink
  },
  blue: {
    backgroundColor: colors.blue_
  },
  green: {
    backgroundColor: colors.green
  }
}));

type VideoModal = {
  stage:               string;
  error:               string;
  lang:                string;
  dir:                 string;
  progress:            number;
  initialPos:          boolean;
  disableSubButton:    boolean;
  showLoader:          boolean;
  success:             boolean;
  handleClose:         () => void;
  onSubmit:            (e: React.FormEvent) => void;
  onChange:            (e: React.FormEvent) => void;
 }

const VideoModal: React.FC<VideoModal> = ({
    stage,
    error,
    lang,
    dir,
    progress,
    disableSubButton,
    showLoader,
    success,
    initialPos,
    handleClose,
    onSubmit,
    onChange
}) => {

  const classes = useStyles();

  return (
    
          <form 
              className = { classes.form }
              onSubmit  = { onSubmit }
              style     = {{
                transform: `translate(0px, ${ initialPos ? 0 : 150 }vh)`
              }}
          >
              {
                showLoader 
                && 
                <>
                    <div className = { classes.progress_title }>
                        { `${ progress } %` }
                    </div>
                    <div className = { classes.loader }>
                        <div 
                            className = { classes.completed } 
                            style     = {{
                              flex: progress / 100
                            }}
                        />
                        <div 
                            className = { classes.not_completed } 
                            style     = {{
                              flex: 1 - (progress / 100)
                            }}
                        />
                    </div>
                </>
              }
              {
                !showLoader
                &&
                <>
                    <div className = { classes.buttons }>
                            <div 
                                dir       = { dir }
                                className = { classes.error }
                            >
                                   { error.length > 0 && error }
                            </div>
                            {
                              !success
                              &&
                              <>
                                  <div 
                                    dir       = { dir }
                                    className = { classes.title }
                                  >
                                      { stage === 'action' ? `${ words['create_action_video'][lang] }` :  `${ words['create_result_video'][lang] }` }
                                  </div>
                                  <label 
                                      className = { clsx(classes.button, classes.green) }
                                      htmlFor   = "file-upload"
                                  >
                                       { `${ words['upload_video'][lang] }` }
                                  </label>
                                  <div 
                                    className = { clsx(classes.button, classes.pink) }
                                    onClick   = { handleClose }
                                  >
                                      { `${ words['return'][lang] }` }
                                  </div>
                                  <button 
                                      type      = "submit"
                                      className = { clsx(classes.button, classes.blue) }
                                      disabled  = { disableSubButton }
                                      style     = {{
                                        opacity: disableSubButton ? 0.3 : 1,
                                        cursor:  disableSubButton ? 'auto' : 'pointer'
                                      }}
                                  >
                                         { `${ words['continue'][lang] }` }
                                  </button>
                            </>
                            }
                            {
                              success
                              &&
                              <div 
                                    className = { clsx(classes.button, classes.blue) }
                                    onClick   = { handleClose }
                                  >
                                       { `${ words['continue'][lang] }` }
                              </div>
                            }   
                    </div>
                    <input
                      className = { classes.input }
                      type      = "file"
                      id        = "file-upload"
                      name      = 'vid'
                      onChange  = { onChange }
                    />
                  </>
                }
              </form>
   )
}

export default VideoModal
