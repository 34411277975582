import React          from 'react';

import Modal          from './Modal';
import background     from '../../graphic_components/main_bgrnd.png';

import colors         from '../../utils/colors';
import { words }      from '../../utils/dictionary';
import { makeStyles } from '@material-ui/core/styles';


var clsx = require('classnames');

const useStyles = makeStyles(() => ({
    flex: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center'
    },
    column: {
        flexFlow: 'column'
    },
    root: {
        width:              '100vw',
        height:             '100vh',
        backgroundSize:     'cover',
        backgroundPosition: 'center',
        backgroundRepeat:   'no-repeat',
    },
    filter: {
        width:           '100vw',
        height:          '100vh',
        backgroundColor: colors.halavi,
        display:         'flex',
        alignItems:      'center',
        justifyContent:  'center',
    },
    text_container: {
       flex:       0.7,
       lineHeight: 2,
       fontSize:   35,
       textAlign: 'center'
    },
    button_container: {
        position:       'fixed',
        bottom:         0,
        left:           '50%',
        height:         '25vh',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'space-around',
        transition:     'all 1s',
    },
    button: {
        width:          180,
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        textAlign:      'center',
        border:         `1px solid #FFF`,
        borderRadius:   '0.5em',
        cursor:         'pointer',
        transition:     'width 0.4s, transform 2.2s',
        backgroundColor:colors.kehe,
        textShadow:     '0 7px 7px rgba(0, 0, 0, 0.8)',
        color:          '#FFF',
        fontSize:       30,
        fomtWeight:     'bold',
        '&:hover': {
           width: 260
        }
       }
}));


type IntroProps = {
    size:        number[];
    signup:      boolean;
    lang:        string;
    dir:         string;
    setShowInfo: (val: boolean) => void;
    setPage:     (val: string) => void;

}

const Intro: React.FC<IntroProps> = ({ 
    size,
    signup,
    lang,
    dir,
    setShowInfo,
    setPage,
}) => {

  const classes = useStyles();

  const [initialPos, setInitialPos] = React.useState(false)

  React.useEffect(() => {
      setTimeout(() => {
          setInitialPos(true)
    },10)
  },[setInitialPos])

  const handleShowInfo = () => {
    setInitialPos(false)
    setTimeout(() => {
        setShowInfo(true)
        setPage('situation_menu')
    },800)
  }
/* 
  const handleShowVideo = () => {
      
} */

  return (
    <div 
       className = { clsx(classes.root, classes.flex) }
       style     = {{
           backgroundImage: `url(${ background })`
       }}
    >
       <div className = { clsx(classes.filter, classes.flex, classes.column) }>
            <Modal
                size       = { size }
                lang       = { lang }
                dir        = { dir }
                initialPos = { initialPos }
            />
             <div 
                className = { classes.button_container }
                style     = {{
                    width:     size[0] > 600 ? '85vw' : '100vw',
                    transform: `translate(-50%, ${ initialPos ? 0 : 100 }vh)`,
                }}
             >
                        {/* <div
                            className = { classes.button }
                            onClick   = { () => {} }
                            style     = {{
                                height:    size[0] > 600 && size[1] > 700 ? 100 : 75,
                            }}
                        >
                            {'סרטון הסבר'}
                        </div> */}
                        <div
                            className = { classes.button }
                            onClick   = { handleShowInfo }
                            style     = {{
                                height:    size[0] > 600 && size[1] > 700 ? 100 : 75,
                            }}
                        >
                            { `${ words['continue'][lang] }` }
                        </div>
            </div>
        </div>
    </div>
    )
  
}

export default Intro







