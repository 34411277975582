import React              from 'react';
import colors             from '../../utils/colors'
import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';
import { makeStyles }     from '@material-ui/core/styles';
import { words }          from '../../utils/dictionary'

const useStyles = makeStyles(theme => ({
  root: {
    position:       'fixed',
    left:            0,
    width:          '100vw',
    height:         '100vh',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    transition:     'all 0.4s',
  },
  container: {
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    borderRadius:    '1em',
    backgroundColor: `rgba(0, 0, 0, 0.25)`,
    border:          '1px solid #FFF',
    padding:         15
  },
  modal: {
    width:           '100%',
    height:          '100%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    flexFlow:        'column',
    textAlign:       'center',
   // backgroundColor: `rgba(250, 250, 250, 0.65)`,
    color:           '#FFF',
    textShadow:      '0 0 7px rgba(0, 0, 0, 1)',
    fontSize:        30,
    fontWeight:      'bold',
    borderRadius:    '1em',
  },
  icon: {
    width:           185,
    height:          85,
    marginTop:       35,
    color:           '#FFF',
    fontSize:        35,
    backgroundColor: colors.blue_,
    borderRadius:    '1em',
    cursor:          'pointer',
    border:          `1px solid #FFF`,
    boxShadow:       '0 5px 5px rgba(0,0,0,0.4)',
    zIndex:          1,
    transition:      'all 0.4s',
    '&:hover': {
      width: 235
    }
  }
}));

type ErrorProps = {
  size:              number[];
  lang:              string;
  dir:               string;
  showMagicWand:     boolean;
  showMagicModal:    boolean;
  playAudio:         boolean;
  goToMagicViedo:    () => void;
  setPlayAudio:      (val: boolean) => void;
  setAudioEnd:       (val: boolean) => void;
  setShowMagicModal: (val: boolean) => void;
  setInitialPos:     (val: boolean) => void;
 }

const Error: React.FC<ErrorProps> = ({ 
    size,
    lang,
    dir,
    showMagicWand,
    showMagicModal,
    playAudio,
    setPlayAudio,
    setAudioEnd,
    goToMagicViedo,
    setShowMagicModal,
    setInitialPos
}) => {

  const classes = useStyles();

  const [showSecondText, setShowSecondText] = React.useState(false)

  const [containerWidth, containerHeight, border, fontSize] = React.useMemo(() => {
      return (
               (size[0] > 800 && size[1] > 600)
               ?
               ['65vw', 350, '1px solid #FFF', 34]
               :
               [size[0], size[1]-30, 'none', 25])
  },[size])

  const handleClick = () => {
    if (playAudio) { 
      setPlayAudio(false)
      setAudioEnd(true)
    }
    if (!showSecondText && !showMagicWand) {
      setShowMagicModal(false)
      setTimeout(() => {
        setShowSecondText(true)
        setShowMagicModal(true)
      },500)
      setTimeout(() => {
        setShowMagicModal(false)
      },3500)
      setTimeout(() => {
        setShowSecondText(false)
        setInitialPos(true)
      },4000)
    }
    if (showMagicWand) {
      setShowMagicModal(false)
      setTimeout(() => {
        setShowSecondText(false)
        setInitialPos(true)
        goToMagicViedo()
      },500)
    }
  }
//
  return (
    <div
      dir       = { dir }
      className = { classes.root }
      style     = {{
        top: showMagicModal ? 0 : '-150vh'
      }}
    >
       <div 
            className = { classes.container }
            style     = {{
                width:  containerWidth,
                height: containerHeight,
                border: border,
                fontSize:fontSize
            }}
       >
          <div className = { classes.modal }>
              <p>
                 { showSecondText ? `${ words['magicModal2'][lang] }` : `${ words['magicModal1'][lang] }` }
              </p>
              {
                !showSecondText
                &&
                <ForwardRoundedIcon 
                  className = { classes.icon }
                  onClick   = { handleClick }
                  style     = {{
                    opacity: playAudio ? 0.3 : 1,
                    cursor:  playAudio ? 'default' : 'pointer'
                  }}
                />
              }
          </div>
        </div>
    </div>
   )
}

export default Error
