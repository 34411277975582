import React                from 'react';
import { makeStyles }       from '@material-ui/core/styles';
import { actionType }       from '../../types';
import Pie                  from './Pie';
import MagicWand            from './MagicWand';


const useStyles = makeStyles(theme => ({
  root: {
    position:       'fixed',
    display:        'flex',
    justifyContent: 'space-around',
    alignItems:     'center',
    top:            '50%',
    width:          '100vw',
    transition:     'all 1.5s'
  },
  pie_switch: {
    width:    100,
    height:   100,
    fontSize: 30,
    color:    '#FFF',
    cursor:   'pointer'
  },
  pie: {
  },
  inner: {
    backgroundPosittion: 'center',
    backgroundSize:      'cover',
    backgroundRepeat:    'no-repeat',
    backgroundColor:     'transparent',
    position:            'absolute',
    left:                '50%',
    top:                 '50%',
    borderRadius:        '50%',
    transform:           `translate(-50%, -50%)`,
    overflow:            'hidden',
    zIndex:               3,
    transition:          'all 0.8s'
  }
}));

type PieChartProps = {
  size:            number[];
  spin:            number;
  circleSize:      number;
  lang:            string;
  userVideoSrc:    string;
  centerImage:     string;
  name:            string;
  orientation:     boolean;
  showMagicWand:   boolean;
  hide:            boolean;
  showFractions:   boolean;
  initialPos:      boolean;
  redoSituation:   boolean;
  actionArr:       actionType[];
  ActionClick:     (ind: number) => void;
  setShowToolTip:  (val: boolean) => void;
  setToolTipText:  (val: string) => void;
  MagicWandClick:  () => void;
  playMagicAudio:  () => void;
}

const PieChart: React.FC<PieChartProps> = ({
  orientation,
  name,
  lang,
  userVideoSrc,
  centerImage,
  size,
  spin,
  hide,
  showMagicWand,
  circleSize,
  actionArr,
  showFractions,
  initialPos,
  redoSituation,
  ActionClick,
  setShowToolTip,
  setToolTipText,
  MagicWandClick,
  playMagicAudio,
}) => {

  const classes = useStyles();

  const [hover, setHover] = React.useState(-1);
 
  return (
    <div 
      className = { classes.root }
      style     = {{
        height:    size[1],
        flexFlow:  orientation ? 'column' : 'row', 
        transform: `translate(0%, ${ initialPos && !hide ? -50 : 400 }%)`,
      }}
    >
      <MagicWand
         size             = { size }
         bool             = { false }
         showMagicWand    = { showMagicWand }
         orientation      = { orientation }
         showFractions    = { showFractions }
         playMagicAudio   = { playMagicAudio }     
         MagicWandClick   = { MagicWandClick }
         setShowToolTip   = { setShowToolTip }
      />
      <div className = { classes.pie } style = {{ height: circleSize, width: circleSize }}>
        <Pie
          size            = { size }
          name            = { name }
          lang            = { lang }
          userVideoSrc    = { userVideoSrc }
          spin            = { spin }
          circleSize      = { circleSize }
          actionArr       = { actionArr }
          hover           = { hover }
          redoSituation   = { redoSituation }
          ActionClick     = { ActionClick }
          setHover        = { setHover }
          setShowToolTip  = { setShowToolTip }
          setToolTipText  = { setToolTipText }
        />
      <div 
            className = { classes.inner }
            style     = {{
              backgroundImage: `url(${ centerImage })`,
              width:           !showFractions ? circleSize : circleSize/3,
              height:          !showFractions ? circleSize : circleSize/3,
            //  border:          !showFractions ? '5px solid transparnet' : '5px solid #FFF'
            }}
      /> 
      </div>
      <MagicWand
         size             = { size }
         bool             = { true }
         showMagicWand    = { showMagicWand }
         orientation      = { orientation }
         showFractions    = { showFractions }
         playMagicAudio   = { playMagicAudio } 
         MagicWandClick   = { MagicWandClick }
         setShowToolTip   = { setShowToolTip }
      />
    </div>
  )
}

export default PieChart
