
import React          from 'react';
import { actionType } from '../../types';

type HiddenProps = {
  images:          string[];
  audio:           string[];
  actionArr:       actionType[];
  setHiddenLoaded: (val: boolean) => void;
}

const Hidden: React.FC<HiddenProps> = ({
    images,
    audio,
    actionArr,
    setHiddenLoaded
  }) => {
  
    
  
    const [imageCounter, setImageCounter]   = React.useState(0)
    const [imagesLoaded, setImagesLoaded]   = React.useState(false)
    const [audioCounter, setAudioCounter]   = React.useState(0)
    //const [audioLoaded, setAudioLoaded]     = React.useState(false)


    const imageArr = React.useMemo(() => {

      let IA  = images.slice();

      for (let i = 0; i < actionArr.length; i++) {
        IA.push(actionArr[i].actionImg, actionArr[i].actionBground, actionArr[i].resultImg)
      }

      return IA

    },[images, actionArr])


    React.useEffect(() => {
      if (imageCounter === imageArr.length) {
        setImagesLoaded(true)
      }
      if (audioCounter === audio.length) {
      /*   setAudioLoaded(true) */
      }
    },[imageCounter, audioCounter, audio, imageArr, setImagesLoaded/* , setAudioLoaded */])

     

    const handleOnImageLoad = () => {
      const newNum = imageCounter + 1;
      setImageCounter(newNum);
    }

 /*    const handleOnAudioLoad = () => {
      console.log('canPlayThrough')
      const newNum = audioCounter + 1;
      setAudioCounter(newNum);
    } */


    React.useEffect(() => {
       if (imagesLoaded/*  && audioLoaded */) {
          setHiddenLoaded(true)
       }
    },[setHiddenLoaded, imagesLoaded/* , audioLoaded */])


    return (
    <>
          {
              imageArr.map((val, ind) => {
                return(
                        <img 
                            key    = { `${ val }_${ ind }` }
                            src    = { val }
                            alt    = { val }
                            width  = '100'
                            height = '100'
                            onLoad = { handleOnImageLoad }
                            style = {{
                                opacity:    0,
                                marginLeft: 10000
                             }}
                        />
                       
                )
              })
          }
        {/*   {
              audio.map((val, ind) => {
                return(
                    <audio  
                        key                = { `${ val }_${ ind }` }
                        muted  
                        webkit-playsinline = "true" 
                        playsInline
                        autoPlay
                        src                = { val } 
                        preload            = "auto"
                        onCanPlayThrough   = { handleOnAudioLoad }
                   /> 
                )
              })
          } */}
    </>
  )
}

export default Hidden

