import React          from 'react';
import Slider         from './SliderComp';
import colors         from '../../utils/colors';
import { makeStyles } from '@material-ui/core/styles';
import { words }      from '../../utils/dictionary';


const useStyles = makeStyles(theme => ({
    root: {
        display:        'flex',
        flexFlow:       'column',
        justifyContent: 'center',
        alignItems:     'center',
        width:          '100vw',
        paddingTop:     40,
        paddingBottom:  40,
        transition:     'all 1s'
      },
    title: {
        color:    colors.darkGrey,
        fontSize: 18,
        paddingBottom: 20
    }
}));    


type Form3Props = {
  size:                  number[];
  initialPos:            boolean;
  lang:                  string;
  dir:                   string;
  setIntroExtro:         (val: number) => void;
  setGratificationDelay: (val: number) => void;
  setViolentBehavior:    (val: number) => void;
}

const Form3: React.FC<Form3Props> = ({ 
    size,
    initialPos,
    lang,
    dir,
    setIntroExtro,
    setGratificationDelay,
    setViolentBehavior
}) => {

  const classes = useStyles();

  const sliders = [setIntroExtro, setGratificationDelay, setViolentBehavior];

  return (
      <div 
         className = { classes.root }
         dir       = 'rtl'
         style     = {{
           transform: `translate(0px, ${ initialPos ? 0 : 150 }vh)`
         }}
      >
            <div 
              className = { classes.title }
              dir       = { dir }
            >
              { `${ words['questionnaire_f_3'][lang] }` }
            </div>
            {
                sliders.map((val, ind) => {
                   return(
                      <Slider 
                        key      = { `slider_${ind}` } 
                        num      = { ind } 
                        size     = { size }
                        lang     = { lang }
                        dir      = { dir }
                        setState = { val }
                     />
                   )
                })
            }
      </div>
    )
  
}

export default Form3








       