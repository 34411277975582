import React                              from 'react';

import { connect }                        from 'react-redux';
import { Dispatch }                       from "redux";
import { ActionTypes, selectProfileSaga } from '../../types/actions';
import { reducerType }                    from '../../reducer';
import { SELECT_PROFILE_SAGA }            from '../../actions'

import { profileType }                    from '../../types';

import background                         from '../../graphic_components/main_bgrnd.png';
import DropDown                           from './DropDown';

import { words }                          from '../../utils/dictionary';

import colors                             from '../../utils/colors';
import { makeStyles }                     from '@material-ui/core/styles';

var clsx = require('classnames')

const useStyles = makeStyles(theme => ({
  root: {
    width:              '100vw',
    minHeight:          '100vh',
    maxHeight:          '100vh',
    overFlow:           'hidden',
    display:            '-webkit-flex',
    justifyContent:     'center',
    alignItems:         'center',
    backgroundPosition: 'center',
    backgroundRepeat:   'no-repeat',
    backgroundSize:     'cover',
  },
  filter: {
    width:              '100vw',
    height:             '100vh',
    //backgroundColor:    colors.halavi,
    backgroundColor:    colors.halavi,
    display:            'flex',
    justifyContent:     'center',
    alignItems:         'center',
  //  boxShadow:          `5px 5px 5px 10px #FFF`
  },
  container: {
    height:             '90vh',
    width:              '90vw',
    display:            'flex',
    justifyContent:     'center',
    alignItems:         'center',
    flexWrap:           'wrap',
    backgroundColor:    'transparent',
    transition:         'all 1s',   
  },
  button: {
    width:               270,
    height:              95,
    margin:              20,
    paddingLeft:         10,
    paddingRight:        10,
    display:            'flex',
    justifyContent:     'center',
    alignItems:         'center',
    color:              '#FFF',
    fontWeight:         'bold',
    borderRadius:       '0.5em',
    cursor:             'pointer',
    border:             '1px solid #FFF',
    fontSize:            30,
    fontWheit:          'bold',
   // backgroundColor:    colors.halavi,
    '&:hover': {
      width: 330,
    }
  },
  fast: {
    color:          '#FFF',
    backgroundColor: colors.kehe,
    textShadow:     '0 5px 7px rgba(50,50,50,0.7)',
    transition:     'transform 1s, width 0.4s'
  },
  slow: {
    color:          '#FFF',
    backgroundColor: colors.kehe,
    textShadow:     '0 5px 7px rgba(50,50,50,0.7)',
    transition:     'transform 1.5s, width 0.4s'
  },
  p2: {
  //  backgroundColor: colors.halavi,
    paddingTop:      15,
    paddingBottom:   15,
    paddingLeft:     40,
    paddingRight:    40,
    borderRadius:    '0.5em'
  },
  drop_down_container: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    overflowY:      'hidden',
    transition:     'all 0.8s',
  }
}));

type ProfileMenuProps = {
  userId:        string;
  lang:          string
  profiles:      profileType[];
  size:          number[];
  setPage:       (val: string) => void;
  selectProfile: (payload: selectProfileSaga) => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({
    userId,
    lang,
    profiles,
    size,
    setPage,
    selectProfile
 }) => {

  const classes = useStyles();

  const [initialPos, setInitialPos]         = React.useState(false);
  const [showMenu, setShowMenu]             = React.useState(false);
  const [showMenuButton, setShowMenuButton] = React.useState(profiles.length > 0);

  // - - - just in case you receive the profiles object from the server after initial rendering of this component

  React.useEffect(() => {
    if (profiles.length) {
      setShowMenuButton(true)
    }
  },[profiles, setShowMenuButton])

  // - - - - 


  React.useEffect(() => {
      setTimeout(() => { setInitialPos(true) }, 10)
  },[setInitialPos])


  const handleShowMenu = () => {
    if (!showMenuButton) { return }
    setShowMenu(true)
  }


  const goToQuestionnaire = () => {
    setShowMenu(false)
    setInitialPos(false)
    setTimeout(() => { setPage('questionnaire') }, 900)
  }


  const handleSelectProfile = (val: number) => {

     const payload = {
      userId:          userId,
      selectedProfile: val
    } 
    selectProfile(payload);
    setInitialPos(false)
    setTimeout(() => { setPage('situation_menu'); }, 800)
  }

  return (
    <div
       className = { classes.root }
       style     = {{
         backgroundImage: `url(${background})`
       }}
    >
        <div className = { classes.filter }>
            <div 
               className = { classes.container }
               style     = {{
                  opacity:         initialPos ? 1 : 0,
                  transform:       `translate(0px, ${ initialPos ? 0 : 100 }vh)`,
                  flexFlow:        size[1] > 600 || size[0] < 600 ? 'column' : 'row',
                  WebkitFlexFlow:  size[1] > 600 || size[0] < 600 ? 'column' : 'row',
               }}
            >
  
                        <div 
                            className = { clsx(classes.button, classes.fast) }
                            onClick   = { handleShowMenu }  
                            style     = {{
                              transform: `translate(0px, ${ initialPos ? 0 : 100 }vh)`,
                              opacity:   showMenuButton ? 1 : 0.35,
                              cursor:    showMenuButton ? 'pointer' : 'default'
                            }}
                        >
                           <p className = { classes.p2 }>
                             { `${ words['select_profile'][lang] }` }
                          </p>
                        </div>
                        <div 
                          className = { classes.drop_down_container }
                          style     = {{
                            height:  showMenu ? 250 : 0,
                            width:   size[0] > 600 ? 450 : '90vw'
                          }}
                        >
                            <DropDown
                              size                = { size }
                              setPage             = { setPage }
                              handleSelectProfile = { handleSelectProfile }
                              profiles            = { profiles }
                            />
                        </div>
                        <div 
                            className = { clsx(classes.button, classes.slow) }
                            onClick   = { goToQuestionnaire }
                            style     = {{
                              transform: `translate(0px, ${ initialPos ? 0 : 100 }vh)`
                            }}
                        >
                           { `${ words['create_profile'][lang] }` }
                        </div>
            
            </div>
          
  
         </div>
    </div>
  )
}


const mapStateToProps = (reducer: reducerType) => {
  return {
    userId:   reducer.userId,
    profiles: reducer.profiles
  }
};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  selectProfile: (payload: selectProfileSaga) => dispatch(SELECT_PROFILE_SAGA(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
