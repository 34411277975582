import React             from 'react';
import SignOutModal      from '../sign_out_modal';
import MenuRoundedIcon   from '@material-ui/icons/MenuRounded';
import colors            from '../../utils/colors';
import { Auth }          from 'aws-amplify';
import { makeStyles }    from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { words }         from '../../utils/dictionary';

const useStyles = makeStyles(() => ({
  root: {
   // position: 'absolute',
   // right:    10
  },
  button_container: {
    height:          60,
    width:           60,
    marginRight:     15,
    marginLeft:      15,
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
  },
  button: {
      height:          '90%',
      width:           '90%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: colors.blue_,
      borderRadius:    '50%',
      cursor:          'pointer',
      transition:      'all 0.2s',
      '&:hover': {
        backgroundColor: '#89D',
      }
  },
  icon: {
     fontSize: 35,
     color:    '#FFF'
  },
  il: {
    padding:     20,
    cursor:      'pointer',
    color:       colors.darkGrey,
    fontWeight:  'bold',
    '&:hover': {
      backgroundColor: '#DDD'
    }
  },
  paper: {
    position:        'absolute',
    right:           0,
    zIndex:          999,
    overflow:        'hidden',
    backgroundColor: '#FFF',
    borderRadius:    '0.5em',
    boxShadow:       '0 5px 5px rgba(0,0,0,0.3)',
    transition:      'all 0.3s'
  }

}));

type MenuButtonProps = {
    size:                  number[];
    lang:                  string;
    dir:                   string;
    intructionsEnabled:    boolean;
    setIntructionsEnabled: (val: boolean) => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({
     size,
     lang,
     dir,
     intructionsEnabled,
     setIntructionsEnabled,
}) => {

  const classes = useStyles()

  const [showSignOutModal, setShowSignOutModal] = React.useState(false)
  const [open, setOpen]                         = React.useState(false);

  const handleClickAway = () => {
     if (open) {
      setOpen(false)
     }
  } 
  
  
  /* 

  const handleShowInfo = () => {
    setShowInfo(true)
    setInfoVariant('general')
  } */


  const signout = async() => {
 
    try {      
      await Auth.signOut({ global: true })
      localStorage.clear();
      window.location.reload(true);
    }
    catch(err) {
      console.error("error occured in signout: ",err.message || err)
    }

  }

  return (
    <div
      dir       = { dir }
      className = { classes.root }
    >
        <ClickAwayListener onClickAway = { handleClickAway } >
          <div>
              <div 
                 className = { classes.button_container }
                 onClick   = { () => setOpen(!open) }
              >
                 <div className = { classes.button }>
                    <MenuRoundedIcon className = { classes.icon }/>
                </div>
              </div> 
              <div 
                  className = { classes.paper } 
                  onClick   = { () => setOpen(false) }
                  style     = {{
                    width:   open ? 300 : 0,
                    opacity: open ? 1 : 0
                  }}
              >
                <div 
                  dir       = { dir } 
                  className = { classes.il } 
                  onClick   = { () => setIntructionsEnabled(!intructionsEnabled) }
                >
                   { intructionsEnabled ? `${ words['disable_inst'][lang] }` : `${ words['enable_inst'][lang] }` }
                </div>
              </div>
          </div>
        </ClickAwayListener>
        <SignOutModal
            size                = { size }
            lang                = { lang }
            dir                 = { dir }
            showSignOutModal    = { showSignOutModal }
            handleSignOut       = { signout }
            setShowSignOutModal = { setShowSignOutModal }
        />     
    </div>
  )
}

export default MenuButton;