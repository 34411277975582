import React           from 'react';
import colors          from '../../utils/colors';
import { makeStyles }  from '@material-ui/core/styles';
import { profileType } from '../../types';

const useStyles = makeStyles(() => ({
  root: {
    display:         'flex',
    width:           '100%',
    height:          '100%',
    justifyContent:  'center',
    alignItems:      'center',
    flexFlow:        'column',
    transition:      'all 0.7s',
  },
  menu: {
    padding:        20,
    width:          '90%',
    height:         '100%',
    overflowY:      "scroll",
    overflowX:      "hidden",
    border:         `1px solid #FFF`,
    backgroundColor: colors.kehe,
    borderRadius:   `1em`,
    transition:     'all 2s',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(250, 250, 250, 0.7)',
      outline:         '1px solid #FFF',
      borderRadius:    10
    }
  },
  title: {
    width:          '55%',
    height:         90,
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    textAlign:      'center',
    fontSize:       16,
    color:          '#FFF',
    transition:     'all 1s'
  },
  button: {
    width:           '100%',
    height:          75,
    margin:          1,
    display:         'flex',
    justifyContent:  'center',
    alignItems:      'center',
    backgroundColor: `rgba(250, 250, 250, 0.9)`,
    color:           colors.darkGrey,
    fontSize:        22,
    fontWeight:      'bold',
    borderRadius:    '0.5em',
    transition:      'all 0.3s',
    cursor:          'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
      color:           '#FFF'
    }
  }
}));

type DropDownProps = {
    size:                number[];
    profiles:            profileType[];
    setPage:             (val: string) => void;
    handleSelectProfile: (val: number) => void;
}

const DropDown: React.FC<DropDownProps> = ({
    size,
    profiles,
    setPage,
    handleSelectProfile,
 }) => {

  const classes = useStyles();


  const exitAnimation = (id: number) => {
    handleSelectProfile(id)
  }

  return (
    <div className = { classes.root }>
         <div className = { classes.menu }>
            {
               profiles.map((val) => {
                  return(
                    <div
                       key       = { `profile_${ val.id }` }
                       dir       = 'rtl'
                       className = { classes.button }
                       onClick   = { () => exitAnimation(val.id) }
                    >
                        { val.child_name }
                    </div>
                  )
              }) 
            }
        </div>
    </div>
  )
}

export default DropDown






