import React                                     from 'react';

import PieChart                                  from './PieChart';
//import Audio                                     from '../../components/audio_component';
import VideoComponent                            from './VideoComponent';
import CompLoader                                from '../../components/comp_loader';
import Result                                    from './Result';
import MagicWModal                               from './MagicModal';
import VideoModal                                from './VideoModal';
import Buttons                                   from './Buttons';
import TextSign                                  from './TextSign';
import ToolTip                                   from './ToolTip';
import Hidden                                    from './Hidden';

import { actionType, situationsObjType }         from '../../types';

import { makeStyles }                            from '@material-ui/core/styles';
import { words }                                 from '../../utils/dictionary';

const WhatsNextHeb  = 'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/whats_next.wav';
const MagicAudioHeb = 'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/magic_text.wav';
const WhatsNextEng  = 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/whats_next.mp3';
const MagicAudioEng = 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/magic_text.mp3';

const useStyles = makeStyles(theme => ({
  black: {
    backgroundColor: '#000',
    position:        'fixed',
    width:           '100vw',
    minHeight:       '100vh',
  },
  root: {
    position:           'fixed',
    top:                0,
    left:               0,
    width:              '100vw',
    height:             '100vh',
    backgroundPosition: 'center',
    backgroundRepeat:   'no-repeat',
    backgroundSize:     'cover',
    transition:         'opacity 1s'
  },
  filter: {
    position:        'fixed',
    top:             0,
    left:            0,
    width:           '100vw',
    height:          '100vh',
    backgroundColor: 'rgba(0,0,0,0.5)'
  }
}));

type ActionMenuProps = {
  size:                       number[];
  action:                     number;
  situationId:                number;
  actionMenuText:             string[];
  loadedVideos:               string[];
  now:                        string;
  lang:                       string;
  dir:                        string;
  name:                       string;
  userVideoSrc:               string;
  background:                 string;
  centerImage:                string;
  magicVideoSrc:              string;
  actionAudio:                string;
  selectedSituation:          string;
  emotionMenuAudio:           string;
  allSituations:              situationsObjType;
  actionArr:                  actionType[];
  showResult:                 boolean;
  showMagicWand:              boolean;
  showUserVideo:              boolean;
  satisfiedWithResult:        boolean;
  magicAction:                boolean;
  customVideo:                boolean;
  userStoryCreated:           boolean;
  objectURLCreated:           boolean;
  redoSituation:              boolean;
  audioEnd:                   boolean;
  playAudio:                  boolean;
  intructionsEnabled:         boolean;
  setLoadedVideos:            (val: string[]) => void;
  setFullStory:               (val: string[]) => void;
  setBackground:              (val: string) => void;
  setPage:                    (val: string) => void;
  setStation:                 (val: string) => void;
  setAudioSrc:                (val: string) => void;
  setAction:                  (val: number) => void;
  handleActionSelection:      (val: number) => void;
  setAudioEnd:                (val: boolean) => void;
  setMuteAudio:               (val: boolean) => void;
  setPlayAudio:               (val: boolean) => void;
  setPauseAudio:              (val: boolean) => void;
  setShowAudioButtons:        (val: boolean) => void;
  setShowMagicWand:           (val: boolean) => void;
  setShowUserVideo:           (val: boolean) => void;
  setCustomVideo:             (val: boolean) => void;
  setMagicAction:             (val: boolean) => void;
  setShowResult:              (val: boolean) => void;
  setShowNavBar:              (val: boolean) => void;
  setSatisfiedWithResult:     (val: boolean) => void;
  setActionArr:               (val: actionType[]) => void;
  setAllSituations:           (val: situationsObjType) => void;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
    now,
    name,
    lang,
    dir,
    situationId,
    userVideoSrc,
    loadedVideos,
    size,
    background,
    actionMenuText,
    centerImage,
    magicVideoSrc,  
    actionAudio,
    actionArr,
    action,
    customVideo,
    magicAction,
    showResult,
    showMagicWand,
    showUserVideo,
    satisfiedWithResult,
    userStoryCreated,
    objectURLCreated,
    redoSituation,
    selectedSituation,
    allSituations,
    audioEnd,
    playAudio,
    emotionMenuAudio,
    intructionsEnabled,
    setFullStory,
    setAudioEnd,
    setMuteAudio,
    setAudioSrc,
    setPlayAudio,
    setPauseAudio,
    setShowAudioButtons,
    setLoadedVideos,
    setAllSituations,
    setShowMagicWand,
    setShowUserVideo,
    setBackground,
    setPage,
    setAction,
    setCustomVideo,
    setMagicAction,
    setActionArr,
    setShowResult,
    setStation,
    setShowNavBar,
    setSatisfiedWithResult,
    handleActionSelection,
}) => {

  const classes = useStyles();

  const [playVideo, setPlayVideo]                   = React.useState(false)
  const [pauseVideo, setPauseVideo]                 = React.useState(false);
  const [videoSrc, setVideoSrc]                     = React.useState('');
  const [downloadComplete, setDownloadComplete]     = React.useState(false);

  const [showVideoLoader, setShowVideoLoader]       = React.useState(false);
  const [showMagicModal, setShowMagicModal]         = React.useState(false)
  const [opacity, setOpacity]                       = React.useState(false);

  const [showUserVideoModal, setShowUserVideoModal] = React.useState(false);
  const [showTextSign, setShowTextSign]             = React.useState(true);
  const [showToolTip, setShowToolTip]               = React.useState(false);
  const [toolTipText, setToolTipText]               = React.useState('');
  const [showFractions, setShowFractions]           = React.useState(false);
  const [spin, setSpin]                             = React.useState(0);
  const [initialPos, setInitialPos]                 = React.useState(false);
  const [buttonPos, setButtonPos]                   = React.useState(false)

  const [hiddenLoaded, setHiddenLoaded]             = React.useState(false);
  const [doOnce, setDoOnce]                         = React.useState(false);
  const [doOnce2, setDoOnce2]                       = React.useState(false);


  React.useEffect(() => {
    if (!doOnce2) {
      setDoOnce2(true)
      let counter = 0;
      for(let i = 0; i < actionArr.length; i++) {

        if (actionArr[i].viewed) { counter++ }
     
        if (counter >= actionArr.length - 1) {
          setShowUserVideo(true);
        }
      } 
    }
    
  },[doOnce2, actionArr, setDoOnce2, setShowUserVideo])
  

  const [WhatsNext, MagicAudio] = React.useMemo(() => {
    return[
      lang === 'eng' ? WhatsNextEng : WhatsNextHeb,
      lang === 'eng' ? MagicAudioEng : MagicAudioHeb
    ]
  },[lang])

  React.useEffect(() => {
      if (hiddenLoaded) {
        if (doOnce) {
          return
        }
        setTimeout(() => { 
          setOpacity(true);
          setInitialPos(true);
        }, 20);
        setTimeout(() => { 
          setPauseAudio(false)
        }, 850);
      }      
  },[hiddenLoaded, doOnce, setDoOnce, setPauseAudio, setOpacity, setInitialPos]);
  
 
  React.useEffect(() => {
    if (audioEnd || !intructionsEnabled) {
      setPlayAudio(false);
      setPauseAudio(true);
    }
    if (audioEnd) {
       setAudioEnd(false);
       if (showToolTip) {
          setShowToolTip(false)
       }
       if (!showResult) {
         setAudioSrc(WhatsNext)
       }
       console.log('audioEnd')
    }
    
  },[audioEnd, showResult, showToolTip, WhatsNext, intructionsEnabled, setPlayAudio, setPauseAudio, setShowToolTip, setAudioSrc, setAudioEnd]
  )
 

  React.useEffect(() => {
     if (showToolTip) {
       setTimeout(() => {
          setShowToolTip(false)
       },4000)
     }
  },[showToolTip, setShowToolTip])


  const circleSize = React.useMemo(() => {
    return( 
      showFractions
      ?
      size[0] > 600 ? (Math.min((size[0] - 400), (size[1] - 100))) : (Math.min((size[0] - 200), (size[1] - 200)))
      :
      Math.min((size[0]/10 * 4.8), (size[1]/10*9)));
  },[size, showFractions]) 


  const orientation = React.useMemo(() => {
    return (size[0] < 800 && size[0] < size[1]);
  },[size])


  const text = React.useMemo(() => {
    return satisfiedWithResult ? `${ words[actionMenuText[0]][lang] }` : `${ words[actionMenuText[1]][lang] }`;
  },[satisfiedWithResult, actionMenuText, lang])



  React.useEffect(() => {
    if (playVideo && !downloadComplete) {
      setShowVideoLoader(true)
    }
    else {
      setShowVideoLoader(false)
      if (videoSrc.length && !loadedVideos.includes(videoSrc)) {
        const arr = loadedVideos.slice();
        arr.push(videoSrc);
        setLoadedVideos(arr)
      } 
    }
  },[playVideo, videoSrc, downloadComplete, loadedVideos, setLoadedVideos, setShowVideoLoader])




   const handleTextSignClick = () => {
      setShowTextSign(false)
      setSpin(1)
      setTimeout(() => {
        setShowFractions(true)
      },650)
   }

  const goToVideoUpload = () => {
    setOpacity(false);
    setInitialPos(false);
    setTimeout(() => { 
      setPage('video_upload')
    }, 800);
  }


  const ActionClick = (ind: number) => {

      if (showTextSign) { return }
      if (showMagicModal) { setShowMagicModal(false); }
      if (ind === -1)  { return }

      setMagicAction(false);

      const fullStoryHeb = [allSituations[selectedSituation].video,    actionArr[ind].actionVideo,    actionArr[ind].resultVideo];
      const fullStoryEng = [allSituations[selectedSituation].videoEng, actionArr[ind].actionVideoEng, actionArr[ind].resultVideoEng];

      setFullStory((lang === 'eng') ? fullStoryEng : fullStoryHeb)

      if (!showResult) {

          if (actionArr[ind].custom) {
                  setAction(ind)
                  if (magicAction) { 
                    setMagicAction(false) 
                  }
                  setShowUserVideoModal(true)
                  setInitialPos(false)
                  if (!showUserVideo && !redoSituation) {
                    setTimeout(() => {
                      setShowUserVideoModal(false)
                      setInitialPos(true)
                    },3500)
                  }
                  return;
          } 
        //  if ( !redoSituation && actionArr[ind].viewed ) { return }

          const actionVid = lang === 'eng' ? actionArr[ind].actionVideoEng : actionArr[ind].actionVideo;

          if (loadedVideos.includes(actionVid)) {
            setDownloadComplete(true)
          }

          setVideoSrc(actionVid)
          setBackground(actionArr[ind].actionBground)
          setAction(ind)
          setActionArr(actionArr.map((val, i) => i === ind ? { ...val, viewed: true } : val)) 
      }

      if (showResult) {
        setBackground(actionArr[ind].resultImg)
        if (actionArr[ind].custom && !userStoryCreated) {
            goToVideoUpload()
            return;
        }

        if (!redoSituation && !actionArr[ind].viewed) {
  
          setActionArr(actionArr.map((val, i) => i === ind ? { ...val, resultViewed: true } : val))
        }  

        const resultVid = lang === 'eng' ? actionArr[ind].resultVideoEng : actionArr[ind].resultVideo

        if (loadedVideos.includes(resultVid)) {
          setDownloadComplete(true)
        }

        setVideoSrc(resultVid)

      }

      handleActionSelection(ind);
      handlePlay()

  }

  const goToMagicViedo = () => {
    const situation = allSituations[selectedSituation];
    const magicVid = lang === 'eng' ? situation.magicVideoSrcEng: situation.magicVideoSrc
    setMagicAction(true);
    setVideoSrc(magicVid)
    if (loadedVideos.includes(magicVid)) {
      setDownloadComplete(true)
    }
 /*    if (situation.magicDownloaded === true) {
      setDownloadComplete(true)
    } */
    handlePlay()
  }


  const handlePlay = () => {
    setButtonPos(false);
    setShowNavBar(false);
    setTimeout(() => {
      if (pauseVideo) { setPauseVideo(false) }
      setPlayVideo(true);
    },500)
  }

  const handleRePlay = () => {
    setDownloadComplete(true)
    handlePlay();
  }

  const handleEnd = () => {
    setPlayVideo(false);
    setDownloadComplete(false)
    if (!showResult) {
      actionArr[action].downloaded = true
    }
    if (showResult) {
      actionArr[action].resultDownloaded = true
    }
    if (magicAction) {
      const situations = allSituations;
      situations[selectedSituation].magicDownloaded = true;
      setAllSituations(situations)
    } 
    setTimeout(() => {
      setButtonPos(true)
    },100)
  }

  const handleContinue = () => {
      setButtonPos(false);
      setTimeout(() => {
            if ( magicAction || customVideo) {
              setShowNavBar(true)
              return;
            }
            if (!showResult) {
                setStation('result');
                setShowResult(true);
                setShowAudioButtons(true)
            }
            else {
                let counter = 0;

                for(let i = 0; i < actionArr.length; i++) {
                  console.log('counter  :  ',counter)

                  if (actionArr[i].viewed) { counter++ }
                  if (counter >= 2) {
                    setShowMagicWand(true);
                  }
                  if (counter >= actionArr.length - 1) {
                    setShowUserVideo(true);
                  }
                } 
                setPage('emotion_menu');
                setStation('second_emotion_menu'); 
            }
            setShowNavBar(true)
          //  setShowButtons(false)
      },500)
  }

  const playMagicAudio = () => {

    if (playAudio) { return }
    setAudioSrc(MagicAudio)
    setPauseAudio(false)
    setTimeout(() => {
      setPlayAudio(true)
    },10)
}


const MagicWandClick = () => {
 // if (openVideoModal) { setOpenVideoModal(false); }
    if (!intructionsEnabled && showMagicWand) {
      goToMagicViedo()
    }
    else {
      setShowAudioButtons(true)
      setShowMagicModal(true)
      setInitialPos(false)
      playMagicAudio()
    }
 };


 
return (
  <div className = { classes.black }>
        <div
          className = { classes.root }
          style     = {{
            backgroundImage: `url(${background})`,
            opacity:         opacity ? 1 : 0
          }}
        >
          <div className = { classes.filter }>
          { !hiddenLoaded && <CompLoader variant = { 'page' } lang = { lang }/> } 
          { hiddenLoaded && 
            <>
                  {
                    (!showResult)
                    &&
                    (
                      <PieChart
                          size            = { size }
                          name            = { name }
                          lang            = { lang }
                          spin            = { spin }
                          hide            = { buttonPos || playVideo}
                          userVideoSrc    = { userVideoSrc }
                          centerImage     = { centerImage }
                          circleSize      = { circleSize }
                          showMagicWand   = { showMagicWand }
                          redoSituation   = { redoSituation }
                          actionArr       = { actionArr }
                          orientation     = { orientation }
                          showFractions   = { showFractions }
                          initialPos      = { initialPos }
                          ActionClick     = { ActionClick }
                          playMagicAudio  = { playMagicAudio }
                          MagicWandClick  = { MagicWandClick }
                          setShowToolTip  = { setShowToolTip }
                          setToolTipText  = { setToolTipText }
                      />
                    )
                  }
                  {
                    (showResult)
                    &&
                    (
                    <Result
                        size                = { size }
                        lang                = { lang }
                        dir                 = { dir }
                        background          = { background }
                        myInd               = { action }
                        playAudio           = { playAudio }
                        emotionMenuAudio    = { emotionMenuAudio }
                        intructionsEnabled  = { intructionsEnabled }
                        setAudioSrc         = { setAudioSrc }
                        ActionClick         = { ActionClick }
                        setPauseAudio       = { setPauseAudio }
                        setPlayAudio        = { setPlayAudio }
                        setShowAudioButtons = { setShowAudioButtons }
                    />
                    )
                  }
                  {
                    ((showVideoLoader)
                    ||
                    (!objectURLCreated && playVideo))
                    &&
                    <CompLoader variant = 'video' lang = { lang }/>
                  }
                  <VideoComponent
                      size                  = { size }
                      name                  = { name }
                      playVideo             = { playVideo }
                      pauseVideo            = { pauseVideo }
                      videoSrc              = { videoSrc } 
                      downloadComplete      = { downloadComplete }
                      setDownloadComplete   = { setDownloadComplete }
                      setPauseVideo         = { setPauseVideo }
                      handleEndVideo        = { handleEnd }
                  />
                  <MagicWModal
                    size              = { size }
                    lang              = { lang }
                    dir               = { dir }
                    showMagicWand     = { showMagicWand }
                    showMagicModal    = { showMagicModal }
                    playAudio         = { playAudio }
                    setPlayAudio      = { setPlayAudio }
                    setAudioEnd       = { setAudioEnd }
                    goToMagicViedo    = { goToMagicViedo }
                    setShowMagicModal = { setShowMagicModal }
                    setInitialPos     = { setInitialPos }
                  />
                  <VideoModal
                    size                   = { size }
                    lang                   = { lang }
                    dir                    = { dir }
                    showUserVideo          = { showUserVideo }
                    showUserVideoModal     = { showUserVideoModal }
                    userStoryCreated       = { userStoryCreated }
                    redoSituation          = { redoSituation }
                    actionArr              = { actionArr }
                    setVideoSrc            = { setVideoSrc }
                    goToVideoUpload        = { goToVideoUpload }
                    setShowUserVideoModal  = { setShowUserVideoModal }
                    handlePlay             = { handlePlay }
                  />
                  <Buttons
                      size           = { size }
                      lang           = { lang }
                      dir            = { dir }
                      buttonPos      = { buttonPos }
                      handleRePlay   = { handleRePlay }
                      handleContinue = { handleContinue }
                  />
                  {
                    !showResult
                    &&
                    <TextSign
                      size                = { size }
                      text                = { text }
                      initialPos          = { initialPos }
                      showTextSign        = { showTextSign }
                      handleTextSignClick = { handleTextSignClick }
                    />
                  }
                  {
                    !playVideo && !showMagicModal
                    &&
                    <ToolTip
                      size           = { size }
                      dir            = { dir }
                      showToolTip    = { showToolTip }
                      toolTipText    = { toolTipText }
                      setShowToolTip = { setShowToolTip }
                    />
                  }
            </>
          }
          </div>
        </div>
        <Hidden
           images          = { [background, centerImage] }
           audio           = { [actionAudio, WhatsNext, MagicAudio] }
           actionArr       = { actionArr }
           setHiddenLoaded = { setHiddenLoaded }
        />   
    </div>
   )
}


export default ActionMenu


