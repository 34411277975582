import React                                from 'react';

import { connect }                          from 'react-redux';
import { Dispatch }                         from "redux";
import { ActionTypes, QuestionnaireValues } from '../../types/actions';
import { profileType }                      from '../../types';
import { reducerType }                      from '../../reducer';
import { SUBMIT_QUESTIONNAIRE_SAGA }        from '../../actions';

import Loader                               from '../../components/Loader';
//import Form0                                from './Form0';
import Form1                                from './Form1';
import Form2                                from './Form2';
import Form3                                from './Form3';

import { words }                            from '../../utils/dictionary';

import background                           from '../../graphic_components/main_bgrnd.png';

import colors                               from '../../utils/colors';
import { makeStyles }                       from '@material-ui/core/styles';

var clsx = require('classnames');

const useStyles = makeStyles(theme => ({
    parent: {
      position: 'relative'
    },
    root: {
        position:           'fixed',
        top:                0,
        left:               0,
        overflowY:          'auto',
        overflowX:          'hidden',
        width:              '100vw',
        height:             '100vh',
        backgroundPosition: 'center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:     'cover'
      },
      container: {
        position:        'absolute',
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
        width:           '100vw',
        flexFlow:        'column',
        minHeight:       '100vh',
        transition:      'all 1s'
      },
    progression: {
       position:   'fixed',
       top:        0,
       left:       0,
       width:      '100vw',
       height:     3,
       display:    'flex',
    },
    blue: {
      backgroundColor: colors.blue_,
      transition:      'all 0.7s'
    },
    white: {
        backgroundColor: '#FFF',
        transition:      'all 0.7s'
     },
    h2: {
       marginTop:    25,
       marginBottom: 20,
       fontSize:     30,
       color:        '#555'
    },
    buttonscontainer: {
        display:        'flex',
        flexFlow:       'column',
        justifyContent: 'flex-start',
        alignItems:     'center'
    },
    buttons: {
        display:    'flex',
        transition: 'all 1.5s'
    },
    button: {
        color:           '#FFF',
        fontWeight:      'bold',
        textAlign:       'center',
        marginBottom:    45,
        marginLeft:      10,
        marginRight:     10,
        width:           100,
        padding:         20,
        cursor:          'pointer',
        boxShadow:       `0 3px 7px rgba(0, 0, 0, 0.7)`,
        borderRadius:    '1em',
        transition:      'all 0.4s',
        '&:hover': {
          width: 130
        }
    },
    submit: {
      backgroundColor: colors.blue_,
    },
    go_back: {
        backgroundColor: colors.pink,

    },
    error: {
        color: '#F00'
    },
    loader_container: {
        width:  40,
        height: 40
     }
}));

type QuestionnaireProps = {
  dir:                 string;
  lang:                string;
  parentId:            string;
  profiles:            profileType[];
  size:                number[];
  signup:              boolean;
  setPage:             (val: string) => void;
  submitQuestionnaire: (payload: QuestionnaireValues) => void;
}

const Questionnaire: React.FC<QuestionnaireProps> = ({
  parentId,
  dir,
  lang,
  size,
  profiles,
  signup,
  setPage,
  submitQuestionnaire,

}) => {

  const classes = useStyles();

  const [submitting, setSubmitting]                 = React.useState(false);
  const [form, setForm]                             = React.useState(1);
  const [error, setError]                           = React.useState('');
  
  const [childName, setChildName]                   = React.useState('');
  const [childAge, setChildAge]                     = React.useState(-1);
  const [childGender, setChildGender]               = React.useState('female');
  const [siblingNum, setSiblingNum]                 = React.useState(-1);
  const [ordinalPosition, setOrdinalPosition]       = React.useState(-1);
  const [city, setCity]                             = React.useState('');
  const [emotionalVocab, setEmotionalVocab]         = React.useState('');
  const [introExtro, setIntroExtro]                 = React.useState(3);
  const [gratificationDelay, setGratificationDelay] = React.useState(3);
  const [violentBehavior, setViolentBehavior]       = React.useState(3);
  const [parentOcupation, setParentOcupation]       = React.useState('');

  const [initialPos, setInitialPos]                 = React.useState(false);
  const [buttonPos, setButtonPos]                   = React.useState(false);
  const [positionForm1, setPositionForm1]           = React.useState(false);
  const [positionForm2, setPositionForm2]           = React.useState(false);
  const [positionForm3, setPositionForm3]           = React.useState(false);

  React.useEffect(() => {
     setTimeout(() => {
       setInitialPos(true)
       setButtonPos(true) 
       setPositionForm1(true)
     },10)
  },[setInitialPos])


  const handleSubmit = () => {

      if (form === 0) { setForm(form + 1) }

      if (form === 1) {

            for (let i = 0; i < profiles.length; i++) {
               if (profiles[i].child_name === childName) {
                    setError(`${ words['name_taken_err1'][lang] }${ childName }${ words['name_taken_err2'][lang] }`);
                    return;
               }
            }
            if (!childName.length)      { setError(`${ words['no_name_err'][lang] }`); return }
            if (childAge === -1)        { setError(`${ words['no_age_err'][lang] }`); return }
            if (siblingNum === -1)      { setError(`${ words['no_sib_num_err'][lang] }`); return }
            if (isNaN(siblingNum))      { setError(`${ words['sib_num_NaN'][lang] }`); return }
            if (ordinalPosition === -1) { setError(`${ words['no_ordinal_pos_err'][lang] }`); return }
            if (isNaN(ordinalPosition)) { setError(`${ words['ordinal_pos_NaN'][lang] }`); return }
            if (!city.length)           { setError(`${ words['no_city_err'][lang] }`); return }
            setError('')
            setPositionForm1(false)
            setButtonPos(false)
            setTimeout(() => { setForm(2) },700)    
            setTimeout(() => {
              setPositionForm2(true);
              setButtonPos(true)
            },810)    
      }
    
      if (form === 2) {
            if (!emotionalVocab.length) { setError(`${ words['questionnaire_f_2_err'][lang] }`); return }
            setError('')
            setPositionForm2(false)
            setButtonPos(false)
            setTimeout(() => { setForm(3) },700)    
            setTimeout(() => {
              setPositionForm3(true)
              setButtonPos(true)
            },810)   
      }

      if (form === 3) {

        const payload = {
          parent_id:           parentId,
          child_name:          childName,
          child_age:           childAge,
          child_gender:        childGender,
          sibling_num:         siblingNum,
          ordinal_position:    ordinalPosition,
          city:                city,
          emotional_vocab:     emotionalVocab,
          intro_extro:         introExtro,
          gratification_delay: gratificationDelay,
          violent_behavior:    violentBehavior,
          parent_ocupation:    parentOcupation
        };

        submitQuestionnaire(payload)
        setSubmitting(true)//true
        
        setPositionForm3(false)
        setButtonPos(false)
        setTimeout(() => {
          if (signup) {
            setPage('intro')
          }
          else {
            setPage('situation_menu');
          }
        },810)   
      }
  }

  const handleGoBack = () => {
    if (form === 1) {
      setInitialPos(false)
      setPositionForm1(false)
      setButtonPos(false)
      setTimeout(() => {
        setPage('profile_menu')
      },800)    
    }
    if (form === 2) {
      setPositionForm2(false)
      setButtonPos(false)
      setTimeout(() => { setForm(1) },700)    
      setTimeout(() => {
        setPositionForm1(true);
        setButtonPos(true)
      },810)    
    }
    if (form === 3) {
      setPositionForm3(false)
      setButtonPos(false)
      setTimeout(() => { setForm(2) },700)    
      setTimeout(() => {
        setPositionForm2(true);
        setButtonPos(true)
      },810)    
    }

  }
  
  return (
    <div 
      className = { classes.root }
      style     = {{
        backgroundImage: `url(${ background })`
      }}
    >
      <div 
        className = { classes.container } 
        dir       = 'rtl'
        style     = {{
          backgroundColor: initialPos ? 'rgba(250, 250, 250, 0.92)' : colors.halavi
        }}
      >
        {
        ((submitting) && (<div className = { classes.loader_container }><Loader color = { 'primary' }/></div>))
        ||
        (
          <>
            <div className = { classes.progression }>
                <div className = { classes.white } style = {{ flex: 3-form }}/>
                <div className = { classes.blue }  style = {{ flex: form }}/>
            </div>
           {/*  {
              (form === 0) && (<Form0 size = { size }/>)
            } */}
            {
              (form === 1)
              &&
              (
                <Form1
                    size               = { size }
                    lang               = { lang }
                    dir                = { dir }
                    initialPos         = { positionForm1 }
                    childName          = { childName }
                    childAge           = { childAge }
                    childGender        = { childGender }
                    siblingNum         = { siblingNum }
                    city               = { city }
                    parentOcupation    = { parentOcupation }
                    ordinalPosition    = { ordinalPosition }
                    setChildGender     = { setChildGender }
                    setChildName       = { setChildName }
                    setSiblingNum      = { setSiblingNum }
                    setOrdinalPosition = { setOrdinalPosition }
                    setCity            = { setCity }
                    setChildAge        = { setChildAge }
                    setParentOcupation = { setParentOcupation }
                />
              )
            }
            {
              (form === 2)
              &&
              (
                <Form2
                    size              = { size }
                    lang              = { lang }
                    dir               = { dir }
                    initialPos        = { positionForm2 }
                    emotionalVocab    = { emotionalVocab }
                    setEmotionalVocab = { setEmotionalVocab }
                />
              )
            }
            {
              (form === 3)
              &&
              (
                <Form3
                    size                  = { size }
                    lang                  = { lang }
                    dir                   = { dir }
                    initialPos            = { positionForm3 }
                    setIntroExtro         = { setIntroExtro }
                    setGratificationDelay = { setGratificationDelay }
                    setViolentBehavior    = { setViolentBehavior }
                />
              )
            }
            <div className = { classes.buttonscontainer }>
                <p className = { classes.error } dir = { dir }>{ error }</p>
                <div 
                   className = { classes.buttons }
                   style     = {{
                      transform: `translate(0px, ${ buttonPos ? 0 : 100 }vh)`
                   }}
                > 
                    <div 
                      className = { clsx(classes.button, classes.submit) }
                      onClick = { handleSubmit } 
                    >
                      { form === 3 ? `${ words['submit'][lang] }` : `${ words['continue'][lang] }` }
                    </div> 
                      <div 
                          className = { clsx(classes.button, classes.go_back) }
                          onClick   = { handleGoBack } 
                      >
                         { `${ words['return'][lang] }` }
                      </div>
                </div>
            </div>
         </>
        )
        }
      </div>
    </div>
    )
  
}



const mapStateToProps = (reducer: reducerType) => {
  return {
    parentId: reducer.userId,
    profiles: reducer.profiles
  }
};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  submitQuestionnaire: (payload: QuestionnaireValues ) => dispatch(SUBMIT_QUESTIONNAIRE_SAGA(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
















  //TestSaga: (testing: number, yes_no: boolean) => dispatch({ type: 'TEST_SAGA', values: { testing, yes_no } })
