import React from 'react';

type EmojiAudioProps = { 
  audioSrc:       string;
  playAudio:      boolean;
  pauseAudio:     boolean;
  handleEndAudio: () => void;
}

const EmojiAudio: React.FC<EmojiAudioProps> = ({ 
    audioSrc, 
    playAudio, 
    pauseAudio,
    handleEndAudio 
}) => {
 
const [src, setSrc]     = React.useState(audioSrc)
const [play, setPlay]   = React.useState(false)

const AudioRef = React.useRef<HTMLAudioElement>(null);


React.useEffect(() => {
    if (playAudio && !play) {
        setSrc(audioSrc)
        setTimeout(() => { 
            setPlay(true) 
        },10)
    } 
    
}, [playAudio, audioSrc, play, pauseAudio, setSrc, setPlay])


React.useEffect(() => {
    if (!playAudio) {
        setPlay(false)
    }
},[playAudio, setPlay])



if (AudioRef && AudioRef.current && audioSrc.length && play && !pauseAudio) {
  AudioRef.current.play(); 
}


return (
     <audio  
           playsInline
           webkit-playsinline = "true" 
           preload            = "auto"
           onEnded            = { () => { handleEndAudio() } }
           ref                = { AudioRef }
           src                = { src } 
     />
  )
}

export default EmojiAudio









