import React          from 'react';
import colors         from '../../utils/colors';
import { makeStyles } from '@material-ui/core/styles';
import Slider         from '@material-ui/core/Slider';
import { words }      from '../../utils/dictionary';


const useStyles = makeStyles({
  root: {
    width:          '100%',
    display:        'flex',
    justifyContent: 'center',
    paddingTop:     10,
    paddingBottom:  10,
  },
  container: {
    width:          '90%',
    display:        'flex',
    justifyContent: 'space-around',
  },
  slider: {
    width: '40%',
  },
  slider_text: {
    width:      '30%' ,
    display:    'flex',
    alignItems: 'center',
    color:       colors.darkGrey,
    fontSize:   18,
  },
  right: {
    paddingRight: 50,
  },
  left:{
    paddingLeft: 50,
  }
});



const marks = new Array(6).fill(0).map((val, ind) => { return({ value: ind+1, label: `${ind+1}` }) });

type SliderCompProps = {
  num:      number;
  size:     number[];
  lang:     string;
  dir:      string;
  setState: (val: number) => void;
}
  
const SliderComp: React.FC<SliderCompProps> = ({
   num,
   size,
   lang,
   dir,
   setState,
  }) => {

  const classes = useStyles();

  const titleArr = [
        [`${ words['introversion'][lang] }`,        `${ words['extroversion'][lang] }`],
        [`${ words['gratification_delay'][lang] }`, `${ words['no_gratification_delay'][lang] }`],
        [`${ words['non_violent'][lang] }`,         `${ words['violent'][lang] }`]
  ];

  const handleChange = (event: any, newValue: number | number[]) => {
    setState(newValue as number);
  };

  return (
    <div className = { classes.root }>
        <div className = { classes.container }>
            <div 
              className = { classes.slider_text } 
              style = {{
                justifyContent: size[0] > 900 ? 'flex-end' : 'center',
                paddingLeft:    size[0] > 900 ? 50 : 30
              }}
            >
              { titleArr[num][0] }
            </div>
            <Slider
                className         = { classes.slider }
                defaultValue      = { 3 }
                valueLabelDisplay = "auto"
                step              = { 1 }
                marks             = { marks }
                min               = { 1 }
                max               = { 6 }
                onChange          = { handleChange }
            />
            <div 
              className = { classes.slider_text }
              style     = {{
                 justifyContent: size[0] > 900 ? 'flex-start' : 'center',
                 paddingRight:   size[0] > 900 ? 50 : 30
              }}
            >
              { titleArr[num][1] }
            </div>
        </div>
    </div>
  );
    

}

export default SliderComp