import React              from 'react';
import { makeStyles }     from '@material-ui/core/styles';
import Loader             from '../Loader';
import { words }          from '../../utils/dictionary';


const useStyles = makeStyles(theme => ({
 
    root: {
       position:        'fixed',
       top:             0,
       left:            0,
       height:          '100vh',
       width:           '100vw',
       display:         'flex',
       justifyContent:  'center',
       alignItems:      'center',
       flexFlow:        'column',
       backgroundColor: 'rgba(0,0,0,0.35)',
       transition:      'all 1.5s',
    },
    text: {
      width:          '100%',
      textAlign:      'center',  
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      color:          '#FFF',
      fontWeight:     'bold',
      padding:        10,
      fontSize:       27
    }
}));

type VidLoaderProps = {
   lang:    string;
   variant: string;
}

const VidLoader: React.FC<VidLoaderProps> = ({ lang, variant }) => {

  const classes = useStyles();

  return (
      <div className = { classes.root }>
          <div className = { classes.text }>
            { variant === 'page' ? `${ words['loading_page'][lang]}` : variant === 'video' ? `${ words['loading_video'][lang]}` : '7:00pm' }
          </div>
          <Loader color = { 'secondary' }/>
      </div>
  )
}

export default VidLoader
