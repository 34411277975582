import React           from 'react';
import { makeStyles }  from '@material-ui/core/styles';
import magic_wand      from '../../graphic_components/wand_.png';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import colors          from '../../utils/colors'

//var clsx = require('classnames')

const arr = new Array(50).fill([0,0]).map(() => {
   return(
     [Math.random() * 80, Math.random() * -80 + 40]
   )
})

const useStyles = makeStyles(theme => ({
  container: {
    flex:           1,
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    flexFlow:       'column',
    color:          '#FFF',
    padding:        20,
    transition:     'transform 0.5s',
  },
  text: {
    position:      'fixed',
    top:           '10vh',
    left:          '50vw',
    width:         '60vw',
    maxWidth:      400,
    borderRadius:  '1em',
    backgroundColor:colors.pink_,
    color:         '#FFF',
    fontWeight:    'bold',
    fontSize:      29,
    textShadow:    colors.textShadow,
    textAlign:     'center',
    paddingBottom: 30,
    zIndex:        1
  },
  image: {
    maxHeight:           80,
    minHeight:           80,
    backgroundPosition: 'center',
    backgroundRepeat:   'no-repeat',
    backgroundSize:     'contain',
    transition:         'all 0.4s',
    animation:          'float 10s linear infinite',
  },
  magic: {
    position:     'absolute',
    marginTop:    0,
    marginLeft:   0,
    width:        85, 
    height:       85,
  },
  star: {
     position: 'absolute',
     width:   10,
     height:  10,
     color:   '#FFF',
  },
  spin: {
    marginTop: 42.5,
    animation: 'spin 7s linear infinite'
  },
  puls: {
    borderRadius:   '50%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
  }, 
  nothing: {

  }
}));

type MagicWandProps = {
    size:             number[];
    showMagicWand:    boolean;
    bool:             boolean;
    orientation:      boolean;
    showFractions:    boolean;
    MagicWandClick:   () => void;
    playMagicAudio:   () => void;
    setShowToolTip:   (val: boolean) => void;
}


const MagicWand: React.FC<MagicWandProps> = ({
  size, 
  showMagicWand,
  bool, 
  orientation, 
  showFractions, 
  MagicWandClick,
  playMagicAudio,
  setShowToolTip
}) => {

  const classes = useStyles();


  const [imageWidth, bigImage] = React.useMemo(() => {
    const IW = orientation ? 100 : (size[0] > 1400 ? 200 : 100);
      return [IW, IW === 200]
  },[size, orientation])

  return (
      <div
          className    = { classes.container }
      /*     onMouseEnter = { handleShowText }
          onTouchStart = { handleShowText }
          onMouseLeave = { handleDontShowText }
          onTouchEnd   = { handleDontShowText }
          onTouchCancel= { handleDontShowText } */
          onClick      = {
              () => {
                if ((orientation && !bool) || (!orientation && bool)) { return };
                MagicWandClick()
              }
          }
          style = {{
            transform: `translate(0px, ${ !showFractions ? 150 : 0 }vh)`,
          }}
      >
          <div 
             className = { (orientation !== bool) ? classes.nothing : classes.puls }
             style     = {{
              height:    imageWidth + 50,
              width:     imageWidth + 70,
              animation: orientation === bool && showMagicWand ? 'puls2 1s infinite' : 'none'
              
            }}
          >
              <div 
                className = { classes.image }
                style = {{
                    width:           imageWidth,
                    backgroundImage: (orientation !== bool) ? 'none' : `url(${magic_wand})`,
                    cursor:          (orientation !== bool) ? 'auto' : 'pointer',
                }}
                
            >
              {
                  orientation === bool
                  &&
                  <div 
                      className = { classes.magic }
                      style     = {{
                        transform:  `translate(${ bigImage ? -10 : -40 }px, ${ bigImage ? 30 : 20 }px)`
                      }}
                  >
                      <div className = { classes.spin }>
                          {
                              arr.map((val, ind) => {
                                  return(  
                                      <StarRoundedIcon
                                          key       = { `start${ ind }` }
                                          className = { classes.star }
                                          style     = {{
                                              left:      val[0],
                                              top:       val[1],
                                              animation: `twinckle ${ind < 5 ? (ind+1) : ind / 3}s  infinite`
                                          }}
                                      />
                                  )
                              })
                          }
                      </div>
                  </div>
              }
            </div>
          </div>
      </div>
  )
}

export default MagicWand
