import React          from 'react';
import colors             from '../../utils/colors'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    flex: {
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',      
      flexFlow:       'column'    
    },
    root: {
      position:        'fixed',
      height:          '25vh',
      bottom:          '1vh',
      display:         'flex',
      justifyContent:  'space-around',
      alignItems:      'center',   
      backgroundColor: colors.kehe,
      border:          `1px solid ${ colors.halavi }`,
      borderRadius:    '1em',
      flexWrap:        'wrap',   
      zIndex:          12
    },
    emoji_container: {
      backgroundPosition: 'center',
      backgroundRepeat:   'no-repeat',
      backgroundSize:     'contain',
      display:            'flex',
      justifyContent:     'center',
      alignItems:         'center',
    },
    text: {
      color:     '#FFF',
      fontWeight:'bold'
    }
}));

type EmojisProps = {
   size:             number[];
   showEmojis:       boolean;
   showButtons:      boolean;
   showResult:       boolean;
   mouseDown:        boolean;
   showTextSign:     boolean;
   imoj:             number;
   ghostName:        string;
   handleMouseDown:  (e: React.MouseEvent) => void;
   handleTouchStart: (e: React.TouchEvent) => void;
   emojis:           { name: string, src: string, audio: string; received: boolean, accept: boolean }[];
}

const Emojis: React.FC<EmojisProps> = ({
    size,
    showEmojis, 
    showButtons,
    showResult,
    imoj,
    ghostName,
    showTextSign,
    mouseDown,
    emojis,
    handleMouseDown,
    handleTouchStart,
  
  }) => {

  const classes = useStyles();

  return (
    <div 
       className = { classes.root }
       style     = {{
        width:      size[0] > 600 ? '97vw' : '100vw',
        left:       size[0] > 600 ? '1.5vw' : 0,
        opacity:    showResult && showButtons ? 0.5 : 1,
        transform:  `translate(0px, ${ showEmojis ? 0 : 100 }vh)`,
        transition: 'transform 2s, opacity 0.5s',
      }}
    >
             {
                emojis.map((val, ind) => {

                  return(
                    <div
                       key       = { val.src }
                       className = { classes.flex }
                       style     = {{
                          opacity: (val.received || (mouseDown && ghostName === val.name)) ? 0 : 1,
                       }}
                    >
                        <div 
                          id           = {`${val.src}:::${val.name}`}
                          translate    = { val.accept ? 'yes' : 'no' }
                          lang         = { val.audio }
                          className    = { classes.emoji_container }
                          onMouseDown  = { e => { if(!val.received) { handleMouseDown(e) }}}
                          onTouchStart = { e => { if(!val.received) { handleTouchStart(e) }}}
                          style        = {{ 
                              width:           imoj,
                              height:          imoj,
                              cursor:          val.received ? 'default' : 'pointer',
                              backgroundImage: `url(${val.src})`
                          }} 
                        />
                        <div 
                          dir       = 'rtl'
                          className = { classes.text }
                        >
                          { val.name }
                        </div>
                    </div>
                  ) 
                      
                })
              }
     </div>
  )
}

export default Emojis





