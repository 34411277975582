import React              from 'react';
import TextComp           from './TextComp';
import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';
import logo               from '../../graphic_components/logo.png';
import colors             from '../../utils/colors'
import { makeStyles }     from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width:           '100vw',
    height:          '100vh',
    display:         'flex',
    flexFlow:        'column',
    justifyContent:  'center',
    alignItems:      'center',
    backgroundColor: 'transparent',
    transition:      'all 1s'
  },
  logo: {
    marginBottom:       100,
    height:             '20vh',
    width:              '50vw',
    backgroundSize:     'contain',
    backgroundPosition: 'center',
    backgroundRepeat:   'no-repeat',
  },
  title: {
    color:   '#FFF',
    fontSize: 150
  },
  button: {
    width:          200,
    position:       'fixed',
    left:           '50%',
    transform:      `translate(-50%, 0%)`,
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    border:         `1px solid #FFF`,
    borderRadius:   '0.5em',
    cursor:         'pointer',
    transition:     'width 0.4s, transform 0.7s',
    backgroundColor:colors.kehe,
    '&:hover': {
     width: 260
    }
   },
  icon_container: {
    width:          60,
    height:         60,
    display:        'flex',
    flexFlow:       'column',
    justifyContent: 'center',
    alignItems:     'center',
    borderRadius:   '50%',
    border:         `1px solid #FFF`,
    backgroundColor: '#FFF'
  },
 icon: {
  fontSize: 40,
  color:    colors.grey,
 },
  text_comp_container: {
     position:   'fixed',
     width:      '100vw',
     height:     '75vh',
     top:        '0vh',
     transition: 'all 1s'
  }
}));

type page1Props = {
  size:         number[];
  signup:       boolean;
  lang:         string;
  dir:          string;
  setLoadVideo: (val: boolean) => void;
  setPage:      (val: string) => void;
}

const Page1: React.FC<page1Props> = ({ size,lang, dir, signup, setPage, setLoadVideo }) => {

  const classes = useStyles();

  const [showTextComp, setShowTextComp] = React.useState(false)
  const [faseOut, setFaseOut]           = React.useState(false)
  
  const handleClick = () => {
    setLoadVideo(true)
    if (!showTextComp && signup) {
      setShowTextComp(true)
    }
    else {
      setFaseOut(true)
      setTimeout(() => {
          setPage('intro')
      },500)
    }
  }

  return (
   <>
      <div 
         className = { classes.container }
         style     = {{
          transform: `translate(0%, ${ faseOut ? -100 : 0 }vh)`,
         }}
      >
      <div
             className = { classes.logo }
             style     = {{
               backgroundImage: `url(${ logo })`
             }}
          /> 
      </div>
      <div
          className = { classes.button }
          onClick   = { handleClick }
          style     = {{
              height:    size[0] > 600 && size[1] > 700 ? 100 : 75,
              transform: `translate(-50%, ${ !faseOut ? 0 : 100 }vh)`,
              bottom:    size[1] > 800 ? 90 : '5vh',
          }}
      >
          <div className = { classes.icon_container }>
               <ForwardRoundedIcon className = { classes.icon }/>
          </div>
      </div>
      <div 
        className = { classes.text_comp_container }
        style     = {{
          transform: `translate(0px, ${ showTextComp ? (faseOut ? -100 : 0) : 100 }vh)`,
          opacity:   showTextComp ? 1 : 0
        }}
      >
        <TextComp size = { size } lang = { lang } dir = { dir }/>
      </div>
   </>
  )
}

export default Page1






