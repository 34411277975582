import React          from 'react';
import colors         from '../../utils/colors'
import { makeStyles } from '@material-ui/core/styles';
import { words }      from '../../utils/dictionary';

var clsx = require('classnames')

const useStyles = makeStyles(theme => ({
  root: {
    position:       'fixed',
    top:            0,
    width:          '100vw',
    height:         '100vh',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    transition:     'all 0.5s',
  },
  container: {
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    borderRadius:    '1em',
    backgroundColor: colors.kehe,
    padding:         15
  },
  modal: {
    width:           '100%',
    height:          '100%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    flexFlow:        'column',
    textAlign:       'center',
    backgroundColor: colors.kehe,
    color:           '#FFF',
    textShadow:      '0 0 7px rgba(0, 0, 0, 1)',
    fontWeight:      'bold',
    borderRadius:    '1em',
  },
  buttons: {
      flex:           0.7,
      width:          '85%',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'space-around',
  },
  button: {
      padding:         20,
      color:           '#FFF',
      fontWeight:      'bold',
      fontSize:        26,
      backgroundColor: colors.blue_,
      width:           100,
      cursor:          'pointer',
      borderRadius:    '1em',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      border:          '1px solid #FFF',
      boxShadow:       `0 3px 7px rgba(0, 0, 0, 0.7)`,
      textShadow:      'none',
      transition:      'width 0.4s',
      '&:hover': {
        width: 135
      }
  },
  button2: {
    padding:         20,
    color:           '#FFF',
    fontWeight:      'bold',
    fontSize:        26,
    backgroundColor: colors.blue_,
    width:           '85%',
    cursor:          'pointer',
    borderRadius:    '1em',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    textAlign:       'center',
    border:          '1px solid #FFF',
    boxShadow:       `0 3px 7px rgba(0, 0, 0, 0.7)`,
    textShadow:      'none',
    transition:      'width 0.4s',
    '&:hover': {
      width: '95%'
    }
},
  blue: {
    backgroundColor: colors.blue_
  },
  pink: {
    backgroundColor: colors.pink
  },
  column: {
    flexFlow: 'column'
  }
}));

type RedoSituationModalProps = {
  size:                      number[];
  lang:                      string;
  dir:                       string;
  showRedoSituationModal:    boolean;
  setRedoSituation:          (val: boolean) => void;
  setShowRedoSituationModal: (val: boolean) => void;
  setGridPos:                (val: boolean) => void;
  setName:                   (val: string) => void;
  handleModalClick:          (val: string) => void;
 }

const RedoSituationModal: React.FC<RedoSituationModalProps> = ({ 
  size,
  lang,
  dir,
  showRedoSituationModal,
  setName,
  setGridPos,
  setRedoSituation,
  setShowRedoSituationModal,
  handleModalClick
}) => {

  const classes = useStyles();

  const [containerWidth, containerHeight, border, fontSize] = React.useMemo(() => {
      return (
               (size[0] > 800 && size[1] > 600)
               ?
               ['65vw', 350, '1px solid #FFF', 34]
               :
               [size[0], size[1]-30, 'none', 25])
  },[size])


  const handleClose = () => {
     setShowRedoSituationModal(false);
  }


  const handleCancel = () => {
    setName('')
    setRedoSituation(false);
    handleClose()
    setGridPos(true);
  }
  

  return (
    <div
      dir       = { dir }
      className = { classes.root }
      style     = {{
        transform: `translate(0px, ${ showRedoSituationModal ? 0 : 150 }vh)`
      }}
    >
       <div 
            className = { classes.container }
            style     = {{
                width:  containerWidth,
                height: containerHeight,
                border: border,
                fontSize:fontSize
            }}
       >
          <div className = { classes.modal }>
              <p>{ `${ words['redo_situation'][lang] }` }</p>
              <br/>
              <div className = { size[0] > 600 ? classes.buttons : clsx(classes.buttons, classes.column) }>
                        <div 
                           className = { size[0] > 600 ? clsx(classes.button, classes.blue) : clsx(classes.button2, classes.blue) }
                           onClick   = { () => { handleModalClick('redo') }}
                        >
                          { `${ words['continue'][lang] }` }
                        </div>
                        <div 
                           className = { size[0] > 600 ? clsx(classes.button, classes.pink) : clsx(classes.button2, classes.pink) }
                           onClick   = { handleCancel }
                        >
                          { `${ words['return'][lang] }` }
                       </div>
                    </div>         
              </div>
        </div>
    </div>
   )
}

export default RedoSituationModal


