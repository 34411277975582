import React                   from 'react';
import VolumeSlider            from '../../components/volume';

import PauseRoundedIcon        from '@material-ui/icons/PauseRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextRoundedIcon     from '@material-ui/icons/SkipNextRounded';
import PlayArrowRoundedIcon    from '@material-ui/icons/PlayArrowRounded';

import colors                  from '../../utils/colors';
import { makeStyles }          from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
     video_container: {
       position:        'fixed',
       top:             0,
       margin:          0,
       display:         'flex',
       justifyContent:  'center',
       backgroundColor: "#000",
       zIndex:          2
     },
     controlls: {
        position:        'fixed',
        display:         'flex',
        justifyContent:  'space-between',
        alignItems:      'center',
        bottom:          '5vh',
        left:            '5vh',
        width:           200,
        padding:         5,
        borderRadius:    '1em',
        backgroundColor: colors.kehe,
        color:           '#FFF',
     },
     icon: {
         cursor: 'pointer'
     }
}));

type VideoComponentProps = {
    size:                number[];
    vol:                 number;
    videoSrc:            string;
    playVideo:           boolean;
    pauseVideo:          boolean;
    downloadComplete:    boolean;
    setPauseVideo:       (val: boolean) => void;
    setDownloadComplete: (val: boolean) => void;
    handleEndVideo:      () => void;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
    size,
    vol,
    videoSrc,
    playVideo,
    pauseVideo,
    downloadComplete,
    setDownloadComplete,
    setPauseVideo,
    handleEndVideo,
}) => {

    const classes = useStyles();

    const [volume, setVolume]       = React.useState(vol);
    const [volSet, setVolSet]       = React.useState(false);
    const [muteVideo, setMuteVideo] = React.useState(true);


    React.useEffect(() => {
        setVolume(vol)
    },[setVolume, vol])


    React.useEffect(() => {
        if (downloadComplete) {
            setMuteVideo(false)
            handleReplay()
        }
        else {
            setMuteVideo(true)
        }
    },[downloadComplete, setMuteVideo])

//
    const videoStyle = React.useMemo(() => {
         return {
            left:   playVideo && downloadComplete ? 0 : -1000,
            width:  playVideo && downloadComplete ? size[0] : 0,
            height: size[1]
        }
    },[size, playVideo, downloadComplete])



    const Ref = React.useRef<HTMLVideoElement>(null);


    const handleDownLoad = () => {
     
        if (Ref && Ref.current && Ref.current.buffered && Ref.current.buffered.length > 0 && Ref.current.buffered.end && Ref.current.buffered.end(0) && Ref.current.duration) {
            
            if (Math.round(Ref.current.buffered.end(0)) / Math.round(Ref.current.seekable.end(0)) >= 0.3 ) {
                setDownloadComplete(true)
             }        
        }
    }

    if (Ref && Ref.current && vol === 100 && !volSet) {
        Ref.current.volume = 1;
        setVolSet(true)
    }


    if (Ref && Ref.current) {
        if (muteVideo) {
            Ref.current.muted = true
        }
        else {
            Ref.current.muted = false
        }
    }


    if (Ref && Ref.current && playVideo && !pauseVideo) {
        Ref.current.play();
    }

    if (Ref && Ref.current && pauseVideo) {
        Ref.current.pause();
    }

    const handlePause = () => {
        if (Ref && Ref.current) {
            Ref.current.pause();
            setPauseVideo(true);
        }
    };

    const handlePlay = () => { 
        setPauseVideo(false) 
    };

    const handleReplay = () => {
        if (Ref && Ref.current) {
            Ref.current.currentTime = 0;
        }
    }

    const handleVolume = (val: number) => { 
        setVolume(val);
        if (Ref && Ref.current) {
            Ref.current.volume = val/100;
        }
    };

    const handleSkip = () => {
        if (Ref && Ref.current) {
            Ref.current.currentTime = 0;
            setPauseVideo(true);
            handleEndVideo()
        }
    }


    return(
        <div 
            className = { classes.video_container }
            style     = { videoStyle }
        >
           <video  
               webkit-playsinline="true"
               playsInline
               onProgress = { handleDownLoad }
               onEnded    = { handleEndVideo } 
               ref        = { Ref }
               src        = { videoSrc }
               height     = '100%'
               width      = '100%'
               preload    = "auto"
           />
           {
            playVideo && downloadComplete
            && 
            (
              <div className = { classes.controlls } >
                  <SkipPreviousRoundedIcon               className = { classes.icon } onClick = { handleReplay }/>
                  { !pauseVideo && <PauseRoundedIcon     className = { classes.icon } onClick = { handlePause }/> }
                  { pauseVideo  && <PlayArrowRoundedIcon className = { classes.icon } onClick = { handlePlay }/> }
                  <VolumeSlider                          volume    = { volume } handleVolume = { handleVolume } />
                  <SkipNextRoundedIcon                   className = { classes.icon } onClick = { handleSkip }/>
              </div>
            )
           }
        </div>
    )
}

export default VideoComponent;
