import React          from 'react';

type HiddenProps = {
    images:          string[];
    audio:           string[];
    setHiddenLoaded: (val: boolean) => void;
}

const Hidden: React.FC<HiddenProps> = ({
    images,
    audio,
    setHiddenLoaded
  }) => {
  
    const [imageCounter, setImageCounter]   = React.useState(0)
    const [imagesLoaded, setImagesLoaded]   = React.useState(false)
    const [audioCounter, setAudioCounter]   = React.useState(0)
    const [audioLoaded, setAudioLoaded]     = React.useState(false)

    // this is a temporary ugly workaround specifically for ios - - - - 

    React.useEffect(() => {

      setTimeout(() => {
        setHiddenLoaded(true)
      }, 1500)

    },[setHiddenLoaded])

    //  - - - - - - - - - - - - - - - - - - - - - - - - - - - - 


    React.useEffect(() => {
      if (imageCounter === images.length) {
        setImagesLoaded(true)
      }
      if (audioCounter > 0) {
        setAudioLoaded(true)
      }
    },[imageCounter, audioCounter, audio, images, setImagesLoaded, setAudioLoaded])



    const handleOnImageLoad = () => {
      const newNum = imageCounter + 1;
      setImageCounter(newNum);
    }

    const handleOnAudioLoad = () => {
      const newNum = audioCounter + 1;
      setAudioCounter(newNum);
    }


    React.useEffect(() => {
       if (imagesLoaded && audioLoaded) {
          setHiddenLoaded(true)
       }
    },[setHiddenLoaded, imagesLoaded, audioLoaded])


    return (
    <>
          {
              images.map((val, ind) => {
                return(
                        <img 
                            key    = { `${ val }_${ ind }` }
                            src    = { val }
                            alt    = { val }
                            width  = '100'
                            height = '100'
                            onLoad = { handleOnImageLoad }
                            style = {{
                                opacity:    0,
                                marginLeft: 10000
                             }}
                        />
                       
                )
              })
          }
          {
              audio.map((val, ind) => {
                return(
                    <audio  
                        key                = { `${ val }_${ ind }` }
                        muted  
                        playsInline
                        autoPlay
                        webkit-playsinline = "true" 
                        src                = { val } 
                        preload            = "auto"
                        onCanPlayThrough   = { handleOnAudioLoad }
                   /> 
                )
              })
          }
    </>
  )
}

export default Hidden





