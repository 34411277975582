import React                 from 'react';
import VideoModal            from './VideoModal';

import { makeStyles }        from '@material-ui/core/styles';

import { actionType }        from '../../types';
import { videoUploadType }   from '../../types/actions';

import { connect }           from 'react-redux';
import { Dispatch }          from "redux";
import { ActionTypes }       from '../../types/actions';
import { reducerType }       from '../../reducer';
import { UPLOAD_VIDEO_SAGA } from '../../actions';
//import { s3Upload }          from "../../libs/awsLib";
//import AWS                   from 'aws-sdk';

import { words }             from '../../utils/dictionary'

/* 
const BucketName     = "7pm-video-bucket";
const BucketRegion   = "us-east-2";
const IdentityPoolId = "us-east-2:801f9bf1-059a-4df8-90ef-baeb507c2bdf";
//  AWS.config.logger = console;
AWS.config.update({
  region: BucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId
  })
}); */

const maxFileSize    = 50000000;


const useStyles = makeStyles(theme => ({
  black: {
    backgroundColor: '#000',
    position:        'fixed',
    width:           '100vw',
    minHeight:       '100vh',
  },
  root: {
    position:           'fixed',
    top:                0,
    left:               0,
    width:              '100vw',
    height:             '100vh',
    backgroundPosition: 'center',
    backgroundRepeat:   'no-repeat',
    backgroundSize:     'cover',
    transition:         'opacity 1s'
  },
  filter: {
    position:        'fixed',
    top:             0,
    left:            0,
    width:           '100vw',
    height:          '100vh',
    backgroundColor: 'rgba(0,0,0,0.5)'
  }
}));

type VideoUploadProps = {
  name:                 string;
  lang:                 string;
  dir:                  string;
  userId:               string;
  background:           string;
  actionAudio:          string;
  actionArr:            actionType[];
  setPage:              (val: string) => void;
  setAudioSrc:          (val: string) => void;
  setAudioEnd:          (val: boolean) => void;
  setPlayAudio:         (val: boolean) => void;
  setPauseAudio:        (val: boolean) => void;
  setObjectURLCreated:  (val: boolean) => void;
  setActionArr:         (val: actionType[]) => void;
  uploadVideo:          (payload: videoUploadType) => void;
}

const VideoUpload: React.FC<VideoUploadProps> = ({
    name,
    lang,
    dir,
    userId,
    background,
    actionArr,
    actionAudio,
    setAudioSrc,
    setAudioEnd,
    setPlayAudio,
    setPauseAudio,
    setPage,
    setActionArr,
    setObjectURLCreated,
    uploadVideo
}) => {

  const classes = useStyles();

  const [opacity, setOpacity]                   = React.useState(false);
  const [initialPos, setInitialPos]             = React.useState(false);

  const [stage, setStage]                       = React.useState('action');
  const [success, setSuccess]                   = React.useState(false);
  const [disableSubButton, setDisableSubButton] = React.useState(true);
  const [error, setError]                       = React.useState('');
  const [file, setFile]                         = React.useState({} as File | null);
  const [showLoader, setShowLoader]             = React.useState(false);
  const [progress, setProgress]                 = React.useState(0);
  

    React.useEffect(() => {
      setTimeout(() => { 
        setOpacity(true);
        setInitialPos(true);
      }, 10);
   },[setOpacity, setInitialPos]);


   const onSubmit = async (e: React.FormEvent) => {

      e.preventDefault();

      if (error !== `${ words['upload_action_success'][lang] }` && error !== `${ words['upload_result_success'][lang] }`) { setError(''); }
      setDisableSubButton(true);
     // setOpenVideoModal(false);

   //  const videoUrl  = `https://${ BucketName }.s3.us-east-2.amazonaws.com/${ userId }_${ name }_${ stage }`
   //  const payload   = { videoUrl : videoUrl }

    if (!file) { return }

    setShowLoader(true);

   /*  const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket:      BucketName,
        Key:         `${ userId }_${ name }_${ stage }`,
        ContentType: file.type,
        Body:        file,
        ACL:         "public-read"
      }
    }); */


    try {
     /*   await upload.on('httpUploadProgress', function(evt) {
        setProgress(Math.round(evt.loaded /  evt.total * 100))
       }).promise() */

       setError(stage === 'action' ? `${ words['upload_action_success'][lang] }` : `${ words['upload_result_success'][lang] }`);

       if (stage === 'action') {
         setActionArr(actionArr.map((val) => val.custom ? {...val, actionVideo: URL.createObjectURL(file), actionVideoEng: URL.createObjectURL(file) } : val))
         setStage('result')
      }
      else {
        setActionArr(actionArr.map((val) => val.custom ? {...val, resultVideo: URL.createObjectURL(file), resultVideoEng: URL.createObjectURL(file) } : val))
        setSuccess(true)
        setObjectURLCreated(true)
      }
    }
    catch (error) {
      setError(`${ words['bad_video_upload'][lang] }`)
      console.error('action menu message: error uploading file - - - ',error)
    }
    finally {
   //   uploadVideo(payload)
      setShowLoader(true);
      setTimeout(() => { setShowLoader(false); },30) 

      setProgress(0)
    }

  };


  const onChange = (e: React.FormEvent) => {

    const target = e.target as HTMLInputElement;
    
    if (!target || !target.files || !target.files[0] || !target.files[0].type) {
      setDisableSubButton(true)
      return;
    }
    else if (target && target.files && target.files[0] && target.files[0].type && target.files[0].type !== 'video/mp4'
        &&
        target && target.files && target.files[0] && target.files[0].type && target.files[0].type !== 'video/quicktime') {
          setError(`${ words['upload_video_type_err'][lang] }`);
          setDisableSubButton(true)
          return;
        }
    else if (target && target.files && target.files[0] && target.files[0].size && target.files[0].size > maxFileSize ) {
          setError(`${ words['upload_video_size_err'][lang] }`);
          setDisableSubButton(true)
          return;
      }
    else {
      if (error !== `${ words['upload_action_success'][lang] }` && error !== `${ words['upload_result_success'][lang] }`) { setError(''); }
      setDisableSubButton(false)
      setFile(target.files && target.files[0]);
    }
  
  };

  const handleClose = () => {
    setInitialPos(false)
    setOpacity(false)
    setAudioSrc(actionAudio)
    setAudioEnd(false)
    setPauseAudio(false)
    setTimeout(() => {
      setPlayAudio(true)
    },10)
    setTimeout(() => {
      setPauseAudio(true)
    },20)
    setTimeout(() => { setPage('action_menu') },800)
  }

  return (
  <div className = { classes.black }>
        <div
          className = { classes.root }
          style     = {{
            backgroundImage: `url(${background})`,
            opacity:         opacity ? 1 : 0
          }}
        >
            <div className = { classes.filter }>
                <VideoModal
                  stage              = { stage }
                  lang               = { lang }
                  dir                = { dir }
                  progress           = { progress }
                  success            = { success }
                  initialPos         = { initialPos }
                  error              = { error }
                  disableSubButton   = { disableSubButton }
                  showLoader         = { showLoader }
                  onChange           = { onChange }
                  onSubmit           = { onSubmit }
                  handleClose        = { handleClose }
                />
            </div>
        </div>
    </div>
   )
}


const mapStateToProps = (reducer: reducerType) => {
  return {
    userId:       reducer.userId,
  //  userVideoSrc: reducer.userVideoSrc,
  }
};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    uploadVideo: (payload: videoUploadType ) => dispatch(UPLOAD_VIDEO_SAGA(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoUpload);
//