import React                                              from 'react';
import MyTheme                                            from '../Theme'
import { makeStyles, createStyles, ThemeProvider, Theme } from '@material-ui/core/styles';
import CircularProgress                                   from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((MyTheme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: MyTheme.spacing(2),
      },
    },
  }),
);


type LoaderProps = {
  color: "primary" | "secondary";
}


const Loader: React.FC<LoaderProps> = ({
  color
}) => {

  const classes = useStyles();

  return (
    
    <div className = { classes.root }>
      <ThemeProvider theme = { MyTheme }>
         <CircularProgress color = { color }/>
      </ThemeProvider>
    </div>
  );

}


export default Loader