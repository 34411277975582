import React                            from 'react';
import colors                           from '../../utils/colors';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import ForwardRoundedIcon               from '@material-ui/icons/ForwardRounded';
import { makeStyles }                   from '@material-ui/core/styles';
import { words }                        from '../../utils/dictionary';

var clsx = require('classnames');

//const t2 = `נגן סרטון שוב`

const useStyles = makeStyles(theme => ({
 
  button_container: {
    height:         '100vh',
    width:          '100vw',
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    transition:     'all 1s'
  },
  column: {
    flexFlow: 'column'
  },
  button_and_text: {
    width:           300,
    height:          250,
    display:         'flex',
    justifyContent:  'center',
    alignItems:      'center',
    flexFlow:        'column',
  },
  button: {
    width:           190,
    height:          90,
    display:         'flex',
    justifyContent:  'center',
    alignItems:      'center',
    transition:      'transform 1s, width 0.4s',
    border:          '1px solid #FFF',
    cursor:          'pointer',
    borderRadius:    '1em',
    '&:hover': {
      width: 250
    }
  },
  pink: {
    backgroundColor: colors.pink,
  },
  blue: {
    backgroundColor: colors.blue_,
  },
  icon: {
    color:    '#FFF',
    fontSize: 50
  },
  button_text: {
    color:           '#FFF',
    width:           '100%',
    fontSize:        30,
    fontWeight:      'bold',
    textAlign:       'center',
    paddingTop:      '0.4em',
    paddingBottom:   '0.4em',
    borderRadius:    '0.5em',
    marginTop:       10,
    cursor:          'pointer',
    textShadow:      '0px 2px 2px #000',
    transition:      'transform 2s, opacity 0.4s',
    '&:hover': {
      opacity: 0.5
    }
  },
  p: {
    color:      '#FFF',
    fontSize:   34,
    fontWeight: 1000
  }
}));

type ButtonsProps = {
     size:          number[];
     lang:          string;
     dir:           string;
     buttonPos:     boolean;
     handleRePlay:  () => void;
     handleContinue:() => void;
}

const Buttons: React.FC<ButtonsProps> = ({
      size,
      lang,
      dir,
      buttonPos,
      handleRePlay,
      handleContinue
  }) => {


  const classes = useStyles();

  return (
    <div
       className = { size[0] > 600 ? classes.button_container : clsx(classes.button_container, classes.column) }
       style     = {{
        transform: `translate(0px, ${ buttonPos ? 0 : 100 }vh)`
      }}
    >
            <div className = { classes.button_and_text }>
                  <div 
                    className = { clsx(classes.button, classes.pink) }
                    onClick   = { handleRePlay }
                  >
                    <PlayCircleFilledWhiteRoundedIcon className = { classes.icon }/>
                  </div>
                  <div 
                     dir       = { dir }
                     className = { classes.button_text }
                     onClick   = { handleRePlay }
                     style     = {{
                      transform: `translate(0px, ${ buttonPos ? 0 : 100 }vh)`
                    }}
                  >
                    { `${ words['replay'][lang] }` }
                  </div>
            </div>
            <div className = { classes.button_and_text }>  
                  <div 
                    className = { clsx(classes.button, classes.blue) }
                    onClick   = { handleContinue }
                  > 
                    <ForwardRoundedIcon className = { classes.icon }/>
                </div>
                <div 
                  dir       = { dir }
                  className = { classes.button_text }
                  onClick   = { handleContinue }
                  style     = {{
                      transform: `translate(0px, ${ buttonPos ? 0 : 100 }vh)`
                  }}
                >
                  { `${ words['continue'][lang] }` }
                </div>
            </div>       
    </div>
  )
}

export default Buttons

