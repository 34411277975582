
import React          from 'react';
import background     from '../../graphic_components/main_bgrnd.png';

type HiddenProps = {
  setHiddenLoaded: (val: boolean) => void;
}

const Hidden: React.FC<HiddenProps> = ({ setHiddenLoaded }) => {

 return (
      
     <img 
         src    = { background }
         alt    = { background }
         width  = '100'
         height = '100'
         onLoad = { () => setHiddenLoaded(true) }
         style = {{
             opacity:    0,
             marginLeft: 10000
          }}
     />
                       
       
  )
}

export default Hidden

