
const general = [
    'בס"ד',
    'אופן/תהליך השימוש באפליקציה:',
    '1. תחילה יופיע בפניכם מאגר סיטואציות קונפליקטואליות מחיי היומיום של ילדים. בחרו באחת מהן.',
    '2. לחיצה על הסיטואציה הנבחרת תוביל לתחילתו של התהליך.',
    '3. בשלב הראשון של התהליך יופיע סרטון המתאר סיטואציה בה מתרחש קונפליקט.',
    '4. כעת יופיע סרגל רגשות. על הילד לבחור מתוך סרגל הרגשות, את הרגשות המתאימים לתחושת גיבור הסיפור. יש לגרור את הרגש/ות אל תוך הלב ולנעול עם מפתח.',
    `5.  מיד אחר כך יופיע 'מנטרה לי': ג'ינגל "לנשום, להרגיש, לחשוב, ועכשיו לפעול".`,
    `6. בשלב זה יופיע גלגל סרטוני תגובות, המכיל 5 תגובות אפשריות לסיטואציה שראיתם. יש לבחור באחת מהן (בהמשך ניתן יהיה לראות את כולן)
    אחת מהתגובות נקראת "תגובת קסם" ומופיעה כשרביט קסמים משמאל לגלגל התגובות. ניתן לצפות בה רק לאחר צפייה ב-2 תגובות לפחות.
    לאחר צפייה בכל 5 התגובות שעל הגלגל, תוכלו לצלם עם ילדכם "קליפלי": סרטון בו הוא/היא מציג/ה תגובה מותאמת אישית הנראית לו/ה,  ולהטמיעו באפליקציה האישית שלכם.
    הסבר מפורט על אופן הביצוע ניתן למצוא בתפריט.`,
    `7. יש לחשוב ולדון עם הילד מה עשויה להיות התוצאה של אותה תגובה. ולצפות בסרטון התוצאה.`,
    `8. בשלב זה יופיע שוב סרגל רגשות. על הילד לבחור מתוך סרגל הרגשות, את הרגשות המתאימים לתחושת גיבור הסיפור לאחר התוצאה. יש לגרור את הרגש/ות אל תוך הלב ולנעול עם מפתח.`,
    `9. על הילד להחליט האם התוצאה רצויה, או שברצונו לבחור תגובה אחרת מגלגל התגובות. אם ההחלטה היתה רצון לבחור תגובה אחרת מגלגל התגובות, יש לחזור על התהליך משלב 6.`,
    `10. אם הילד החליט שהתוצאה רצויה, יופיע סרטון המסכם באופן רציף את התרחיש כולו – סיטואציה-תגובה-תוצאה.`,
    `11. בשלב זה תופיע שאלה האם גיבור הסיפור ראוי לתואר מלך. יש לנסות לגרור את הכתר לראשו של גיבור הסיפור. באם התגובה שנבחרה הינה תגובה חיובית, הכתר יתמגנט לראש גיבור הסיפור. באם התגובה שנבחרה הינה שלילית הכתר לא ימוגנט.`,
    `12. בשלב זה ניתן:
    לסיים את תהליך הסיטואציה הנוכחית,
    להעמיק את התרגול על ידי צפייה בתגובות שטרם נצפו או לתרגל שוב את אותה הסיטואציה. 
    כמו כן, ניתן לתרגל שוב סיטואציות שכבר נפתחו לתרגול בעבר.
    צפייה בתרחיש חדש תתאפשר שוב רק ביום שלמחרת בשעה PM7:00 (או בשעת האיכות האישית שבחרתם עבור ילדכם :)
    הרחבות מעשירות להורה, אשר יופיעו בשלבים מהותיים במהלך האפליקציה, יסומנו עם אייקון i. מומלץ מאוד לפתוח ולקרוא! 
    ניתן לצפות בהרחבות המעשירות גם מתוך עמוד הבית.`
]

const gingel = [
    `מנטרה-לי: שמיעת המנטרה ״לנשום, להרגיש, לנשום ועכשיו - לפעול״`,
    `המטרות:`,
    `1) כלי עזר להשהיית תגובה.`,
    `ככול שילדנו יצליח להשהות את תגובתו לאירוע שלילי,
    כך יגדל הסיכוי שתגובתו תהיה יעילה יותר (אדפטיבית).`,
    `המטנרה-לי מהווה מרחב של השהייה, בו הילד ילמד לנשום, להרגיש ולחשוב:`,
    `לנשום- נשימה סרעפתית גורמת להרפיה ורגיעה.`,
    `להרגיש- חיבור לרגשות וזיהויים מפחית מצוקה ומאפשר מרחב לחשיבה.`,
    `לחשוב- עיבוד וחשיבה מובילים לתגובה איכותית ומקדמת מטרה.`,
    `2) מנטרה-לי הינה ג׳ינגל קליט, קליל וחוויתי אשר יקל על ילדינו להשתמש בו
    בסיטואציות נוספות בחיי היומיום שלו.`,
    `3) מנטרה-לי מערבת את חוש השמיעה, ראיה ותנועה דבר המסייע לקידוד והפנמת
    המסר ולשליפתו בקלות וביעילות בשעת הצורך.`
]

const emotion_menu = [
    `סרגל רגשות: מאגר הבעות פנים המייצגות רגשות חיוביים ושליליים ומוצגים בתמונות ובמילים.`,
    `המטרות:`,
    `1) כלי עזר: להכרת מגוון הרגשות. לזהוי כל רגש בהבעת הפנים ושיום הרגש.`,
    `2) תרגול בהתאמת הרגש המתאים בהתאם לחוויה שנחוותה.`,
    `סרגל רגשות - לאחר צפייה בסצנה הראשית ובסצנות התגובה, נפתח על המסך
    סרגל רגשות ובו מגוון הבעות פנים המייצגות רגשות חיוביים ושליליים (המוצגים
    באימוג׳י ובמילים).`,
    `על הילד לגרור לתוך ליבו של גיבור/ת הסצנה את הרגשות
    המתאימים (רגש אחד או יותר).`,
    `התהליך:`,
    `לאחר צפייה בסצנה הראשית, נשאל את הילד מה לדעתו גיבור הסיפור מרגיש
    עכשיו? איך היית מרגיש אם זה היה קורה לך?
    ילד אשר עדיין אינו יודע לזהות רגשות נתמקד תחילה בזיהוי הרגש הבולט
    והדומיננטי לסצנה.`,
    `נוכל להציע לו מסרגל הרגשות הבסיסי מספר רגשות כגון: האם הילד שמח? עצוב?
    כועס? פוחד?`,
    `ננחה את הילד להביט בגיבור הסיפור ולהשוות את הבעת פניו לאמוג'י שבסרגל הרגשות.`,
    `ניתן להעמיק את הלמידה בכך שהילד יחקה הבעת פנים זו וייזכר מתי ובאיזו סיטואציה הרגיש כך בעבר.`,
    `נשאל היכן בגוף הרגיש רגש זה?`,
    `משמצא את הרגש המתאים נבקש מהילד לגרור רגש זה לליבו של גיבור הסיפור.`,
    `באם הילד למד לזהות רגשות ניתן להרחיב את השיח לזיהוי תמהיל של רגשות:`,
    `בשיח זה ידובר על כך שסיטואציה אחת יכולה לעורר בנו מספר רגשות בו זמנית ופעמים רגשות אלו יכולים להיות גם מנוגדים וסותרים, כגון:`,
    `שמח ועצוב, שמח ומקנא, וכדומה.`,
    `נשאל את הילד אלו רגשות גיבור הסיפור מרגיש בסיטואציה זו ומדוע מרגיש כך?`,
    `ילדים בוגרים יותר ניתן לשלב בשיח אלמנט נוסף שהוא האדם הנוסף שנכח בסיטואציה.`,
    `כיצד התגובה של גיבור הסיפור משפיעה על אדם זה ועל רגשותיו?`,
    `משציין את הרגשות המתאימים נבקש מהילד לגרור רגשות אלו לליבו של גיבור הסיפור.`
];

const action_wheel = [
   `גלגל התגובות: גלגל שבו ניתן לצפות בחמש תגובות אפשריות לתרחיש שנצפה.`,
   `התגובות שונות אחת מהשנייה באסטרטגיה ובמידת האדפטיביות (סתגלנות יעילה) שלה, החל מתגובה יעילה ומקדמת מטרה וכלה בתגובה אשר אינה יעילה ואף מסלימה.`,
   `כמו כן, חלק מהתגובות מתגוונות בהתאם לסגנון התמודדות אישי של הילד (אינטרוברטיות/אקסטרוברטיות = מופנם/מוחצן).`,
   `לאחר צפייה בתגובות יש לאפשר לילד לבחור באופן ספונטני את התגובה הרצויה לו, וזאת מבלי לשפוט או לבקר את בחירתו.`,
   `אחר הבחירה, יש ליצור שיח ובו ידון עם הילד טיב התגובה ויעילותה, ומה לדעתו תהיה התוצאה לאותה התגובה?`,
   `האם הוא זוכר את עצמו מגיב באופן דומה, ומה הייתה התוצאה?`,
   `לכל אחת מחמשת התגובות השונות נגזרת תוצאה שונה הנובעת מאופי וטיב התגובה.`
];

const magic = [
    `תגובת קסם - סצנה בה מומחז פתרון פנטזיוני-דמיוני לקונפליקט.`,
    `תוצאה פנטזיונית-דמיונית היא פתרון לקונפליקט ומילוי משאלה אשר אינה מתחשבת בחוקי המציאות.`,
    `המטרה הינה לתת מקום למשאלות ופנטזיות גם אם הן לא מציאותיות וברות מימוש. מאפשר מרחב משחקי בו הילד משתמש בדמיון ויוצא מחוץ לגבולות ולכללים המותרים/אפשריים במציאות, באופן בריא ובונה.`,
    `ניתן לשאול את הילד: אילו היה יכול להתרחש קסם, שכל מה שאתה רוצה שיקרה באמת יקרה, מה היית רוצה שיקרה? איזה פתרון קל וכייפי היית רוצה שיקרה עכשיו?`,
    `יש לאפשר דיון עם הילד על ההבדל בין פנטזיה למציאות. למה הפנטזיה שלך לא יכולה להתממש במציאות? טכנית/פיזית זה לא אפשרי? כי זה עלול לגרום נזק לאדם או לרכוש? כי זה עלול לפגוע במישהו אחר? מה קורה לפנטזיות או משאלות שכן התממשו במציאות? האם הן ענו על הציפיות? האם הן אכזבו והיו יותר נחמדות בפנטזיה? איזו פנטזיה היית רוצה שיתגשם במציאות? איזו פנטזיה עדיף לה שתישאר כפנטזיה?`,
];

const custom_video = [
    `קליפלי: יצירת סרטוני תגובה ותוצאה מותאמים אישית`,
    `המטרות:`,
    `1) יצירת משחק תפקידים המאפשר תרגול של תגובה חיובית. כאשר הילד מתרגל באופן מעשי את התנהגות רצויה, התנהגות זו מופנמת והופכת לזמינה יותר לביצוע בזמן אמת.`,
    `2) פעילות משותפת זו, של יצירת התסריט, בימויו והסרטתו, מהווה מרחב משחקי, יצירתי וחוויתי לילד ולהורה.`,
    `באופציה של תגובה מותאמת אישית תוכלו לחשוב יחד עם ילדכם על פתרון אחר לקונפליקט המוצג בסיטואציה הנוכחית. פתרון שהוא תוצר של חשיבה משותפת של ילדכם ושלכם לקונפליקט. פתרון ייחודי, המשקף את האקלים המשפחתי שלכם, את הצרכים והערכים שלכם.`,
    `הצעדים ליישום קליפלי:`,
    `1. תחילה עליכם לחשוב יחד עם ילדכם על תגובה חיובית המתאימה למשפחתכם.`,
    `2. עליכם ליצור שני תסריטים קצרים של תגובה ותוצאה (עד 12 שניות לכל תסריט). יש לקחת בחשבון את המיקום, המשתתפים, האביזרים והטקסט.`,
    `3. יש לבצע יחד עם ילדכם חזרות ותרגול של התסריט עד לביצוע שמשביע את רצונכם ולהסריט את הביצוע.`,
    `4. יש להטמיע את הסרטונים באפליקציה בהתאם להוראות המופיעות במיקום של התגובה מותאמת אישית באפליקציה עצמה.`
]

const result_test = [
    `כתר:`,
    `כתר – לאחר בחירת התגובה המועדפת על הילד וצפייה בתוצאת התגובה, גיבור/ת הסיפור יופיע על הצג, ולצדו כתר. את הכתר ניתן יהיה לגרור על ראש גיבור/ת הסצנה.`,
    `באם התגובה שנבחרה יעילה דייה הכתר ימוגנט על ראש גיבור/ת הסצנה. הכתר מבטא בחירה המוכיחה שליטה עצמית והתנהלות אצילית וממלכתית (מסמל שלטון אמתי של האדם על דחפיו ורצונותיו).`,
    `באם הילד בחר תגובה יעילה המקדמת מטרה גם באופן חלקי (או מונעת הסלמה), הכתר ימוגנט לראשו של גיבור הסיפור.`,
    `בשלב זה ניתן לשוחח עם הילד על החיבור בין הכתר לשליטה עצמית.`,
    `באם הילד בחר בתגובה אשר אינה מקדמת מטרה, הכתר לא ימוגנט לראש גיבור הסיפור.`,
    `לאחר דיון עם הילד לגבי איכות התגובה והתוצאה שלה, ניתן לצפות שוב בסרטוני התגובה יחד עם סרטוני התוצאה. לאחר מכן לאפשר בחירה מחודשת של תגובה המתבססת עתה על תובנה ופרספקטיבה רחבים יותר.`,
    `לאחר הבחירה המחודשת ניתן יהיה לנסות ולמגנט שוב את הכתר לראשו של גיבור הסיפור.`
]


const generalEng = [
    'USING 7:PM',
    `1. Enter the situation menu, where you will find collection of conflictual situations from a child's everyday life.`,
    `2. To get started, select a situation.`,
    '3. Clicking on a situation will prompt a short video depicting the chosen narrative to play.',
    '4. The following section will present the emotion scale, prompting the child to estimate the emotional impact that the situation had on the hero/heroine of the story. A selection of images representing various emotions will be displayed on a bar on the bottom of the screen. The emotions deemed by the child to most accurately represent the emotional state of the protagonist should be dragged and dropped into his/her heart. Once this is done, the heart should be locked with the key.',
    `5. Locking the heart will prompt the 'MyJingle' video - a short song about processing one's own personal inner state before choosing a course of action.`,
    `6. Upon reaching the action menu, the child will be presented with five potential reactions to the situation. One action must be selected, however, eventually you will get a chance to view all of them. A sixth, magical option, will be presented in the form of a magic wand. In order to receive access to the magic action, two entire scenarios (actions and results) must be completed. The completion of all five scenarios will grant you access to 'MyClip', that will allow the child to compose his/her own personal short action video for the situation.`,
    `7. Immediately After watching the selected action video would be a good time for a discussion about the potential results of the action.`,
    `8. After watching the result video the emotion scale will be presented a second time.`,
    `9. Now that the child has viewed the result, he/she should decide if that result is a desirable one. If it is not - you may return to the action menu and select a different action.`,
    `10. In case the child is happy with the result - the entire scenario (situation, action and result) will be played.`,
    `11. The final section includes feedback on the child's choice. A successful attempt to place a crown on to the protagonists head (by dragging and dropping) will indicate that the action chosen by the child is the correct one. An unsuccessful attempt will indicate that the action chosen by the child is wrong.`,
    `12. After completing the entire scenario you may get a deeper understanding of the situation and the implications of different behaviors by viewing other reactions to the same situation, or select a different situation from the situation menu. previously opened situations can be rewatched, while only one new situations can be opened per day at 7:00 PM (or any other parent-child quality time)`,
    `Further information regarding specific pages can be accessed throughout the app by clicking the I button.`
]

const gingelEng = [
    `MyJingle (Breath, Feel, Think, Act)`,
    `MyJingle is an effective tool for delaying the child's reaction to a negative experience, allowing him or her to formulate an adaptive and more efficient response.`,
    `The child is encouraged by the song to breath, feel, think and then act.`,
    `This method is designed to relieve any stress and anxiety caused by the situation, identify the emotions felt by the child, understanding and processing them, thus making space for reason and thought, leading to a considered and constructive reaction.`,
    `MyJingle is a catchy song, making its principles easy to memorise, internalise, integrate and make use of in everyday situations encountered by the child.`
];

const emotionMenuEng = [
    `Emotion Scale - a collection of positive and negative emotion represented in images and names.`,
    `This tool assists the child in identifying and recognizing emotions both by name and by bodily expression, as well as associating various emotions with an experience that may evoke them.`,
    `The emotion scale will be displayed after playing the situation and result videos.`,
    `Any number of the images can be dragged and dropped into the story's protagonists heart by the child.`,
    `After watching the video, ask the child if they can intuit how the character was emotionally impacted by what happened, and, how would they feel if they were in that position`,
    `Ask the child to try to remember if they ever experienced a similar feeling, and if so, what caused them to feel that way.`,
    `In case the child is not yet capable of identifying a wide spectrum of emotions, focus on one or two dominant emotions conveyed by the video.`,
    `In case the child is able to identify emotions, discuss the possibility of a situation evoking numerous emotions that may be conflicting, such as happiness and envy.`,
    `Encourage the child to consider why they feel the way they do and where do their emotions come from.`,
    `With more mature children, you may add another aspect to the discussion and ask them to consider how their actions effect the feeling of other characters in the situation.`
];


const actionWheelEng = [
    `Action Menu - View five potential reactions to the situation and their results.`,
    `The actions vary in their strategy and level of adaptiveness - from constructive and goal-furthering actions to distructive and escalating actions.`,
    `Moreover, the actions vary in their protagonists character and approach to challenges (e.g. the protagonists levels of extraversion/introversion).`,
    `Allow the child to choose his/her own preferred action without criticism.`,
    `After the choice has been made, discuss the level of its propriety and efficiency.`,
    `Ask the child to try to predict the consequence of their selected action.`,
];


const magicEng = [
    `Magic Action - An imaginary solution to the situation.`,
    `This is a solution that disregards the nature of reality. It makes room for wishes and phantasies that are impossible to fulfill in real life.`,
    `It enables the creation of a playful, limit-free space that allows the child cross the borders of permitted behavior.`,
    `Ask the child, in the context of the situation - 'If you could do anything you wanted, what would you do?'`,
    `Once the child's ideal easy and fun solution has been established, the magic action may be used to invoke a discussion about the difference between phantasy and reality.`,
    `Ask the child to consider what would happen in real life if he/she were to act upon their phantasy. Would it cause harm to others or to themselves? Would the consequence be desirable or destructive? Which phantasies should they try to realise, and which should remain imaginary?`
];


const customVideoEng = [
    `MyClip - Create your own action and result videos`,
    `The MyClip option enables a role playing game, centered around the formation of a personal positive response to the situation. It places the child in the role of the protagonist, allowing him/her to act out, internalise and integrate the desirable behavior.`,
    `The shared parent-child activity of screenwriting, acting out, and producing a short video displaying a positive solution, can be a creative and thought provoking activity for all participants.`,
    `The custom videos should be an original and unique solution that is the product of collaborative thinking of all participants, and reflect your family values.`,
    `Using this feature:`,
    `Together with your child, think through a positive response to the situation.`,
    `Create a short script (up to 15 seconds long) for the action video and another one for the result video. Location, participants, props, and dialogue should be taken into consideration.`,
    `Rehearse the script with your child`,
    `Follow the instructions displayed in the MyClip page to integrate your videos with the app.`
];


const resultTestEng = [
    `Conclusion`,
    `After completing the selected action and result sections, the conclusion page will be displayed, prompting the child to drag and drop a crown on top of the head of the protagonist.`,
    `The crown, which represents self restraint, responsibility and dignified behavior, will remain on the figures head only if the child chose a constructive, goal-furthering or de-escalating action.`,
    `The successful placing of the crown on top of the protagonists head, or failure thereof, may serve as catalyst for a discussion about the relation between the virtues represented by the crown, the action chosen by the child in the action menu and its consequences.`,
    `Following the discussion, the insights gained by the child through this attempt may serve to inform future choices of actions in the action menu.`
];


export const texts: { [key: string]: string[] } = {
    general:      general,
    gingel:       gingel,
    emotion_menu: emotion_menu,
    action_wheel: action_wheel,
    magic:        magic,
    custom_video: custom_video,
    result_test:  result_test,
    action_menu:  ['']
}

export const textsEng: { [key: string]: string[] } = {
    general:      generalEng,
    gingel:       gingelEng,
    emotion_menu: emotionMenuEng,
    action_wheel: actionWheelEng,
    magic:        magicEng,
    custom_video: customVideoEng,
    result_test:  resultTestEng,
    action_menu:  ['']
}
