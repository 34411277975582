import React                   from 'react';
import VolumeSlider            from '../volume';

import PauseRoundedIcon        from '@material-ui/icons/PauseRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextRoundedIcon     from '@material-ui/icons/SkipNextRounded';
import PlayArrowRoundedIcon    from '@material-ui/icons/PlayArrowRounded';

import colors                  from '../../utils/colors';
import { makeStyles }          from '@material-ui/core/styles';

var playPromise: Promise<any>;

const useStyles = makeStyles(theme => ({
  video_container: {
    position:        'fixed',
    top:             0,
    margin:          0,
    display:         'flex',
    justifyContent:  'center',
    backgroundColor: "#000",
    zIndex:          2
  },
  gingel_container: {
     height:          '75vh',
     width:           '75vw',
     borderRadius:    '3em',
     display:         'flex',
     justifyContent:  'center',
     alignItems:      'center',
     backgroundColor: colors.kehe,
     border:          `1px solid ${ colors.halavi }`,
     transition:      'opacity 1s',
     overflow:        'hidden',
     paddingLeft:     30,
     paddingRight:    30,
     zIndex:          2
   },
  controlls: {
     position:        'fixed',
     display:         'flex',
     justifyContent:  'space-between',
     alignItems:      'center',
     bottom:          '5vh',
     left:            '5vh',
     width:           200,
     padding:         5,
     borderRadius:    '1em',
     backgroundColor: colors.kehe,
     color:           '#FFF',
  },
  icon: {
      cursor: 'pointer'
  }
}));


type AudioProps = { 
  audioSrc:       string;
  playAudio:      boolean;
  pauseAudio:     boolean;
  showButtons:    boolean;
  muteAudio:      boolean;
  setPauseAudio:  (val: boolean) => void;
  setPlayAudio:   (val: boolean) => void;
  handleEndAudio: () => void;
}

const Audio: React.FC<AudioProps> = ({ 
    audioSrc, 
    playAudio, 
    pauseAudio, 
    showButtons, 
    muteAudio,
    setPauseAudio, 
    setPlayAudio,
    handleEndAudio 
}) => {

  const classes = useStyles();

  const [muted, setMuted]       = React.useState(true);
  const [volume, setVolume]     = React.useState(30);
  const [src, setSrc]           = React.useState(audioSrc);


  React.useEffect(() => {
    if (playAudio && !pauseAudio) {
      setTimeout(() => {
        setMuted(false)
      }, 10)
    }
    else {
      if (!muted) { setMuted(true) }
    }
  },[playAudio, pauseAudio, muted, setMuted])


  React.useEffect(() => {
    setSrc(audioSrc)
  },[ setSrc, audioSrc])


  const AudioRef = React.useRef<HTMLAudioElement>(null);


    if (!playAudio) {
      if (playPromise !== undefined) {
          playPromise.then(_ => {
            if (AudioRef && AudioRef.current) {
              AudioRef.current.pause();
            }
          }).catch(error => {
              console.log('Audio Component Error   :   ',error)
          });  
      }
    }

  

  if (AudioRef && AudioRef.current && src.length && playAudio && !pauseAudio) {
    playPromise = AudioRef.current.play(); 
  }


  if (pauseAudio && playPromise !== undefined) {
        playPromise.then(_ => {
          if (AudioRef && AudioRef.current) {
            AudioRef.current.pause();
          }
        }).catch(error => {
            console.log('Audio Component Error   :   ',error)
        });  
     
  } 

 /* 
  console.log('playAudio    :   ', playAudio)
  console.log('pauseAudio   :   ', pauseAudio)
  console.log('audioSrc     :   ', audioSrc)
  console.log('    -    -    -    -    -    -   -')  */

  const handlePlay = () => { 
     setPauseAudio(false) 
  };


  const handlePause = () => {
    if (AudioRef && AudioRef.current) {
        AudioRef.current.pause();
        setPauseAudio(true);
    }
  };

  const handleReplay = () => {
    if (AudioRef && AudioRef.current) {
      AudioRef.current.currentTime = 0;
    }
  }

  const handleVolume = (val: number) => { 
    setVolume(val);
    if (AudioRef && AudioRef.current) {
        AudioRef.current.volume = val/100;
    }
};

const handleSkip = () => {
    if (AudioRef && AudioRef.current) {
        AudioRef.current.currentTime = 0;
        AudioRef.current.pause();
        setPauseAudio(true);
        handleEndAudio()
    }
}

const onEnd = () => {
  setMuted(true)
  setPlayAudio(false)
  setPauseAudio(true);
  setSrc('')
  handleEndAudio()
}

//
  return (
     <>
     <audio  
           webkit-playsinline = "true" 
           playsInline
           preload     = "auto"
           muted       = { muted }
           onEnded     = { onEnd }
           ref         = { AudioRef }
           src         = { src } 
     />
     {
            showButtons && playAudio
            && 
            (
              <div className = { classes.controlls } >
                  <SkipPreviousRoundedIcon               className = {classes.icon } onClick = { handleReplay }/>
                  { !pauseAudio && <PauseRoundedIcon     className = {classes.icon } onClick = { handlePause }/> }
                  { pauseAudio  && <PlayArrowRoundedIcon className = {classes.icon } onClick = { handlePlay }/> }
                  <VolumeSlider                          volume    = { volume } handleVolume = { handleVolume } />
                  <SkipNextRoundedIcon                   className = {classes.icon } onClick = { handleSkip }/>
              </div>
            )
           }
     </>
  )
}

export default Audio









