import { call, take, put }                             from 'redux-saga/effects';
import axios                                           from 'axios';
import { PRELOAD_VIDEO_SUCCESS, PRELOAD_VIDEO_FAILUR } from '../actions';

function* videoTest() {
  while(true) {
    try {

         const userInput = yield take('PRELOAD_VIDEO_SAGA');

         const { videoName, videoUrl } = userInput.payload;
    
         const response = yield call(
           axios.get,
           videoUrl,
           { 
            responseType: 'blob',
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
           }
         );

         if (response.status === 200) {

           //  console.log('video preloaded successfully')
             const payload = {
               videoBlob: URL.createObjectURL(response.data),
               videoName: videoName
             }
             
             yield put(PRELOAD_VIDEO_SUCCESS( payload ));

            }
         if (response.status !== 200) {
            console.log('video not preloaded successfully ')
            yield put(PRELOAD_VIDEO_FAILUR( false ));
         }
    }
    catch(err) {
      console.log('err  :  '+err);
      yield put(PRELOAD_VIDEO_FAILUR( false ));
    }
    finally {}
  }
}


export default videoTest;

