
const colors = {
    purple:       '#362C3C',
    brightPurple: '#403552',
    grey:         '#6576A4',
    darkGrey:     '#354674',
    orange:       '#F3AA4E',
    dark:         '#556694',
    red:          '#B55',
   // green_:       '#1A9',
    blue:         '#8596C4',
    lavan:        'rgba(250, 250, 250, 0.8)',
    halavi:       'rgba(250, 250, 250, 0.45)',
    kehe:         'rgba(0, 0, 0, 0.15)',
    pink:         'rgba(220, 80, 70, 0.75)',
    green:        'rgba(20, 180, 160, 0.75)',
    pink_:        'rgba(220, 100, 70, 0.5)',
    blue_:        'rgba(100, 110, 190, 0.75)',
    moka:         'rgba(230, 145, 85, 0.7)',
    brown:        '#AF762E',
    textShadow:   '0 5px 7px rgba(50,50,50,0.7)',
    button:       '#95A6D4',

}

export default colors;