import React          from 'react';
import colors         from '../../utils/colors';
import { makeStyles } from '@material-ui/core/styles';
import { words }      from '../../utils/dictionary';


const useStyles = makeStyles(theme => ({
    root: {
        width:          '100vw',
        display:        'flex',
        flexFlow:       'column',
        justifyContent: 'center',
        alignItems:     'center',
        transition:     'all 1s'
    },
    title: {
      color:    colors.darkGrey,
      fontSize: 18,
    },
    textField: {
          width:           '100%',
          marginTop:       20,
          border:          `1px solid ${ colors.darkGrey }`,
          backgroundColor: `transparent`,
          paddingLeft:     10,
          paddingRight:    10,
          borderRadius:    '0.25em',
          fontFamily:      'Varela Round',
          fontSize:        17,
          color:           colors.darkGrey,
          '&:focus': {
            outline: 'none',
            border:  `2px solid ${ colors.darkGrey }`,
           },
          '&::placeholder': {
             color: colors.darkGrey,
          }
    }
}));


type Form2Props = {
  size:              number[];
  emotionalVocab:    string;
  lang:              string;
  dir:               string;
  initialPos:        boolean;
  setEmotionalVocab: (text: string) => void;
}

const Form2: React.FC<Form2Props> = ({ 
    size,
    lang,
    dir,
    initialPos,
    emotionalVocab,
    setEmotionalVocab
}) => {

  const classes = useStyles();

  const changeInputValue = (e: React.FormEvent) => {

    e.preventDefault();

    const target = e.target as HTMLInputElement;

    setEmotionalVocab(target.value)

  }

  return (
    <div 
       className = { classes.root }
       dir       = { dir }
       style     = {{
         width:     size[0] > 900 ? '50%' : '75%',
         transform: `translate(0px, ${ initialPos ? 0 : 150 }vh`
       }}
    >
       <div className = { classes.title }>
         { `${ words['questionnaire_f_2'][lang] }` }
       </div>
       <textarea 
           rows         = { 10 } 
           className    = { classes.textField }
           onChange     = { changeInputValue }
           id           = "emotionalVocabulary"
           placeholder  = { emotionalVocab.length ? emotionalVocab : `${ words['questionnaire_f_2_text'][lang] }` }
       />
    </div>
    )
  
}

export default Form2







