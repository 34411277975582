import React          from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const arr = [
  `זיהוי ושיום רגשות`,
  `וויסות רגשי`,
  `תקשורת בריאה`,
  `אסרטיביות ותקשורת מונעת הסלמה`,
  `יכולת לצפות סיבה ותוצאה`,
  `פתרון בעיות וקבלת החלטות`,
  `חיזוק הקשר הורה - ילד`
]

const p1 = `אפליקציה זו מלמדת ומתרגלת באופן חוויתי ואינטראקטיבי:`
const p2 = `ובשתי מילים: אינטליגנציה רגשית.`

const arrEng = [
  `Identification of emotions`,
  `Emotional regulation`,
  `Healthy communication`,
  `Assertiveness and de-escalating communication`,
  `Cause and result prediction`,
  `problem solving and decision making`,
  `Strengthening parent-child bond`
]

const p1eng = `7:00 PM is an app for learning and practicing:`
const p2eng = `In two words: Emotional Intelligence`


const useStyles = makeStyles(theme => ({
  text_container: {
     height:          '75vh',
     minHeight:       300,
     width:           '100vw',
     display:         'flex',
     justifyContent:  'center',
     alignItems:      'center',
     flexFlow:        'column',
     backgroundColor: colors.halavi,
     transition:      'all 1.5s',
  },
  text: {
    width:          '100%',
    height:         '80%',
    textAlign:      'center',  
    display:        'flex',
    alignItems:     'space-around',
    justifyContent: 'space-around',
    flexFlow:       'column',
    color:          colors.darkGrey,
    fontWeight:     900,
    padding:        10,
  },
  list: {
    marginLeft:    '50%',
    transform:      'translate(-50%, 0%)', 
    border:         `1.5px solid ${ colors.darkGrey }`,//'#FFF'}`,
    borderRadius:   '1em',
    color:          colors.darkGrey,// '#FFF',
    textShadow:     '0 0 7px rgba(250, 250, 250, 1)',
    fontSize:       25,
    fontWeight:     900,
    padding:        15
  },
  li: {
     padding: 5
  },
}));


type TextCompProps = {
  size: number[];
  lang: string;
  dir:  string;
}

const TextComp: React.FC<TextCompProps> = ({ size, lang, dir }) => {

  const classes = useStyles();

  const textArr = React.useMemo(() => {
    return lang === 'eng' ? arrEng : arr
  },[lang])

  return (
        <div className = { classes.text_container }>
            <div 
               className = { classes.text }
               style     = {{
                fontSize: size[0] > 600 && size[1] > 650  ? 25 : 17,
               }}
            >
                <p dir = { dir }>
                  { lang === 'eng' ? p1eng : p1 }
                </p>
                <div 
                  className = { classes.list }
                  style     = {{ 
                    width:     size[0] > 1000 ? '40%' : '80%',
                    fontSize:  size[0] > 600 && size[1] > 650 ? 25 : 17,
                    textAlign: lang === 'eng' ? 'left' : 'right'

                  }}
                >
                  {
                    textArr.map((val, ind) => {
                        return(
                          <li key = {`li_${ind}`} dir = { dir } className = { classes.li } >{ val }</li>
                        )
                    })
                  }
                </div>
                <p dir = { dir }>
                  { lang === 'eng' ? p2eng : p2 }
                </p>
            </div>
         
        </div>
  )
}

export default TextComp

