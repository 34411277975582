import React                                     from 'react';

//import { connect }                               from 'react-redux';
//import { Dispatch }                              from "redux";
//import { ActionTypes, situationAndScenarioType } from '../../types/actions';
//import { reducerType }                           from '../../reducer';
//import { SUBMIT_COMPLETION_OF_SCENARIO_SAGA }    from '../../actions';

import { actionType }                            from '../../types';

import handIcon                                  from '../../graphic_components/icons/handIcon.png';
import wheelIcon                                 from '../../graphic_components/icons/wheelIcon.png';
import { makeStyles }                            from '@material-ui/core/styles';
import colors                                    from '../../utils/colors';
import { words }                                 from '../../utils/dictionary';

var clsx = require('classnames')

/**
 * doneForNow
end
 */

const useStyles = makeStyles(theme => ({
    root: {
        position:        'fixed',
        width:           '100vw',
        height:          '100vh',
        backgroundColor: 'transparent',
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center',
        zIndex:          3,
        transition:      'all 0.4s'
    },
    options: {
        width:          '55%',
        height:         '50%',
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        transition:     'all 1.3s'
    },
    button_and_text: {
      flex:           1,
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
      flexFlow:       'column',
    },
    button: {
      width:           190,
      height:          90,
      display:         'flex',
      justifyContent:  'center',
      alignItems:      'center',
      transition:      'transform 1s, width 0.4s',
      border:          '1px solid #FFF',
      cursor:          'pointer',
      borderRadius:    '1em',
      '&:hover': {
        width: 250
      }
    },
    pink: {
      backgroundColor: colors.pink,
    },
    blue: {
      backgroundColor: colors.blue_,
    },
    icon: {
      height:             55,
      width:              55,
      backgroundPosition: 'center',
      backgroundSize:     'contain',
      backgroundRepeat:   'no-repeat'
    },
    text: {
      color:          '#FFF',
      cursor:         'pointer',
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
      textAlign:      'center',
      textShadow:     '0 0 7px rgba(0, 0, 0, 1)',
    },
    column: {
      flexFlow: 'column'
    }
}));


type OptionMenuProps = {
  actionArr:           actionType[];
  size:                number[];
  action:              number;
  lang:                string;
  dir:                 string;
  handleGoToActionMenu:() => void;
  resetEverything:     () => void;
  setOpacity:          (val: boolean) => void;
  handleMouseEnter:    (val: number) => void;
  setActionArr:        (val: actionType[]) => void;
}

const OptionMenu: React.FC<OptionMenuProps> = ({
    size,
    actionArr,
    action,
    lang,
    dir,
    handleMouseEnter,
    resetEverything,
    handleGoToActionMenu,
    setOpacity,
    setActionArr,
  }) => {

  const classes = useStyles();

  const [showMenu, setShowMenu] = React.useState(false)

  React.useEffect(() => {
    setTimeout(function(){ setShowMenu(true); }, 800); 
   },[]);

  const handleEnd = () => {
    setActionArr(actionArr.map((val, i) => i === action ? { ...val, concluded: true } : val))
    setOpacity(false);
    setShowMenu(false);
    setTimeout(() => {
      resetEverything();
    },1000)
  }

  const handleTryAgain = () => {

    setActionArr(actionArr.map((val, i) => i === action ? { ...val, concluded: true } : val))
    setOpacity(false);
    setShowMenu(false)
    setTimeout(() => {
      handleGoToActionMenu()
    },1000)
  
  }

  return (
        <div className = { classes.root }>
                <div 
                    className = { size[0] > 1300 ? classes.options : clsx(classes.options, classes.column) }
                    style     = {{
                      marginTop: showMenu ? 0 : '200vh'
                    }}
                >
                    <div className = { classes.button_and_text }>
                        <div 
                           className    = { clsx(classes.button, classes.pink) }
                           onMouseEnter = { () => { handleMouseEnter(0) }}
                           onClick      = { handleEnd }
                        >
                            <div 
                               className = { classes.icon }
                               style     = {{
                                 backgroundImage: `url(${ handIcon })`
                               }}
                            />
                        </div>
                        <div 
                           dir          = { dir }
                           className    = { classes.text }
                           onClick      = { handleEnd }
                           style     = {{
                             fontSize:  size[0] > 1100 ? 35 : 25,
                             marginTop: size[0] > 600  ? 40 : 10,
                           }}
                        >
                            {`${ words['end'][lang] }`}
                        </div>
                    </div>
                    <div className = { classes.button_and_text }>
                        <div 
                           className    = { clsx(classes.button, classes.blue) }
                           onMouseEnter = { () => { handleMouseEnter(1) }}
                           onClick      = { handleTryAgain }
                        >
                            <div 
                               className = { classes.icon }
                               style     = {{
                                 backgroundImage: `url(${ wheelIcon })`
                               }}
                            />                        
                        </div>
                        <div 
                            dir          = { dir }
                            className    = { classes.text }
                            onClick      = { handleTryAgain }
                            style        = {{
                              fontSize:  size[0] > 1100 ? 35 : 25,
                              marginTop: size[0] > 600  ? 40 : 10,
                            }}
                        >
                            {`${ words['tryAgain'][lang] }`}
                        </div>
                    </div>
                </div>
        </div>
  )
}



export default OptionMenu;








/**
 * 
 * 
 * 
 * 
 * 
 * 
 * 
const mapStateToProps = (reducer: reducerType) => {
  return {
    profileId: reducer.profileId
  }
};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  updateScenarioTable: (payload: situationAndScenarioType ) => dispatch(SUBMIT_COMPLETION_OF_SCENARIO_SAGA(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionMenu);




 */













