
import ActionGirlEng                        from '../audio/action_girl_eng.mp3';
import { situationsObjType, scenariosType } from '../types';

const bucket    = (window.screen.width < 750 || window.screen.height < 550) ? `https://7pm-videos-compressed.s3.us-east-2.amazonaws.com` : `https://7pm-videos.s3.us-east-2.amazonaws.com`;
const bucketEng = (window.screen.width < 750 || window.screen.height < 550) ? `https://7pm-videos-compressed-eng.s3.us-east-2.amazonaws.com` : `https://7pm-videos-eng.s3.us-east-2.amazonaws.com`;

//   -  -  -  -  -  -  -  -  -  -  -  -  -  -   -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  - 


const shaming_emojis = [    
  { name: 'סיפוק',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/contentment.mp3' ,   image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/contentment.png' },
  { name: 'עלבון',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/insult.mp3' ,        image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/insult.png' },
  { name: 'הנאה',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/pleasure.mp3' ,      image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/pleasure.png' },
  { name: 'פחד',    audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/fear.mp3' ,          image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/fear.png' },
  { name: 'שמחה',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/hapiness.mp3' ,      image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/happiness.png' },
  { name: 'דאגה',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/concern.mp3',        image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/anxiety.png' },
  { name: 'אכזבה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/disapointment.mp3' , image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/disapointment.png' },
  { name: 'עצב',    audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/sadness.mp3' ,       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/sadness.png' },
  { name: 'ניטרלי', audio: '',                                                                       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/nutral.png' },
  { name: 'כעס',    audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/anger.mp3' ,         image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/anger.png' }
]

const shaming_emojis_eng = [    
  { name: 'satisfied',    audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/satisfied.mp3' ,    image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/contentment.png' },
  { name: 'embarrassed',  audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/embarrassed.mp3' ,  image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/insult.png' },
  { name: 'pleasure',     audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/pleasure.mp3' ,     image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/pleasure.png' },
  { name: 'afraid',       audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/afraid.mp3' ,       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/fear.png' },
  { name: 'happy',        audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/happy.mp3' ,        image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/happiness.png' },
  { name: 'worried',      audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/worried.mp3',       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/anxiety.png' },
  { name: 'disappointed', audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/disappointed.mp3' , image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/disapointment.png' },
  { name: 'sad',          audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/sad.mp3' ,          image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/sadness.png' },
  { name: 'neutral',      audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/neutral.mp3',       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/nutral.png' },
  { name: 'angry',        audio: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/emojis/angry.mp3' ,        image: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/emojis/anger.png' }
]

const clean_room_emojis = [
  { name: 'כעס',    audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/anger.mp3' ,         image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/anger.png' },
  { name: 'אכזבה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/disapointment.mp3' , image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/disapointment.png' },
  { name: 'שמחה',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/hapiness.mp3' ,      image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/hapiness.png' },
  { name: 'עצבנות', audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/edginess.mp3',       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/edginess.png' },
  { name: 'עייפות', audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/fatigue.mp3',        image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/fatigue.png' },
  { name: 'הנאה',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/pleasure.mp3' ,      image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/pleasure.png' },
  { name: 'עצב',    audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/sadness.mp3' ,       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/sadness.png' },
  { name: 'פחד',    audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/fear.mp3' ,          image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/fear.png' },
  { name: 'רגיל',   audio: '',                                                                       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/emojis/normal.png' }
]


const ball_thief_emojis = [
  { name: 'כעס',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/anger.mp3' ,         image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/anger.png' },
  { name: 'בלבול', audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/confusion.mp3' ,     image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/confusion.png' },
  { name: 'אכזבה', audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/disapointment.mp3' , image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/disapointment.png' },
  { name: 'פחד',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/fear.mp3' ,          image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/fear.png' },
  { name: 'שמחה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/hapiness.mp3' ,      image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/happiness.png' },
  { name: 'הנאה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/pleasure.mp3' ,      image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/joy.png' },
  { name: 'חרטה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/regret.mp3' ,        image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/regret.png' },
  { name: 'עצב',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/sadness.mp3' ,       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/sadness.png' },
  { name: 'בושה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/shame.mp3' ,         image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/shame.png' },
  { name: 'חברות', audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/friendship.mp3' ,    image: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/emojis/friendship.png' }
]

const cookie_jar_emojis = [
  { name: 'סיפוק', audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/contentment.mp3' , image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/contentment.png' },
  { name: 'בושה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/shame.mp3' ,       image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/shame.png' },
  { name: 'הקלה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/relief.mp3' ,      image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/relief.png' },
  { name: 'הנאה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/pleasure.mp3',     image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/pride.png' },
  { name: 'פחד',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/fear.mp3' ,        image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/fear.png' },
  { name: 'שמחה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/hapiness.mp3' ,    image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/happiness.png' },
  { name: 'חרטה',  audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/regret.mp3' ,      image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/regret.png' },
  { name: 'עצב',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/sadness.mp3' ,     image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/sadness.png' },
  { name: 'כאב',   audio: 'https://7pm-audio.s3.us-east-2.amazonaws.com/emojis/pain.mp3' ,        image: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/emojis/pain.png' },
]


//   -  -  -  -  -  -  -  -  -  -  -  -  -  -   -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  - 

const audioBoy = {
  action:            'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/action_boy.wav',
  result:            'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/result_boy.wav',
  emotion_menu:      'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/emotion_menu_boy.wav',
  conclusion_test:   'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/conclusion_test_boy.wav',
  conclusion_result: 'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/conclusion_result_boy.wav',
}

const audioGirl = {
  action:            'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/action_girl.wav',
  result:            'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/result_girl.wav',
  emotion_menu:      'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/emotion_menu_girl.wav',
  conclusion_test:   'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/conclusion_test_girl.wav',
  conclusion_result: 'https://7pm-audio.s3.us-east-2.amazonaws.com/audio/conclusion_result_girl.wav',
}

const audioGirlEng = {
  action:            ActionGirlEng,
  result:            'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/result_girl.mp3',
  emotion_menu:      'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/emotion_menu_girl.mp3',
  conclusion_test:   'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/conclusion_test_girl.mp3',
  conclusion_result: 'https://7pm-audio-eng.s3.us-east-2.amazonaws.com/audio/conclusion_result_girl.mp3',
}


//   -  -  -  -  -  -  -  -  -  -  -  -  -  -   -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  - 


export const situations: situationsObjType = {
  shaming: {
    id:                 -1,
    completed:          0,
    name:               'shaming',
    title:              'חברים העליבו אותי',
    text:               '',
    audio:              audioGirl,
    audioEng:           audioGirlEng,
    introVideoEng:      `${ bucket }/shaming/intro.mp4`,
    introVideo:         `${ bucket }/shaming/intro.mp4`,
    videoEng:           `${ bucketEng }/shaming/shaming_situation.mp4`,
    video:              `${ bucket }/shaming/shaming_situation.mp4`,
    gingleEng:          `${ bucketEng }/shaming/gingle.mp4`,
    gingle:             `${ bucket }/shaming/gingle.mp4`,
    videoDownLoaded:    false,
    gingleDownLoaded:   false,
    image:              'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/shaming_situation_image.png',
    large_figure:       'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/lioness_image.png',
    small_figure:       'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/lioness_image_small.png',
    background:         'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/shaming_situation_background.png',
    emojis:             shaming_emojis,
    emojis_eng:         shaming_emojis_eng,
    acceptEmojis:       [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    magicVideoSrcEng:   `${ bucketEng }/shaming/shaming_magic.mp4`,
    magicVideoSrc:      `${ bucket }/shaming/shaming_magic.mp4`,
    magicDownloaded:    false,
    magicText:          '',
    magicBackground:    'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/shaming_magic_background.png',
    emotionMenuText:    ['girlEmotionMenuText1', 'girlEmotionMenuText2'],
    resultText:         ['girlresultText0', 'girlresultText1', 'girlresultText2', 'girlresultText3'],
    actionMenuText:     [`girlActionMenuText1`, `girlActionMenuText2`],
  },
  clean_room: {
      id:                 -1,
      completed:          0,
      name:               'clean_room',
      title:              'לא רוצה לאסוף צעצועים',
      text:               'אמו של נובי ביקשה ממנו לסדר את הצעצועים',
      audio:              audioBoy,
      audioEng:           audioGirlEng,
      introVideoEng:      '',
      introVideo:         '',
      videoEng:           '',
      video:              `${ bucket }/clean_room/situation_cleanup_room.mp4`,
      gingleEng:          '',
      gingle:             `${ bucket }/shaming/gingle.mp4`,
      videoDownLoaded:    false,
      gingleDownLoaded:   false,  
      image:              'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/situationCleanRoom.png',
      large_figure:       'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/lion_image.png',
      small_figure:       'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/lion_image_small.png',
      background:         'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/aaa.png',
      emojis:             clean_room_emojis,
      emojis_eng:         shaming_emojis_eng,
      acceptEmojis:       [1, 1, 1, 1, 1, 1, 1, 1, 1],
      magicVideoSrcEng:   '',
      magicVideoSrc:      `${ bucket }/clean_room/clean_up_room_with_magic.mp4`,
      magicDownloaded:    false,
      magicText:          'נובי ניסה את כוחו בטלקניזיס',
      magicBackground:    'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/clean_room_magic_background.png',
      emotionMenuText:    ['boyEmotionMenuText1', 'boyEmotionMenuText2'],
      resultText:         ['boyresultText0', 'boyresultText1', 'boyresultText2', 'boyresultText3'],
      actionMenuText:     [`boyActionMenuText1`, `boyActionMenuText2`],
  },
  ball_thief: {
     id:                 -1,
     completed:          0,
     name:               'ball_thief',
     title:              'לקחו לי משחק',
     text:               'נל לקחה את כדורו של נובי לעצמה והחלה לשחק בו',
     audio:              audioBoy,
     audioEng:           audioGirlEng,
     introVideoEng:      '',
     introVideo:         '',
     videoEng:           '',
     video:              `${ bucket }/ball_thief/ball_thief_situation.mp4`,
     gingleEng:          '',
     gingle:             `${ bucket }/ball_thief/gingle.mp4`,
     videoDownLoaded:    false,
     gingleDownLoaded:   false, 
     image:              'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/ball_thief_situation.png',
     large_figure:       'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/boy_image.png',
     small_figure:       'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/boy_image_small.png',
     background:         'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/ball_thief_situation.png',
     emojis:             ball_thief_emojis,
     emojis_eng:         shaming_emojis_eng,
     acceptEmojis:       [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
     magicVideoSrcEng:   '',
     magicVideoSrc:      `${ bucket }/ball_thief/ball_thief_magic.mp4`,
     magicDownloaded:    false,
     magicText:          'נובי השתמש בכוחות העל שלו כדי להשיב לעצמו את הכדור',
     magicBackground:    'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/magic_background.png',
     emotionMenuText:    ['boyEmotionMenuText1', 'boyEmotionMenuText2'],
     resultText:         ['boyresultText0', 'boyresultText1', 'boyresultText2', 'boyresultText3'],
     actionMenuText:     [`boyActionMenuText1`, `boyActionMenuText2`],
  },
  cookie_jar: {
    id:                 -1,
    completed:          0,
    name:               'cookie_jar',
    title:              'קשה לי לומר את האמת',
    text:               'נל שברה בטעות את צנצנת העוגיות',
    audio:              audioGirl,
    audioEng:           audioGirlEng,
    introVideoEng:      '',
    introVideo:         `${ bucket }/cookie_jar/intro.mp4`,
    videoEng:           '',
    video:              `${ bucket }/cookie_jar/cookie_jar_situation.mp4`,
    gingleEng:          '',
    gingle:             `${ bucket }/cookie_jar/gingle.mp4`,
    videoDownLoaded:    false,
    gingleDownLoaded:   false,
    image:              'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/cookie_jar_image.png',
    large_figure:       'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/girl_image.png',
    small_figure:       'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/girl_image_small.png',
    background:         'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/cookie_jar_situation.png',
    emojis:             cookie_jar_emojis,
    emojis_eng:         shaming_emojis_eng,
    acceptEmojis:       [1, 1, 1, 1, 1, 1, 1, 1, 1],
    magicVideoSrcEng:   '',
    magicVideoSrc:      `${ bucket }/cookie_jar/cookie_jar_magic.mp4`,
    magicDownloaded:    false,
    magicText:          'נל השיבה את מצב הצנצנת לקדמותו באמצעות מעשה קסמים',
    magicBackground:    'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/magic_background.png',
    emotionMenuText:    ['girlEmotionMenuText1', 'girlEmotionMenuText2'],
    resultText:         ['girlresultText0', 'girlresultText1', 'girlresultText2', 'girlresultText3'],
    actionMenuText:     [`girlActionMenuText1`, `girlActionMenuText2`],
  },
}


export const scenarios: scenariosType = {
  shaming: [   
    { deg: 0,   custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/accept_criticism_background.png', actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/accept_criticism_action.png', action: 'לענות באסרטיביות ולהמשיך לשחק', actionEng: 'Respond assertively and continue playing',  actionVideoEng: `${ bucketEng }/shaming/accept_criticism.mp4`,  actionVideo: `${ bucket }/shaming/accept_criticism.mp4`, actionText: '',     resultText: '',     result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/accept_criticism_res_bgrnd.png', resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/small/accept_criticism_res_small.png', resultVideoEng: `${ bucketEng }/shaming/accept_criticism_result.mp4`,  resultVideo: `${ bucket }/shaming/accept_criticism_result.mp4`, acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }, 
    { deg: 60,  custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/be_a_snob_background.png',        actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/be_a_snob_action.png',        action: 'לענות באסרטיביות וללכת',        actionEng: 'Respond assertively and walk away',         actionVideoEng: `${ bucketEng }/shaming/be_a_snob.mp4`,         actionVideo: `${ bucket }/shaming/be_a_snob.mp4`,        actionText: '',     resultText: '',     result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/be_a_snob_res_bgrnd.png',        resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/small/be_a_snob_res_small.png',        resultVideoEng: `${ bucketEng }/shaming/be_a_snob_result.mp4`,         resultVideo: `${ bucket }/shaming/be_a_snob_result.mp4`,        acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }, 
    { deg: 120, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/cry_background.png',              actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/cry_action.png',              action: 'לבכות וללכת',                   actionEng: 'Cry and walk away',                         actionVideoEng: `${ bucketEng }/shaming/cry.mp4`,               actionVideo: `${ bucket }/shaming/cry.mp4`,              actionText: '',     resultText: '',     result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/cry_res_bgrnd.png',              resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/small/cry_res_small.png',              resultVideoEng: `${ bucketEng }/shaming/cry_result.mp4`,               resultVideo: `${ bucket }/shaming/cry_result.mp4`,              acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false }, 
    { deg: 180, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/fight_background.png',            actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/fight_action.png',            action: 'להרביץ',                        actionEng: 'Fight',                                     actionVideoEng: `${ bucketEng }/shaming/fight.mp4`,             actionVideo: `${ bucket }/shaming/fight.mp4`,            actionText: '',     resultText: '',     result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/fight_res_bgrnd.png',            resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/small/fight_res_small.png',            resultVideoEng: `${ bucketEng }/shaming/fight_result.mp4`,             resultVideo: `${ bucket }/shaming/fight_result.mp4`,            acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false }, 
    { deg: 240, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/shame_friends_background.png',    actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/actions/shame_friends_action.png',    action: 'להעליב בחזרה',                  actionEng: 'Insult friends',                            actionVideoEng: `${ bucketEng }/shaming/shame_friends.mp4`,     actionVideo: `${ bucket }/shaming/shame_friends.mp4`,    actionText: '',     resultText: '',     result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/backgrounds/shame_friends_res_bgrnd.png',    resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/small/shame_friends_res_small.png',    resultVideoEng: `${ bucketEng }/shaming/shame_friends_result.mp4`,     resultVideo: `${ bucket }/shaming/shame_friends_result.mp4`,    acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false },
    { deg: 300, custom: true,  viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/shaming_situation_image.png',                 actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/vidIconPng.png',                              action: 'קליפלי',                        actionEng: 'Myclip',                                    actionVideoEng: '',                                             actionVideo: '',                                         actionText: 'none', resultText: 'none', result: 'none', resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/shaming/shaming_situation_image.png',                resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/vidIconGreen.png',                             resultVideoEng: '',                                                    resultVideo: '',                                                acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }
  ],
  clean_room: [
    { deg: 0,   custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/clean_room_with_mother_background.png', actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/actions/clean_room_with_mother_action.png', action: 'לסדר ביחד עם אמא',  actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/clean_room/clean_up_room_with_mother.mp4`, actionText: 'נובי ביקש מאמו לסדר את הצעצועים יחד עמו, אמו נעתרה לבקשתו',  resultText: 'לאחר שסידרו את הצעצועים יחד, אמו של נובי הקריאה לו סיפור לפני השינה',                      result: 'read_story_with_mother', resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/clean_room_with_mother_result.png',  resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/small/clean_room_with_mother_result_small.png', resultVideoEng: '',  resultVideo: `${ bucket }/clean_room/read_story_with_mother.mp4`,   acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1],  correct: true }, 
    { deg: 120, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/destroy_room_background.png',           actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/actions/destroy_room_action.png',           action: 'לבעוט בצעצועים',    actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/clean_room/destroy_room.mp4`,              actionText: 'במקום לאסוף את הצעצועים, נובי השתולל וזרע הרס בחדרו',        resultText: 'כתוצאה מכך, נובי התעורר באמצע הלילה ונבהל מהצללים המפחידים שהטילו הצעצועים הפזורים בחדרו', result: 'wake_up_in_fear',        resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/backgrounds/wake_up_in_fear_background.png',     resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/small/wake_up_in_fear_small.png',               resultVideoEng: '',  resultVideo: `${ bucket }/clean_room/wake_up_in_fear.mp4`,          acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1],  correct: false }, 
    { deg: 240, custom: true,  viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/aaa.png',                                           actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/vidIconPng.png',                                       action: 'קליפלי',            actionEng: '', actionVideoEng: '',  actionVideo: '',                                                     actionText: 'none',                                                       resultText: 'none',                                                                                    result: 'none',                   resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/clean_room/aaa.png',                                         resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/vidIconGreen.png',                                         resultVideoEng: '',  resultVideo: '',                                                                                      acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }
  //  { deg: 0,   custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: clean_room_game_bckgrd,     actionImg: Test1,                action: 'לסדר הצעצועים כמשחק',       actionVideoEng: '',  actionVideo: clean_up_room,             actionText: 'נובי בחר להפוך את סידור הצעצועים למשחק',                     resultText: 'נל, ידידתו של נובי משכבר  הימים, הצטרפה אליו למשחק והם נהנו מאוד',                          result: 'play_with_sister',         resultImg: clean_room_game_result,   resultImgSmall: clean_room_game_result_small,   resultVideoEng: '',  resultVideo: play_with_sister,         acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }, 
  //  { deg: 180, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: clean_room_magic_bckgrd,    actionImg: Test4,                action: 'clean_up_room_magic',       actionVideoEng: '',  actionVideo: clean_up_room_with_magic,  actionText: 'נובי ניסה את כוחו בטלקניזיס',                                resultText: 'לנוכח תוצאות ניסיון זה הק הילה המדעית הוכתה בתדהמה',                                        result: 'clean_up_room_with_magic', resultImg: clean_room_magic_bckgrd,  resultImgSmall: clean_room_magic_small,         resultVideoEng: '',  resultVideo: clean_up_room_with_magic, acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false }, 
  //  { deg: 240, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: destroy_room_bckgrd,        actionImg: destroy_room_action,  action: 'לסרב לסדר את הצעצועים',     actionVideoEng: '',  actionVideo: destroy_room,              actionText: 'במקום לאסוף את הצעצועים, נובי השתולל וזרע הרס בחדרו',        resultText: 'כתוצאה מכך, נובי התעורר באמצע הלילה ונבהל מהצללים המפחידים שהטילו הצעצועים הפזורים בחדרו', result: 'wake_up_in_fear',          resultImg: wake_up_in_fear_bckgrd,   resultImgSmall: wake_up_in_fear_bckgrd_small,   resultVideoEng: '',  resultVideo: wake_up_in_fear,          acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false },

  ],
  ball_thief: [
    { deg: 0,   custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/aggresivly_demand_background.png',   actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/aggresivly_demand_action.png',   action: 'לבקש בכעס',    actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/ball_thief/aggresivly_demand_ball.mp4`, actionText: 'נובי דרש בכעס את הכדור בחזרה',      resultText: 'נל הגיבה בכעס ובעטה את הכדור רחוק',     result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/aggresivly_demand_res_bgrnd.png', resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/small/aggresivly_demand_res_small.png', resultVideoEng: '',  resultVideo: `${ bucket }/ball_thief/aggresivly_demand_ball_result.mp4`, acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false }, 
    { deg: 60,  custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/politely_demand_background.png',     actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/politely_demand_action.png',     action: 'לבקש יפה',     actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/ball_thief/politely_demand_ball.mp4`,   actionText: 'נובי ביקש באדיבות את הכדור בחזרה',  resultText: 'נל השיבה לנובי את הכדור שלו באדיבות',   result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/politely_demand_res_bgrnd.png',   resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/small/politely_demand_res_small.png',   resultVideoEng: '',  resultVideo: `${ bucket }/ball_thief/politely_demand_ball_result.mp4`,   acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }, 
    { deg: 120, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/attack_background.png',              actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/attack_action.png',              action: 'לבעוט ולריב',  actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/ball_thief/attack_ball_thief.mp4`,      actionText: 'נובי התקוטט עם נל',                 resultText: 'נובי ונל נותרו מוכים וחבולים',          result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/attack_res_bgrnd.png',            resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/small/attack_res_small.png',            resultVideoEng: '',  resultVideo: `${ bucket }/ball_thief/attack_ball_thief_result.mp4`,      acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false }, 
    { deg: 180, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/play_together_background.png',       actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/play_together_action.png',       action: 'לשחק ביחד',    actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/ball_thief/play_together.mp4`,          actionText: 'נובי בחר לשחק בכדור יחד עם נל',     resultText: 'נובי ונל נהנו מאוד מהמשחק המשותף',      result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/play_together_res_bgrnd.png',     resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/small/play_together_res_small.png',     resultVideoEng: '',  resultVideo: `${ bucket }/ball_thief/play_together_result.mp4`,          acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }, 
    { deg: 240, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/surrender_background.png',           actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/actions/surrender_action.png',           action: 'לא לבקש',      actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/ball_thief/surrender.mp4`,              actionText: 'נובי בחר לא לבקש את הכדור בחזרה',   resultText: 'נל שיחקה לבדה בכדור מבלי לשתף את נובי', result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/backgrounds/surrender_res_bgrnd.png',         resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/small/surrender_res_small.png',         resultVideoEng: '',  resultVideo: `${ bucket }/ball_thief/surrender_result.mp4`,              acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false },
    { deg: 300, custom: true,  viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/ball_thief_situation.png',                       actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/vidIconPng.png',                                    action: 'קליפלי',       actionEng: '', actionVideoEng: '',  actionVideo: '',                                                  actionText: 'none',                              resultText: 'none',                                  result: 'none', resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/ball_thief/ball_thief_situation.png',                    resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/vidIconGreen.png',                                 resultVideoEng: '',  resultVideo: '',                                                                                           acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }
  ],
  cookie_jar: [
    { deg: 0,   custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/tell_the_truth_background.png',       actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/tell_the_truth_action.png',       action: 'לבוא ולומר את האמת', actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/cookie_jar/tell_the_truth.mp4`,       actionText: 'נל הלכה אל אביה וסיפרה לא את אשר ארע',              resultText: 'אביה של נל שיבח אותה על יושרה',                result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/tell_the_truth_result_background.png',       resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/small/admit_truth_res_small.png',      resultVideoEng: '',  resultVideo: `${ bucket }/cookie_jar/tell_the_truth_result.mp4`,       acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }, 
    { deg: 60,  custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/admit_the_truth_background.png',      actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/admit_the_truth_action.png',      action: 'לענות אמת',          actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/cookie_jar/admit_the_truth.mp4`,      actionText: 'נל הודתה באמת',                                     resultText: 'אביה של נל סלח לה על הטעות שעשתה',             result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/admit_the_truth_result_background.png',      resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/small/admit_truth_res_small.png',      resultVideoEng: '',  resultVideo: `${ bucket }/cookie_jar/admit_the_truth_result.mp4`,      acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }, 
    { deg: 120, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/dont_admit_the_truth_background.png', actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/dont_admit_the_truth_action.png', action: 'לא להודות באמת',     actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/cookie_jar/dont_admit_the_truth.mp4`, actionText: 'נל בחרה שלא להודות באשמתה',                         resultText: 'אביה של נל גילה שאין לה אומץ להודות באמת',     result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/dont_admit_the_truth_result_background.png', resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/small/dont_admit_truth_res_small.png', resultVideoEng: '',  resultVideo: `${ bucket }/cookie_jar/dont_admit_the_truth_result.mp4`, acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false }, 
    { deg: 180, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/lie_background.png',                  actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/lie_action.png',                  action: 'לשקר',               actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/cookie_jar/lie.mp4`,                  actionText: 'נל שיקרה לאביה',                                    resultText: 'אביה של נל חש צער על כך שבתו שיקרה לו',        result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/lie_result_background.png',                  resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/small/lie_res_small.png',              resultVideoEng: '',  resultVideo: `${ bucket }/cookie_jar/lie_result.mp4`,                  acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false }, 
    { deg: 240, custom: false, viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/hide_evidence_background.png',        actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/actions/hide_evidence_action.png',        action: 'להסתיר את מה שקרה',  actionEng: '', actionVideoEng: '',  actionVideo: `${ bucket }/cookie_jar/hide_evidence.mp4`,        actionText: 'נל בחרה להסתיר את שברי הצנצנת, ונפצעה משבר זכוכית', resultText: 'אביה של נל גילה את אשר עשתה ואת ניסון ההסתרה', result: '',     resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/backgrounds/hide_evidence_result_background.png',        resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/small/hide_evidence_small.png',        resultVideoEng: '',  resultVideo: `${ bucket }/cookie_jar/hide_evidence_result.mp4`,        acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: false },
    { deg: 300, custom: true,  viewed: false, downloaded: false,  resultViewed: false, resultDownloaded: false,  concluded: false, actionBground: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/cookie_jar_situation.png',                        actionImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/vidIconPng.png',                                     action: 'קליפלי',             actionEng: '', actionVideoEng: '',  actionVideo: '',                                                                                  actionText: 'none',                                              resultText: 'none',                                         result: 'none', resultImg: 'https://7pm-images.s3.us-east-2.amazonaws.com/cookie_jar/cookie_jar_situation.png',                               resultImgSmall: 'https://7pm-images.s3.us-east-2.amazonaws.com/vidIconGreen.png',                                resultVideoEng: '',  resultVideo: '',                                                                                         acceptEmojis: [1, 1, 1, 1, 1, 1, 1, 1, 1], correct: true }
  ]
}
















