import React              from 'react';
import ScenariosSmall     from './ScenariosSmall';
import { actionType }     from '../../types';
import { makeStyles }     from '@material-ui/core/styles';
import { words }          from '../../utils/dictionary';


const useStyles = makeStyles({
  root: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column',
    marginLeft:     10,
    marginRight:    10,
  },
  container: {
    width:          '100%',
    overflowY:      'scroll',
    overflowX:      'hidden',
    border:         '1px solid #FFF',
    borderRadius:   '1em',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(250, 250, 250, 0.7)',
      borderRadius:    10
    }
  },
  scenarios: {
    position:       'relative',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column'
  },
  title_container: {
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
  },
  title: {
    flex:      1,
    textAlign: 'center',
    color:     '#FFF'
  },
  space: {
    height: 20
  }
 
});

type ScenariosBigProps = {
    size:                     number[];
    speed:                    number;
    action:                   number;
    iconSize:                 number;
    lang:                     string;
    dir:                      string;
    station:                  string;
    closeBigScenario:         boolean;
    actionArr:                actionType[];
    setCloseBigScenario:      (val: boolean) => void;
    setShowMinimizedScenario: (val: boolean) => void;
}
  
const ScenariosBig: React.FC<ScenariosBigProps> = ({ 
    size,
    speed,
    action,
    iconSize,
    lang,
    dir,
    station,
    closeBigScenario,
    actionArr,
    setCloseBigScenario,
    setShowMinimizedScenario
}) => {

  const classes = useStyles();

  const [initialPos, setInitialPos] = React.useState(false)

  React.useEffect(() => {
    if (!closeBigScenario) {
      setTimeout(() => {
        setInitialPos(true)
      },10)
    }
    else {
      setInitialPos(false)
      setTimeout(() => {
        setShowMinimizedScenario(true)
        setCloseBigScenario(true)
      },speed)
    }
  },[speed, closeBigScenario, setInitialPos, setCloseBigScenario, setShowMinimizedScenario])



  return (
    <div 
       className = { classes.root }
       style     = {{
           width: iconSize * 2 + 30,
           height: Math.min(iconSize * 5.4, size[1]*0.85)
       }}
    >
          <div 
               dir       = { dir }
               className = { classes.title_container }
          >
                    <div className = { classes.title }>
                      { `${ words['action'][lang] }` }
                    </div>
                    <div className = { classes.title }>
                      { `${ words['result'][lang] }` }
                    </div>
          </div>
          <div 
              className = { classes.container }
              style     = {{
                height:     initialPos ? '100%' : 1,
                transition: `${ speed / 1000 }s`
              }}
          >
              <div className = { classes.scenarios }>
                  {
                      actionArr.map((val, ind) => {
                        return(
                            val.action === 'none'
                            ?
                            <div 
                               key       = { `ScenariosSmall_${ ind }` }
                               className = { classes.space } 
                            />
                            :
                            <ScenariosSmall
                                key       = { `ScenariosSmall_${ ind }` }
                                action    = { ind }
                                speed     = { speed }
                                lang      = { lang }
                                dir       = { dir }
                                iconSize  = { iconSize }
                                station   = { station }
                                actionArr = { actionArr }
                                withTitle = { false }
                                current   = { action === ind }
                            />
                        )
                      })
                  }
              </div>
          </div>
    </div>
  );
}

export default ScenariosBig