import React          from 'react';
import crown          from '../../graphic_components/crown_golden.png';
import { makeStyles } from '@material-ui/core/styles';

var clsx = require('classnames')

const bright = '0px 0px 20px 20px rgba(250, 250, 250, 0.6)';
const red    = '0px 0px 20px 20px rgba(250, 0, 0, 0.6)';
const none   = '0px 0px 0px 0px transparent';

const useStyles = makeStyles(theme => ({
    container: {
       display:        'flex',
       alignItems:     'center',
       justifyContent: 'center',
       float:          'left',
       position:       'fixed',
       zIndex:         2,
       borderRadius:   '50%',
       transition:     'box-shadow 1s'
    },
    crown: {
       backgroundPosition: 'center',
       backgroundRepeat:   'no-repeat',
       backgroundSize:     'contain',
       cursor:             'grab',
    },
    shake: {
      animation:               'shake 0.5s infinite',
      animationIterationCount: '0.5s',
    },
}));

type GhostProps = { 
  position:    number[];
  width:       number;
  height:      number;
  ghostBright: boolean;
  shake:       boolean;
}

const Ghost: React.FC<GhostProps> = ({ position, ghostBright, width, height, shake }) => {

  const classes = useStyles();

  return (
    <div
       className = { !shake ? classes.container : clsx(classes.container, classes.shake) }
       style     = {{
        width:      width + 30,
        height:     height + 30,
        left:       position[0], 
        top:        position[1],
        boxShadow:  ghostBright ? bright : (shake ? red : none)
       }}
    >
        <div 
          className = { classes.crown }
          style     = {{ 
            backgroundImage: `url(${ crown })`,
            width:           width,
            height:          height,
          }}
        />
    </div>
   )
}

export default Ghost;


