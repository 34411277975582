import React              from 'react';
import colors             from '../../utils/colors'
import { actionType }     from '../../types';
import { makeStyles }     from '@material-ui/core/styles';
import { words }          from '../../utils/dictionary';


var clsx = require('classnames')

const useStyles = makeStyles(theme => ({
  root: {
    position:       'fixed',
    left:            0,
    width:          '100vw',
    height:         '100vh',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    transition:     'all 0.4s',
  },
  container: {
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    borderRadius:    '1em',
    backgroundColor: `rgba(0, 0, 0, 0.25)`,
    border:          '1px solid #FFF',
    padding:         15
  },
  modal: {
    width:           '100%',
    height:          '100%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    flexFlow:        'column',
    textAlign:       'center',
   // backgroundColor: `rgba(250, 250, 250, 0.65)`,
    color:           '#FFF',
    textShadow:      '0 0 7px rgba(0, 0, 0, 1)',
    fontSize:        30,
    fontWeight:      'bold',
    borderRadius:    '1em',
  },
  buttons: {
    width:          '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'space-around',
  },
  button: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    textAlign:      'center',
    width:           300,
    height:          85,
    marginTop:       35,
    color:           '#FFF',
    fontSize:        25,
    borderRadius:    '1em',
    cursor:          'pointer',
    border:          `1px solid #FFF`,
    boxShadow:       '0 5px 5px rgba(0,0,0,0.4)',
    zIndex:          1,
    transition:      'all 0.4s',
    '&:hover': {
      width: 335
    }
  },
  pink: {
    backgroundColor: colors.pink
  },
  green: {
    backgroundColor: colors.green
  }
}));


type VideoModalProps = {
  size:                  number[];
  lang:                  string;
  dir:                   string;
  showUserVideoModal:    boolean;
  showUserVideo:         boolean;
  userStoryCreated:      boolean;
  redoSituation:         boolean;
  actionArr:             actionType[];
  goToVideoUpload:       () => void;
  handlePlay:            () => void;
  setVideoSrc:           (val: string) => void;
  setShowUserVideoModal: (val: boolean) => void;
 }

const VideoModal: React.FC<VideoModalProps> = ({ 
    size,
    lang,
    dir,
    showUserVideoModal,
    showUserVideo,
    userStoryCreated,
    redoSituation,
    actionArr,
    setVideoSrc,
    goToVideoUpload,
    handlePlay,
    setShowUserVideoModal,
}) => {

  const classes = useStyles();


  const [containerWidth, containerHeight, border, fontSize] = React.useMemo(() => {
      return (
               (size[0] > 800 && size[1] > 600)
               ?
               ['65vw', 350, '1px solid #FFF', 34]
               :
               [size[0], size[1]-30, 'none', 25])
  },[size])

  
  const handleViewVideos = () => {
      if (!userStoryCreated) { return } 
      setVideoSrc(actionArr[actionArr.length-1].actionVideo)
      setShowUserVideoModal(false)
      setTimeout(() => {
        handlePlay()
      },500)
    
  }

  const handleUploadVideos = () => {
    
    setShowUserVideoModal(false)
    setTimeout(() => {
        goToVideoUpload()
    },500)
  
}
//
  return (
    <div
      dir       = { dir }
      className = { classes.root }
      style     = {{
        top: showUserVideoModal ? 0 : '-150vh'
      }}
    >
       <div 
            className = { classes.container }
            style     = {{
                width:  containerWidth,
                height: containerHeight,
                border: border,
                fontSize:fontSize
            }}
       >
          <div className = { classes.modal }>
              <p>
                 { (showUserVideo || redoSituation) ? `${ words['my_clip'][lang] }` : `${ words['user_video_err'][lang] }` }
              </p>
              {
                (showUserVideo || redoSituation)
                &&
                <div 
                   className = { classes.buttons }
                   style     = {{
                       flexFlow: size[0] > 650 ? 'row' : 'column'
                   }}
                >
                        <div 
                            dir       = { dir }
                            className = { clsx(classes.button, classes.green) }
                            onClick   = { handleViewVideos }
                            style     = {{
                                opacity: !userStoryCreated ? 0.3 : 1,
                                cursor:  !userStoryCreated ? 'default' : 'pointer'
                            }} 
                        >
                           { `${ words['view_user_videos'][lang] }` }
                        </div>
                        <div 
                            dir       = { dir }
                            className = { clsx(classes.button, classes.pink) }
                            onClick   = { handleUploadVideos }
                        >
                           { `${ words['create_user_videos'][lang] }` }
                        </div>
                </div>
              }
          </div>
        </div>
    </div>
   )
}

export default VideoModal
