import React               from 'react';

import colors              from '../../utils/colors';
import logo                from '../../graphic_components/logo.png';
import { texts, textsEng } from './texts';

import Text                from './Text';
import Menu                from './Menu';

import { makeStyles }      from '@material-ui/core/styles';
import { words }           from '../../utils/dictionary';
 
var clsx = require('classnames')

const useStyles = makeStyles({
  flex: {
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    flexFlow:        'column',
  },
  root: {
    position:       'fixed',
    top:            0,
    left:           0,
    height:         '100vh',
    width:          '100vw',
    backgroundColor: colors.kehe,
    transition:     'opacity 1s'
  },
  container: {
    height:          '95vh',
    width:           '95vw',
    backgroundColor: `rgba(250, 250, 250, 0.6)`,
    borderRadius:    '1em'
  },
  center: {
    width:  '80%',
    height: '60%',
  },
  logo_container: {
    height:    '20%',
    width:     '100%',
  },
  logo: {
    height:             '75%',
    width:              '100%',
    backgroundRepeat:   'no-repeat',
    backgroundPosition: 'center',
    backgroundSize:     'contain'
  },
  button_container: {
    width:          '80%',
    height:         '20%',
    display:        'flex',
    flexFlow:       'column',
    alignItems:     'center',
    justifyContent: 'center',
  },
  button: {
    padding:         20,
    color:           '#FFF',
    fontWeight:      'bold',
    fontSize:        26,
    backgroundColor: colors.blue_,
    width:           120,
    cursor:          'pointer',
    borderRadius:    '1em',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    textAlign:       'center',
    border:          '1px solid #FFF',
   // boxShadow:       `0 3px 7px rgba(0, 0, 0, 0.7)`,
    textShadow:      'none',
    transition:      'width 0.4s',
    '&:hover': {
      width: 155
    }
},
});

type InfoProps = {
  size:           number[];
  variant:        string;
  page:           string;
  lang:           string;
  dir:            string;
  setShowInfo:    (val: boolean) => void;
  setInfoVariant: (val: string) => void;
}

const Info: React.FC<InfoProps> = ({ size, variant, page, lang, dir, setShowInfo, setInfoVariant }) => {

  const classes = useStyles();

  const [opacity, setOpacity]   = React.useState(0)
  const [showMenu, setShowMenu] = React.useState(true)

   React.useEffect(() => {
  
    if (page === 'emotion_menu' ||
        page === 'gingel'       ||
        page === 'action_menu'  ||
        page === 'result_test'
     ) {
      setShowMenu(true)
    }
    else {
      setInfoVariant('general')
      setShowMenu(false)
    }

  },[page, setInfoVariant, setShowMenu])
 


  React.useEffect(() => {
     setTimeout(() => { setOpacity(1) },10)
  },[setOpacity])



  const text = React.useMemo(() => {
      return lang === 'eng' ? (textsEng[variant] || ['']) : (texts[variant] || [''])
  },[variant, lang])


  const handleClose = () => {
    setOpacity(0)
    setTimeout(() => { setShowInfo(false) },1000)
  }

return (
    <div 
      dir       = { dir }
      className = { clsx(classes.flex, classes.root) }
      style     = {{
        opacity: opacity
      }}
    >
       <div className = { clsx(classes.flex, classes.container) }>
            <div className = { clsx(classes.flex, classes.logo_container) }>
                <div className = { classes.logo }
                   style = {{
                      backgroundImage: `url(${ logo })`
                   }}
                />
            </div>
            <div className = { classes.center }>
                <Text 
                   showMenu = { showMenu }
                   text     = { text }
                   dir      = { dir }
                />
                <Menu 
                   size           = { size }
                   dir            = { dir }
                   lang           = { lang }
                   showMenu       = { showMenu }
                   page           = { page }
                   setShowMenu    = { setShowMenu }
                   setInfoVariant = { setInfoVariant }
                />
            </div>
            <div className = { classes.button_container }>
                <div 
                   className = { classes.button }
                   onClick   = { handleClose }
                >
                    { `${ words['continue'][lang] }` }
                </div>
            </div>
        </div>
    </div>
  );
}


export default Info