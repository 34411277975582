import React                            from 'react';
import VideoComponent                   from './VideoComponent';
//import Audio                            from '../../components/audio_component';
//import CompLoader                       from '../../components/comp_loader';
//import Hidden                           from './Hidden';
import colors                           from '../../utils/colors';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import { makeStyles }                   from '@material-ui/core/styles';
import { words }                        from '../../utils/dictionary';

const useStyles = makeStyles(theme => ({
  black: {
    backgroundColor: '#000',
    position:        'fixed',
    width:           '100vw',
    minHeight:       '100vh'
  },
    root: {
      backgroundPosition: 'center',
      backgroundSize:     'cover',
      backgroundRepeat:   'no-repeat',
      position:           'fixed',
      width:              '100vw',
      minHeight:          '100vh',
      transition:         'opacity 1s',
    },
    filter: {
      position:        'fixed',
      width:           '100%',
      minHeight:       '100%',
      backgroundColor: `rgba(0, 0, 0, 0.5)`,
    },
    text: {
      position:       'fixed',
      width:          '100vw',
      height:         '100vh',
      display:        'flex',
      alignItems:     'center',
      justifyContent: 'center',
      flexFlow:       'column',
      textAlign:      'center',
      color:          '#FFF',
      transition:     'all 1s',
      textShadow:     '0 0 7px rgba(0, 0, 0, 1)',
    },
    button: {
      marginTop:       60,
      width:           190,
      height:          90,
      display:         'flex',
      justifyContent:  'center',
      alignItems:      'center',
      transition:      'transform 1s, width 0.4s',
      border:          '1px solid #FFF',
      cursor:          'pointer',
      borderRadius:    '1em',
      backgroundColor: colors.pink,
      '&:hover': {
        width: 250
      }
    },
    icon: {
      color:    '#FFF',
      fontSize: 50
    }
}));

type StoryProps = {
  lang:                string;
  dir:                 string;
  background:          string;
  testAudio:           string;
  fullLengthVideo:     string[];
  size:                number[];
  audioEnd:            boolean;
  setPage:             (val: string) => void;
  setStation:          (val: string) => void;
  setAudioSrc:         (val: string) => void;
  setAudioEnd:         (val: boolean) => void;
  setShowNavBar:       (val: boolean) => void;
  setPauseAudio:       (val: boolean) => void; 
  setPlayAudio:        (val: boolean) => void;
  setShowAudioButtons: (val: boolean) => void;
}

const Story: React.FC<StoryProps> = ({ 
    size,
    lang,
    dir,
    background,
    testAudio,
    fullLengthVideo,
    audioEnd,
    setPage,
    setShowNavBar,
    setStation,
    setPauseAudio,
    setPlayAudio,
    setAudioSrc,
    setAudioEnd,
    setShowAudioButtons
  }) => {

  const classes = useStyles();

  const [start, setStart]               = React.useState(false);
  const [opacity, setOpacity]           = React.useState(false);
  const [playVid, setPlayVid]           = React.useState('pending');

  //const [hiddenLoaded, setHiddenLoaded] = React.useState(false);
  const [doOnce, setDoOnce]             = React.useState(false);

  React.useEffect(() => {
    if (audioEnd) {
      setPlayAudio(false);
      setPauseAudio(true);
    }
  },[audioEnd, setPlayAudio, setPauseAudio]) 


  React.useEffect(() => {
    if (doOnce) {
      return
    }
    setDoOnce(true)
    setTimeout(() => { 
      setPauseAudio(false)
    }, 850); 
  },[doOnce, setDoOnce, setPauseAudio]);


  React.useEffect(() => {
    setTimeout(() => { setOpacity(true); }, 10); 
  /*   if (hiddenLoaded) { 
      setTimeout(() => { setPlayAudio(true); }, 850); 
    } */
  },[/* hiddenLoaded,  */setOpacity]);

  const handlePlayVideo = () => {
    setShowNavBar(false);
    setStart(true);
    setTimeout(() => {
      setPlayVid('play')
    },20)
  }
 
  const handleGoToNextPage = () => {
      setAudioSrc(testAudio)
      setAudioEnd(false)
      setPauseAudio(false)
      setTimeout(() => {
        setPlayAudio(true)
      },10)
      setTimeout(() => {
        setPauseAudio(true)
      },20)
      setTimeout(() => {
        setShowAudioButtons(true)
        setStation('result_test') 
        setPage('result_test') 
      },1000) 
  }


  return (
    <div className = { classes.black }>
        <div
          className = { classes.root }
          style = {{
            backgroundImage: start ? 'none' : `url(${ background })`,
            opacity:         opacity ? 1 : 0
        }}
        >
          <div className = { classes.filter }>
            {/*   {
                !hiddenLoaded && <CompLoader variant = 'page' lang = { lang }/>
              } */}
                <>
                  <VideoComponent
                      videoCompSrcArr    = { fullLengthVideo }
                      start              = { start}
                      setStart           = { setStart }
                      playVid            = { playVid }
                      setPlayVid         = { setPlayVid }
                      handleGoToNextPage = { handleGoToNextPage }
                  />
                  <div
                      dir       = { dir }
                      className = { classes.text }
                      style     = {{
                        fontSize:  size[0] > 800 ? 34 : 20,
                        transform: `translate(0px, ${ opacity /* && hiddenLoaded */ && playVid === 'pending' ? 0 : 150 }vh )`,
                        opacity:   opacity && playVid === 'pending' ? 1 : 0
                      }}
                    >
                      { !start && `${ words['story_title'][lang] }` }
                      {
                        !start
                        &&
                        (
                          <div
                            className = { classes.button }
                            onClick   = { handlePlayVideo }
                            style     = {{
                                cursor: start ? 'default' : 'pointer',
                                transform: `translate(0px, ${ /* hiddenLoaded && */ playVid === 'pending' ? 0 : 150 }vh )`,
                            }}
                          >
                            <PlayCircleFilledWhiteRoundedIcon className = { classes.icon }/>
                          </div>
                        )
                      }
                    </div>
                {/*     <Audio
                      audioSrc         = { storyAudio }
                      playAudio        = { playAudio }
                      pauseAudio       = { pauseAudio }
                      muteAudio        = { false }
                      setPauseAudio    = { setPauseAudio }
                      handleEndAudio   = { handleEndAudio }
                      setPlayAudio     = { setPlayAudio }
                      showButtons
                    /> */}
                </>
        </div>
      </div>
      {/* <Hidden audioSrc = { storyAudio } setHiddenLoaded = { setHiddenLoaded }/> */}
   </div>
  )
}

export default Story



















