import React              from 'react';

import lock               from '../../graphic_components/lock.png';
import crown              from '../../graphic_components/crown_golden.png';
import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';

import { stationArr }     from '../../utils';
import { words }          from '../../utils/dictionary';

import { makeStyles }     from '@material-ui/core/styles';

var clsx = require('classnames')

const useStyles = makeStyles({
  root: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',  
  },
  container: {
    width:          '100%',
    height:         '100%',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column'
  },
  title: {
    width:          '100%',
    height:         '20%',
    color:          '#FFF',
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    flexFlow:       'column',
    textAlign:      'center'
  },
  image: {
    backgroundRepeat:   'no-repeat',
    backgroundPosition: 'center',
    width:              '80%',
    height:             '80%',
    borderRadius:       '1em',
    overFlow:           'hidden',
  },
  mark: {
    animation: 'puls 1s infinite'
  },
  arrow: {
    color: '#FFF'
  },
});

type ImageProps = {
  iconSize:  number;
  lang:      string;
  dir:       string;
  variant:   string;
  station:   string;
  sitImg:    string;
}

const Image: React.FC<ImageProps> = ({ 
  iconSize,
  lang,
  dir,
  variant,
  station,
  sitImg,
}) => {

  const classes = useStyles();

  const [imgSrc, text] = React.useMemo(() => {

    if (variant === 'situation') {
        return [sitImg, `${ words['situation'][lang] }`];
    }
    if (variant === 'heart1' || variant === 'heart2') {
      return [lock, `${ words['emotion_scale'][lang] }`];
    }
    return [crown, `${ words['conclusion'][lang] }`];

  },[variant, sitImg, lang])


  const mark = React.useMemo(() => {
    if (
       ((variant === 'situation') && (station === 'home' || station === 'situation'))
       ||
       ((variant === 'heart1') && station === 'first_emotion_menu')
       ||
       ((variant === 'heart2') && station === 'second_emotion_menu')
       ||
       ((variant === 'lock') && station === 'result_test')
    )
    {
      return true
    }
    return false
  },
  [variant, station])


  const opacity = React.useMemo(() => {
    if (
      (variant === 'situation' && stationArr.indexOf(station) >=2)
      ||
      (variant === 'heart1' && stationArr.indexOf(station) >= 3)
      ||
      (variant === 'heart2' && stationArr.indexOf(station) === 6) 
    )
    {
      return 0.3
    }
    return 1
  },
  [variant, station])
 
  return (
    <div 
       className = { classes.root }
       style     = {{
        width:   iconSize + 30,
        height:  iconSize,
        opacity: opacity
       }}
    >
        <div className = { classes.container }>
              <div className = { classes.title }>
                  { text }
              </div>
              <div className = { mark ? clsx(classes.image, classes.mark) : classes.image }
                style     = {{
                    backgroundImage: `url(${ imgSrc })`,
                    backgroundSize:  variant === 'situation' ? 'cover' : 'contain',
                }}
              />
        </div>
        <ForwardRoundedIcon 
          className = { classes.arrow }
          style     = {{
            opacity: variant === 'lock' ? 0 : 1
          }}
        />
    </div>
  );
}


export default Image