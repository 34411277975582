export const stationArr = [
  'home',
  'situation_menu',
  'first_emotion_menu',
  'action_menu',
  'result',
  'second_emotion_menu',
  'result_test'
];

export const pageArr = [
  'home',
  'situation_menu',
  'emotion_menu',
  'action_menu',
  'action_menu',
  'emotion_menu',
  'result_test'
];

